import { createSelector } from "reselect";

import { getTagsEntities } from "redux/entities/selectors";
import { buildFilterQuery } from 'Utils/filters';

const selectTagsState = state => state.tags;

// SHOW
export const makeGetTag = () =>
  createSelector(
    getTagsEntities,
    (_, tagId) => tagId,
    (entities, id) => entities[id]
  );

export const getTagLoading = createSelector(selectTagsState, state => {
  return state.tagLoading || false;
});
export const getTagLoaded = createSelector(selectTagsState, state => {
  return state.tagLoaded || false;
});
export const getTagError = createSelector(selectTagsState, state => {
  return state.tagError || null;
});

// INDEX
export const getLoadedQueries = createSelector(selectTagsState, state => {
  return state.loadedQueries || false;
});

export const getTotalCount = createSelector(selectTagsState, state => {
  return state.totalCount || false;
})

export const getTotalPages = createSelector(selectTagsState, state => {
  return state.totalPages || null;
});

export const selectFilteredItems = (state, filters) => {
  const tags = selectTagsState(state);

  return tags[buildFilterQuery(filters, 'tags')];
};

export const getTags = createSelector(
  (state, { filter, pageNumber }) =>
    selectFilteredItems(state, {
      filter,
      pageNumber
    }) || [],
  state => getTagsEntities(state) || {},
  (ids, tags) => ids.map(id => tags[id])
);

export const getLoading = createSelector(selectTagsState, state => {
  return state.loading || false;
});

export const getLoaded = createSelector(selectTagsState, state => {
  return state.loaded || false;
});

export const getError = createSelector(selectTagsState, state => {
  return state.error || null;
});

// CREATE
export const getCreateTagLoading = createSelector(selectTagsState, state => {
  return state.isSubmitting || false;
});

export const getCreateTagSuccess = createSelector(selectTagsState, state => {
  return state.wasSuccessful || false;
});
