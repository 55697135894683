import { createSelector } from 'reselect';

const selectEntitiesState = (state) => state?.entities;

export const getNotificationsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.notifications || [];
  }
);

export const getDashboardEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.dashboard || [];
  }
);

export const getAppointmentsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.appointments || [];
  }
);

export const getProvidersEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.providers || [];
  }
);

export const getLeadsEntities = createSelector(selectEntitiesState, (state) => {
  return state?.leads || [];
});

export const getProviderContactsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.providerContacts || {};
  }
);

export const getConversationsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.conversations || [];
  }
);

export const getInteractionsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.interactions || [];
  }
);

export const getInvoicesEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.invoices || [];
  }
);

export const getActivitiesEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.activities || [];
  }
);

export const getClientsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.clients || [];
  }
);

export const getAdminEntities = createSelector(selectEntitiesState, (state) => {
  return state?.admins || [];
});

export const getServicesEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.services || [];
  }
);

export const getCandidateLeadsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.candidateLeads || [];
  }
);

export const getProviderServicesEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.providerServices || [];
  }
);

export const getRatingsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.ratings || [];
  }
);

export const getTeamsEntities = createSelector(selectEntitiesState, (state) => {
  return state?.teams || [];
});

export const getTagsEntities = createSelector(selectEntitiesState, (state) => {
  return state?.tags || [];
});

export const getVisitsEntities = createSelector(
  selectEntitiesState,
  (state) => {
    return state?.visits || [];
  }
);
