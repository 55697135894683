import { ACTION_TYPES } from './actions';
import { statusStrings } from 'utils/appointmentStatus';

const initialState = {
  settings: [],
  settingsLoading: false,
  settingsLoaded: false,
  settingsError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SETTINGS:
      return {
        ...state,
        settingsLoading: true,
      };
    case ACTION_TYPES.GET_SETTINGS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const settings = {
        admins: Object.entries(action.payload.admins).map((admin) => {
          return { label: admin[0], value: admin[1] };
        }),
        appointment_status: Object.entries(
          action.payload.appointment_status
        ).map((status) => {
          return { label: statusStrings[status[0]], value: status[1], original_label: status[0] };
        }),
        status: Object.entries(action.payload.provider_status).map((status) => {        
          return { label: status[0], value: status[1] };
        }),
        candidate_lead_status: Object.entries(
          action.payload.candidate_lead_status
        ).map((status) => {
          return { label: status[0], value: status[1] };
        }),
        cities: Object.entries(action.payload.cities).map((city) => {
          return { label: city[0], value: city[1] };
        }),
        services: Object.entries(action.payload.services).map((service) => {
          return { label: service[0], value: service[1] };
        }),
        newsletter: [
          { label: 'Activo', value: true },
          { label: 'Inactivo', value: false },
        ],
        recurring: [
          { label: 'Recorrentes', value: true },
          { label: 'Data Única', value: false },
        ],
        is_premium: [{ label: 'Premium', value: 'true' }],
        distance: [{ label: 'A Menos de 8k', value: true }],
        availability: [{ label: 'Disponível', value: true }],
      };
      
      return {
        ...state,
        settings: settings,
        settingsLoading: false,
        settingsError: false,
        settingsLoaded: true,
      };
    case ACTION_TYPES.GET_SETTINGS_FAIL:
      return {
        ...state,
        settingsError: action.error,
        settingsLoading: false,
        settingsLoaded: true,
      };

    default:
      return state;
  }
};
