import React from 'react';
import PropTypes from 'prop-types';

import useAdminsListing from 'Hooks/admins/useAdminsListing';

import AvatarLabel from 'Components/AvatarLabel';
import { ErrorText, Body } from 'Components/Text';
import StyledSelect, { SelectContainer, selectStyles } from './style';

const Select = ({
  error,
  placeholder,
  options = [],
  label,
  onChange,
  isDisabled,
  defaultValue,
  overrideStyles,
  isMenuOpen,
  isFullWidth,
  value,
  styleProps,
  selectType,
  isMulti
}) => {
  // TODO Add different color to placeholder
  const isAdminSelect = selectType === "admin"
  const { admins } = useAdminsListing({
    shouldFetch: isAdminSelect,
    filters: { show_all: true }
  });

  const adminOptions = isAdminSelect &&
    admins?.map((admin) => {
      return {
        label: <AvatarLabel userId={admin?.id} />,
        value: admin?.id,
      }
    })
  const currentAdmin = isAdminSelect &&
    adminOptions?.find(a => { return a.value === value })

  const getOptions = () => {
    if (!isAdminSelect) {
      return options
    }

    return options.length > 0 ? options : adminOptions
  }

  return (
    <SelectContainer error={error} isFullWidth={isFullWidth}>
      {label && <Body>{label}</Body>}
      <StyledSelect
        isMulti={isMulti}
        onChange={onChange}
        isDisabled={isDisabled}
        styles={overrideStyles || selectStyles}
        options={getOptions()}
        error={error}
        defaultValue={defaultValue}
        placeholder={placeholder}
        defaultMenuIsOpen={isMenuOpen}
        value={isAdminSelect ? currentAdmin : value}
        styleProps={styleProps}
        selectType={selectType}
      />
      <ErrorText error={error}>{error}</ErrorText>
    </SelectContainer>
  );
};

Select.propTypes = {
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  overrideStyles: PropTypes.object,
  isMenuOpen: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectType: PropTypes.oneOf(["team", "admin", "tag"])
};

export default Select;
