import appointmentsClient from 'Services/appointmentsService';


const REDUCER = 'appointments';
export const ACTION_TYPES = {
  GET_APPOINTMENTS: `${REDUCER}/GET_APPOINTMENTS`,
  GET_APPOINTMENTS_SUCCESS: `${REDUCER}/GET_APPOINTMENTS_SUCCESS`,
  GET_APPOINTMENTS_FAIL: `${REDUCER}/GET_APPOINTMENTS_FAIL`,
  
  GET_APPOINTMENT: `${REDUCER}/GET_APPOINTMENT`,
  GET_APPOINTMENT_SUCCESS: `${REDUCER}/GET_APPOINTMENT_SUCCESS`,
  GET_APPOINTMENT_FAIL: `${REDUCER}/GET_APPOINTMENT_FAIL`,
  CREATE_NEW_APPOINTMENT: `${REDUCER}/CREATE_NEW_APPOINTMENT`,
  CREATE_NEW_APPOINTMENT_SUCCESS: `${REDUCER}/CREATE_NEW_APPOINTMENT_SUCCESS`,
  CREATE_NEW_APPOINTMENT_FAIL: `${REDUCER}/CREATE_NEW_APPOINTMENT_FAIL`,
  CREATE_RECURRING_APPOINTMENT: `${REDUCER}/CREATE_RECURRING_APPOINTMENT`,
  CREATE_RECURRING_APPOINTMENT_SUCCESS: `${REDUCER}/CREATE_RECURRING_APPOINTMENT_SUCCESS`,
  CREATE_RECURRING_APPOINTMENT_FAIL: `${REDUCER}/CREATE_RECURRING_APPOINTMENT_FAIL`,
  UPDATE_APPOINTMENT: `${REDUCER}/UPDATE_APPOINTMENT`,
  UPDATE_APPOINTMENT_SUCCESS: `${REDUCER}/UPDATE_APPOINTMENT_SUCCESS`,
  UPDATE_APPOINTMENT_FAIL: `${REDUCER}/UPDATE_APPOINTMENT_FAIL`,
  REMOVE_APPOINTMENT_PROVIDER: `${REDUCER}/REMOVE_APPOINTMENT_PROVIDER`,
  REMOVE_APPOINTMENT_PROVIDER_SUCCESS: `${REDUCER}/REMOVE_APPOINTMENT_PROVIDER_SUCCESS`,
  REMOVE_APPOINTMENT_PROVIDER_FAIL: `${REDUCER}/REMOVE_APPOINTMENT_PROVIDER_FAIL`,
  DUPLICATE_APPOINTMENT: `${REDUCER}/DUPLICATE_APPOINTMENT`,
  DUPLICATE_APPOINTMENT_SUCCESS: `${REDUCER}/DUPLICATE_APPOINTMENT_SUCCESS`,
  DUPLICATE_APPOINTMENT_FAIL: `${REDUCER}/DUPLICATE_APPOINTMENT_FAIL`
};

const getAppointments = (dispatch, query) => {
  dispatch({
    type: ACTION_TYPES.GET_APPOINTMENTS,
    query: query,
  });
  appointmentsClient
    .fetchAppointments(query)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_APPOINTMENTS_SUCCESS,
          payload: res.data,
          query: query,
          meta: res.data.meta
        })
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_APPOINTMENTS_FAIL,
        query: query,
        error: e,
      })
    })
}

const getAppointment = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_APPOINTMENT
  });
  appointmentsClient
    .getAppointment(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_APPOINTMENT_SUCCESS,
          payload: res.data,
          form: res.data?.included?.find(d => d.type === 'form')?.attributes
            ?.questions?.questions,
          answers: res.data?.included?.find(d => d.type === 'lead')?.attributes
            ?.form_answers
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_APPOINTMENT_FAIL,
        error: 'Error getting Appointment'
      });
    });
};

const updateAppointment = (
  dispatch,
  payload,
  appointmentId,
  callbacks = {}
) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_APPOINTMENT
  });
  const { error, success } = callbacks;
  appointmentsClient
    .updateAppointment(payload, appointmentId)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_APPOINTMENT_SUCCESS,
          payload: res.data,
          answers: res.data?.included?.find(d => d.type === 'lead')?.attributes
            ?.form_answers
        });
        if (success) {
          success();
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_APPOINTMENT_FAIL,
        error: 'Error updating appointment'
      });
      if (error) {
        const errorData = e.response.data?.meta?.message;
        if (errorData.email) {
          error('Este email já está em uso');
        } else {
          error('Ocorreu um erro a actualizar o pedido');
        }
      }
    });
};

const duplicateAppointment = (
  dispatch,
  appointmentId,
  callbacks = {}
) => {
  dispatch({
    type: ACTION_TYPES.DUPLICATE_APPOINTMENT,
  });
  const { success } = callbacks;
  appointmentsClient
  .duplicateAppointment(appointmentId)
  .then(res => {
    if (res && res.data) {
      dispatch({
        type: ACTION_TYPES.DUPLICATE_APPOINTMENT_SUCCESS,
        payload: res.data
      });
    }
    if (success) {
      success();
    }
  })
  .catch(e => {
    dispatch({
      type: ACTION_TYPES.DUPLICATE_APPOINTMENT_FAIL,
      error: 'Error duplicating appointment'
    });
  });  
};

const createNewAppointment = (dispatch, data, cb = {}) => {
  dispatch({
    type: ACTION_TYPES.CREATE_NEW_APPOINTMENT
  });
  const { error, success } = cb;
  appointmentsClient
    .createNewAppointment(data)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_NEW_APPOINTMENT_SUCCESS,
          payload: res.data
        });
        if (success) {
          success();
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_NEW_APPOINTMENT_FAIL,
        error: e.message
      });
      if (error) {
        error('Ocorreu um erro a criar o pedido');
      }
    });
};

const removeAppointmentProvider = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER
  });
  appointmentsClient
    .removeAppointmentProvider(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER_SUCCESS,
          payload: res.data
        });
        dispatch({
          type: ACTION_TYPES.UPDATE_APPOINTMENT_SUCCESS,
          payload: res.data,
          answers: res.data?.included?.find(d => d.type === 'lead')?.attributes
            ?.form_answers
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER_FAIL,
        error: e.message
      });
    });
};

export {
  // getAppointmentsListing,
  getAppointment,
  updateAppointment,
  createNewAppointment,
  removeAppointmentProvider,
  getAppointments,
  duplicateAppointment
};
