import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/settings';

const useSettings = () => {
  const dispatch = useDispatch();
  const { getSettings } = actions;

  const { getAllSettings, getLoading, getError, getLoaded } = selectors;

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state));
  const loaded = useSelector(state => getLoaded(state));

  const settings = useSelector(state => getAllSettings(state));

  useEffect(() => {
    if (!loaded && !loading) {
      getSettings(dispatch);
    }
  }, [dispatch, getSettings, loaded, loading]);

  return {
    settings,
    error,
    loaded,
    loading
  };
};

export default useSettings;
