import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'redux/invoices';

import { buildFilterQuery } from 'Utils/filters';

const useInvoicesListing = ({ filters = {}, shouldFetch = false } = {}) => {
  const dispatch = useDispatch();
  const {
    getInvoicesListing,
    getProviderInvoices,
    patchProviderInvoices,
  } = actions;
  const {
    getInvoices,
    getInvoicesLoading,
    getInvoicesError,
    getInvoicesLoaded,
    getLoadedQueries,
    getInvoicesFromProvider,
  } = selectors;

  const providerId = filters?.provider_id;

  const invoices = useSelector((state) =>
    getInvoices(state, { filter: filters })
  );

  const providerInvoices = useSelector((state) =>
    getInvoicesFromProvider(state)
  );

  const error = useSelector((state) => getInvoicesError(state));
  const loading = useSelector(
    (state) => getInvoicesLoading(state),
    shallowEqual
  );
  const loaded = useSelector((state) => getInvoicesLoaded(state), shallowEqual);

  const loadedQueries = useSelector((state) => getLoadedQueries(state));

  const key = buildFilterQuery({ filter: filters }, 'invoices');
  const queryState = loadedQueries[key];

  const dispatchGetInvoicesListing = useCallback(
    (filters) => {
      getInvoicesListing(dispatch, filters);
    },
    [dispatch, getInvoicesListing]
  );

  const dispatchUpdateProvideInvoice = useCallback(
    (id) => {
      patchProviderInvoices(dispatch, id);
    },
    [dispatch, patchProviderInvoices]
  );

  useEffect(() => {
    if (shouldFetch && !queryState?.loaded && !loading && providerId) {
      return getProviderInvoices(dispatch, providerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    if (error) return;
    if (shouldFetch && !queryState?.loaded && !loading && !providerId) {
      return getInvoicesListing(dispatch, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getInvoicesListing, filters, shouldFetch, loading]);

  return {
    invoices,
    providerInvoices,
    getInvoicesListing: dispatchGetInvoicesListing,
    updateProviderInvoice: dispatchUpdateProvideInvoice,
    error,
    loading,
    loaded,
  };
};

export default useInvoicesListing;
