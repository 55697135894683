export const getCandidateStatusOptions = (t, currentStatus) => {
  switch (currentStatus) {
    case 'new_candidate':
      return [
        {
          label: t('analysis'),
          value: 1,
          default: true
        },
        {
          label: t('rejected'),
          value: 5
        },
        {
          label: t('closed'),
          value: 4
        }
      ];
    case 'analysis':
      return [
        {
          label: t('accepted'),
          value: 6,
          default: true
        },
        {
          label: t('awaiting_details'),
          value: 2
        },
        {
          label: t('closed'),
          value: 4
        },
        {
          label: t('rejected'),
          value: 5
        }
      ];
    case 'awaiting_details':
      return [
        {
          label: t('rejected'),
          value: 5
        },
        {
          label: t('closed'),
          value: 4
        },
        {
          label: t('accepted'),
          value: 6,
          default: true
        }
      ];
    case 'reopened':
      return [
        {
          label: t('accepted'),
          value: 6
        },
        {
          label: t('awaiting_details'),
          value: 2,
          default: true
        },
        {
          label: t('closed'),
          value: 4
        },
        {
          label: t('rejected'),
          value: 5
        }
      ];
    case 'closed':
      return [
        {
          label: t('reopened'),
          value: 3,
          default: true
        }
      ];
    default:
      return [
        {
          label: 'disabled',
          value: true,
          default: true,
          disabled: true
        }
      ];
  }
};

export const getValueFromStatusString = status => {
  switch (status) {
    case 'new_candidate':
      return 0;
    case 'analysis':
      return 1;
    case 'awaiting_details':
      return 2;
    case 'reopened':
      return 3;
    case 'closed':
      return 4;
    case 'rejected':
      return 5;
    case 'accepted':
      return 6;
    default:
      return null;
  }
};

export const getValueFromDestinationCol = destinationCol => {
  switch (destinationCol) {
    case 'closed':
      return 4;
    case 'new_candidate':
      return 0;
    case 'analysis':
      return 1;
    case 'awaiting_details':
      return 2;
    case 'reopened':
      return 3;
    case 'accepted':
      return 6;
    case 'rejected':
      return 5;
    default:
      return null;
  }
};

export const isColumnValid = (status, column) => {
  switch (column) {
    case 'closed':
      return [
        'new_candidate',
        'awaiting_details',
        'analysis',
        'reopened'
      ].includes(status);
    case 'new_candidate':
      return false;
    case 'analysis':
      return status === 'new_candidate';
    case 'awaiting_details':
      return ['analysis', 'reopened'].includes(status);
    case 'reopened':
      return status === 'closed';
    case 'accepted':
      return ['awaiting_details', 'analysis'].includes(status);
    case 'rejected':
      return [
        'new_candidate',
        'awaiting_details',
        'analysis',
        'reopened'
      ].includes(status);
    default:
      return false;
  }
};
