import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../../features/polyglot';

import useTeam from 'Hooks/teams/useTeam';

import { ACTION_TYPES as TEAM_ACTON_TYPES } from 'redux/teams/actions';

import { Col, Row, TextInput, Button } from 'ui-55';

import { ButtonContainer } from 'Pages/Team/styles';

const NewTeamRender = ({ handleToggleModal }) => {
  const t = useTranslate('team');
  const dispatch = useDispatch();

  const [name, setName] = useState('');

  const { createTeam, createTeamSuccess } = useTeam();

  const handleCreateTeam = () => {
    if (name.length) {
      const payload = { name: name };
      createTeam(payload);
    }
  };

  useEffect(() => {
    if (createTeamSuccess) {
      handleToggleModal();
      dispatch({
        type: TEAM_ACTON_TYPES.CLEAR_TEAM_SUCCESS
      });
    }
  }, [createTeam, createTeamSuccess, dispatch, handleToggleModal]);

  return (
    <>
      <Row>
        <Col size={12}>
          <TextInput
            label={t('teamName')}
            defaultValue={name}
            onChange={v => setName(v)}
            isFullWidth
          />
        </Col>
      </Row>

      <ButtonContainer>
        <Button
          btnType='borded'
          text={t('cancel')}
          isFullWidth
          action={() => handleToggleModal()}
        />
        <Button
          btnType='primary'
          text={t('createTeam')}
          isFullWidth
          action={handleCreateTeam}
        />
      </ButtonContainer>
    </>
  );
};

export default NewTeamRender;
