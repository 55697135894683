import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import { makeGetProviderService } from 'Redux/providerService/selectors';

import { GridCol, Badge, StarsRating, Row } from 'ui-55';
import StyledProviderServiceCard, {
  HeadingDetail,
  SmallBodyDetail,
  RatingContainer,
  BadgeContainer
} from './style';

const ProviderServiceCard = ({ providerServiceId, settings }) => {
  const t = useTranslate('specialists');
  const b = useTranslate('badges');

  const makeProviderService = useMemo(makeGetProviderService, []);
  const providerService = useSelector(state =>
    makeProviderService(state, providerServiceId)
  );

  const serviceData = settings?.services?.filter(
    s => s.value === providerService?.attributes?.serviceId
  )[0];

  const serviceType = providerService?.attributes?.serviceType || 'standard';

  return (
    <StyledProviderServiceCard>
      <GridCol size={8}>
        <BadgeContainer>
          <Badge translate={b} text={serviceType} status={serviceType} />
        </BadgeContainer>
        <HeadingDetail size={6}>{serviceData?.label}</HeadingDetail>
      </GridCol>

      <GridCol size={4} padding={0}>
        <RatingContainer>
          <SmallBodyDetail grey={true}>{t('rating')}</SmallBodyDetail>
          <Row padding={0}>
            <StarsRating
              defaultRating={providerService?.attributes?.averageRating}
              starSize={13}
            />
            <SmallBodyDetail grey={true}>
              {providerService?.attributes?.averageRating}
            </SmallBodyDetail>
          </Row>
        </RatingContainer>

        <SmallBodyDetail grey={true}>{t('totalServices')}</SmallBodyDetail>
        <SmallBodyDetail>
          {providerService?.attributes?.providerServiceCount}
        </SmallBodyDetail>
      </GridCol>
    </StyledProviderServiceCard>
  );
};

export default ProviderServiceCard;

ProviderServiceCard.propTypes = {
  settings: PropTypes.object,
  providerServiceId: PropTypes.string
};
