import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from 'Redux/providerService';

const useProviderService = ({ providerServiceId } = {}) => {
  const {
    // show
    makeGetProviderService
  } = selectors;

  // SHOW
  const makeProviderService = useMemo(makeGetProviderService, []);
  const providerService = useSelector(state =>
    makeProviderService(state, providerServiceId)
  );

  return {
    // show
    providerService
  };
};

export default useProviderService;
