import authClient from 'Services/authService';
import { setToken } from 'features/authUser/authUserSlice';
import { get } from 'lodash';

const REDUCER = 'auth';
export const ACTION_TYPES = {
  LOGIN_USER: `${REDUCER}/LOGIN_USER`,
  LOGIN_USER_FAIL: `${REDUCER}/LOGIN_USER_FAIL`,
  LOGIN_USER_SUCCESS: `${REDUCER}/LOGIN_USER_SUCCESS`,
  LOGOUT_USER: `${REDUCER}/LOGOUT_USER`,
  LOGOUT_USER_SUCCESS: `${REDUCER}/LOGOUT_USER_SUCCESS`,
  LOGOUT_USER_FAIL: `${REDUCER}/LOGOUT_USER_FAIL`,
  ACCEPT_INVITATION: `${REDUCER}/ACCEPT_INVITATION`,
  ACCEPT_INVITATION_SUCCESS: `${REDUCER}/ACCEPT_INVITATION_SUCCESS`,
  ACCEPT_INVITATION_FAIL: `${REDUCER}/ACCEPT_INVITATION_FAIL`,
  UPDATE_USER: `${REDUCER}/UPDATE_USER`,
  UPDATE_USER_SUCCESS: `${REDUCER}/UPDATE_USER_SUCCESS`,
  UPDATE_USER_FAIL: `${REDUCER}/UPDATE_USER_FAIL`
};

const loginUser = (dispatch, { email, password, callback }) => {
  dispatch({
    type: ACTION_TYPES.LOGIN_USER
  });
  authClient
    .postUserLogin({ email, password })
    .then(res => {
      const authorizationHeader = get(res, 'headers.authorization');
      if (authorizationHeader) {
        dispatch(setToken(authorizationHeader.split(' ')[1]));
      }
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.LOGIN_USER_SUCCESS,
          payload: res.data
        });
        if (callback.success) {
          callback.success();
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.LOGIN_USER_FAIL,
        error: e.response.data
      });
      if (callback.fail) {
        callback.fail(e.response.data);
      }
    });
};

const logoutUser = (dispatch, { callback }) => {
  dispatch({
    type: ACTION_TYPES.LOGOUT_USER
  });

  authClient
    .deleteUserLogout()
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.LOGOUT_USER_SUCCESS
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.LOGOUT_USER_FAIL,
        error: 'Error logging out'
      });
    })
    .finally(callback);
};

const acceptInvitation = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.ACCEPT_INVITATION
  });

  authClient
    .acceptInvitation(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.ACCEPT_INVITATION_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.ACCEPT_INVITATION_FAIL,
        error: 'Error accepting invitation'
      });
    });
};
const updateUser = (dispatch, payload, callback) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_USER
  });

  authClient
    .updateUser(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_USER_SUCCESS,
          payload: res.data
        });
        if (callback) {
          callback(res);
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_USER_FAIL,
        error: 'Error updating user'
      });
    });
};

export { loginUser, logoutUser, acceptInvitation, updateUser };
