import providersClient from 'Services/providersService';

const REDUCER = 'providers';
export const ACTION_TYPES = {
  GET_PROVIDERS: `${REDUCER}/GET_PROVIDERS`,
  GET_PROVIDERS_SUCCESS: `${REDUCER}/GET_PROVIDERS_SUCCESS`,
  GET_PROVIDERS_FAIL: `${REDUCER}/GET_PROVIDERS_FAIL`,

  GET_PROVIDER: `${REDUCER}/GET_PROVIDER`,
  GET_PROVIDER_SUCCESS: `${REDUCER}/GET_PROVIDER_SUCCESS`,
  GET_PROVIDER_FAIL: `${REDUCER}/GET_PROVIDER_FAIL`,
  CREATE_PROVIDER: `${REDUCER}/CREATE_PROVIDER`,
  CREATE_PROVIDER_SUCCESS: `${REDUCER}/CREATE_PROVIDER_SUCCESS`,
  CREATE_PROVIDER_FAIL: `${REDUCER}/CREATE_PROVIDER_FAIL`,
  UPDATE_PROVIDER: `${REDUCER}/UPDATE_PROVIDER`,
  UPDATE_PROVIDER_SUCCESS: `${REDUCER}/UPDATE_PROVIDER_SUCCESS`,
  UPDATE_PROVIDER_FAIL: `${REDUCER}/UPDATE_PROVIDER_FAIL`,
  UPDATE_PROVIDER_FORM: `${REDUCER}/UPDATE_PROVIDER_FORM`,
  UPDATE_PROVIDER_FORM_SUCCESS: `${REDUCER}/UPDATE_PROVIDER_FORM_SUCCESS`,
  UPDATE_PROVIDER_FORM_FAIL: `${REDUCER}/UPDATE_PROVIDER_FORM_FAIL`,
};

const updateProviderForm = (dispatch, payload, id, callback) => {
  const { success, error } = callback;
  dispatch({
    type: ACTION_TYPES.UPDATE_PROVIDER_FORM,
    payload,
  });
  providersClient
    .updateProviderForm(payload, id)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_PROVIDER_FORM_SUCCESS,
          payload: res.data,
        });
        if (success) {
          success();
        }
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROVIDER_FORM_FAIL,
        errors: 'An error ocurred while updating your form',
      });
      if (error) {
        const errorData = e.response.data?.meta?.message;
        if (errorData.email) {
          error('Este email já está em uso');
        } else {
          error('Ocorreu um erro a actualizar especialista');
        }
      }
    });
};

// index
const getProviders = (dispatch, query) => {
  dispatch({
    type: ACTION_TYPES.GET_PROVIDERS,
    query: query,
  });
  providersClient
    .fetchProviders(query)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_PROVIDERS_SUCCESS,
          payload: res.data,
          query: query,
          meta: res.data.meta,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_PROVIDERS_FAIL,
        query: query,
        error: e,
      });
    });
};

const getProvider = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_PROVIDER,
  });

  providersClient
    .getProvider(id)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_PROVIDER_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_PROVIDER_FAIL,
        error: 'Error gething provider',
      });
    });
};

const updateProvider = (dispatch, id, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PROVIDER,
  });
  providersClient
    .updateProvider(id, payload)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_PROVIDER_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROVIDER_FAIL,
        error: e,
      });
    });
};

const createProvider = (val, dispatch, cb = {}) => {
  dispatch({
    type: ACTION_TYPES.CREATE_PROVIDER,
  });
  const { error, success } = cb;
  providersClient
    .createProvider(val)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_PROVIDER_SUCCESS,
          payload: res.data,
        });
        if (success) {
          success();
        }
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.CREATE_PROVIDER_FAIL,
        error: 'Error creating provider',
      });
      if (error) {
        const errorData = e.response.data?.meta?.message;
        if (errorData.email) {
          error('Este email já está em uso');
        } else {
          error('Ocorreu um erro com este pedido');
        }
      }
    });
};

export {
  getProviders,
  getProvider,
  updateProvider,
  updateProviderForm,
  createProvider,
};
