import styled from 'styled-components';
import {
  Row,
  Col,
  SmallBody,
  Body,
  SubHeading,
  ErrorText,
} from 'ui-55';

export const StyledInputError = styled(ErrorText)`
  margin: 0px;
  font-size: 12px;
  line-height: 0px;
  position: relative;
  top: 5px;
`;

export const StyledInput = styled.input`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: none;
  padding: 0px;
  outline: none;
`;

export const StyledButton = styled.button.attrs({ type: 'button' })``;

const StyledServiceDetailCard = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;

  > div {
    display: flex;
    justify-content: center;
  }
`;

export const SubHeadingDetail = styled(SubHeading)`
  margin-left: 24px;
  margin-bottom: 14px;
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;
  .hour-picker {
    margin: 0;
    max-width: 250px;
    p {
      display: none;
    }
    > div {
      min-height: unset;
      max-width: 100px;
      margin-top: 16px;
      margin-right: 16px;
    }
    img {
      max-width: 150px;
    }
  }
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.lightBeige};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;
  }
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${(props) => props.theme.colors.grey};
  margin: 0px;
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
`;

export const RowDetail = styled(Row)`
  border-bottom: ${(props) =>
    props.position === 'top'
      ? `1px solid ${props.theme.colors.mediumBeige}`
      : 'none'};
`;

export const ColDetail = styled(Col)`
  padding-left: 24px;
  flex-flow: row nowrap;
`;

export const Iva = styled(SmallBodyDetail)`
    margin: 5px 0px 0px 5px;
}
`;

export default StyledServiceDetailCard;
