import { produce } from 'immer';
import normalize from 'json-api-normalizer';

import { ACTION_TYPES } from './actions';

import { ACTION_TYPES as APPOINTMENT_ACTION_TYPES } from '../appointments/actions';
import { ACTION_TYPES as PROVIDER_ACTION_TYPES } from '../providers/actions';
import { ACTION_TYPES as CLIENT_ACTION_TYPES } from '../clients/actions';
import { ACTION_TYPES as CANDIDATE_LEAD_ACTION_TYPES } from '../candidateLeads/actions';
import { ACTION_TYPES as NOTIFICATIONS_ACTION_TYPES } from '../notifications/actions';
import { ACTION_TYPES as SERVICES_ACTION_TYPES } from '../services/actions';
import { ACTION_TYPES as ACTIVITY_ACTION_TYPES } from '../activities/actions';
import { ACTION_TYPES as ADMIN_ACTION_TYPES } from '../admin/actions';
import { ACTION_TYPES as PROVIDER_CONTACTS_ACTION_TYPES } from '../providerContacts/actions';
import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../conversations/actions';
import { ACTION_TYPES as INTERACTION_ACTION_TYPES } from '../interactions/actions';
import { ACTION_TYPES as TEAM_ACTION_TYPES } from '../teams/actions';
import { ACTION_TYPES as TAG_ACTION_TYPES } from '../tags/actions';
import { ACTION_TYPES as INVOICE_ACTION_TYPES } from '../invoices/actions';
import { ACTION_TYPES as VISIT_ACTION_TYPES } from '../visits/actions';

const initialState = {
  appointments: {},
  providers: {},
  admins: {},
  clients: {},
  services: {},
  providerServices: {},
  candidateLeads: {},
  notifications: {},
  activities: {},
  conversations: {},
  interactions: {},
  leads: {},
  teams: {},
  tags: {},
  providerContacts: {},
  contactedProviders: {},
  invoices: {},
  visits: {},
};

const updateEntitiesData = (state, draft, entity, dataKey, data) => {
  const entityData = data[dataKey];
  if (entityData) {
    draft[entity] = {
      ...state[entity],
      ...entityData,
    };
  }
};

const deleteEntitiesData = (state, draft, resource, id) => {
  draft[resource] = state[resource];
  delete draft[resource][id];
};

export default (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // UPDATE ENTITIES DATA
      case APPOINTMENT_ACTION_TYPES.GET_APPOINTMENTS_SUCCESS:
      case APPOINTMENT_ACTION_TYPES.GET_APPOINTMENT_SUCCESS:
      case APPOINTMENT_ACTION_TYPES.UPDATE_APPOINTMENT_SUCCESS:
      case APPOINTMENT_ACTION_TYPES.CREATE_NEW_APPOINTMENT_SUCCESS:
      case APPOINTMENT_ACTION_TYPES.CREATE_RECURRING_APPOINTMENT_SUCCESS:
      case APPOINTMENT_ACTION_TYPES.REMOVE_APPOINTMENT_PROVIDER_SUCCESS:
      case PROVIDER_ACTION_TYPES.GET_PROVIDERS_SUCCESS:
      case PROVIDER_ACTION_TYPES.GET_PROVIDER_SUCCESS:
      case PROVIDER_ACTION_TYPES.UPDATE_PROVIDER_SUCCESS:
      case PROVIDER_ACTION_TYPES.UPDATE_PROVIDER_FORM_SUCCESS:
      case CLIENT_ACTION_TYPES.GET_CLIENTS_SUCCESS:
      case CLIENT_ACTION_TYPES.DELETE_CLIENT_SUCCESS:
      case CANDIDATE_LEAD_ACTION_TYPES.GET_CANDIDATE_LEADS_SUCCESS:
      case CANDIDATE_LEAD_ACTION_TYPES.UPDATE_CANDIDATE_LEAD_SUCCESS:
      case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS_SUCCESS:
      case SERVICES_ACTION_TYPES.GET_SERVICE_LISTING_SUCCESS:
      case CLIENT_ACTION_TYPES.GET_CLIENT_SUCCESS:
      case CLIENT_ACTION_TYPES.UPDATE_CLIENT_SUCCESS:
      case ACTIVITY_ACTION_TYPES.GET_ACTIVITY_SUCCESS:
      case ACTIVITY_ACTION_TYPES.GET_ACTIVITIES_SUCCESS:
      case ACTIVITY_ACTION_TYPES.CREATE_ACTIVITY_SUCCESS:
      case ADMIN_ACTION_TYPES.GET_ADMIN_SUCCESS:
      case ADMIN_ACTION_TYPES.UPDATE_ADMIN_SUCCESS:
      case ADMIN_ACTION_TYPES.GET_ADMINS_SUCCESS:
      case CONVERSATION_ACTION_TYPES.GET_CONVERSATION_SUCCESS:
      case INTERACTION_ACTION_TYPES.GET_INTERACTION_SUCCESS:
      case INTERACTION_ACTION_TYPES.CREATE_INTERACTION_SUCCESS:
      case INVOICE_ACTION_TYPES.GET_INVOICE_SUCCESS:
      case INVOICE_ACTION_TYPES.GET_INVOICES_SUCCESS:
      case INVOICE_ACTION_TYPES.UPDATE_INVOICE_SUCCESS:
      case INVOICE_ACTION_TYPES.GET_PROVIDER_INVOICES_SUCCESS:
      case INVOICE_ACTION_TYPES.PATCH_PROVIDER_INVOICE_SUCCESS:
      case PROVIDER_CONTACTS_ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS_SUCCESS:
      case TEAM_ACTION_TYPES.GET_TEAMS_SUCCESS:
      case TEAM_ACTION_TYPES.CREATE_TEAM_SUCCESS:

      case TAG_ACTION_TYPES.GET_TAGS_SUCCESS:
      case TAG_ACTION_TYPES.CREATE_TAG_SUCCESS:

      case PROVIDER_CONTACTS_ACTION_TYPES.UPDATE_PROVIDER_CONTACT_SUCCESS:
      case PROVIDER_CONTACTS_ACTION_TYPES.GET_PROVIDER_CONTACTS_SUCCESS:
      case VISIT_ACTION_TYPES.CREATE_VISIT_SUCCESS:
      case VISIT_ACTION_TYPES.UPDATE_VISIT_SUCCESS:
        if (action.operation === 'delete') {
          deleteEntitiesData(state, draft, 'clients', action.payload);
        } else {
          const data = normalize(action.payload);
          updateEntitiesData(state, draft, 'appointments', 'appointment', data);
          updateEntitiesData(state, draft, 'providers', 'provider', data);
          updateEntitiesData(
            state,
            draft,
            'providerContacts',
            'providerContact',
            data
          );
          updateEntitiesData(
            state,
            draft,
            'providers',
            'contactedProvider',
            data
          );

          updateEntitiesData(state, draft, 'admins', 'admin', data);
          updateEntitiesData(state, draft, 'clients', 'client', data);
          updateEntitiesData(state, draft, 'activities', 'activity', data);
          updateEntitiesData(state, draft, 'services', 'service', data);
          updateEntitiesData(
            state,
            draft,
            'conversations',
            'conversation',
            data
          );
          updateEntitiesData(state, draft, 'interactions', 'interaction', data);
          updateEntitiesData(state, draft, 'invoices', 'invoice', data);
          updateEntitiesData(state, draft, 'ratings', 'rating', data);
          updateEntitiesData(state, draft, 'teams', 'team', data);
          updateEntitiesData(state, draft, 'tags', 'tag', data);
          updateEntitiesData(
            state,
            draft,
            'candidateLeads',
            'candidateLead',
            data
          );
          updateEntitiesData(
            state,
            draft,
            'notifications',
            'notification',
            data
          );
          updateEntitiesData(state, draft, 'leads', 'lead', data);
          updateEntitiesData(
            state,
            draft,
            'providerServices',
            'providerService',
            data
          );
          updateEntitiesData(state, draft, 'visits', 'visit', data);
        }
        break;

      case ADMIN_ACTION_TYPES.UPDATE_ADMIN_DELETE_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        let { [action.payload?.data?.id]: _, ...result } = state.admins;
        draft.admins = result;
        break;

      case ACTION_TYPES.RESET:
        draft.entities = initialState.entities;
        break;

      default:
        break;
    }
  });
};
