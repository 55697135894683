import { useEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/interactions';

const useInteraction = ({ shouldFetch = false, interactionId } = {}) => {
  const dispatch = useDispatch();
  const { 
    getInteraction,
    createInteraction
  } = actions;
  const { 
    // show
    makeGetInteraction, 
    getInteractionLoading, 
    getInteractionError,
    // create
    getCreateInteractionLoading,
    getCreateInteractionSuccess,
  } = selectors;

  // SHOW
  const makeInteraction = useMemo(makeGetInteraction, []);
  const interaction = useSelector(state => makeInteraction(state, interactionId));

  const interactionError = useSelector(state => getInteractionError(state), shallowEqual);

  const interactionLoading = useSelector(
    state => getInteractionLoading(state),
    shallowEqual
  );

  const dispatchGetInteraction = useCallback(
    id => {
      getInteraction(id, dispatch);
    },
    [dispatch, getInteraction]
  );

  useEffect(() => {
    if (shouldFetch && !interaction && interactionId) {
      dispatchGetInteraction(interactionId);
    }
  }, [dispatchGetInteraction, shouldFetch, interaction, interactionId]);

  // CREATE
  const dispatchCreateInteraction = useCallback(
    (payload) => {
      createInteraction(dispatch, payload);
    },
    [createInteraction, dispatch]
  );

  const createInteractionLoading = useSelector(
    state => getCreateInteractionLoading(state),
    shallowEqual
  );

  const createInteractionSuccess = useSelector(
    state => getCreateInteractionSuccess(state),
    shallowEqual
  );

  return {
    // show
    interaction,
    interactionError,
    interactionLoading,
    getInteraction: dispatchGetInteraction,
    // create
    createInteraction: dispatchCreateInteraction,
    createInteractionLoading,
    createInteractionSuccess
  };
};

export default useInteraction;
