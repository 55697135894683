import styled from 'styled-components';
import { SmallBody } from 'Components/Text';

export const NotificationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${(props) => props.theme.margin}px;
  align-items: flex-start;
  justify-content: space-between;
  .icon-container {
    > div > span {
      margin-right: 0px;
    }
    margin-right: ${(props) => props.theme.margin}px;
    height: 32px;
    width: 32px;
    background-color: ${(props) => props.theme.colors.lightBeige};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    > div > div {
      margin: 0;
    }
  }
`;

export const SmallBodyCustom = styled(SmallBody)`
  margin: 0;
  color: ${(props) =>
    props.grey ? props.theme.colors.grey : props.theme.colors.darkBlue};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  &:last-of-type {
    padding-right: ${(props) => props.theme.margin}px;
  }
`;

export const SmallBodyGrey = styled(SmallBody)`
  font-weight: bold;
`;

export const FlexHorizontal = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    &:first-of-type {
      margin-bottom: ${(props) => props.theme.margin / 4}px;
    }
  }
`;
