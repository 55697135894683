import { createSelector } from "reselect";

import { getAdminEntities } from "redux/entities/selectors";
import { buildFilterQuery } from 'Utils/filters';

const selectAdminsState = state => state.admins;

// SHOW
export const makeGetAdmin = () =>
  createSelector(
    getAdminEntities,
    (_, adminId) => adminId,
    (entities, id) => entities[id]
  );
  
export const getAdminLoading = createSelector(selectAdminsState, state => {
  return state.adminLoading || false;
});
export const getAdminLoaded = createSelector(selectAdminsState, state => {
  return state.adminLoaded || false;
});
export const getAdminError = createSelector(selectAdminsState, state => {
  return state.adminError || null;
});

// INDEX
export const getLoadedQueries = createSelector(selectAdminsState, state => {
  return state.loadedQueries || false;
});

export const getTotalCount = createSelector(selectAdminsState, state => {
  return state.totalCount || false;
})

export const getTotalPages = createSelector(selectAdminsState, state => {
  return state.totalPages || null;
});

export const selectFilteredItems = (state, filters) => {
  const admins = selectAdminsState(state);

  return admins[buildFilterQuery(filters, 'admins')];
};

export const getAdmins = createSelector(
  (state, { filter, pageNumber }) =>
    selectFilteredItems(state, {
      filter,
      pageNumber
    }) || [],
  state => getAdminEntities(state) || {},
  (ids, admins) => ids.map(id => admins[id])
);

export const getLoading = createSelector(selectAdminsState, state => {
  return state.loading || false;
});

export const getLoaded = createSelector(selectAdminsState, state => {
  return state.loaded || false;
});

export const getError = createSelector(selectAdminsState, state => {
  return state.error || null;
});

// INVITE
export const getInviteAdminLoading = createSelector(selectAdminsState, state => {
  return state.inviteLoading || false;
});

export const getInviteAdminSuccess = createSelector(selectAdminsState, state => {
  return state.inviteSuccess || false;
});

// UPDATE
export const getUpdateAdminLoading = createSelector(selectAdminsState, state => {
  return state.updateAdminLoading || false;
});

export const getUpdateAdminSuccess = createSelector(selectAdminsState, state => {
  return state.updateAdminSuccess || false;
});

export const getUpdateAdminFail = createSelector(selectAdminsState, state => {
  return state.updateAdminFail || false;
});