import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import activitiesReducer from './activities';
import adminReducer from './admin';
import appointmentsReducer from './appointments';
import authReducer from './auth';
import candidateLeadsReducer from './candidateLeads';
import citiesReducer from './cities';
import clientsReducer from './clients';
import contentReducer from './content';
import conversationsReducer from './conversations';
import dashboardReducer from './dashboard';
import entitiesReducer from './entities';
import interactionsReducer from './interactions';
import notificationsReducer from './notifications';
import providerContactsReducer from './providerContacts';
import providersReducer from './providers';
import servicesReducer from './services';
import settingsReducer from './settings';
import teamsReducer from './teams';
import tagsReducer from './tags';
import invoicesReducer from './invoices';
import visitsReducer from './visits';
import filterBarReducer from './filterBar';
import polyglotSlice from '../features/polyglot/polyglotSlice';
import authUserSlice from '../features/authUser/authUserSlice';
import { apiSlice } from '../features/api/apiSlice';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  activities: activitiesReducer,
  admins: adminReducer,
  appointments: appointmentsReducer,
  authUser: authUserSlice,
  auth: authReducer,
  candidateLeads: candidateLeadsReducer,
  cities: citiesReducer,
  clients: clientsReducer,
  content: contentReducer,
  conversations: conversationsReducer,
  dashboard: dashboardReducer,
  entities: entitiesReducer,
  interactions: interactionsReducer,
  notifications: notificationsReducer,
  polyglot: polyglotSlice,
  providerContacts: providerContactsReducer,
  providers: providersReducer,
  services: servicesReducer,
  settings: settingsReducer,
  teams: teamsReducer,
  tags: tagsReducer,
  invoices: invoicesReducer,
  visits: visitsReducer,
  filterBar: filterBarReducer,
});

const store = configureStore({
  reducer: rootReducer,
  ...getDefaultMiddleware,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});

export default store;
