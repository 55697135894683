import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { TopBar } from 'ui-55';

import usePolymorph from 'hooks/usePolymorph';
import { selectors as clientSelectors } from 'Redux/clients';
import { selectors as providerSelectors } from 'Redux/providers';
import { selectors as adminSelectors } from 'Redux/admin';
import {StyledNavbarContainer} from './style'

const NavigationBar = ({ routes, ...otherProps }) => {
  let location = useLocation();
  let history = useHistory();
  const { treeComponents } = usePolymorph();
  const activeRoute = useMemo(
    () => routes.find(r => r.path === location.pathname),
    [location, routes]
  );
  const queryId = queryString.parse(location.search).id;
  const querySlug = queryString.parse(location.search).slug;

  const makeClient = useMemo(clientSelectors.makeGetClient, []);
  const client = useSelector(state => makeClient(state, queryId));

  const makeProvider = useMemo(providerSelectors.makeGetProvider, []);
  const specialist = useSelector(state => makeProvider(state, queryId));

  const makeAdmin = useMemo(adminSelectors.makeGetAdmin, []);
  const member = useSelector(state => makeAdmin(state, queryId));
  const contentName =
    treeComponents
      .map(c => (c.elements ? [...c.elements] : { ...c }))
      .flat()
      .find(ck => ck?.key === querySlug)?.label || null;


  const dynamicLocations = useMemo(
    () => ({
      client: client?.attributes?.fullName,
      specialist: specialist?.attributes?.fullName,
      member: member?.attributes?.fullName,
      contentName: contentName ?? querySlug
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, specialist, member, contentName]
  );

  const getRouteTitle = useCallback(
    route => {
      switch (route.dynamicLocation) {
        case 'request-detail':
          return `#-${queryId ?? ''}`;
        default:
          return dynamicLocations[route.dynamicLocation] ?? '';
      }
    },
    [dynamicLocations, queryId]
  );

  const t = useTranslate('routes');
  return (
    (activeRoute && (
      <StyledNavbarContainer>
        <TopBar
          {...otherProps}
          onBackClick={() => history.goBack()}
          onAvatarClick={() => history.push('settings')}
          location={activeRoute.title && t(activeRoute.title)}
          title={
            activeRoute.dynamicLocation
              ? getRouteTitle(activeRoute)
              : t(activeRoute.location)
          }
          back={!activeRoute.noBack}
        />
      </StyledNavbarContainer>
    )) || <></>
  );
};

NavigationBar.propTypes = {
  routes: PropTypes.array
};

export default NavigationBar;
