import { createSelector } from 'reselect';

import { getClientsEntities } from 'redux/entities/selectors';

const selectClientsState = state => state.clients;

// INDEX refactored
const selectClients = createSelector(selectClientsState, state => {
  return state?.clients || null; 
});
const selectQueries = createSelector(selectClientsState, state => {
  return state?.queries || null; 
});
const selectQueryData = createSelector(
  selectClientsState, 
  (state, query) => {
  return state?.queries[query] || null;
})

// SHOW
const makeGetClient = () =>
  createSelector(
    getClientsEntities,
    (_, clientId) => clientId,
    (entities, id) => entities[id]
  );

const getClientLoading = createSelector(selectClientsState, state => {
  return state.clientLoading;
});

const getClientError = createSelector(selectClientsState, state => {
  return state.clientError;
});

const getTotalPages = createSelector(selectClientsState, state => {
  return state.totalPages || null;
});

export {
  selectClients,
  selectQueries,
  selectQueryData,
  makeGetClient,
  getClientError,
  getClientLoading,
  getTotalPages
};
