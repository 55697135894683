import { useMemo, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/providers';
import { updateProvider } from 'Redux/providers/actions';

const useProvider = ({ shouldFetch = false, providerId } = {}) => {
  const dispatch = useDispatch();
  const { getProvider, updateProviderForm, createProvider } = actions;
  const {
    // show
    makeGetProvider,
    getProviderLoading,
    getProviderError,
    getExtraProps,
    // update
    getUpdateProviderLoading,
    getUpdateProviderFail,
    getUpdateProviderSuccess,
    getProvidersForms
  } = selectors;

  // SHOW
  const makeProvider = useMemo(makeGetProvider, []);
  const provider = useSelector(state => makeProvider(state, providerId));
  const extraProps = useSelector(state => getExtraProps(state), shallowEqual);

  const providerLoading = useSelector(
    state => getProviderLoading(state),
    shallowEqual
  );

  const providerError = useSelector(
    state => getProviderError(state),
    shallowEqual
  );

  const dispatchGetProvider = useCallback(
    id => {
      getProvider(id, dispatch);
    },
    [dispatch, getProvider]
  );

  const providerForms = useSelector(
    state => getProvidersForms(state),
    shallowEqual
  );
  useEffect(() => {
    if (shouldFetch && providerId && (!provider || !extraProps[providerId])) {
      dispatchGetProvider(providerId);
    }
  }, [dispatchGetProvider, shouldFetch, provider, providerId, extraProps]);

  // CREATE
  const dispatchCreateProvider = useCallback(
    (values, cb) => {
      createProvider(values, dispatch, cb);
    },
    [createProvider, dispatch]
  );

  // UPDATE
  const dispatchUpdateProvider = useCallback(
    (id, payload) => {
      updateProvider(dispatch, id, payload);
    },
    [dispatch]
  );

  const updateProviderLoading = useSelector(state =>
    getUpdateProviderLoading(state)
  );
  const updateProviderFail = useSelector(state => getUpdateProviderFail(state));
  const updateProviderSuccess = useSelector(state =>
    getUpdateProviderSuccess(state)
  );

  // FORM
  const dispatchUpdateProviderForm = useCallback(
    (values, id, callback) => {
      updateProviderForm(dispatch, values, id, callback);
    },
    [dispatch, updateProviderForm]
  );

  return {
    provider,
    getProvider: dispatchGetProvider,
    createProvider: dispatchCreateProvider,
    updateProviderForm: dispatchUpdateProviderForm,
    providerLoading,
    providerError,
    providerForms,
    // update
    updateProvider: dispatchUpdateProvider,
    updateProviderLoading,
    updateProviderFail,
    updateProviderSuccess
  };
};

export default useProvider;
