import { Row } from 'ui-55';
import styled from 'styled-components';

import ContentEditable from 'react-contenteditable';

export const StyledEditor = styled(ContentEditable)`
  width: 100%;
  min-height: ${({ theme }) => theme.margin * 3}px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.colors.mediumBeige};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumBeige};
  min-width: 250px;
  max-width: 720px;
  padding: ${({ theme }) => theme.margin}px;
`;

const TOP_BAR_HEIGHT = 75;
const SIDEBAR_WIDTH = 240;

export const ControlsRow = styled(Row)`
  position: fixed;
  top: ${TOP_BAR_HEIGHT}px;
  left: ${SIDEBAR_WIDTH}px;
  background: ${({ theme }) => theme.colors.lightestBeige};
  width: calc(100% - ${SIDEBAR_WIDTH}px) !important;
  margin-top: ${({ theme }) => theme.margin}px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  div {
    width: auto !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
`;
export const EditorBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background: ${({ theme }) => theme.colors.lightestBeige};
  padding: ${({ theme }) => theme.margin}px;
  &:hover {
    background: ${({ theme }) => theme.colors.brand.yellow};
  }
  svg {
    color: ${({ theme }) => theme.colors.mediumBeige};
    width: 24px;
    height: 24px;
  }
`;
export const LinksModal = styled.div`
  outline: none;
  position: absolute;
  top: 60px;
  left: -50px;
  && {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  border: none;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.margin}px;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 13px rgba(193, 188, 183, 0.3));
  svg {
    color: ${({ theme }) => theme.colors.mediumBeige};
    width: 24px;
    height: 24px;
  }
  > div > div {
    width: 100% !important;
    justify-content: space-between;
    margin: 0;
    button {
      margin-right: ${({ theme }) => theme.margin / 2}px;
      padding: ${({ theme }) => theme.margin / 2}px;
    }
  }
`;
export const ClickOutsideContainer = styled.div`
  && {
    position: fixed;
    z-index: -1;
    height: 100vh;
    width: 100vw !important;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0;
  }
`;
export const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledDropdown = styled(EditorBtn)`
  position: relative;
  display: inline-block;
  div {
    width: auto !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .drop {
    height: 8px;
    width: 8px;
    margin-left: 4px;
  }
  &:hover {
    .dropdown-content {
      display: flex;
    }
  }
  .dropdown-content {
    filter: drop-shadow(0px 4px 13px rgba(193, 188, 183, 0.3));
    display: none;
    position: absolute;
    background: ${({ theme }) => theme.colors.lightestBeige};
    min-width: 160px;
    flex-flow: column nowrap;
    z-index: 1;
    button {
      display: flex;
      width: 100%;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      svg {
        min-width: 24px;
        margin-right: ${({ theme }) => theme.margin / 2}px;
      }
    }
  }
`;
