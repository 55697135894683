/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useAdmin from 'Hooks/admins/useAdmin';

import Select from 'Components/Select';

import { Row, Col, Button, Icon, TextInput } from 'ui-55';
import {
  StyledTeamBadge,
  SelectedTeams,
  SelectContainer,
  ButtonContainer
} from './styles';
import { useCallback } from 'react';

const MemberForm = ({ t, member, teams, formType }) => {
  const { updateAdmin, updateAdminSuccess } = useAdmin();
  const teamOptions = teams.map(team => {
    return {
      label: team.attributes?.name,
      value: team.id
    };
  });

  const memberFirstName = member?.attributes?.firstName || '';
  const memberLastName = member?.attributes?.lastName || '';
  const memberEmail = member?.attributes?.email || '';
  const memberTeamIds = member?.relationships?.teams?.data?.map(
    team => team.id
  );

  const [name, setName] = useState(memberFirstName);
  const [lastName, setLastName] = useState(memberLastName);
  const [email, setEmail] = useState(memberEmail);
  const [teamIds, setTeamIds] = useState(memberTeamIds || []);

  const [pendingUpdate, setPendingUpdate] = useState(false);

  const compareTeamIds = useCallback(() => {
    const formattedOld = memberTeamIds.sort().join();
    const formattedNew = teamIds.sort().join();
    return formattedOld === formattedNew;
  }, [memberTeamIds, teamIds]);

  useEffect(() => {
    if (
      memberFirstName === name &&
      memberLastName === lastName &&
      memberEmail === email &&
      compareTeamIds()
    ) {
      setPendingUpdate(false);
    } else {
      setPendingUpdate(true);
    }
  }, [
    name,
    lastName,
    email,
    teamIds,
    setPendingUpdate,
    memberTeamIds,
    compareTeamIds,
    memberFirstName,
    memberLastName,
    memberEmail
  ]);

  const handleSelectChange = v => {
    let updatedTeamIds = [];
    if (teamIds.includes(v.value)) {
      updatedTeamIds = teamIds.filter(id => id !== v.value);
    } else {
      updatedTeamIds = [v.value].concat(teamIds);
    }
    setTeamIds(updatedTeamIds);
  };

  const handleUpdateAdmin = () => {
    const payload = {};
    if (memberFirstName !== name) payload.first_name = name;
    if (memberLastName !== lastName) payload.last_name = lastName;
    if (memberEmail !== email) payload.email = email;
    if (!compareTeamIds()) payload.team_ids = teamIds;

    updateAdmin(payload, member?.id);
  };

  useEffect(() => {
    if (updateAdminSuccess) {
      setPendingUpdate(false);
    }
  }, [updateAdminSuccess, setPendingUpdate]);

  const TeamBadge = ({ teamOption }) => {
    return (
      <StyledTeamBadge>
        <div
          role='button'
          tabIndex={-1}
          onClick={() => handleSelectChange(teamOption)}
        >
          {teamOption?.label}
          <Icon name='Close' />
        </div>
      </StyledTeamBadge>
    );
  };

  return (
    <>
      <Row>
        <Col size={6}>
          <TextInput
            label={t('name')}
            defaultValue={name}
            onChange={v => setName(v)}
            isFullWidth
          />
          <TextInput
            label={t('email')}
            defaultValue={email}
            onChange={v => setEmail(v)}
            isFullWidth
          />
        </Col>

        <Col size={6}>
          <TextInput
            label={t('lastName')}
            defaultValue={lastName}
            onChange={v => setLastName(v)}
            isFullWidth
          />
          {teamOptions && (
            <>
              <SelectContainer>
                <Select
                  label={t('team')}
                  options={teamOptions}
                  onChange={v => handleSelectChange(v)}
                  selectType='team'
                  value={null}
                />
              </SelectContainer>
              <SelectedTeams>
                {teamIds.length > 0 &&
                  teamIds.map(id => {
                    const team = teamOptions.find(tm => tm.value === `${id}`);
                    return (
                      <>
                        {team && (
                          <TeamBadge
                            key={`team-badge-${team?.value}`}
                            teamOption={team}
                          />
                        )}
                      </>
                    );
                  })}
              </SelectedTeams>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <ButtonContainer size={6}>
          <Button
            btnType='primary'
            text={t('update')}
            isFullWidth
            isDisabled={!pendingUpdate}
            action={handleUpdateAdmin}
          />
        </ButtonContainer>
        <Col size={6} />
      </Row>
    </>
  );
};

MemberForm.propTypes = {
  t: PropTypes.func,
  member: PropTypes.object,
  teams: PropTypes.arrayOf(PropTypes.object),
  formType: PropTypes.oneOf(['create', 'update'])
};

export default MemberForm;
