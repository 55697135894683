import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProvidersEntities } from 'redux/entities/selectors';
import { actions, selectors } from 'Redux/providers';

const useProviders = ({ query = '', shouldFetch = false } = {}) => {
  const dispatch = useDispatch();
  const { getProviders } = actions;
  const { selectQueries, selectProviders } = selectors;

  const allProviders = useSelector((state) => selectProviders(state));
  const entityProviders = useSelector((state) => getProvidersEntities(state));
  const queries = useSelector((state) => selectQueries(state));

  const [providers, setProviders] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  const makeProviders = () =>
    queries[query]?.data?.map((id) => allProviders[id]);

  const makeAllProviders = () => {
    const provs = { ...allProviders, ...entityProviders };
    return Object.keys(provs).map((k) => provs[k]);
  };

  useEffect(() => {
    if (!query.length) {
      setProviders(makeAllProviders());
    }
  }, []);

  useEffect(() => {
    if (queries && queries[query]?.data) {
      setProviders(makeProviders());
      setLoading(queries[query]?.loading);
      setLoaded(queries[query]?.loaded);
      setError(queries[query]?.error);
      setTotalPages(queries[query]?.totalPages);
    }
  }, [queries]);

  const dispatchGetProviders = useCallback(
    (dispatch, query) => {
      getProviders(dispatch, query);
    },
    [dispatch, getProviders, query]
  );

  const getProvidersById = (idAry) => {
    const provs = { ...allProviders, ...entityProviders };
    return idAry.map((id) => provs[parseInt(id)]);
  };

  useEffect(() => {
    if (shouldFetch) {
      dispatchGetProviders(dispatch, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    providers,
    queries,
    loading,
    loaded,
    error,
    totalPages,
    getProvidersById,
    makeAllProviders,
  };
};

export default useProviders;
