import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import queryString from 'query-string';

import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslate } from '../../features/polyglot';

import useInvoicesListing from 'hooks/invoices/useInvoicesListing';

import {
  Heading,
  Button,
  Row,
  Col,
  Body,
  Icon,
  Badge,
  Loader,
  SmallBody
} from 'ui-55';
import {
  // PaymentDetails,
  // PaymentCol,
  StyledContainer,
  IconContainer,
  StyledSmallBody,
  InvoiceHeaderRow,
  InvoiceStatusHeader,
  ModalStyles,
  InvoicesContainer,
  InvoicesHeader,
  Circle,
  ModalButtonRow,
} from './styles';

const PaymentRender = ({ provider }) => {
  const t = useTranslate('payment');
  const match = useLocation();
  const providerId = queryString.parse(match.search).id || provider?.id;

  const {
    providerInvoices,
    loading,
    error,
    updateProviderInvoice,
  } = useInvoicesListing({
    filters: { provider_id: providerId },
    shouldFetch: true,
  });

  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectecInvoice] = useState(null);

  const paymentMethodLabels = {
    0: t('monthly_invoice'),
    1: t('acumulate'),
    2: t('ato_isolado'),
  };
  const formatValue = (value) =>
    Number(value).toLocaleString('pt-PT', {
      style: 'currency',
      currency: 'EUR',
    });

  const paymentMethod =
    paymentMethodLabels[provider?.attributes?.formAnswers?.paymentMethod];

  const invoiceData = useMemo(() => {
    if (!providerInvoices) return [];
    return Array.isArray(providerInvoices?.data)
      ? providerInvoices.data
      : [providerInvoices.data];
  }, [providerInvoices]);

  const totalAmountPaid = useMemo(() => {
    if (!invoiceData) return;
    const total = invoiceData.reduce(
      (n, { attributes }) => n + attributes?.total_payment,
      0
    );
    return formatValue((Number.parseInt(total) / 100).toFixed(0));
  }, [invoiceData]);

  const totalHours = useMemo(() => {
    if (!invoiceData) return;

    const total = invoiceData.reduce(
      (n, { attributes }) => n + attributes?.total_hours,
      0
    );
    return total;
  }, [invoiceData]);

  const totalOpen = useMemo(() => {
    if (!invoiceData) return;
    const open = invoiceData
      .filter((e) => e?.attributes?.status === 'open')
      .reduce((n, { attributes }) => n + attributes?.total_payment, 0);
    return formatValue((Number.parseInt(open) / 100).toFixed(0));
  }, [invoiceData]);

  const totalPaid = useMemo(() => {
    if (!invoiceData) return;
    const paid = invoiceData
      .filter((e) => e?.attributes?.status === 'paid')
      .reduce((n, { attributes }) => n + attributes?.total_payment, 0);
    return formatValue((Number.parseInt(paid) / 100).toFixed(0));
  }, [invoiceData]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handlePayment = useCallback(
    (item) => {
      updateProviderInvoice(item?.id);
      setOpenModal(!openModal);
    },
    [openModal, updateProviderInvoice]
  );

  const invoiceRender = (item, index) => {
    const invoiceAttributes = item?.attributes;
    const paidSince = invoiceAttributes?.status_transitions?.paid
      ? new Date(invoiceAttributes?.status_transitions?.paid)
          .toLocaleTimeString('pt-PT', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          .split(',')[0]
      : null;

    return (
      <React.Fragment key={`invoice-${index}`}>
        <InvoicesContainer padding={16} align={'baseline'}>
          <Row size={1}>
            <Body>{invoiceAttributes?.period}</Body>
          </Row>
          <Row size={1}>
            <Col padding={0}>
              <Badge
                translate={t}
                text={invoiceAttributes?.status}
                status={invoiceAttributes?.status}
              />
              {paidSince && (
                <StyledSmallBody noMargin>{paidSince}</StyledSmallBody>
              )}
            </Col>
          </Row>
          <Row size={1}>
            <SmallBody>
              {invoiceAttributes?.total_hours} {t('hours')}
            </SmallBody>
          </Row>
          <Row size={1}>
            <SmallBody>
              {formatValue((invoiceAttributes?.total_payment / 100).toFixed(2))}
            </SmallBody>
          </Row>
          <Row size={2} justify='flex-end'>
            <SmallBody>
              {invoiceAttributes?.status === 'open' && (
                <Button
                  btnType='borded'
                  text={t('setAsPaid')}
                  action={() => {
                    setOpenModal(!openModal);
                    setSelectecInvoice(item);
                  }}
                />
              )}
            </SmallBody>
          </Row>
        </InvoicesContainer>
      </React.Fragment>
    );
  };

  return (
    <>
      {loading || !invoiceData ? (
        <Loader />
      ) : (
        <StyledContainer>
          {openModal && (
            <Modal style={ModalStyles} ariaHideApp={false} isOpen={openModal}>
              <Row justify='space-between' align='center'>
                <Heading size={3}>{t('modalHeader')}?</Heading>
                <Button
                  icon='Close'
                  btnType='transparent'
                  action={() => setOpenModal(!openModal)}
                />
              </Row>
              <Row>
                <Body>{t('modalSubHeader')}</Body>
              </Row>
              {/*   <FileUploader  action={() => {}} /> */}
              <ModalButtonRow justify='space-between' align='center'>
                <Button
                  btnType='borded'
                  action={() => setOpenModal(!openModal)}
                  text={t('cancel')}
                />
                <Button
                  btnType='primary'
                  action={() => handlePayment(selectedInvoice)}
                  text={t('confirm')}
                />
              </ModalButtonRow>
            </Modal>
          )}
          <InvoiceStatusHeader>
            <InvoiceHeaderRow align='center'>
              <IconContainer>
                <Icon size={24} name={'Payment'} />
              </IconContainer>
              <Col padding={0} size={1}>
                <StyledSmallBody>{t('paymentMethod')}</StyledSmallBody>
                <Body>{paymentMethod}</Body>
              </Col>
            </InvoiceHeaderRow>
            <InvoiceHeaderRow align='center'>
              <IconContainer>
                <Icon size={24} name={'Hourglass'} />
              </IconContainer>
              <Col padding={0} size={1}>
                <StyledSmallBody>{t('totalHours')}</StyledSmallBody>
                <Body>
                  {totalHours}
                  {t('hours')}
                </Body>
              </Col>
            </InvoiceHeaderRow>
            <InvoiceHeaderRow align='center'>
              <IconContainer>
                <Icon size={24} name={'Total'} />
              </IconContainer>
              <Col padding={0} size={1}>
                <>
                  <StyledSmallBody>{t('totalAmount')}</StyledSmallBody>
                  <Body>{totalAmountPaid}</Body>
                </>
              </Col>
            </InvoiceHeaderRow>
          </InvoiceStatusHeader>
          <InvoiceStatusHeader top>
            <Row>
              <Circle status={'paid'} />
              <Body>
                {t('totalPaid')}: {totalPaid}
              </Body>
            </Row>
            <Row>
              <Circle status={'open'} />
              <Body>
                {t('totalOpen')}: {totalOpen}
              </Body>
            </Row>
          </InvoiceStatusHeader>
          {invoiceData?.length > 0 && (
            <>
              <InvoicesHeader>
                <Row size={1}>
                  <SmallBody>{t('paymentMonth')}</SmallBody>
                </Row>
                <Row size={1}>
                  <SmallBody>{t('status')}</SmallBody>
                </Row>
                <Row size={1}>
                  <SmallBody>{t('totalHours')}</SmallBody>
                </Row>
                <Row size={1}>
                  <SmallBody>{t('totalAmount')}</SmallBody>
                </Row>
                <Row size={2} />
              </InvoicesHeader>
              {invoiceData.map(invoiceRender)}
            </>
          )}
        </StyledContainer>
      )}
    </>
  );
};

export default PaymentRender;

PaymentRender.propTypes = {
  provider: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string,
    relationships: PropTypes.object,
    type: PropTypes.string,
  }),
};
