import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../../features/polyglot';
import { useHistory } from 'react-router-dom';

import { makeGetClient } from 'Redux/clients/selectors';
import { makeGetService } from 'Redux/services/selectors';
import { makeGetRating } from 'Redux/ratings/selectors';

import { Col } from 'Components/Layout';
import { Avatar, Badge, Row, StarsRating } from 'ui-55';

import {
  StyledTableRow,
  SmallBodyDetail,
  ServiceName,
  BadgeContainer,
} from './style';

import { showId } from 'utils/showId';

const TableRow = ({ item }) => {
  const history = useHistory();
  const t = useTranslate('specialists');
  const b = useTranslate('badges');

  const handleNavigateToClient = (clientId) => {
    history.push(`/dashboard/client-detail?client_id=${clientId}`);
  };
  const user = useSelector((state) => state?.auth?.user);

  const makeClient = useMemo(makeGetClient, []);
  const client = useSelector((state) =>
    makeClient(state, item.relationships?.client?.data?.id)
  );

  const makeService = useMemo(makeGetService, []);
  const service = useSelector((state) =>
    makeService(state, item.relationships?.service?.data?.id)
  );

  const makeRating = useMemo(makeGetRating, []);
  const rating = useSelector((state) =>
    makeRating(state, item.relationships?.rating?.data?.id)
  );

  const createdAtStartDate = new Date(item?.attributes?.createdAt);
  const createdAtStartDay = createdAtStartDate.getDate();
  const createdAtStartMonth = createdAtStartDate.toLocaleString('default', {
    month: 'long',
  });
  const createdAtStartYear = createdAtStartDate.getFullYear();

  const startDate =
    item?.attributes?.deliveredOn && new Date(item?.attributes?.deliveredOn);
  const startDay = startDate?.getDate();
  const startMonth = startDate?.toLocaleString('default', { month: 'long' });
  const startYear = startDate?.getFullYear();

  const handleAppointmentDetails = () => {
    history.push(`/dashboard/request-details?id=${item.id}`);
  };

  return (
    <StyledTableRow>
      <Col size={3}>
        {service && (
          <div onClick={handleAppointmentDetails} role='presentation'>
            {item.attributes?.status && (
              <BadgeContainer>
                <Badge
                  translate={b}
                  text={item.attributes?.status}
                  status={item.attributes?.status}
                />
              </BadgeContainer>
            )}
            <ServiceName>
              {service.attributes?.name} {showId(user) && `(${item?.id})`}
            </ServiceName>
          </div>
        )}
      </Col>

      <Col size={2}>
        {client && (
          <Avatar
            user={client?.attributes}
            hasText={true}
            action={() => handleNavigateToClient(client.id)}
          />
        )}
      </Col>

      <Col size={2}>
        {createdAtStartDate && (
          <SmallBodyDetail>
            {createdAtStartDay} {createdAtStartMonth}, {createdAtStartYear}
          </SmallBodyDetail>
        )}
      </Col>

      <Col size={2}>
        {startDate && (
          <SmallBodyDetail>
            {startDay} {startMonth}, {startYear}
          </SmallBodyDetail>
        )}
      </Col>

      <Col size={2}>
        <SmallBodyDetail>
          {item?.attributes?.totalHours || 0} {t('hours')}
        </SmallBodyDetail>
      </Col>

      <Col size={2}>
        <SmallBodyDetail>
          {item?.attributes?.providerTotalPrice?.toFixed(2) || 0}€
        </SmallBodyDetail>
      </Col>

      <Col size={2}>
        {' '}
        <Row align='center'>
          {rating && (
            <>
              <SmallBodyDetail grey={true}>
                {rating?.attributes?.value}
              </SmallBodyDetail>
              <StarsRating
                starSize={13}
                defaultRating={rating.attributes?.value}
              />
            </>
          )}
        </Row>
      </Col>
    </StyledTableRow>
  );
};

export default TableRow;
