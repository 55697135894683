import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar, Badge, Icon } from 'ui-55';

import { useTranslate } from '../../features/polyglot';
import { Draggable } from 'react-beautiful-dnd';

import { makeGetClient } from 'Redux/clients/selectors';
import { makeGetProvider } from 'Redux/providers/selectors';
import { makeGetService } from 'Redux/services/selectors';
import { makeGetAdmin } from 'Redux/admin/selectors';

import { getNewStatusOptions } from 'utils/appointmentStatus';
import { getCandidateStatusOptions } from 'utils/candidateStatus';

import { ButtonText, SmallBody } from 'Components/Text';
import { showId } from 'utils/showId';
import StyledKanbanCard, {
  BadgeContainer,
  Details,
  IconContainer,
  AdminContainer,
  ServiceDetails,
  Recurrent
} from './style';

const KanbanCard = ({ cardKey, index, cardData, cardType, action, item }) => {
  const t = useTranslate('requests');
  const b = useTranslate('badges');
  const currentAdmin = useSelector((state) => state?.auth?.user);
  const makeAdmin = useMemo(makeGetAdmin, []);
  const admin = useSelector(state =>
    makeAdmin(
      state,
      item.relationships?.admin?.data?.id ||
        item.relationships?.assignedTo?.data?.id
    )
  );

  const makeClient = useMemo(makeGetClient, []);
  const client = useSelector(state =>
    makeClient(state, item.relationships?.client?.data?.id)
  );

  const makeProvider = useMemo(makeGetProvider, []);
  const provider = useSelector(state =>
    makeProvider(state, item.relationships?.provider?.data?.id)
  );

  const makeService = useMemo(makeGetService, []);
  const service = useSelector(state =>
    makeService(state, item.relationships?.service?.data?.id)
  );

  const currentStatus = item.attributes?.status;
  const newStatusOptions =
    item.type === 'appointment'
      ? getNewStatusOptions(b, currentStatus)
      : getCandidateStatusOptions(b, currentStatus);
  const isDisabled = newStatusOptions[0].disabled;

  const deliveredOnDate = new Date(item?.attributes?.deliveredOn);
  const deliveredOnDay = deliveredOnDate.getDate() < 10 ?  "0" + deliveredOnDate.getDate() : deliveredOnDate.getDate();
  const deliveredOnMonth = deliveredOnDate.getMonth() < 9 ? "0" + (deliveredOnDate.getMonth() + 1) : (deliveredOnDate.getMonth() + 1)
  const deliveredOnYear = deliveredOnDate.getFullYear();

  const createdAt = new Date(item?.attributes?.createdAt);
  const createdDay = createdAt.getDate() < 10 ?  "0" + createdAt.getDate() : createdAt.getDate();
  const createdMonth = createdAt.getMonth() < 9 ? "0" + (createdAt.getMonth() + 1) : (createdAt.getMonth() + 1)
  const createdYear = createdAt.getFullYear();

  let isUrgentIsPremium = ""
  if (item?.attributes?.isUrgent) {
    isUrgentIsPremium = isUrgentIsPremium + "*URGENT* "
  }
  if (item?.attributes?.serviceType && item?.attributes?.serviceType !== "standard") {
    isUrgentIsPremium = isUrgentIsPremium + "*" + item?.attributes?.serviceType?.toUpperCase() + "* "
  }

  return (
    <Draggable
      key={cardKey}
      draggableId={cardKey}
      index={index}
      isDragDisabled={isDisabled}
    >
      {(provided, snapshot) => {
        return (
          <StyledKanbanCard
            onClick={action}
            ref={provided.innerRef}
            isDisabled={isDisabled}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <BadgeContainer>
              <Badge
                translate={b}
                text={item?.attributes?.status}
                status={item?.attributes?.status}
              />
            </BadgeContainer>

            <ButtonText>
              {cardType === 'candidates'
                ? provider?.attributes?.fullName
                : isUrgentIsPremium + service?.attributes?.name}
                {showId(currentAdmin) && `(${item?.id})`}
            </ButtonText>

            {item?.attributes?.status && !isNaN(createdDay) && item?.attributes?.status === "received" &&
              <ServiceDetails>
                <span>{t('requestDate')}: </span>
                {createdDay + "/" + createdMonth + "/" + createdYear}
              </ServiceDetails>
            }

            {item?.attributes?.status && !isNaN(deliveredOnDay) && item?.attributes?.status !== "received" &&
              <ServiceDetails>
                <span>{t('serviceDate')}: </span>
                {deliveredOnDay + "/" + deliveredOnMonth + "/" + deliveredOnYear}
              </ServiceDetails>
            }

            {cardType === 'candidates' ? (
              <div>
                {provider?.attributes?.serviceNames.length > 0 && (
                  <Details>
                    <IconContainer>
                      <Icon name='tool-1' />
                    </IconContainer>

                    <SmallBody>
                      {provider?.attributes?.serviceNames.join(', ')}
                    </SmallBody>
                  </Details>
                )}

                {provider?.attributes?.district && (
                  <Details>
                    <IconContainer>
                      <Icon name='map-pin' />
                    </IconContainer>

                    <SmallBody>{provider?.attributes?.district}</SmallBody>
                  </Details>
                )}
              </div>
            ) : (
              <div>
                {client && (
                  <ServiceDetails>
                    <span>{t('client')}: </span>
                    {client.attributes?.fullName}
                  </ServiceDetails>
                )}
                {provider && (
                  <ServiceDetails>
                    <span>{t('specialist')}: </span>
                    {provider.attributes?.fullName}
                  </ServiceDetails>
                )}
                {item?.attributes?.isRecurrent && <Recurrent></Recurrent>}
              </div>
            )}

            {item.attributes?.recurrent && (
              <Recurrent>
                <IconContainer>
                  <Icon name='repeat-1' />
                </IconContainer>
                <SmallBody>{t('recurring')}</SmallBody>
              </Recurrent>
            )}

            {admin && (
              <AdminContainer recurrent={item.attributes?.recurrent}>
                <Avatar
                  user={admin?.attributes}
                  size='small'
                  hasText={true}
                ></Avatar>
              </AdminContainer>
            )}
          </StyledKanbanCard>
        );
      }}
    </Draggable>
  );
};

KanbanCard.propTypes = {
  cardKey: PropTypes.string,
  index: PropTypes.number,
  cardType: PropTypes.oneOf(['requests', 'candidates']),
  cardData: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    isRecurrent: PropTypes.bool,
    adminId: PropTypes.object,
    clientId: PropTypes.object,
    providerId: PropTypes.object,
    serviceId: PropTypes.object
  })
};

export default KanbanCard;
