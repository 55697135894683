import React from 'react';

import { MONTHS } from 'utils/dateStrings';

import useInteraction from 'Hooks/useInteraction';
import useProvider from 'Hooks/providers/useProvider';

import { Avatar } from 'ui-55';
import StyledInteractionCard, {
  Header,
  SmallBodyDetail,
  BodyDetail
} from './styles';

const InteractionCard = ({ interactionId }) => {
  const { interaction } = useInteraction({
    interactionId: interactionId,
    shouldFetch: true
  });
  const { provider } = useProvider({
    providerId: interaction?.attributes?.senderId
  });

  const createdAt = new Date(interaction?.attributes?.createdAt);
  const hour = createdAt.getHours();
  const minutes = createdAt.getMinutes();
  const date = createdAt.getDate();
  const month = MONTHS[createdAt.getMonth()];

  return (
    <>
      {interaction && (
        <StyledInteractionCard>
          <Header>
            {provider && (
              <Avatar user={provider.attributes} hasText={true} size='small' />
            )}

            <SmallBodyDetail>
              {date} {month}, {hour}h{minutes}
            </SmallBodyDetail>
          </Header>
          <BodyDetail>{interaction.attributes?.text}</BodyDetail>
        </StyledInteractionCard>
      )}
    </>
  );
};

export default InteractionCard;
