import React from 'react';

import useServicesListing from 'Hooks/services/useServicesListing';

import { Avatar, Icon } from 'ui-55';

import {
  NotificationContainer,
  FlexVertical,
  FlexHorizontal,
  SmallBodyCustom,
} from './style';

const NotificationRow = ({ notification, candidate, appointment }) => {
  switch (notification.attributes.notificationType) {
    case 'new_candidate':
      return NewCandidate(notification, candidate);
    case 'new_appointment':
      return NewAppointment(notification, appointment);
    case 'provider_submitted_form':
      return NewProviderFormSubmitted(notification, candidate);
    default:
      return null;
  }
};

const NewAppointment = (notification, appointment) => {
  const { services } = useServicesListing({ shouldFetch: true });
  const service = services[appointment?.relationships?.service?.data?.id];

  if (!service) {
    return null;
  }

  let [hour, minute] = new Date(notification?.attributes?.createdAt)
    .toLocaleTimeString()
    .slice(0, 7)
    .split(':');

  const date = new Date(appointment?.attributes?.startDate);

  const startDay = date.getDate();
  const startMonth = date.toLocaleString('default', { month: 'long' });
  const startYear = date.getFullYear();

  return (
    <NotificationContainer>
      <FlexHorizontal>
        <div className='icon-container'>
          <Icon size={14} name='tool-1' />
        </div>
        <FlexVertical>
          <SmallBodyCustom
            bold={true}
          >{`#${appointment.id} - ${service?.attributes?.name}`}</SmallBodyCustom>
          <SmallBodyCustom
            grey={true}
          >{`${startDay} ${startMonth}, ${startYear}`}</SmallBodyCustom>
        </FlexVertical>
      </FlexHorizontal>
      <SmallBodyCustom
        name='hour-minute'
        grey={true}
      >{`${hour}h${minute}`}</SmallBodyCustom>
    </NotificationContainer>
  );
};

const NewCandidate = (notification, candidate) => {
  let [hour, minute] = new Date(notification?.attributes?.createdAt)
    .toLocaleTimeString()
    .slice(0, 7)
    .split(':');

  return (
    <NotificationContainer>
      {candidate && (
        <>
          <FlexHorizontal>
            <div className='icon-container'>
              <Avatar size='small' user={candidate.attributes} />
            </div>
            <FlexVertical>
              <SmallBodyCustom
                bold={true}
              >{`#${candidate.id} - ${candidate.attributes.fullName}`}</SmallBodyCustom>
              <SmallBodyCustom grey={true}>
                {candidate.attributes.serviceNames}
              </SmallBodyCustom>
            </FlexVertical>
          </FlexHorizontal>
          <SmallBodyCustom grey={true}>{`${hour}h${minute}`}</SmallBodyCustom>
        </>
      )}
    </NotificationContainer>
  );
};

const NewProviderFormSubmitted = (notification, candidate) => {
  let [hour, minute] = new Date(notification.attributes.createdAt)
    .toLocaleTimeString()
    .slice(0, 7)
    .split(':');

  return (
    <NotificationContainer>
      <FlexHorizontal>
        <div className='icon-container'>
          <Avatar size='small' user={candidate.attributes} />
        </div>
        <FlexVertical>
          <SmallBodyCustom
            bold={true}
          >{`#${candidate.id} - ${candidate.attributes.fullName}`}</SmallBodyCustom>
          <SmallBodyCustom grey={true}>
            {notification.attributes.message ?? 'message'}
          </SmallBodyCustom>
        </FlexVertical>
      </FlexHorizontal>
      <SmallBodyCustom grey={true}>{`${hour}h${minute}`}</SmallBodyCustom>
    </NotificationContainer>
  );
};

export default NotificationRow;
