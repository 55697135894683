import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';

import useSettings from 'Hooks/useSettings';
import useAdmin from 'Hooks/admins/useAdmin';
import useProvider from 'Hooks/providers/useProvider';
import useCandidateLead from 'Hooks/candidateLeads/useCandidateLead';

import { customSelectStyles } from 'Components/ModalContent/style';
import Select from 'Components/Select';

import { GridCol, Icon, Avatar, Switcher } from 'ui-55';
import {
  HeadingDetail,
  SmallBodyDetail,
  StyledProviderStatusCard,
  Line,
  AdminContainer,
  AddAdmin,
  ProviderStatus,
  StatusSelect,
  ToggleAdmin,
} from './style';

const ProviderStatusCard = ({ provider, providerProps }) => {
  const t = useTranslate('specialists');
  const b = useTranslate('badges');
  const { settings } = useSettings();

  const [providerIsActive, setProviderIsActive] = useState(
    provider?.attributes?.status === 'active'
  );

  const { updateProvider, updateProviderLoading } = useProvider();

  const candidateLeadId =
    provider?.relationships?.candidateLead?.data?.id ||
    providerProps?.relationships?.candidateLead?.data?.id;

  const { candidateLead, updateCandidateLead } = useCandidateLead({
    candidateLeadId: candidateLeadId,
  });

  const adminId = candidateLead?.relationships?.assignedTo?.data?.id;
  const { admin } = useAdmin({ adminId: adminId });
  const [showAdminSelect, setShowAdminSelect] = useState(false);

  useEffect(() => {
    setProviderIsActive(provider?.attributes?.status === 'active');
  }, [provider.attributes.status, updateProviderLoading]);

  const currentStatus = settings.candidate_lead_status?.find(
    (e) => e.label === candidateLead?.attributes?.status
  );
  const defaultValue = currentStatus
    ? {
        ...currentStatus,
        label: b(currentStatus.label),
      }
    : '';

  const options = settings.candidate_lead_status?.map((opt) => ({
    ...opt,
    label: b(opt.label),
  }));

  const handleToggleAdminSelect = () => {
    setShowAdminSelect(!showAdminSelect);
  };

  const handleSelectAdmin = (e) => {
    const payload = { assigned_to_id: parseInt(e.value) };
    updateCandidateLead(candidateLead?.id, payload);
    setShowAdminSelect(false);
  };

  const handleSelectStatus = (e) => {
    const payload = { status: e.value };
    updateCandidateLead(candidateLead?.id, payload);
  };

  const handleToggleActive = () => {
    const payload = { status: providerIsActive ? 'inactive' : 'active' };
    updateProvider(provider?.id, payload);
  };

  return (
    <StyledProviderStatusCard>
      <ProviderStatus>
        <div>
          <HeadingDetail size={6}>{t('state')}:</HeadingDetail>
          <StatusSelect
            options={options}
            overrideStyles={customSelectStyles}
            onChange={(e) => handleSelectStatus(e)}
            placeholder={defaultValue?.label}
            styleProps={{ type: 'candidateLead' }}
            value={defaultValue}
          />
        </div>

        {provider?.attributes?.status && (
          <Line>
            <GridCol size={6}>
              <SmallBodyDetail grey={true}>{t('active')}:</SmallBodyDetail>
            </GridCol>
            <GridCol size={6}>
              <Switcher
                checked={providerIsActive}
                disabled={candidateLead?.attributes?.status !== 'accepted'}
                action={handleToggleActive}
              />
            </GridCol>
          </Line>
        )}

        {provider?.attributes?.responsiveness && (
          <Line>
            <GridCol size={6}>
              <SmallBodyDetail grey={true}>
                {t('responsiveness')}:
              </SmallBodyDetail>
            </GridCol>
            <GridCol size={6}></GridCol>
          </Line>
        )}

        {providerProps && (
          <Line>
            <GridCol size={6}>
              <SmallBodyDetail grey={true}>
                {t('totalServices')}:
              </SmallBodyDetail>
            </GridCol>
            <GridCol size={6}>
              <SmallBodyDetail>
                {providerProps?.finishedServicesCount || '0'}
              </SmallBodyDetail>
            </GridCol>
          </Line>
        )}
      </ProviderStatus>
      <AdminContainer>
        <div>
          <HeadingDetail size={6}>{t('responsible')}:</HeadingDetail>

          {showAdminSelect ? (
            <Select
              options={settings?.admins}
              onChange={handleSelectAdmin}
              isMenuOpen={true}
            />
          ) : (
            <ToggleAdmin onClick={handleToggleAdminSelect}>
              {admin ? (
                <Avatar user={admin.attributes} hasText={true} />
              ) : (
                <AddAdmin>
                  <Icon name='Plus' />
                  {t('addAdmin')}
                </AddAdmin>
              )}
            </ToggleAdmin>
          )}
        </div>
      </AdminContainer>
    </StyledProviderStatusCard>
  );
};

export default ProviderStatusCard;

ProviderStatusCard.propTypes = {
  provider: PropTypes.object,
  providerProps: PropTypes.object,
};
