import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import { makeGetClient } from 'Redux/clients/selectors';
import { makeGetProvider } from 'Redux/providers/selectors';
import { makeGetService } from 'Redux/services/selectors';
import { makeGetAdmin } from 'Redux/admin/selectors';

import { Col } from 'Components/Layout';
import { Avatar, Icon, Badge } from 'ui-55';

import StyledRequestCard, {
  Status,
  BadgeContainer,
  Details,
  DateDetails,
  IconContainer,
} from './style';

const RequestCard = ({
  appointment,
  listPosition,
  action,
}) => {
  const t = useTranslate('requests');
  const b = useTranslate('badges');

  const makeAdmin = useMemo(makeGetAdmin, []);
  const admin = useSelector(state =>
    makeAdmin(state, appointment.relationships?.admin?.data?.id)
  );

  const makeClient = useMemo(makeGetClient, []);
  const client = useSelector(state =>
    makeClient(state, appointment.relationships?.client?.data?.id)
  );

  const makeProvider = useMemo(makeGetProvider, []);
  const provider = useSelector(state =>
    makeProvider(state, appointment.relationships?.provider?.data?.id)
  );

  const makeService = useMemo(makeGetService, []);
  const service = useSelector(state =>
    makeService(state, appointment.relationships?.service?.data?.id)
  );

  return (
    <StyledRequestCard onClick={action} listPosition={listPosition}>
      <Col size={4} justify='center'>
        <Status>
          <BadgeContainer>
            <Badge
              translate={b}
              text={appointment?.attributes.status}
              status={appointment?.attributes.status}
            />
          </BadgeContainer>
          {service?.attributes.name && <span>{service.attributes.name}</span>}
        </Status>
      </Col>

      <Col size={3} justify='center'>
        <Details>
          <p>
            {t('client')}:{' '}
            {client?.attributes.fullName && (
              <span>{client.attributes.fullName}</span>
            )}
          </p>
          <p>
            {t('specialist')}:{' '}
            {provider?.attributes.fullName ? (
              <span>{provider.attributes.fullName}</span>
            ) : (
              <span></span>
            )}
          </p>
        </Details>
      </Col>

      <Col size={3} justify='center'>
        {appointment?.attributes.recurrent ? (
          <DateDetails>
            <IconContainer>
              <Icon name='repeat' />
            </IconContainer>
            <span>{t('recurring')}</span>
          </DateDetails>
        ) : (
          <DateDetails>
            {appointment?.attributes.deliveredOn && (
              <>
                <IconContainer>
                  <Icon name='calendar' />
                </IconContainer>
                {appointment?.attributes.deliveredOn && (
                  <span>
                    {new Date(
                      appointment?.attributes.deliveredOn
                    ).toLocaleDateString('PT-PT')}
                  </span>
                )}
              </>
            )}
          </DateDetails>
        )}
      </Col>

      <Col size={2}>
        {admin ? (
          <Avatar size='small' hasText={true} user={admin.attributes} />
        ) : (
          <div></div>
        )}
      </Col>
    </StyledRequestCard>
  );
};

RequestCard.propTypes = {
  appointment: PropTypes.object,
  providerId: PropTypes.string,
  clientId: PropTypes.string,
  adminId: PropTypes.string,
  serviceId: PropTypes.string,
  listPosition: PropTypes.string,
};

RequestCard.defaultProps = {
  listPosition: 'middle',
};

export default RequestCard;
