import styled from 'styled-components';

const KanbanColumn = styled.div`
  background-color: ${props => props.theme.colors.lightestBeige};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px 16px;
  min-width: 260px;
  position: relative;
  opacity: ${({ invalid }) => (invalid ? 0.4 : 1)};

  .items > * {
    margin-bottom: 16px;
  }
`;

export const ColumnCardsContainer = styled.div`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    margin-top: ${props => props.theme.margin * 2}px;
    height: 4px;
    width: 4px;
    background-color: ${props => props.theme.colors.lightestBeige};
  }

  &::-webkit-scrollbar-thumb {
    margin-top: 30px;
    background: ${props => props.theme.colors.darkBlue};
    border-radius: 30px;
  }
`;

export const Header = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: ${props => props.theme.colors.lightestBeige};
  align-items: baseline;
  justify-content: space-between;
  width: 85%;
  padding: 0px 30px;
  margin-bottom: ${props => (props.hasCreateButton ? '64px' : '0')};

  h3 {
    font-size: 16px;
  }
`;

export const IconContainer = styled.div`
  path {
    fill: ${props => props.theme.colors.grey};
  }
`;

export const CreateNewRequestContainer = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 14px;
  position: absolute;
  top: 80px;
  left: 16px;
  color: ${props => props.theme.colors.brand.orange};
  svg {
    height: 28px;
    width: 28px;
    margin-right: 12px;
  }
  path {
    fill: ${props => props.theme.colors.brand.orange};
  }
  :hover {
    cursor: pointer;
  }
`;

export default KanbanColumn;
