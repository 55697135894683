import { useMemo, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/providerContacts';

const useProviderContact = ({
  shouldFetch = false,
  providerContactId
} = {}) => {
  const dispatch = useDispatch();
  const {
    getProviderContact,
    bulkCreateProviderContacts,
    updateProviderContact
  } = actions;
  const {
    // show
    makeGetProviderContact,
    getProviderContactLoading,
    getProviderContactError,
    // bulk create
    getBulkCreateProviderContactsLoading,
    getBulkCreateProviderContactsSuccess,
    // update
    getUpdateProviderContactLoading,
    getUpdateProviderContactFail,
    getUpdateProviderContactSuccess
  } = selectors;

  // SHOW
  const makeProviderContact = useMemo(makeGetProviderContact, []);
  const providerContact = useSelector(state =>
    makeProviderContact(state, providerContactId)
  );

  const providerContactLoading = useSelector(
    state => getProviderContactLoading(state),
    shallowEqual
  );

  const providerContactError = useSelector(
    state => getProviderContactError(state),
    shallowEqual
  );

  const dispatchGetProviderContact = useCallback(
    id => {
      getProviderContact(id, dispatch);
    },
    [dispatch, getProviderContact]
  );

  useEffect(() => {
    if (
      shouldFetch &&
      providerContactId &&
      !providerContactLoading &&
      !providerContactError
    ) {
      // dispatchGetProviderContact(providerContactId);
    }
  }, [
    dispatchGetProviderContact,
    shouldFetch,
    providerContact,
    providerContactId,
    providerContactLoading,
    providerContactError
  ]);

  // BULK CREATE PROVIDER CONTACTS
  const dispatchBulkCreateProviderContacts = useCallback(
    payload => {
      bulkCreateProviderContacts(dispatch, payload);
    },
    [bulkCreateProviderContacts, dispatch]
  );

  const bulkCreateProviderContactsLoading = useSelector(
    state => getBulkCreateProviderContactsLoading(state),
    shallowEqual
  );

  const bulkCreateProviderContactsSuccess = useSelector(
    state => getBulkCreateProviderContactsSuccess(state),
    shallowEqual
  );

  // UPDATE
  const dispatchUpdateProviderContact = useCallback(
    (id, payload, cb) => {
      updateProviderContact(dispatch, id, payload, cb);
    },
    [dispatch, updateProviderContact]
  );

  const updateProviderContactLoading = useSelector(state =>
    getUpdateProviderContactLoading(state)
  );
  const updateProviderContactFail = useSelector(state =>
    getUpdateProviderContactFail(state)
  );
  const updateProviderContactSuccess = useSelector(state =>
    getUpdateProviderContactSuccess(state)
  );

  return {
    // show
    getProviderContact: dispatchGetProviderContact,
    providerContact,
    providerContactLoading,
    providerContactError,
    // bulk create provider contacts
    bulkCreateProviderContacts: dispatchBulkCreateProviderContacts,
    bulkCreateProviderContactsLoading,
    bulkCreateProviderContactsSuccess,
    // update
    updateProviderContact: dispatchUpdateProviderContact,
    updateProviderContactLoading,
    updateProviderContactFail,
    updateProviderContactSuccess
  };
};

export default useProviderContact;
