import { ACTION_TYPES } from './actions';

const initialState = {
  // show
  invoiceLoading: false,
  invoiceLoaded: false,
  invoiceError: false,
  // index
  invoicesLoading: false,
  invoicesError: false,
  invoicesLoaded: false,
  // udpate
  updateInvoiceFail: false,
  updateInvoiceLoading: false,
  updateInvoiceSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // SHOW
    case ACTION_TYPES.GET_INVOICE:
      return {
        ...state,
        invoiceLoading: true,
      };
    case ACTION_TYPES.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceLoading: false,
        invoiceError: false,
        invoiceLoaded: true,
      };
    case ACTION_TYPES.GET_INVOICE_FAIL:
      return {
        ...state,
        invoiceError: action.error,
        invoiceLoading: false,
        invoiceLoaded: true,
      };

    // INDEX
    case ACTION_TYPES.GET_INVOICES:
      return {
        ...state,
        invoicesLoading: true,
      };
    case ACTION_TYPES.GET_INVOICES_FAIL:
      return {
        ...state,
        invoicesError: action.error,
        invoicesLoading: false,
        invoicesLoaded: true,
      };
    case ACTION_TYPES.GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoicesLoaded: true,
        [action.meta.filter
          ? action.meta.filter
          : 'activities']: action.payload.data.map((d) => d.id),

        loadedQueries: {
          ...state.loadedQueries,
          [action.meta.filter && action.meta.filter]: {
            loaded: true,
            error: false,
          },
        },
      };

    // UPDATE
    case ACTION_TYPES.UPDATE_INVOICE:
      return {
        ...state,
        updateInvoiceLoading: true,
      };
    case ACTION_TYPES.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        updateInvoiceLoading: false,
        updateInvoiceSuccess: true,
      };
    case ACTION_TYPES.UPDATE_INVOICE_FAIL:
      return {
        ...state,
        invoiceError: action.error,
        updateInvoiceLoading: false,
        updateInvoiceFail: true,
      };
    case ACTION_TYPES.GET_PROVIDER_INVOICES:
      return {
        ...state,
        invoicesLoading: true,
        invoicesError: null,
      };
    case ACTION_TYPES.GET_PROVIDER_INVOICES_SUCCESS:
      return {
        ...state,
        providerInvoices: action?.payload,
        invoicesLoading: false,

        invoicesError: null,
      };
    case ACTION_TYPES.GET_PROVIDER_INVOICES_FAIL:
      return {
        ...state,
        invoicesLoading: false,
        invoicesError: action?.error,
      };
    case ACTION_TYPES.PATCH_PROVIDER_INVOICE:
      return {
        ...state,
        invoicesLoading: true,
        invoicesError: null,
      };
    case ACTION_TYPES.PATCH_PROVIDER_INVOICE_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        providerInvoices: {
          data: state.providerInvoices?.data.map((e) =>
            e?.id === action?.payload?.data?.id ? action.payload.data : e
          ),
        },
        invoicesError: null,
      };
    case ACTION_TYPES.PATCH_PROVIDER_INVOICE_FAIL:
      return {
        ...state,
        invoicesLoading: false,
        invoicesError: action?.error,
      };
    default:
      return state;
  }
};
