import { useMemo, useEffect, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import {
  selectors as servicesSelectors,
  actions as servicesActions
} from 'redux/services';

const useService = ({ serviceId, shouldFetch = false } = {}) => {
  const dispatch = useDispatch();
  const { getService } = servicesActions;
  const {
    makeGetService,
    getServiceLoading,
    getServiceSuccess,
    getServiceError
  } = servicesSelectors;

  const makeService = useMemo(makeGetService, []);
  const service = useSelector(state => makeService(state, serviceId));

  const serviceLoading = useSelector(
    state => getServiceLoading(state),
    shallowEqual
  );

  const serviceSuccess = useSelector(
    state => getServiceSuccess(state),
    shallowEqual
  );

  const serviceError = useSelector(
    state => getServiceError(state),
    shallowEqual
  );

  const dispatchGetService = useCallback(
    id => {
      getService(id, dispatch);
    },
    [dispatch, getService]
  );

  useEffect(() => {
    if (shouldFetch && !service && serviceId) {
      dispatchGetService(serviceId);
    }
  }, [dispatchGetService, shouldFetch, service, serviceId]);

  return {
    service,
    serviceLoading,
    serviceSuccess,
    serviceError
  };
};

export default useService;
