import axios from 'axios';
import normalize from 'json-api-normalizer';
import { get } from 'lodash';

import CONFIG from 'Config';
import useStorage from 'Hooks/useStorage';

const { clientHeaders, setStorageAuthToken, clearStorage } = useStorage();
const instance = axios.create({
  baseURL: CONFIG.apiOrigin,
  headers: clientHeaders
});

const cleanUser = user => {
  const data = normalize(user);
  return data.admin[Object.keys(data.admin)[0]];
};

instance.interceptors.response.use(
  res => {
    const authorizationHeader = get(res, 'headers.authorization');
    if (authorizationHeader) {
      instance.defaults.headers.common['authorization'] = authorizationHeader;
      setStorageAuthToken({
        token: authorizationHeader,
        tokenDate: new Date(),
        user: cleanUser(res.data)
      });
    }
    const logoutMetaData = get(res, 'data.meta.logout');
    if (logoutMetaData) {
      clearStorage();
    }
    return res;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
