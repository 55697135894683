import styled from 'styled-components';
import { Col, SmallBody } from 'ui-55';

export const StyledTableRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige}
`;

export const ServiceTotalRow = styled(StyledTableRow)`
  border-bottom: 1px solid ${props => props.theme.colors.darkBlue};
  font-weight: bold;
`;

export const TotalCell = styled(Col)`
  background-color: ${props => props.theme.colors.lightestBeige};
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};
  border-top: 1px solid ${props => props.userType === 'Client' 
    ? props.theme.colors.mediumBeige
    : 'transparent'};
  font-weight: bold;
`;

export const UnderlineCell = styled(Col)`
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};
`;

export const InputContainer = styled(UnderlineCell)`
  height: 48px;
`;

export const SmallBodyDetail = styled(SmallBody)`
  margin: 0px;
  padding: 0px;
`;

export const PaymentInput = styled.input`
  width: 48px;
  height: 48px;
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  border-radius: 2px;
  padding-left: 8px;
`;

export const DescriptionInput = styled(PaymentInput)`
  width: 200px;
`;