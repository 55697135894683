import React from 'react';
import PropTypes from 'prop-types';

import ProviderServiceCard from 'Components/ProviderServiceCard';
import useSettings from 'hooks/useSettings';

import { Heading, Icon, Avatar, Col } from 'ui-55';
import {
  IconContainer,
  IconDetails,
  BodyDetail,
  StyledProviderCard,
  Provider,
  AvatarContainer,
  Services,
  ContactDetails,
  Empty
} from './style';
import { useTranslate } from '../../features/polyglot';

const ProviderDetailsCard = ({ provider }) => {
  const { settings } = useSettings();
  const translateLanguage = useTranslate('languageSelect');

  return (
    <StyledProviderCard>
      <Provider>
        <AvatarContainer>
          {provider && <Avatar user={provider.attributes} size='large' />}
        </AvatarContainer>
        <Col padding={0}>
          <Heading size={4}>{provider?.attributes?.fullName}</Heading>

          <ContactDetails>
            <IconDetails>
              <IconContainer>
                <Icon name='phone' />
              </IconContainer>
              <BodyDetail>{provider?.attributes?.phoneNumber}</BodyDetail>
            </IconDetails>

            {(provider?.attributes?.languageCode && <IconDetails>
              <IconContainer>
                <Icon name='Language' />
              </IconContainer>
              <BodyDetail>{translateLanguage(provider?.attributes?.languageCode)}</BodyDetail>
            </IconDetails>)}

            <IconDetails>
              <IconContainer marginBottom={true}>
                <Icon name='mail' />
              </IconContainer>
              <BodyDetail>{provider?.attributes?.email}</BodyDetail>
            </IconDetails>
          </ContactDetails>
        </Col>
      </Provider>

      {provider?.relationships?.providerServices?.data && (
        <Services>
          {provider?.relationships?.providerServices?.data?.map(
            (service, index) => (
              <ProviderServiceCard
                settings={settings}
                key={'providerServiceCard' + index}
                providerServiceId={service.id}
              />
            )
          )}
          {provider?.relationships?.providerServices?.data?.length % 2 !==
            0 && <Empty />}
        </Services>
      )}
    </StyledProviderCard>
  );
};

export default ProviderDetailsCard;

ProviderDetailsCard.propTypes = {
  provider: PropTypes.object
};
