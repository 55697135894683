import { useCallback } from 'react';
import kc from 'lodash.kebabcase';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import {
  actions as citiesActions,
  selectors as citiesSelectors
} from 'redux/cities';

const useCities = () => {
  const dispatch = useDispatch();
  const { getCitiesListing } = citiesActions;
  const { getCities, getLoading, getError, getLoaded } = citiesSelectors;

  const cities = useSelector(
    state => getCities(state)?.data || [],
    shallowEqual
  );

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const dispatchGetCitiesListing = useCallback(() => {
    getCitiesListing(dispatch);
  }, [dispatch, getCitiesListing]);

  const getCityFromDistrict = useCallback(
    answers => {
      return cities.find(
        e => kc(e.attributes?.name) === kc(answers['district'])
      )?.id;
    },
    [cities]
  );

  return {
    dispatchGetCitiesListing,
    getCityFromDistrict,
    cities,
    error,
    loading,
    loaded
  };
};

export default useCities;
