import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../../features/polyglot';

import useAppointment from 'hooks/appointments/useAppointment';

import InvoiceItemsTable from 'components/InvoiceItemsTable';

import { Heading, Button } from 'ui-55';

import {
  // PaymentDetails,
  // PaymentCol,
  // SmallBodyDetail,
  // Dot,
  // TableContainer,
  ButtonContainer
} from './styles';

const PaymentRender = ({ appointment }) => {
  const t = useTranslate('payment');

  // const { invoices } = useInvoicesListing({
  //   filters: { appointment_id: appointment?.id },
  //   shouldFetch: true
  // })

  const { updateAppointment } = useAppointment();

  // const [itemIds, setItemIds] = useState(appointment?.relationships?.invoices?.data)
  // useEffect(() => {
  //   if (invoices) {
  //     setItemIds(appointment?.relationships?.invoices?.data)
  //   }
  // }, [appointment?.relationships?.invoices])


  // const totalInvoicesPaid = (Math.round(appointment?.attributes?.totalInvoicesPaid) / 100).toFixed(2);
  // const totalInvoicesOpen = (Math.round(appointment?.attributes?.totalInvoicesOpen) / 100).toFixed(2);

  const [updateData, setUpdateData] = useState({});
  const handleClientTableChange = (e) => {
    const amountOff = e.amountOff ? parseFloat(e.amountOff) : 0;
    const newUpdateData = {
      ...updateData,
      line_items: e.lineItems,
      amount_off: amountOff.toFixed(),
      total_price_with_tax: e.totalPriceWithTax,
    }
    setUpdateData(newUpdateData)
  }
  const handleProviderTableChange = (e) => {
    const newUpdateData = {
      ...updateData,
      override_provider_price: e.overrideProviderPrice
    }
    setUpdateData(newUpdateData)
  }

  const handleUpdate = () => {
    updateAppointment(updateData, appointment?.id)
  }

  return (
    <>
      {appointment?.attributes?.lineItems &&
      Object.keys(appointment?.attributes?.lineItems).length > 0
      ?
        <>
          <Heading size={5}>{t('clientInvoiceTable')}</Heading>
          <InvoiceItemsTable
            userType="Client"
            appointment={appointment}
            handleInputChange={handleClientTableChange}
          />

          <Heading size={5}>{t('providerInvoiceTable')}</Heading>
          <InvoiceItemsTable
            userType="Provider"
            appointment={appointment}
            handleInputChange={handleProviderTableChange}
          />

          {![
            'awaiting_service_payment',
            'awaiting_visit_payment'
          ].includes(appointment?.attributes?.status) &&
            <ButtonContainer>
              <Button
                text={t('update')}
                onClick={() => handleUpdate()}
                isFullWidth
              />
            </ButtonContainer>
          }
        </>
      :
        <>
        Confirm hours to generate payment details
        </>
      }


      {/* <PaymentDetails>
        <PaymentCol size={6}>
          <Dot paid={true}/>
          <SmallBodyDetail>{t('totalPaid')}: {totalInvoicesPaid}€</SmallBodyDetail>
        </PaymentCol>
        <PaymentCol size={6}>
          <Dot />
          <SmallBodyDetail>{t('isOpen')}: {totalInvoicesOpen}€</SmallBodyDetail>
        </PaymentCol>
      </PaymentDetails>

      <TableContainer>
        <PaymentTable itemIds={itemIds}/>
      </TableContainer> */}
    </>
  )
}

export default PaymentRender;

PaymentRender.propTypes = {
  appointment: PropTypes.object
}