import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'redux/admin';

const useAdmin = ({ shouldFetch = false, adminId } = {}) => {
  const dispatch = useDispatch();
  const { getAdmin, inviteAdmin, updateAdmin } = actions;
  const {
    getAdminLoading,
    getAdminError,
    getAdminLoaded,
    makeGetAdmin,
    // invite admin
    getInviteAdminLoading,
    getInviteAdminSuccess,
    // update admin
    getUpdateAdminLoading,
    getUpdateAdminFail,
    getUpdateAdminSuccess
  } = selectors;

  const error = useSelector(state => getAdminError(state));
  const loading = useSelector(state => getAdminLoading(state), shallowEqual);
  const loaded = useSelector(state => getAdminLoaded(state), shallowEqual);

  const makeAdmin = useMemo(makeGetAdmin, []);
  const admin = useSelector(state => makeAdmin(state, adminId));

  const dispatchGetAdmin = useCallback(
    id => {
      getAdmin(dispatch, id);
    },
    [dispatch, getAdmin]
  );

  useEffect(() => {
    if (shouldFetch && !admin && adminId) {
      dispatchGetAdmin(adminId);
    }
  }, [dispatchGetAdmin, shouldFetch, admin, adminId]);

  // INVITE ADMIN
  const dispatchInviteAdmin = useCallback(
    payload => {
      inviteAdmin(dispatch, payload);
    },
    [inviteAdmin, dispatch]
  );

  const inviteAdminLoading = useSelector(
    state => getInviteAdminLoading(state),
    shallowEqual
  );

  const inviteAdminSuccess = useSelector(
    state => getInviteAdminSuccess(state),
    shallowEqual
  );

  // UPDATE ADMIN
  const dispatchUpdateAdmin = useCallback(
    (payload, id) => {
      updateAdmin(dispatch, payload, id);
    },
    [dispatch, updateAdmin]
  );

  const updateAdminLoading = useSelector(state => getUpdateAdminLoading(state));
  const updateAdminFail = useSelector(state => getUpdateAdminFail(state));
  const updateAdminSuccess = useSelector(state => getUpdateAdminSuccess(state));

  return {
    admin,
    error,
    loaded,
    loading,
    // invite admin
    inviteAdmin: dispatchInviteAdmin,
    inviteAdminLoading,
    inviteAdminSuccess,
    // update admin
    updateAdmin: dispatchUpdateAdmin,
    updateAdminLoading,
    updateAdminFail,
    updateAdminSuccess
  };
};

export default useAdmin;
