import styled from 'styled-components';
import { Body, Heading } from 'Components/Text';
import Select from 'Components/Select';
import { getBadgeColorFromStatus } from 'ui-55';
import theme from 'Theme';

const StyledServiceDetailSidebar = styled.div``;

export const StatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const selectStyles = {
         control: (provided, { status }) => ({
           ...provided,
           backgroundColor: getBadgeColorFromStatus(theme, status)
         })
       };

export const StatusSelect = styled(Select)`
  background-color: ${props => props.theme.colors.mediumBeige};
`;

export const Section = styled.div`
  border-bottom: ${props =>
    props.hasBorder ? `1px solid ${props.theme.colors.mediumBeige}` : 'none'};
  padding-bottom: ${props => (props.hasBorder ? '25px' : '32px')};
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px 0px 8px 0px;
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
`;

export const AddAssociationLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${props => (props.margin ? props.margin : '0')}px;

  svg {
    padding-bottom: 2px;
    height: 28px;
    width: 28px;
  }

  path {
    fill: ${props => props.theme.colors.brand.orange};
  }

  span {
    margin-left: 12px;
  }
`;

export const LinkDetail = styled.span`
  color: ${props => props.theme.colors.brand.orange};

  :hover {
    color: ${props => props.theme.colors.brand.orange};
  }
  :active {
    color: ${props => props.theme.colors.brand.orange};
  }
  :visited {
    color: ${props => props.theme.colors.brand.orange};
  }
  :focus {
    color: ${props => props.theme.colors.brand.orange};
  }
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.lightBeige};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: ${props => (props.marginBottom ? '16px' : '0px')};

  svg {
    width: 14px;
  }
`;

export const IconDetails = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default StyledServiceDetailSidebar;
