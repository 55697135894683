import React from 'react';
import styled from "styled-components";
import { useTranslate } from '../../polyglot';
import useSettings from 'hooks/useSettings';
import { Avatar } from 'ui-55';


export const StyledTableRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  height: 80px;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};

  :hover {
    background-color: ${props => props.theme.colors.lightestBeige};
    cursor: pointer;
  }
`;

const TableRow = ({ item, action }) => {
  return (
    <StyledTableRow onClick={() => action && action(item)}>
      <Col size={10}>{item.attributes.name}</Col>
      <Col size={2}>{item.attributes.country}</Col>
    </StyledTableRow>
  );
};


const media = {
  mobile: styles => `
    @media only screen and (max-width: 375px) {
      ${styles}
    }
  `,
  tablet: styles => `
    @media only screen and (max-width: 768px) {
      ${styles}
    }
  `,
  smallDesktop: styles => `
    @media only screen and (max-width: 1024px) {
      ${styles}
    }
  `,
  desktop: styles => `
    @media only screen and (min-width: 1024px) {
      ${styles}
    }
  `
};

const StyledClientTable = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBlue};
`;

const Col = styled.div`
  flex: ${props => props.size};
  display: flex;
  flex-flow: column nowrap;
  align-items: ${props => (props.center ? 'center' : 'flex-start')};
  justify-content: ${props => (props.justify ? props.justify : 'none')};
  padding: ${props => props.padding || props.theme.margin}px;
  ${props =>
    props.collapse &&
    media[props.collapse](`
  display: none;
  `)}
  ${props => props.center && 'margin: 0 auto;'}
`;

export const Header = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.lightestBeige};
  height: 48px;
  width: 100%;
  display: flex;
  font-weight: bold;
`;


export const LanguageTable = ({ items, action }) => {
  const translate = useTranslate('clients');
  const { settings } = useSettings();
  return (
    <StyledClientTable>
      <Header>
        <Col size={9}>{translate('name')}</Col>

        <Col size={3}>{translate('country')}</Col>
      </Header>

      {items &&
        Object.keys(items).map((item, index) => {
          const hasCity = items[item]?.relationships?.cities?.data?.length > 0;
          let clientCity = null;

          if (hasCity) {
            const cityObj = items[item]?.relationships?.cities?.data[0];
            clientCity = settings?.cities.filter(c =>
              parseInt(c.value) === parseInt(cityObj.id));
            clientCity = clientCity[0].label;
          }

          return (
            <TableRow
              key={'clientTable' + index}
              item={items[item]}
              action={action}
            />
          );
        })}
    </StyledClientTable>
  );
}
