import React, { useState } from 'react';

import { ReactComponent as BoldSvg } from './icons/bold.svg';
import { ReactComponent as ItalicSvg } from './icons/italic.svg';
import { ReactComponent as EditSvg } from './icons/edit.svg';
import { ReactComponent as JustifySvg } from './icons/justify.svg';
import { ReactComponent as CenterSvg } from './icons/center.svg';
import { ReactComponent as AlignRightSvg } from './icons/align-right.svg';
import { ReactComponent as AlignLeftSvg } from './icons/align-left.svg';
import { ReactComponent as DropSvg } from './icons/drop.svg';
import { ReactComponent as LinkSvg } from './icons/link.svg';
import { ReactComponent as OListSvg } from './icons/ol.svg';
import { ReactComponent as UlistSvg } from './icons/ul.svg';
import { ReactComponent as UnderlineSvg } from './icons/underline.svg';
import { ReactComponent as HeadingSvg } from './icons/heading.svg';

import { Button, Row, Col, Body, TextInput } from 'ui-55';
import {
  EditorBtn,
  ModalContainer,
  ClickOutsideContainer,
  LinksModal
} from './style';

const ICON_MAP = {
  bold: <BoldSvg />,
  italic: <ItalicSvg />,
  edit: <EditSvg />,
  justify: <JustifySvg />,
  alignRight: <AlignRightSvg />,
  alignLeft: <AlignLeftSvg />,
  alignCenter: <CenterSvg />,
  link: <LinkSvg />,
  heading: <HeadingSvg />,
  ol: <OListSvg />,
  ul: <UlistSvg />,
  underline: <UnderlineSvg />,
  drop: <DropSvg />
};
const EditButton = props => {
  const [hrefModalOpen, setHrefModalOpen] = useState(false);
  const [selectionData, setSelectionData] = useState(null);
  const [href, setHref] = useState('');

  const getSelectionData = () => {
    const target = props.editorRef.current?.el?.current.firstChild;
    const selection = window.getSelection(target);
    const substr = selection.toString();
    setSelectionData({
      selection,
      substr,
      target
    });
  };
  const replaceSelectionWithLink = () => {
    selectionData.target.parentElement.focus();
    const value = selectionData.target.parentElement.innerHTML;
    const newValue = value.replace(
      selectionData.substr,
      `<a href=${href}>${selectionData.substr}</a>`
    );
    selectionData.target.parentElement.innerHTML = newValue;
    props.onChange({
      target: { value: newValue }
    });
    setHrefModalOpen(false);
    setHref('');
    setSelectionData(null);
  };
  return (
    <>
      <EditorBtn
        key={props.cmd}
        onMouseDown={evt => {
          evt.preventDefault(); // Avoids loosing focus from the editable area
          if (props.modal) {
            setHrefModalOpen(!hrefModalOpen);
            getSelectionData();
          } else {
            document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
          }
        }}
      >
        {ICON_MAP[props.name] ? (
          ICON_MAP[props.name]
        ) : (
          <Body>{props.name || props.cmd}</Body>
        )}
        {props.description && <Body>{props.description || ''}</Body>}
      </EditorBtn>
      {hrefModalOpen && (
        <>
          <ClickOutsideContainer onClick={() => setHrefModalOpen(false)} />
          <ModalContainer>
            <LinksModal>
              <Col>
                <Body>Insert URL</Body>
                <TextInput onChange={setHref} placeholder={props.t('link')} />
                <Row noWrap>
                  <Button
                    text={props.t('addLink')}
                    btnType='primary'
                    action={evt => {
                      evt.preventDefault(); // Avoids loosing focus from the editable area
                      replaceSelectionWithLink();
                      // document.execCommand(props.cmd, false, href);
                    }}
                  />
                  <Button
                    text={props.t('cancel')}
                    btnType='secondary'
                    onMouseDown={evt => {
                      evt.preventDefault(); // Avoids loosing focus from the editable area
                      setHrefModalOpen(false);
                    }}
                  />
                </Row>
              </Col>
            </LinksModal>
          </ModalContainer>
        </>
      )}
    </>
  );
};

export default EditButton;
