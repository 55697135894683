import { createSelector } from 'reselect';

import { getServicesEntities } from 'redux/entities/selectors';

const selectServicesState = state => state.services;

// INDEX
export const getServices = createSelector(
  getServicesEntities,
  services => services
);

export const getServicesLoading = createSelector(selectServicesState, state => {
  return state.serviceLoading || state.loading || false;
});
export const getServicesLoaded = createSelector(selectServicesState, state => {
  return state.serviceLoaded || state.loaded || false;
});
export const getServicesError = createSelector(selectServicesState, state => {
  return state.serviceError || state.error || null;
});

//FORMS
export const getServiceForms = createSelector(selectServicesState, state => {
  return state.serviceForms || [];
});

// SHOW
export const makeGetService = () =>
  createSelector(
    getServicesEntities,
    (_, serviceId) => serviceId,
    (entities, id) => entities[id]
  );

export const getServiceLoading = createSelector(selectServicesState, state => {
  return state.serviceLading || [];
});

export const getServiceError = createSelector(selectServicesState, state => {
  return state.serviceError || [];
});

export const getServiceSuccess = createSelector(selectServicesState, state => {
  return state.serviceSuccess || []
})