import React, { useCallback, useState, useEffect } from 'react';
import usePolymorph from 'hooks/usePolymorph';
import capitalize from 'lodash.capitalize';
import { Row, Loader, Col, Icon, BackofficeContainer } from 'ui-55';
import { ReactComponent as EditSvg } from 'Components/ContentEditor/icons/edit.svg';
import { useHistory } from 'react-router';
import Filters from 'components/Filters';
import { useDispatch, useSelector } from "react-redux";
import { selectLocale, setLocale } from '../polyglot/polyglotSlice';
import { useCreateLanguageMutation } from "../languages/languageSlice";
import styled from 'styled-components';
import { LanguageCreate } from 'features/languages/LanguageCreate';
import { toast } from 'react-toastify';


export const ContentRow = styled(Row)`
  justify-content: 'space-between';
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
`;

export const NestLine = styled.div`
  content: '';
  width: ${({ theme }) => theme.margin}px;
  height: 1px;
  background: ${({ theme }) => theme.colors.mediumBeige};
  margin-left: -${({ theme }) => theme.margin}px;
`;

export const ChildrenFieldsCol = styled(Col)`
  justify-content: 'space-between';
  padding-left: ${({ theme }) => theme.margin}px;
  margin-left: ${({ theme }) => theme.margin}px;
  width: calc(100% - ${({ theme }) => theme.margin * 2}px);
  padding-top: ${({ theme }) => theme.margin}px;
  margin-top: -8px;
  border-left: 1px solid ${({ theme }) => theme.colors.mediumBeige};
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.margin}px;
  &:hover {
    background: ${({ theme }) => theme.colors.lightBeige}aa;
  }
  > svg {
    margin: ${({ theme }) => theme.margin}px;
    height: 24px;
    width: 24px;
  }
`;


const AVAILABLE_FILTERS = [];
const MULTI_FILTERS = [];

export const ContentList = () => {
    const [createLanguage, status] = useCreateLanguageMutation();
    const locale = useSelector(selectLocale);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    let history = useHistory();
    const { languageContent, getContentKeys, treeComponents } = usePolymorph({
        language: locale
    });
    const [openTrees, setOpenTrees] = useState([]);
    const handleOpenModalCreate = () => {
        setOpenModalCreate(true)
    }
    useEffect(() => {
        if (!languageContent.loading && !languageContent.loaded) {
            getContentKeys();
        }
    }, [getContentKeys, languageContent]);

    const TreeRow = useCallback(
        ({ label, items = [], lastUpdate, itemKey, key }) => {
            const treeIsOpen = openTrees.indexOf(label) !== -1;
            const renderRow = (l, itx = [], updatedAt = '', nested, itmKey) => (
                <Row noWrap={true} align='center'>
                    {nested && <NestLine />}
                    <ContentRow>
                        <Col>{capitalize(l)}</Col>
                        <Col>
                            {itx.length ? (
                                <IconButton
                                    onClick={() => {
                                        if (treeIsOpen) {
                                            setOpenTrees(openTrees.filter((t) => t !== label));
                                        } else {
                                            setOpenTrees([...openTrees, label]);
                                        }
                                    }}
                                >
                                    <Icon
                                        name={treeIsOpen ? 'chevron-up' : 'chevron-down'}
                                    ></Icon>
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        history.push(`page-editor?slug=${itmKey || itemKey}`);
                                    }}
                                >
                                    <EditSvg />
                                </IconButton>
                            )}
                        </Col>
                        <Col>{updatedAt && new Date(updatedAt).toLocaleDateString('PT-PT')}</Col>
                    </ContentRow>
                </Row>
            );
            return (
                <>
                    {renderRow(label, items, lastUpdate, false, itemKey)}
                    {treeIsOpen && (
                        <ChildrenFieldsCol>
                            {items.map((itm) =>
                                renderRow(
                                    itm.label || itm.key,
                                    [],
                                    itm.updated_at,
                                    true,
                                    itm.key
                                )
                            )}
                        </ChildrenFieldsCol>
                    )}
                </>
            );
        },
        [history, openTrees]
    );

    const handleSubmit = async (values) => {
        const languageList = {
            fr: {
                name: "French",
                active: true,
                country: "France",
                code: "fr"
            },
            en: {
                name: "English",
                active: true,
                country: "England",
                code: "en-uk"
            },
            pt: {
                name: "Portuguese",
                active: true,
                country: "Portugal",
                code: "pt"
            },
            es: {
                name: "Spanish",
                active: true,
                country: "Spain",
                code: "es"
            }
        };
        const selected = languageList[values.code];
        setOpenModalCreate(false);
        await createLanguage(selected);
    };

    useEffect(() => {
        if (status.isSuccess) {
            toast.success('Idioma habilitado com sucesso!');
        }
        if (status.error) {
            toast.error('Esta requisição pode demorar, aguarde um pouco.');
        }
    }, [toast, status.error, status.isSuccess]);
    return (
        <>
            <BackofficeContainer>
                <Filters
                    hasLanguageSelect={true}
                    availableFilters={AVAILABLE_FILTERS}
                    multiFilters={MULTI_FILTERS}
                    showLayout={false}
                    hasFilters={false}
                    hasSearch={false}
                    hasAddButton={true}
                    addButtonAction={handleOpenModalCreate}
                    hasCSVLink={false}
                />
                <LanguageCreate
                    isOpen={openModalCreate}
                    action={{
                        submit: (values) => handleSubmit(values),
                        cancel: () => setOpenModalCreate(false),
                    }}
                />
                {((status.isLoading || languageContent.loading) && <Loader/>) || (
                    <Col>
                        {treeComponents.map((comp) => (
                            <TreeRow
                                itemKey={comp.key}
                                label={comp.label}
                                items={comp.elements}
                                lastUpdate={comp.updated_at}
                                key={'cms-elements' + comp.label}
                            />
                        ))}
                    </Col>
                )}
            </BackofficeContainer>
        </>
    );
};
