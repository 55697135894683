import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';

import { Button, Form } from 'ui-55';

import useSettings from 'Hooks/useSettings';
import { newClientSchema } from './newClientSchema';

import {
  StyledFormContainer,
  StyledHeading,
  modalCustonStyles,
  StyledIcon
} from './styles';
import { Row } from 'ui-55';

const NewClient = ({ isOpen, action }) => {
  const t = useTranslate('newClients');
  const cities = useSettings()?.settings?.cities;

  const handleError = errors => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
    }
  };

  return (
    <ReactModal
      appElement={document.getElementById('root')}
      style={modalCustonStyles}
      isOpen={isOpen}
    >
      <Row justify='space-between'>
        <StyledHeading size={3}>{t('newClient')}</StyledHeading>
        <span role='presentation' onClick={() => action.cancel()}>
          <StyledIcon name='Close' />
        </span>
      </Row>
      <StyledFormContainer>
        <Form
          questions={newClientSchema(cities)}
          submitLabel={t('addClient')}
          onSubmit={values => action.submit(values)}
          hiddenFields={['district_parish']}
          onError={errors => handleError(errors)}
        />
        <Button
          text={t('cancel')}
          btnType='borded'
          action={() => action.cancel()}
        />
      </StyledFormContainer>
    </ReactModal>
  );
};

NewClient.propTypes = {
  action: PropTypes.shape({ submit: PropTypes.func, cancel: PropTypes.func }),
  isOpen: PropTypes.bool
};

export default NewClient;
