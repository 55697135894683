import styled from "styled-components";

const ArchiveContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 72vh;
    padding-bottom: ${props => props.theme.margin}px
`;

export default ArchiveContent;
