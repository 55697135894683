export const getNewStatusOptions = (t, currentStatus, destinationStatus) => {
  let statusOptions = [];
  switch (currentStatus) {
    case 'received':
      statusOptions = [
        {
          label: t('analysis'),
          destination: 'analysis',
          value: 2,
          default: true,
        },
      ];
      break;
    case 'contact':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('received'),
          destination: 'received',
          value: 0,
          default: true,
        },
      ];
      break;
    case 'analysis':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
          default: true,
        },
        {
          label: t('rejected'),
          destination: 'cancelled',
          value: 12,
        },
        {
          label: t('awaiting_specialist'),
          destination: 'analysis',
          value: 3,
          default: true,
        },
      ];
      break;
    case 'awaiting_specialist':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('awaiting_visit_payment'),
          destination: 'awaiting_details',
          value: 4,
        },
        {
          label: t('awaiting_visit'),
          destination: 'awaiting_details',
          value: 5,
        },
        {
          label: t('awaiting_service_payment'),
          destination: 'awaiting_details',
          value: 7,
          default: true,
        },
      ];
      break;
    case 'awaiting_visit_payment':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('awaiting_visit'),
          destination: 'awaiting_details',
          value: 5,
          default: true,
        },
      ];
      break;
    case 'awaiting_visit':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('rejected'),
          destination: 'cancelled',
          value: 12,
        },
        {
          label: t('awaiting_service_payment'),
          destination: 'awaiting_details',
          value: 7,
          default: true,
        },
      ];
      break;
    case 'awaiting_interview':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('awaiting_service_payment'),
          destination: 'awaiting_details',
          value: 7,
          default: true,
        },
      ];
      break;
    case 'awaiting_service_payment':
      statusOptions = [
        {
          label: t('awaiting_conclusion'),
          destination: 'awaiting_conclusion',
          value: 8,
          default: true,
        },
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('closed'),
          destination: 'closed',
          value: 10,
        },
      ];
      break;
    case 'awaiting_conclusion':
      statusOptions = [
        {
          label: t('cancelled'),
          destination: 'cancelled',
          value: 11,
        },
        {
          label: t('rejected'),
          destination: 'cancelled',
          value: 12,
        },
        {
          label: t('complete'),
          destination: 'closed',
          value: 9,
          default: true,
        },
      ];
      break;
    case 'rejected':
      statusOptions = [
        {
          label: t('analysis'),
          destination: 'analysis',
          value: 2,
          default: true,
        },
      ];
      break;
    case 'cancelled':
      statusOptions = [
        {
          label: 'disabled',
          value: true,
          default: true,
          disabled: true,
        },
      ];
      break;
    case 'closed':
      statusOptions = [
        {
          label: 'disabled',
          value: true,
          default: true,
          disabled: true,
        },
      ];
      break;
    default:
      statusOptions = [
        {
          label: 'disabled',
          value: true,
          default: true,
          disabled: true,
        },
      ];
      break;
  }
  return destinationStatus
    ? statusOptions.filter((opt) => opt.destination === destinationStatus)
    : statusOptions;
};

export const getStringFromStatusValue = (status) => {
  switch (status) {
    case 0:
      return 'received';
    case 1:
      return 'contact';
    case 2:
      return 'analysis';
    case 3:
      return 'awaiting_specialist';
    case 4:
      return 'awaiting_visit_payment';
    case 5:
      return 'awaiting_visit';
    case 6:
      return 'awaiting_meeting';
    case 7:
      return 'awaiting_service_payment';
    case 8:
      return 'awaiting_conclusion';
    case 9:
      return 'complete';
    case 10:
      return 'closed';
    case 11:
      return 'cancelled';
    case 12:
      return 'rejected';
    default:
      return null;
  }
};

export const getValueFromStatusString = (status) => {
  switch (status) {
    case 'received':
      return 0;
    case 'contact':
      return 1;
    case 'analysis':
      return 2;
    case 'awaiting_specialist':
      return 3;
    case 'awaiting_visit_payment':
      return 4;
    case 'awaiting_visit':
      return 5;
    case 'awaiting_meeting':
      return 6;
    case 'awaiting_service_payment':
      return 7;
    case 'awaiting_conclusion':
      return 8;
    case 'complete':
      return 9;
    case 'closed':
      return 10;
    case 'cancelled':
      return 11;
    case 'rejected':
      return 12;
    default:
      return null;
  }
};

export const getDefaultValueFromDestinationCol = (destinationCol) => {
  switch (destinationCol) {
    case 'received':
      return 0;
    case 'analysis':
      return 2;
    case 'awaiting_details':
      return 4;
    case 'active':
      return 8;
    case 'closed':
      return 9;
    case 'cancelled':
      return 11;
    default:
      return null;
  }
};

export const isColumnValid = (status, column) => {
  switch (column) {
    case 'received':
      return status === 'received';
    case 'analysis':
      return ['received', 'rejected'].includes(status);
    case 'awaiting_details':
      return [
        'awaiting_specialist',
        'awaiting_visit',
        'awaiting_visit_payment',
        'awaiting_meeting',
      ].includes(status);
    case 'awaiting_conclusion':
      return status === 'awaiting_service_payment';
    case 'closed':
      return ['awaiting_service_payment', 'awaiting_conclusion'].includes(
        status
      );
    case 'cancelled':
      return [
        'contact',
        'analysis',
        'awaiting_specialist',
        'awaiting_visit',
        'awaiting_visit_payment',
        'awaiting_meeting',
        'awaiting_service_payment',
        'awaiting_conclusion',
      ].includes(status);
    default:
      return true;
  }
};

export const statusStrings = {
  active: 'Activo',
  inactive: 'Inactivo',
  contact: 'Contacto',
  received: 'Recebido',
  complete: 'Completo',
  closed: 'Fechado',
  analysis: 'Análise',
  awaiting_specialist: 'Aguarda Especialista',
  awaiting_visit_payment: 'Aguarda Pagamento Visita',
  awaiting_interview: 'Aguarda Entrevista',
  awaiting_payment: 'Aguarda Pagamento Serviço',
  awaiting_conclusion: 'Aguarda Conclusão',
  awaiting_visit: 'Aguarda Visita',
  awaiting_meeting: 'Aguarda Entevista',
  awaiting_service_payment: 'Aguarda Pagamento Serviço',
  cancelled: 'Cancelado',
  rejected: 'Rejeitado',
  new_candidate: 'Candidato',
  awaiting_details: 'Aguarda Detalhes',
  reopened: 'Reaberto',
  accepted: 'Aceite',
};
