import React, { useRef, useState, useEffect } from 'react';

import sanitizeHtml from 'sanitize-html';

import { Button, Col } from 'ui-55';
import { StyledEditor, ControlsRow } from './style';

import EditorButton from './EditorButton';
import EditorDropdown from './EditorDropdown';

const sanitizeConf = {
  allowedTags: [
    'b',
    'i',
    'em',
    'strong',
    'a',
    'p',
    'h1',
    'div',
    'ol',
    'ul',
    'li'
  ],
  allowedAttributes: { a: ['href'], div: ['style'], li: ['style'] }
};

const Editor = ({ value = '', t, onSaveChanges, onCancel, disabled }) => {
  const htmlContent = useRef(value);
  const editorRef = useRef(null);
  const [newValue, setNewValue] = useState(htmlContent.current);
  const onChange = e => {
    htmlContent.current = sanitizeHtml(e.target.value || '', sanitizeConf);
  };
  // const sanitize = e => {
  //   setHtmlContent(e.target.value, sanitizeConf);
  // };

  useEffect(() => {
    if (value !== newValue) {
      htmlContent.current = sanitizeHtml(value || '', sanitizeConf);
      setNewValue(value);
    }
  }, [newValue, value]);
  return (
    <Col>
      <StyledEditor
        className='editable'
        tagName='div'
        ref={editorRef}
        onBlur={() => {
          htmlContent.current = value;
        }}
        disabled={disabled}
        html={htmlContent.current}
        onChange={onChange}
      />
      {!disabled && (
        <ControlsRow>
          <Col size={1} />
          <EditorButton cmd='bold' name='bold' />
          <EditorButton cmd='italic' name='italic' />
          <EditorButton cmd='underline' name='underline' />
          <EditorDropdown
            name='alignRight'
            items={[
              {
                cmd: 'justifyFull',
                name: 'justify',
                description: t('justify')
              },
              {
                cmd: 'justifyLeft',
                name: 'alignLeft',
                description: t('alignLeft')
              },
              {
                cmd: 'justifyRight',
                name: 'alignRight',
                description: t('alignRight')
              },
              {
                cmd: 'justifyCenter',
                name: 'alignCenter',
                description: t('alignCenter')
              }
            ]}
          />
          <EditorButton cmd='insertUnorderedList' name='ul' />
          <EditorButton cmd='insertOrderedList' name='ol' />
          <EditorButton
            cmd='createLink'
            name='link'
            modal
            t={t}
            onChange={onChange}
            editorRef={editorRef}
          />
          {/*
        <EditButton cmd='formatBlock' name='formatBlock' arg='H1' name='heading' />
         */}
          <Col size={2} />
          <Button
            text={t('saveChanges')}
            btnType='primary'
            onMouseDown={e => {
              e.preventDefault();
              onSaveChanges(sanitizeHtml(htmlContent.current, sanitizeConf), e);
            }}
          />
          <Button
            text={t('cancel')}
            btnType='secondary'
            onMouseDown={() => {
              onCancel();
              htmlContent.current = value;
            }}
          />
          <Col size={2} />
        </ControlsRow>
      )}
    </Col>
  );
};
export default Editor;
