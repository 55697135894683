import styled from 'styled-components';
import { Col } from 'ui-55';

export const ButtonContainer = styled(Col)`
  width: 100%;
  button {
    width: 100%;
    margin-right: 24px;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const SelectedTeams = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const StyledTeamBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  line-height: 16px;
  background: ${props => props.theme.colors.lightBeige};
  border-radius: 2px;
  width: fit-content;
  margin-right: 4px;
  cursor: pointer;

  svg {
    margin-left: 4px;
    height: 14px;
    width: 14px;
    fill: ${props => props.theme.colors.darkBlue};
  }
`;
