import { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import {
  selectors as servicesSelectors,
  actions as servicesActions
} from 'redux/services';

const useServicesListing = ({ shouldFetch = false } = {}) => {
  const dispatch = useDispatch();
  const { getServicesListing } = servicesActions;
  const {
    getServicesLoading,
    getServicesError,
    getServices,
    getServicesLoaded,
    getServiceForms
  } = servicesSelectors;

  const error = useSelector(state => getServicesError(state));
  const loading = useSelector(state => getServicesLoading(state));
  const loaded = useSelector(state => getServicesLoaded(state));

  const services = useSelector(state => getServices(state), shallowEqual);

  const serviceForms = useSelector(
    state => getServiceForms(state),
    shallowEqual
  );

  useEffect(() => {
    if (shouldFetch && !loaded && !loading) {
      getServicesListing(dispatch);
    }
  }, [
    dispatch,
    getServices,
    getServicesListing,
    getServiceForms,
    serviceForms,
    loaded,
    loading,
    shouldFetch
  ]);

  // const dispatchGetService = useCallback((serviceId) => {
  //     getService(serviceId, dispatch);
  // }, [dispatch, getService])

  return {
    services,
    serviceForms,
    loaded,
    error,
    loading
  };
};

export default useServicesListing;
