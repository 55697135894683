import styled from 'styled-components';
import theme from 'Theme';
import { Row } from 'ui-55';

export const NewRequestFormContainer = styled(Row)`
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  border-radius: 4px;
  margin: ${props => props.theme.margin}px 0px;
  > div {
    margin: 0;
    padding: 16px;
    max-width: 100%;
  }
  form > button {
    transition: background-color 0.25s ease-in;
    ${({ success, error, theme }) =>
      `background-color:  ${(success &&
        theme.colors.feedback.success.default) ||
        (error && theme.colors.feedback.error.default) ||
        ''}`};
    span {
      ${({ success, error, theme }) =>
        `color:  ${((success || error) && theme.colors.white) || ''}`};
    }
    &: hover {
      ${({ success, error, theme }) =>
        `background-color:  ${(success &&
          theme.colors.feedback.success.hover) ||
          (error && theme.colors.feedback.error.hover) ||
          ''}`};
    }
  }
`;

export const ClientContainer = styled.div`
  border: ${props => `1px solid ${props.theme.colors.mediumBeige}`};
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  svg {
    path {
      fill: ${props => props.theme.colors.grey};
    }
  }
  span {
    align-self: center;
    :hover {
      cursor: pointer;
    }
  }
`;

export const NoClientContainer = styled.div`
  padding: ${props => props.theme.margin}px;
  color: ${props => props.theme.colors.brand.orange};
  border-radius: 4px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  >div  {
    cursor: pointer;
  }
  svg {
    :hover {
      cursor: pointer;
    }
    height: 28px;
    width: 28px;
    path {
      fill: ${props => props.theme.colors.brand.orange};
    }
  }
`;

export const ClientDetailsContainer = styled(Row)`
  border-top: 1px solid ${props => props.theme.colors.mediumBeige};
  > svg > path {
    fill: ${props => props.theme.colors.grey};
  }
`;

export const ModalCustomStyles = {
  overlay: {
    backgroundColor: `${theme.colors.backdrop}`
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: 'none',
    background: `${theme.colors.white}`,
    overflow: 'auto',
    padding: 40,
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none',
    maxWidth: '1060px',
    margin: '0 auto'
  }
};

export const AddClientModalContainer = styled.div`
  h2 {
    margin: 0px;
  }
  
  .client-modal-close-button {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ClientHeaderDetails = styled(Row)`
  padding: 24px;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${theme.colors.lightBeige};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 16px 0px 10px;
`;

export const IconDetails = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 14px;
    path {
      fill: ${theme.colors.grey};
    }
  }
`;

export const SearchField = styled.div`
  position: relative;

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 8px;
    top: 33px;
  }  

  input {
    padding: 0 ${theme.margin * 2}px;
    border-radius: 2px;
    font-family: Muli;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid rgb(210, 204, 198) !important;

    min-height: 48px;
    margin: 16px 0px;
    width: calc(100% - ${theme.margin * 2 * 2}px);

    &:focus {
      outline: none;
    }  
  }
`;