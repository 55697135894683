import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslate } from '../../features/polyglot';

import useFilters from 'hooks/useFilters';
import useAppointments from 'hooks/appointments/useAppointments';

import ArchiveTable from 'Components/ArchiveTable';
import Filters from 'Components/Filters';
import { Loader, Pagination, BackofficeContainer } from 'ui-55';

import ArchiveContent from './style';

const AVAILABLE_FILTERS = [
  'admins',
  'appointment_status',
  'appointment_tag',
  'cities',
  'services',
  'start_date',
  'delivery_start_date'
];
const MULTI_FILTERS = ['status', 'service_ids', 'admin_id', 'city_ids', 'tag'];

const Archive = () => {
  const t = useTranslate('archive');

  const history = useHistory();
  const location = useLocation();

  const { active, queryString, page, getQueryStringFromFilters } = useFilters(
    location
  );
  const { appointments, loading, totalPages } = useAppointments({
    query: queryString,
    shouldFetch: true,
  });

  const handleNavigation = (pageNumber) => {
    history.push(
      `${getQueryStringFromFilters(active)}&page=${parseInt(pageNumber)}`
    );
  };

  return (
    <>
      <BackofficeContainer>
        <Filters availableFilters={AVAILABLE_FILTERS} hasCSVLink multiFilters={MULTI_FILTERS} />
        <ArchiveContent>
          {loading ? <Loader /> : <ArchiveTable items={appointments} />}
          <Pagination
            translate={t}
            totalPages={totalPages}
            currentPage={page}
            action={handleNavigation}

          />
        </ArchiveContent>
      </BackofficeContainer>
    </>
  );
};

export default Archive;
