import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { useTranslate } from '../../features/polyglot';

import useAdmin from 'Hooks/admins/useAdmin';
import useTeamsListing from 'Hooks/teams/useTeamsListing';
import useAdminsListing from 'Hooks/admins/useAdminsListing';

import { ACTION_TYPES as ADMIN_ACTON_TYPES } from 'Redux/admin/actions';

import MemberForm from 'Components/MemberForm';
import Select from 'Components/Select';
import AvatarLabel from 'Components/AvatarLabel';

import {
  BackofficeContainer,
  Row,
  Body,
  Heading,
  Loader,
  Button,
  Icon
} from 'ui-55';

import {
  DeleteMember,
  IconContainer,
  ModalStyles,
  HeadingDetail,
  Reassign,
  ButtonContainer
} from './styles';

const MemberDetail = () => {
  const t = useTranslate('team');
  const dispatch = useDispatch();
  const history = useHistory();
  const memberId = queryString.parse(history?.location?.search)?.id;

  const {
    admin: member,
    updateAdmin,
    updateAdminSuccess,
    updateAdminLoading
  } = useAdmin({
    shouldFetch: true,
    adminId: memberId
  });
  const { teams } = useTeamsListing({ shouldFetch: true });
  const { admins } = useAdminsListing({ shouldFetch: true });

  const [reassignedAdminId, setReassignedAdminId] = useState(null);

  const handleAdminUpdate = () => {
    const payload = {
      delete: true,
      reassigned_admin_id: reassignedAdminId
    };
    updateAdmin(payload, member?.id);
  };

  const redirectToTeamIndex = useCallback(
    () => history.push('/dashboard/members'),
    [history]
  );

  useEffect(() => {
    if (updateAdminSuccess) {
      redirectToTeamIndex();
      dispatch({
        type: ADMIN_ACTON_TYPES.CLEAR_ADMIN_UPDATE
      });
    }
  }, [updateAdminSuccess, redirectToTeamIndex, dispatch]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAdminSelect = v => {
    setReassignedAdminId(v.value);
  };
  const availableAdmins = admins.filter(admin => admin?.id !== memberId);
  const adminOptions = availableAdmins.map(admin => {

    return {
      label: <AvatarLabel userId={admin?.id} />,
      value: admin?.id
    };
  });

  return (
    <>
      {member && (
        <BackofficeContainer>
          <MemberForm t={t} member={member} teams={teams} formType='update' />

          <Row>
            <DeleteMember onClick={() => handleToggleModal()}>
              <IconContainer>
                <Icon name='trash' />
              </IconContainer>
              <div>
                <Heading size={6}>{t('deleteMember')}</Heading>
                <Body>{t('deleteMemberFromDatabase')}</Body>
              </div>
            </DeleteMember>
          </Row>
          {/* {renderModal()} */}

          <Modal style={ModalStyles} ariaHideApp={false} isOpen={isModalOpen}>
            {(updateAdminLoading && <Loader />) || (
              <>
                <Row justify='space-between' align='center'>
                  <HeadingDetail size={3}>
                    {t('alertDeleteAdmin')}
                  </HeadingDetail>
                  <Button
                    icon='Close'
                    btnType='transparent'
                    action={() => handleToggleModal()}
                  />
                </Row>
                <Row>
                  <Body>{t('memberWillBeRemoved')}</Body>
                </Row>

                {adminOptions.length > 0 && (
                  <Reassign>
                    <Row>
                      <Select
                        label={t('assignRequests')}
                        selectType='admin'
                        isFullWidth
                        options={adminOptions}
                        onChange={(v) => handleAdminSelect(v)}
                      />
                    </Row>
                  </Reassign>
                )}

                <ButtonContainer>
                  <Button
                    btnType='borded'
                    text={t('cancel')}
                    isFullWidth
                    action={() => handleToggleModal()}
                  />
                  <Button
                    btnType='primary'
                    text={t('confirm')}
                    isFullWidth
                    action={() => handleAdminUpdate()}
                  />
                </ButtonContainer>
              </>
            )}
          </Modal>
        </BackofficeContainer>
      )}
    </>
  );
};

export default MemberDetail;
