import activitiesClient from 'Services/activitiesService';

import { buildFilterQuery } from 'Utils/filters';

const REDUCER = 'activities';
export const ACTION_TYPES = {
  GET_ACTIVITIES: `${REDUCER}/GET_ACTIVITIES`,
  GET_ACTIVITIES_SUCCESS: `${REDUCER}/GET_ACTIVITIES_SUCCESS`,
  GET_ACTIVITIES_FAIL: `${REDUCER}/GET_ACTIVITIES_FAIL`,
  GET_ACTIVITY: `${REDUCER}/GET_ACTIVITY`,
  GET_ACTIVITY_SUCCESS: `${REDUCER}/GET_ACTIVITY_SUCCESS`,
  GET_ACTIVITY_FAIL: `${REDUCER}/GET_ACTIVITY_FAIL`,
  CREATE_ACTIVITY: `${REDUCER}/CREATE_ACTIVITY`,
  CREATE_ACTIVITY_SUCCESS: `${REDUCER}/CREATE_ACTIVITY_SUCCESS`,
  CREATE_ACTIVITY_FAIL: `${REDUCER}/CREATE_ACTIVITY_FAIL`,
};

const getActivitiesListing = (dispatch, pageNumber, filter) => {
  dispatch({
    type: ACTION_TYPES.GET_ACTIVITIES
  });
  activitiesClient
    .getActivities(pageNumber, filter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_ACTIVITIES_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter, pageNumber }, 'activities')
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_ACTIVITIES_FAIL,
        error: 'Error getting activities '
      });
    });
};

const getActivity = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_ACTIVITY
  });
  activitiesClient
    .getActivity
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_ACTIVITY_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_ACTIVITY_FAIL,
        error: 'Error getting Activity'
      });
    });
};

const createActivity = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.CREATE_ACTIVITY,
  });
  activitiesClient
    .createActivity(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_ACTIVITY_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_ACTIVITY_FAIL,
        error: e
      });
    });
};

export {
  getActivitiesListing,
  getActivity,
  createActivity
};
