import styled from 'styled-components';
import { Heading, Row, SmallBody, Body, Col } from 'ui-55';
import theme from 'Theme';

export const ModalStyles = {
  overlay: {
    backgroundColor: `${theme.colors.backdrop}`
  },
  content: {
    border: 'none',
    background: `${theme.colors.white}`,
    overflow: 'auto',
    // padding: '40px',
    WebkitOverflowScrolling: 'touch',
    borderRadius: `${theme.margin}px`,
    outline: 'none',
    maxWidth: '760px',
    margin: '10% auto 0px',
    height: 'fit-content'
  }
};

export const HeadingDetail = styled(Heading)`
  margin: 0px;
  display: block;
`;

export const SmallBodyDetail = styled(SmallBody)`
  padding: 0px;
  margin: 0px 0px 8px; 
  color: ${props => props.theme.colors.grey};
`;

export const BodyDetail = styled(Body)`
  padding:  0px 0px 8px;
  margin: 0px; 
`;

export const ButtonContainer = styled(Row)`
  button {
    margin-right: ${props => props.theme.margin * 1.5}px;
  }
`;

export const Container = styled.div`
  margin: ${props => props.theme.margin * 0.5}px 0px;
  width: 100%;
`;

export const Content = styled(Col)`
  padding-top: 0px;
`;