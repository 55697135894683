import React, { useMemo } from 'react';

import { useHistory } from 'react-router';
import { useTranslate } from '../../features/polyglot';
import { Loader, TopBar, BackofficeContainer } from 'ui-55';

import useAuth from 'Hooks/useAuth';
import useDashboard from 'Hooks/useDashboard';

import {StyledNavbarContainer} from './style'

import DashboardContainer from 'Components/DashboardContainer';

const InfoGraphs = () => {
  const t = useTranslate('dashboard');
  const history = useHistory();
  const { user } = useAuth();

  const { dashboard, loading } = useDashboard();

  const memoDashboard = useMemo(
    () => ({
      dashboard,
      loading,
    }),
    [dashboard, loading]
  );

  return (
    <>
    <StyledNavbarContainer>
      <TopBar
        location={t('welcome')}
        user={user}
        onAvatarClick={() => history.push('dashboard/settings')}
        title={t('dashboard')}
      />
    </StyledNavbarContainer>
      <BackofficeContainer>
        {memoDashboard.loading ? (
          <Loader />
        ) : (
          <>
            {/* <FilterBar availableFilters={filters} /> */}
            <DashboardContainer
              dashboardData={memoDashboard.dashboard}
              // myNotifications={notificationData.myNotifications}
            />
          </>
        )}
      </BackofficeContainer>
    </>
  );
};

export default InfoGraphs;
