import candidateLeadsClient from 'Services/candidateLeadsService';
import { updateCandidatesLead } from 'Services/candidateLeadsService';

import { buildFilterQuery } from 'Utils/filters';

const REDUCER = 'candidateLeads';
export const ACTION_TYPES = {
  GET_CANDIDATE_LEADS: `${REDUCER}/GET_CANDIDATE_LEADS`,
  GET_CANDIDATE_LEADS_SUCCESS: `${REDUCER}/GET_CANDIDATE_LEADS_SUCCESS`,
  GET_CANDIDATE_LEADS_FAIL: `${REDUCER}/GET_CANDIDATE_LEADS_FAIL`,
  GET_CANDIDATE_LEAD: `${REDUCER}/GET_CANDIDATE_LEAD`,
  GET_CANDIDATE_LEAD_SUCCESS: `${REDUCER}/GET_CANDIDATE_LEAD_SUCCESS`,
  GET_CANDIDATE_LEAD_FAIL: `${REDUCER}/GET_CANDIDATE_LEAD_FAIL`,
  UPDATE_LEAD_SUCCESS: `${REDUCER}/UPDATE_LEAD_SUCCESS`,
  UPDATE_LEAD_FAIL: `${REDUCER}/UPDATE_LEAD_FAIL`,
  UPDATE_LEAD: `${REDUCER}/UPDATE_LEAD`,
  UPDATE_CANDIDATE_LEAD_SUCCESS: `${REDUCER}/UPDATE_CANDIDATE_LEAD_SUCCESS`,
  UPDATE_CANDIDATE_LEAD_FAIL: `${REDUCER}/UPDATE_CANDIDATE_LEAD_FAIL`,
  UPDATE_CANDIDATE_LEAD: `${REDUCER}/UPDATE_CANDIDATE_LEAD`
};

const getCandidateLeadsListing = (dispatch, filters) => {
  dispatch({
    type: ACTION_TYPES.GET_CANDIDATE_LEADS
  });
  candidateLeadsClient
    .getCandidateLeads(filters)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CANDIDATE_LEADS_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter: filters }, 'candidateLeads')
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CANDIDATE_LEADS_FAIL,
        filters: filters,
        error: 'Error getting candidate leads '
      });
    });
};

const getCandidateLead = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_CANDIDATE_LEAD
  });
  candidateLeadsClient
    .getCandidateLead(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CANDIDATE_LEAD_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CANDIDATE_LEAD_FAIL,
        error: e
      });
    });
};

// This is for updating a Lead and NOT a CandidateLead
const patchCandidateLead = async (dispatch, payload) => {
  dispatch({ type: ACTION_TYPES.UPDATE_LEAD });
  updateCandidatesLead(payload.id, payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_LEAD_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_LEAD_FAIL,
        error: e
      });
    });
};

const updateCandidateLead = (dispatch, id, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CANDIDATE_LEAD
  });
  candidateLeadsClient
    .updateCandidateLead(id, payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_CANDIDATE_LEAD_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_CANDIDATE_LEAD_FAIL,
        error: e
      });
    });
};

export {
  getCandidateLeadsListing,
  getCandidateLead,
  patchCandidateLead,
  updateCandidateLead
};
