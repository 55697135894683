import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/admin';

import { buildFilterQuery } from 'Utils/filters';

const useAdminsListing = ({
  pageNumber = 0,
  filters = {},
  shouldFetch = false
} = {}) => {
  const dispatch = useDispatch();
  const { getAdminsListing } = actions;
  const {
    getAdmins,
    getLoading,
    getError,
    getLoaded,
    getLoadedQueries,
    getTotalCount
  } = selectors;

  const admins = useSelector(state =>
    getAdmins(state, { filter: filters, pageNumber })
  );

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const loadedQueries = useSelector(state => getLoadedQueries(state));
  const totalCount = useSelector(state => getTotalCount(state));

  const key = buildFilterQuery({ filter: filters, pageNumber }, 'admins');
  const queryState = loadedQueries[key];
  const totalCountAdmins = totalCount[key];

  const dispatchGetAdminsListing = useCallback(
    (pageNumber, filters) => {
      getAdminsListing(dispatch, pageNumber, filters);
    },
    [dispatch, getAdminsListing]
  );

  useEffect(() => {
    if (shouldFetch && !queryState?.loaded && !loading) {
      getAdminsListing(dispatch, pageNumber, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getAdminsListing,
    pageNumber,
    filters,
    shouldFetch,
    loading,
    admins.length
  ]);

  return {
    admins,
    totalCountAdmins,
    getAdminsListing: dispatchGetAdminsListing,
    error,
    loading,
    loaded
  };
};

export default useAdminsListing;
