import styled from 'styled-components';

export const StyledTableRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  height: 80px;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};

  :hover {
    background-color: ${props => props.theme.colors.lightestBeige};
    cursor: pointer;
  }
`;

export default StyledTableRow;
