import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useConversation from 'Hooks/useConversation';
import useInteraction from 'Hooks/useInteraction';

import InteractionCard from 'Components/InteractionCard';
import { Button, TextInput } from 'ui-55';

import StyledSpecialistFeed, {
  Conversation,
  InputContainer,
  AvailableChars
} from './style.js';

const SpecialistConversationFeed = ({ conversationId, providerId }) => {
  const [messageValue, setMessageValue] = useState('');
  const [messageLength, setMessageLength] = useState(messageValue.length);
  const setValidValue = v => {
    if (v.length <= 160) {
      setMessageValue(v);
      setMessageLength(v.length);
    }
  };

  const { conversation } = useConversation({
    conversationId: conversationId,
    shouldFetch: true
  });
  const interactionsData = conversation?.relationships?.interactions?.data;
  let orderedInteractionsData;
  if (interactionsData) {
    orderedInteractionsData = [...interactionsData]?.reverse();
  }

  const { createInteraction, createInteractionSuccess } = useInteraction();

  const handleSendSms = e => {
    const payload = {
      conversation_id: conversation?.id,
      provider_id: providerId,
      interaction_type: 'sms',
      text: messageValue
    };
    createInteraction(payload);
  };

  useEffect(() => {
    if (createInteractionSuccess) {
      setMessageValue('');
    }
  }, [setMessageValue, createInteractionSuccess]);

  return (
    <StyledSpecialistFeed>
      <InputContainer>
        <TextInput
          placeholder={`Envie uma mensagem`}
          onChange={v => setValidValue(v)}
          value={messageValue}
        />
        <AvailableChars>{messageLength}/160</AvailableChars>
      </InputContainer>
      <Button
        text={`Enviar SMS`}
        isDisabled={messageValue.length <= 0}
        isFullWidth
        action={handleSendSms}
      />

      <Conversation>
        {orderedInteractionsData &&
          orderedInteractionsData.map((interaction, index) => {
            return (
              <InteractionCard
                key={`interaction-card-${index}`}
                interactionId={interaction.id}
              />
            );
          })}
      </Conversation>
    </StyledSpecialistFeed>
  );
};

SpecialistConversationFeed.propTypes = {
  conversationId: PropTypes.string,
  providerId: PropTypes.string
};

export default SpecialistConversationFeed;
