import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import useAuth from 'hooks/useAuth';
import useClient from 'hooks/clients/useClient';
import useProvider from 'hooks/providers/useProvider';
import { makeGetService } from 'Redux/services/selectors';

import {
  getCandidateStatusOptions,
  getValueFromDestinationCol
} from 'utils/candidateStatus';
import {
  getNewStatusOptions,
  getDefaultValueFromDestinationCol
} from 'utils/appointmentStatus';
// import { getTemplateFromStatus } from "utils/statusEmails";

import { Avatar, Icon } from 'ui-55';
import Select from 'Components/Select';
import { Col } from 'Components/Layout';
// import Dropzone from "Components/Dropzone";

import {
  Text,
  Section,
  SmallHeading,
  DetailSection,
  RoundIconContainer,
  IconDetails,
  // DropzoneContainer,
  customSelectStyles
} from './style';

const ModalContent = ({ modalType, data, updateData, setUpdateData }) => {
  const t = useTranslate('modals');
  const b = useTranslate('badges');

  const item = data?.item;

  const { user } = useAuth();
  const { client } = useClient({
    clientId: item?.relationships?.client?.data?.id
  });
  const { provider } = useProvider({
    providerId: item?.relationships?.provider?.data?.id
  });

  const makeService = useMemo(makeGetService, []);
  const service = useSelector(state =>
    makeService(state, item?.relationships?.service?.data?.id)
  );

  const currentStatus = item?.attributes.status;
  const newStatusOptions =
    item?.type === 'appointment'
      ? getNewStatusOptions(b, currentStatus, data.destinationCol)
      : getCandidateStatusOptions(b, currentStatus);

  const defaultStatus = useMemo(() => {
    if (item?.type === 'candidateLead') {
      return newStatusOptions.find(
        e => e.value === getValueFromDestinationCol(data.destinationCol)
      );
    } else {
      return newStatusOptions.find(
        e => e.value === getDefaultValueFromDestinationCol(data.destinationCol)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.destinationCol, item?.type, newStatusOptions]);

  const createUpdateData = useCallback(
    newStatus => {
      const newUpdateData = {};

      switch (modalType) {
        case 'kanban-0':
          newUpdateData['appointment'] = {
            id: item.id,
            payload: {
              status: newStatus,
              admin_id: user.id,
              current_admin_id: user.id
            }
          };
          break;
        case 'kanban-1':
          newUpdateData['appointment'] = {
            id: item.id,
            payload: {
              status: newStatus,
              current_admin_id: user.id
            }
          };
          break;
        case 'kanban-2':
          newUpdateData['appointment'] = {
            id: item.id,
            payload: {
              status: newStatus,
              current_admin_id: user.id
            }
          };
          break;
        case 'kanban-3':
          newUpdateData['appointment'] = {
            id: item.id,
            payload: {
              status: newStatus,
              current_admin_id: user.id
            }
          };
          newUpdateData['newAppointment'] = {
            currentAppointmentId: item.id
          };
          break;
        case 'kanban-candidato-0':
          newUpdateData['candidateLead'] = {
            id: item.id,
            payload: {
              status: newStatus,
              assigned_to_id: parseInt(user.id)
            }
          };
          break;
        case 'kanban-candidato-1':
          newUpdateData['candidateLead'] = {
            id: item.id,
            payload: {
              status: newStatus
            }
          };
          break;
        case 'add-member':
          newUpdateData['member'] = {};
          break;
        default:
          return null;
      }
      return newUpdateData;
    },
    [item.id, modalType, user.id]
  );

  // const [newStatus, setNewStatus] = useState(defaultStatus.value);
  // const [template, setTemplate] = useState(getTemplateFromStatus(defaultStatus.value));

  const handleStatusSelect = e => {
    // setNewStatus(e.value)
    // setTemplate(getTemplateFromStatus(e.value))
    setUpdateData(createUpdateData(e.value));
  };

  useEffect(() => {
    if (!updateData || updateData[item.type].id !== item.id) {
      setUpdateData(createUpdateData(defaultStatus?.value));
    }
  }, [
    createUpdateData,
    defaultStatus,
    item,
    item.id,
    item.type,
    setUpdateData,
    updateData
  ]);
  const getDeliveryDate = item =>
    new Date(item.attributes?.deliveredOn).toLocaleDateString('PT-PT');

  const renderContent = (modalType, item) => {
    switch (modalType) {
      case 'kanban-0':
        return (
          <>
            <Section>
              <SmallHeading>{t('newStatus')}</SmallHeading>
              <Select
                options={newStatusOptions}
                overrideStyles={customSelectStyles}
                styleProps={{ type: item?.type }}
                onChange={handleStatusSelect}
                defaultValue={defaultStatus}
              />
            </Section>

            <Section>
              <SmallHeading>{t('assignTo')}</SmallHeading>
              {user && <Avatar hasText={true} user={user.attributes} />}
            </Section>
          </>
        );
      case 'kanban-1':
        return (
          <>
            <Section>
              <SmallHeading>{t('newStatus')}</SmallHeading>
              <Select
                options={newStatusOptions}
                overrideStyles={customSelectStyles}
                styleProps={{ type: item?.type }}
                onChange={handleStatusSelect}
                defaultValue={defaultStatus}
              />
            </Section>

            {/*   E M A I L    &     D O C U M E N T S   */}
            {/* {template && (
                            <Section>
                                <SmallHeading>{t("sendToClient")}</SmallHeading>
                                <Text><strong>{template.id}:</strong> {template.title}</Text>
                            </Section>
                        )}

                        {[4, 7].includes(currentStatus) && (
                            <Section>
                                <SmallHeading>{t("documents")}</SmallHeading>
                                <DropzoneContainer>
                                    <Dropzone
                                        canRemove={true}
                                    />
                                </DropzoneContainer>
                            </Section>
                        )} */}
          </>
        );
      case 'kanban-2':
        return (
          <>
            <Section>
              <SmallHeading>{t('newStatus')}</SmallHeading>
              <Select
                options={newStatusOptions}
                overrideStyles={customSelectStyles}
                styleProps={{ type: item?.type }}
                onChange={handleStatusSelect}
                defaultValue={defaultStatus}
              />
              {/* temporary until add provider modal is finished */}
              <Text>
                To move this request from analysis, add a specialist on the
                detail page.
              </Text>
            </Section>
          </>
        );
      case 'kanban-3':
        return (
          <>
            <div>
              <SmallHeading>{t('service')}</SmallHeading>
              <Text>{service?.attributes?.name}</Text>
            </div>
            <div>
              <SmallHeading>{t('newStatus')}</SmallHeading>
              <Text>{b('awaiting_conclusion')}</Text>
            </div>
            {/* client & specialist details */}
            <DetailSection>
              {client && (
                <Col size={6} padding='0'>
                  <SmallHeading>{t('client')}</SmallHeading>
                  <IconDetails>
                    <Avatar hasText={true} user={client.attributes} />
                  </IconDetails>

                  <IconDetails>
                    <RoundIconContainer>
                      <Icon name='Hourglass' />
                    </RoundIconContainer>
                    <div>
                      <SmallHeading noMargin={true}>
                        {t('estimatedHours')}
                      </SmallHeading>
                      <Text>
                        {item.attributes?.estimatedHours} {t('hours')}
                      </Text>
                    </div>
                  </IconDetails>

                  <IconDetails>
                    <RoundIconContainer>
                      <Icon name='repeat-1' />
                    </RoundIconContainer>
                    <div>
                      <SmallHeading noMargin={true}>
                        {t('recurrent')}
                      </SmallHeading>
                    </div>
                  </IconDetails>
                </Col>
              )}

              {provider && (
                <Col size={6} padding='0'>
                  <SmallHeading>{t('specialist')}</SmallHeading>
                  <IconDetails>
                    <Avatar hasText={true} user={provider.attributes} />
                  </IconDetails>

                  <IconDetails>
                    <RoundIconContainer>
                      <Icon name='calendar' />
                    </RoundIconContainer>
                    <div>
                      <SmallHeading noMargin={true}>
                        {t('appointmentTime')}
                      </SmallHeading>
                      <Text>{getDeliveryDate(item)}</Text>
                    </div>
                  </IconDetails>
                  <IconDetails>
                    <RoundIconContainer>
                      <Icon name='Clock' />
                    </RoundIconContainer>
                    <div>
                      <SmallHeading noMargin={true}>
                        {t('preferredHours')}
                      </SmallHeading>
                    </div>
                  </IconDetails>
                </Col>
              )}
            </DetailSection>

            {/* status select */}
            {/* {template && (
                            <Section>
                                <SmallHeading>{t("sendToClient")}</SmallHeading>
                                <Text><strong>{template.id}:</strong> {template.title}</Text>
                            </Section>
                        )}          */}
          </>
        );
      case 'kanban-candidato-0':
        return (
          <>
            <Section>
              <SmallHeading>{t('newStatus')}</SmallHeading>
              {/* <Text>Aguarda Conclusão</Text> */}
              <Select
                options={newStatusOptions}
                overrideStyles={customSelectStyles}
                styleProps={{ type: item?.type }}
                onChange={handleStatusSelect}
                defaultValue={defaultStatus}
              />

              <Section>
                <SmallHeading>{t('assignTo')}</SmallHeading>
                {user && <Avatar hasText={true} user={user.attributes} />}
              </Section>
            </Section>
          </>
        );
      case 'kanban-candidato-1':
        return (
          <>
            <Section>
              <SmallHeading>{t('newStatus')}</SmallHeading>
              <Select
                options={newStatusOptions}
                overrideStyles={customSelectStyles}
                styleProps={{ type: item?.type }}
                onChange={handleStatusSelect}
                defaultValue={defaultStatus}
              />
            </Section>
          </>
        );

      case 'add-member':
        return (
          <>
            <Section>
              <SmallHeading>{t('newMember')}</SmallHeading>
            </Section>
          </>
        );
      default:
        break;
    }
  };

  return <>{renderContent(modalType, item)}</>;
};

ModalContent.propTypes = {
  modalType: PropTypes.oneOf([
    'kanban-0',
    'kanban-1',
    'kanban-2',
    'kanban-3',
    'kanban-candidate-0',
    'kanban-candidate-1',
    'add-member'
  ]),
  data: PropTypes.object,
  updateData: PropTypes.object,
  setUpdateData: PropTypes.func
};

export default ModalContent;
