import client from "./client";
import { applyFilters } from 'Utils/filters';

const getTag = (id) => {
    return client.get(`tags/${id}`)
};

const getTags = (pageNumber, filters) => {
  if (pageNumber) {
    return client.get(`${applyFilters(filters, 'tags')}&page=${pageNumber}`);
  } else if (filters !== 'tags') {
    return client.get(`${applyFilters(filters, 'tags')}`);
  }
  return client.get(applyFilters({}, 'tags'));
};

const createTag = payload => {
  return client.post(`tags`, payload);
};


export default { getTag, getTags, createTag, client };

