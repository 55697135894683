import client from "./client";
import { applyFilters } from 'Utils/filters';

const getTeam = (id) => {
    return client.get(`teams/${id}`)
};

const getTeams = (pageNumber, filters) => {
  if (pageNumber) {
    return client.get(`${applyFilters(filters, 'teams')}&page=${pageNumber}`);
  } else if (filters !== 'teams') {
    return client.get(`${applyFilters(filters, 'teams')}`);
  }
  return client.get(applyFilters({}, 'teams'));
};

const createTeam = payload => {
  return client.post(`teams`, payload);
};


export default { getTeam, getTeams, createTeam, client };

