import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';
import { toast } from 'react-toastify';

import useAdmin from 'Hooks/admins/useAdmin';
import useAppointment from 'Hooks/appointments/useAppointment';
import useClient from 'Hooks/clients/useClient';
import useProvider from 'Hooks/providers/useProvider';
import useAuth from 'Hooks/useAuth';

import { Avatar, Icon, Button } from 'ui-55';
import { Body, SmallBody } from 'Components/Text';
import Select from 'Components/Select';
import useSettings from 'Hooks/useSettings';

import { MONTHS } from 'utils/dateStrings';

import StyledServiceDetailSidebar, {
  StatusHeader,
  StatusSelect,
  Section,
  Card,
  AddAssociationLink,
  LinkDetail,
  HeadingDetail,
  BodyDetail,
  IconContainer,
  IconDetails,
  ButtonContainer
} from './style';

import { customSelectStyles } from 'Components/ModalContent/style';
import CardTagAppointmnet from 'Components/CardTagAppointmnet';

const ServiceDetailSidebar = ({
  appointment,
  data,
  action,
  togglePaymentModal,
}) => {
  const t = useTranslate('requests');
  const b = useTranslate('badges');
  const translateLanguage = useTranslate('languageSelect');


  const { user } = useAuth();

  const history = useHistory();

  const { client } = useClient({ clientId: data.clientId });
  const { provider } = useProvider({ providerId: data.providerId });
  const { admin } = useAdmin({
    adminId: appointment?.relationships?.admin?.data?.id,
  });

  const settings = useSettings().settings;

  const dateCreated = new Date(appointment?.attributes?.createdAt);
  const formattedDate = `${dateCreated?.getDate()} ${
    MONTHS[dateCreated?.getMonth()]
    }, ${dateCreated?.getFullYear()}`;

  const [addAdmin, setAddAdmin] = useState(false);

  const {
    updateAppointment,
    duplicateAppointment
  } = useAppointment();

  const [currentStatus, setCurrentStatus] = useState(
    settings.appointment_status?.find(
      (e) => e.label === appointment?.attributes?.status
    )
  );

  useEffect(() => {
    setCurrentStatus(
      settings.appointment_status?.find(
        (e) => e.label === appointment?.attributes?.status
      )
    );
  }, [appointment?.attributes?.status, settings.appointment_status]);

  const defaultValue = currentStatus
    ? {
      ...currentStatus,
      label: currentStatus.label,
    }
    : '';

  const handleStatusSelect = (e) => {
    /* const awaitingStatuses = ['awaiting_visit_payment', 'awaiting_service_payment'];
    if (awaitingStatuses.includes(appointment?.attributes?.status)) {
      toast.error(t('errorPendingPayment'))
      setCurrentStatus(settings.appointment_status?.find(
        e => e.label === appointment?.attributes?.status
      ))
      return
    } */
    if (e.label === 'Aguarda Especialista') {
      toast.error(t('errorProviderRequired'));
      setCurrentStatus(
        settings.appointment_status?.find(
          (e) => e.label === appointment?.attributes?.status
        )
      );
      return;
    }

    const payload = {
      status: e.value,
      current_admin_id: user?.id,
    };

    if (e.label === 'Aguarda Pagamento Serviço') {
      togglePaymentModal('issueServiceInvoice', payload, appointment);
    } else if (e.label === 'Aguarda Pagamento Visita') {
      const visitPayload = {
        ...payload,
        visit_required: true,
        provider_id: data.providerId,
      };
      togglePaymentModal('issueVisitInvoice', visitPayload, appointment);
    } else if (e.label === 'Análise') {
      togglePaymentModal('resetToAnalysis', payload, appointment);
    } else if (e.label === 'Aguarda Conclusão') {
      togglePaymentModal('voidToConcluded', payload, appointment)
    } else {
      updateAppointment(payload, appointment?.id, {
        success: () => {},
        error: (e) => {
          toast.error(e);
        },
      });
    }
  };

  const handleAdminSelection = (v) => {
    const payload = {
      admin_id: v.value,
    };
    updateAppointment(payload, appointment.id, {
      success: () => {},
      error: (e) => {
        toast.error(e);
      },
    });
  };


  const handleDuplicate = () => {
    duplicateAppointment(appointment.id, {
      success: () => {
        toast.success(t('requestDuplicated'));
        history.push(`/dashboard/requests?kanban=true`)
      },
      error: (e) => {toast.error(e)},
    });
  }

  return (
    <StyledServiceDetailSidebar>
      <Section>
        <StatusHeader>
          <BodyDetail>{t('status')}:</BodyDetail>
          <Icon name='more' />
        </StatusHeader>

        <StatusSelect
          options={settings.appointment_status?.map((opt) => ({
            ...opt,
            label: opt.label && b(opt.original_label),
          }))}
          placeholder={
            appointment?.attributes?.status &&
            b(appointment?.attributes?.status)
          }
          defaultValue={defaultValue}
          value={defaultValue}
          // styles={selectStyles}
          overrideStyles={customSelectStyles}
          styleProps={{ type: 'appointment' }}
          onChange={handleStatusSelect}
        />
        <ButtonContainer>
          <Button
            text={t('duplicate')}
            onClick={() => handleDuplicate()}
            isFullWidth
          />
        </ButtonContainer>

      </Section>

      <Section>
        <>
          <HeadingDetail size={6}>{t('responsible')}</HeadingDetail>
          {admin ? (
            // <Avatar size='medium' user={admin?.attributes} hasText={true} />
            <>
              <Select
                selectType='admin'
                value={admin?.id}
                onChange={(v) => handleAdminSelection(v)}
              />
            </>
          ) : (
            <>
              {addAdmin ? (
                <>
                  <Select
                    selectType='admin'
                    onChange={(v) => handleAdminSelection(v)}
                  />
                </>
              ) : (
                <AddAssociationLink
                  onClick={() => setAddAdmin(true)}
                  margin={12}
                >
                  <Icon name='Plus' />
                  <LinkDetail>{t('addTeamMember')}</LinkDetail>
                </AddAssociationLink>
              )}
            </>
          )}
        </>
      </Section>

      <Section>
        <HeadingDetail size={6}>{t('dateCreated')}</HeadingDetail>
        <BodyDetail>{formattedDate ?? ''}</BodyDetail>
      </Section>

      {/*
      <Section>
        <HeadingDetail size={6}>{t("paid")}</HeadingDetail>
        <BodyDetail>⁄{appointment?.atrributes?.paymentStatus}</BodyDetail>
      </Section>
    */}

      <CardTagAppointmnet appointment={appointment} />

      <Card>
        <HeadingDetail size={6}>{t('specialists')}</HeadingDetail>

        {provider ? (
          <>
            <Section hasBorder={true}>
              <Avatar
                size='medium'
                user={provider.attributes}
                hasText={true}
                action={() =>
                  history.push(`specialist-details?provider_id=${provider?.id}`)
                }
              />
            </Section>
            <div>
              <SmallBody bold={true}>{t('details')}</SmallBody>
              <IconDetails>
                <IconContainer marginBottom={true}>
                  <Icon name='mail' />
                </IconContainer>
                <BodyDetail>{provider.attributes?.email}</BodyDetail>
                {/* <BodyDetail>{"something"}</BodyDetail> */}
              </IconDetails>

              <IconDetails>
                <IconContainer>
                  <Icon name='phone' />
                </IconContainer>
                <BodyDetail>{provider.attributes?.phoneNumber}</BodyDetail>
              </IconDetails>
            </div>
          </>
        ) : (
          <>
            <Body alt='true'>{t('noSpecialist')}</Body>
            <AddAssociationLink onClick={action}>
              <Icon name='Plus' />
              <LinkDetail>{t('selectSpecialist')}</LinkDetail>
            </AddAssociationLink>
          </>
        )}
      </Card>
      {client && (
        <Card>
          <HeadingDetail size={6}>{t('client')}</HeadingDetail>

          <Section hasBorder={true}>
            <Avatar
              size='medium'
              user={client.attributes}
              hasText={true}
              action={() =>
                history.push(`client-detail?client_id=${client?.id}`)
              }
            />
            {/* <Avatar size="medium" user={"something"} hasText={true}/> */}
          </Section>

          <Section hasBorder={true}>
            <SmallBody bold={true}>{t('details')}</SmallBody>
            <IconDetails>
              <IconContainer marginBottom={true}>
                <Icon name='mail' />
              </IconContainer>
              <BodyDetail>{client.attributes?.email}</BodyDetail>
              {/* <BodyDetail>{"something"}</BodyDetail> */}
            </IconDetails>

            <IconDetails>
              <IconContainer>
                <Icon name='phone' />
              </IconContainer>
              <BodyDetail>{client.attributes?.phoneNumber}</BodyDetail>
            </IconDetails>
          </Section>
          {(client.attributes?.address ||
            client.attributes?.postalCode ||
            client.attributes?.city) &&
            <Section hasBorder={true}>
              <SmallBody bold={true}>{t('address')}</SmallBody>
              <BodyDetail>{client.attributes?.address}</BodyDetail>
              <BodyDetail>
                {client.attributes?.postalCode}
                {''}
                {client.attributes?.city}
              </BodyDetail>
            </Section>
          }

          <div>
            <SmallBody bold={true}>{t('nif')}</SmallBody>
            <BodyDetail>{client.attributes?.nif}</BodyDetail>
          </div>

          {(client.attributes?.languageCode && <div>
            <SmallBody bold={true}>{translateLanguage('language')}</SmallBody>
            <BodyDetail>{translateLanguage(client.attributes?.languageCode)}</BodyDetail>
          </div>)}

        </Card>
      )}
    </StyledServiceDetailSidebar>
  );
};

ServiceDetailSidebar.propTypes = {
  appointment: PropTypes.object,
  data: PropTypes.object,
  action: PropTypes.func,
  togglePaymentModal: PropTypes.func,
};

export default ServiceDetailSidebar;
