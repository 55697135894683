import { useEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/clients';
import { updateClientDetailForm, deleteClient } from 'Redux/clients/actions';

const useClient = ({ shouldFetch = false, clientId } = {}) => {
  const dispatch = useDispatch();
  const { getClient } = actions;
  const { makeGetClient, getClientLoading, getClientError } = selectors;

  const makeClient = useMemo(makeGetClient, []);
  const client = useSelector(state => makeClient(state, clientId));

  const clientError = useSelector(state => getClientError(state), shallowEqual);

  const clientLoading = useSelector(
    state => getClientLoading(state),
    shallowEqual
  );

  const dispatchGetClient = useCallback(
    id => {
      getClient(id, dispatch);
    },
    [dispatch, getClient]
  );

  const dispatchUpdateClient = useCallback(
    (payload, id) => {
      updateClientDetailForm(dispatch, payload, id);
    },
    [dispatch]
  );

  const dispatchDeleteClient = useCallback(
    id => {
      deleteClient(id, dispatch);
    },
    [dispatch]
  );

  useEffect(() => {
    if (shouldFetch && !client && clientId) {
      dispatchGetClient(clientId);
    }
  }, [dispatchGetClient, shouldFetch, client, clientId]);

  return {
    client,
    clientError,
    clientLoading,
    updateClient: dispatchUpdateClient,
    getClient: dispatchGetClient,
    deleteClient: dispatchDeleteClient
  };
};

export default useClient;
