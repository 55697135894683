import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';
import _ from 'lodash';

import Filters from 'components/Filters';

import TableRow from './TableRow';
import { Icon, Col, Loader, Heading } from 'ui-55';

import StyledSpecialistTable, { Header, SortIconContainer } from './style';

const AVAILABLE_FILTERS = ['status', 'distance', 'is_premium', 'availability'];
const MULTI_FILTERS = [];

const SpecialistTable = ({
  isSorted,
  hasCheckBox,
  columns = [
    { name: 'name', size: 3 },
    { name: 'service', size: 3 },
    { name: 'region', size: 3 },
    { name: 'state', size: 2 },
  ],
  items,
  itemsLoading,
  title,
  action,
  filterRows,
  connectedDisplay,
  hasSearch,
  hasFilter,
  isFilterable,
  availabilityFilterData,
  gpsFilterData,
  availableFilters,
  hasCSVLink,
  multiFilters
}) => {
  const t = useTranslate('specialists');

  const filterNames = availableFilters || AVAILABLE_FILTERS;
  const [filteredItems, setFilteredItems] = useState(items);

  const handleSort = (sortBy, sortAction) => {
    const sortedItems = items;
    let temp = [];
    if (sortBy === 'specialists') {
      switch (sortAction) {
        case 'asc':
          temp = _.orderBy(
            sortedItems,
            [(user) => user?.attributes?.fullName?.toLowerCase()],
            ['asc']
          );
          break;
        case 'desc':
          temp = _.orderBy(
            sortedItems,
            [(user) => user?.attributes?.fullName?.toLowerCase()],
            ['desc']
          );
          break;
        default:
          return;
      }
    } else {
      switch (sortAction) {
        case 'asc':
          temp = _.orderBy(sortedItems, ['attributes.status'], ['asc']);
          break;
        case 'desc':
          temp = _.orderBy(sortedItems, ['attributes.status'], ['desc']);
          break;
        default:
          return;
      }
    }

    setFilteredItems(temp);
  };

  useEffect(() => {
    if (items.length !== filteredItems.length) {
      setFilteredItems(items);
    }
  }, [filteredItems.length, items]);

  return (
    <StyledSpecialistTable>
      {title && <Heading size={3}>{title}</Heading>}

      {isFilterable && hasFilter && (
        <Filters
          availableFilters={filterNames}
          showLayout={false}
          hasAddButton={false}
          hasCSVLink={hasCSVLink}
          gpsData={gpsFilterData}
          availabilityData={availabilityFilterData}
          multiFilters={multiFilters || MULTI_FILTERS}
        />
      )}
      {itemsLoading ? (
        <Loader />
      ) : !items.length ? (
        <>{/* <p>no records</p> */}</>
      ) : (
        <>
          <Header>
            {columns &&
              columns.length > 0 &&
              columns.map((col, index) => {
                return (
                  <Col key={`${col.name}-${index}`} size={col.size}>
                    {isSorted ? (
                      <>
                        <SortIconContainer>
                          {t(col)}
                          <div>
                            <span
                              role='presentation'
                              onClick={() => handleSort(col, 'asc')}
                            >
                              <Icon name='chevron-up' />
                            </span>
                            <span
                              role='presentation'
                              onClick={() => handleSort(col, 'desc')}
                            >
                              <Icon name='chevron-down' />
                            </span>
                          </div>
                        </SortIconContainer>
                      </>
                    ) : (
                      t(col.name)
                    )}
                  </Col>
                );
              })}
          </Header>
          {items.filter(item => item.attributes.fullName != null).map((item, index) => {
            return (
              <TableRow
                hasSearch
                connectedDisplay={connectedDisplay}
                filterRows={filterRows}
                hasCheckBox={hasCheckBox}
                action={action}
                key={'specialistRow' + index}
                item={item}
              ></TableRow>
            );
          })}
        </>
      )}
    </StyledSpecialistTable>
  );
};

SpecialistTable.propTypes = {
  isSorted: PropTypes.bool,
  hasFilter: PropTypes.bool,
  hasCheckBox: PropTypes.bool,
  columns: PropTypes.array,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  action: PropTypes.func,
  filterRows: PropTypes.array,
  connectedDisplay: PropTypes.array,
  hasSearch: PropTypes.bool,
  isFilterable: PropTypes.bool,
  availabilityFilterData: PropTypes.shape({
    deliveredOn: PropTypes.string,
  }),
  gpsFilterData: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    filterByGps: PropTypes.bool,
    setFilterByGps: PropTypes.func,
  }),
  hasCSVLink: PropTypes.bool,
};

SpecialistTable.defaultProps = {
  isFilterable: true,
};

export default SpecialistTable;
