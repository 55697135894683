import { createSelector } from 'reselect';

import { getConversationsEntities } from 'redux/entities/selectors';

const selectConversationsState = state => state?.conversations;

const makeGetConversation = () =>
  createSelector(
    getConversationsEntities,
    (_, conversationId) => conversationId,
    (entities, id) => entities[id]
  );

const getConversationLoading = createSelector(selectConversationsState, state => {
  return state.conversationLoading;
});

const getConversationError = createSelector(selectConversationsState, state => {
  return state.conversationError;
});

export {
  makeGetConversation,
  getConversationError,
  getConversationLoading,
};
