import contentService from 'Services/contentService';

const REDUCER = 'content';

export const ACTION_TYPES = {
  GET_CONTENT: `${REDUCER}/GET_CONTENT`,
  GET_CONTENT_FAIL: `${REDUCER}/GET_CONTENT_FAIL`,
  GET_CONTENT_SUCCESS: `${REDUCER}/GET_CONTENT_SUCCESS`,
  UPDATE_CONTENT: `${REDUCER}/UPDATE_CONTENT`,
  UPDATE_CONTENT_FAIL: `${REDUCER}/UPDATE_CONTENT_FAIL`,
  UPDATE_CONTENT_SUCCESS: `${REDUCER}/UPDATE_CONTENT_SUCCESS`,
  GET_CONTENT_KEYS: `${REDUCER}/GET_CONTENT_KEYS`,
  GET_CONTENT_KEYS_FAIL: `${REDUCER}/GET_CONTENT_KEYS_FAIL`,
  GET_CONTENT_KEYS_SUCCESS: `${REDUCER}/GET_CONTENT_KEYS_SUCCESS`,
};

const getContent = (dispatch, { id, lang }) => {
  dispatch({
    type: ACTION_TYPES.GET_CONTENT,
    payload: { id },
  });
  contentService
    .getContentDetail(id, lang)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CONTENT_SUCCESS,
          payload: { data: res.data, id },
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_CONTENT_FAIL,
        payload: { id },
        error: 'Error getting content from the server',
      });
    });
};
const updateContent = (dispatch, { id, content, lang }, successCallback) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CONTENT,
    payload: { id },
  });
  contentService
    .updateContent(id, content, lang)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_CONTENT_SUCCESS,
          payload: { data: res.data.data, id },
        });
        if (successCallback) {
          successCallback();
        }
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_CONTENT_FAIL,
        payload: { id },
        error: 'Error updating this content',
      });
    });
};
const getContentKeys = (dispatch, { lang = 'en-uk' } = {}) => {
  dispatch({
    type: ACTION_TYPES.GET_CONTENT_KEYS,
    payload: { lang },
  });
  contentService
    .getContentKeys(lang)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CONTENT_KEYS_SUCCESS,
          payload: { data: res.data, lang },
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_CONTENT_KEYS_FAIL,
        payload: { lang },
        error: 'Error getting content from the server',
      });
    });
};

export { getContent, getContentKeys, updateContent };
