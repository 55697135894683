import { ACTION_TYPES } from './actions';
import { get } from 'lodash';

const initialState = {
  content: {},
  keys: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.id]: {
            ...state.content[action.payload.id],
            loading: true
          }
        }
      };
    case ACTION_TYPES.GET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.id]: { loading: true }
        }
      };
    case ACTION_TYPES.GET_CONTENT_KEYS:
      return {
        ...state,
        keys: {
          ...state.keys,
          [action.payload.lang]: { loading: true }
        }
      };
    case ACTION_TYPES.GET_CONTENT_KEYS_FAIL:
      return {
        ...state,
        keys: {
          ...state.keys,
          [action.payload.lang]: {
            loading: false,
            loaded: true,
            error: action.error
          }
        }
      };
    case ACTION_TYPES.GET_CONTENT_KEYS_SUCCESS:
      return {
        ...state,
        keys: {
          ...state.keys,
          [action.payload.lang]: {
            loading: false,
            loaded: true,
            error: null,
            data: get(action, 'payload.data.data')
          }
        }
      };
    case ACTION_TYPES.UPDATE_CONTENT_FAIL:
    case ACTION_TYPES.GET_CONTENT_FAIL:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.id]: {
            loading: false,
            loaded: true,
            error: action.error
          }
        }
      };
    case ACTION_TYPES.GET_CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          [action.payload.id]: {
            loading: false,
            loaded: true,
            data: get(action, 'payload.data.data')
          }
        }
      };
    case ACTION_TYPES.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.id]: {
            loading: false,
            loaded: true,
            data: get(action, 'payload.data')
          }
        }
      };
    default:
      return state;
  }
};
