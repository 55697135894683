import styled from 'styled-components';
import { Col } from 'ui-55';

export const StyledInvoiceItemsTable = styled.div`
  font-size: 14px;
  line-height: ${props => props.theme.margin}px;
  color: ${props => props.theme.colors.darkBlue};
`;

export const Header = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.lightestBeige};
  height: ${props => props.theme.margin * 3}px;
  width: 100%;
  display: flex;
  font-weight: bold;
`;

export const BoldCell = styled(Col)`
  font-weight: bold;
`;


export const AddAssociationLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${props => (props.margin ? props.margin : '0')}px;

  svg {
    padding-bottom: 2px;
    height: 28px;
    width: 28px;
  }

  path {
    fill: ${props => props.theme.colors.brand.orange};
  }

  span {
    margin-left: 12px;
  }
`;

export const LinkDetail = styled.span`
  color: ${props => props.theme.colors.brand.orange};

  :hover {
    color: ${props => props.theme.colors.brand.orange};
  }
  :active {
    color: ${props => props.theme.colors.brand.orange};
  }
  :visited {
    color: ${props => props.theme.colors.brand.orange};
  }
  :focus {
    color: ${props => props.theme.colors.brand.orange};
  }
`;