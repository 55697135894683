const REDUCER = 'filterbar';
export const ACTION_TYPES = {
  UPDATE_LOCAL_FILTERS: `${REDUCER}/UPDATE_LOCAL_FILTERS`,
  RESET_LOCAL_FILTERS: `${REDUCER}/RESET_LOCAL_FILTERS`,
};

const resetLocalFilters = (dispatch) => {
  dispatch({ type: ACTION_TYPES.RESET_LOCAL_FILTERS });
};

const updateLocalFilters = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_LOCAL_FILTERS,
    payload,
  });
};
export { updateLocalFilters, resetLocalFilters };
