import client from './client';
import { applyFilters } from 'Utils/filters';

const getCandidateLeads = filters => {
  if (filters !== 'candidate_leads') {
    return client.get(`${applyFilters(filters, 'candidate_leads')}`);
  }
  return client.get('/candidate_leads');
};

const getCandidateLead = id => {
  return client.get(`/candidate_leads/${id}`);
}

// this is for update leads NOT candidateLeads
export const updateCandidatesLead = (leadID, data) => {
  return client.patch(`/leads/${leadID}`, { lead: data });
};

export const updateCandidateLead = (id, payload) => {
  return client.patch(`/candidate_leads/${id}`, { candidate_lead: payload });
};

export default { 
  getCandidateLeads, 
  getCandidateLead,
  updateCandidateLead, 
  client };
