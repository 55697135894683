import camelcase from 'camelcase-keys';
import { ACTION_TYPES } from './actions';

const initialState = {
  clientLoading: false,
  clientLoaded: false,
  clientError: false,

  // refactor
  clients: {},
  queries: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CLIENTS:
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: true,
            loaded: false,
            error: false,
            totalPages: null,
            data: [],
          },
        },
      };
    case ACTION_TYPES.GET_CLIENTS_SUCCESS: {
      const data = [...camelcase(action.payload.data, { deep: true })];
      const clientIds = data?.map((e) => e.id);
      const updatedAppsObj = { ...state.clients };
      clientIds.map(
        (id) => (updatedAppsObj[id] = data.filter((e) => e.id === id)[0])
      );
      const totalPages = parseInt(action.meta?.['Total-Pages']);

      return {
        ...state,
        clients: updatedAppsObj,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: false,
            loaded: true,
            error: false,
            totalPages: totalPages,
            data: clientIds,
          },
        },
      };
    }
    case ACTION_TYPES.GET_CLIENTS_FAIL:
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: false,
            loaded: false,
            error: action.error,
            data: null,
          },
        },
      };

    case ACTION_TYPES.GET_CLIENT:
      return {
        ...state,
        clientLoading: true,
      };
    case ACTION_TYPES.GET_CLIENT_SUCCESS:
      return {
        ...state,
        clientLoading: false,
        clientError: false,
        clientLoaded: true,
      };
    case ACTION_TYPES.GET_CLIENT_FAIL:
      return {
        ...state,
        clientError: action.error,
        clientLoading: false,
        clientLoaded: true,
      };
    case ACTION_TYPES.DELETE_CLIENT:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        clients: state.clients.filter((e) => e !== action.payload),
        loadedQueries: {},
      };
    case ACTION_TYPES.DELETE_CLIENT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPES.CREATE_CLIENT:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: [],
        loadedQueries: {},
      };
    case ACTION_TYPES.CREATE_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPES.UPDATE_CLIENT:
      return {
        ...state,
        clientLoading: true,
        clientLoaded: false,
      };
    case ACTION_TYPES.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clientLoading: false,
        clientLoaded: true,
      };
    case ACTION_TYPES.UPDATE_CLIENT_FAIL:
      return {
        ...state,
        clientLoading: false,
        clientLoaded: true,
        error: action.error,
      };
    default:
      return state;
  }
};
