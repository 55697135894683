import React from 'react';

import { useTranslate } from '../../../features/polyglot';

import { Col } from 'Components/Layout';
import TableRow from './TableRow';

import StyledRequestListTable, { Header } from './style';

const RequestListTable = ({ items }) => {
  const t = useTranslate('specialists');

  return (
    <StyledRequestListTable>
      <Header>
        <Col size={3}>{t('serviceType')}</Col>

        <Col size={2}>{t('client')}</Col>

        <Col size={2}>{t('createdAt')}</Col>

        <Col size={2}>{t('deliveredAt')}</Col>

        <Col size={2}>{t('totalHours')}</Col>

        <Col size={2}>{t('totalPrice')}</Col>

        <Col size={2}>{t('rating')}</Col>
      </Header>

      {items &&
        Object.keys(items).map((item, index) => {
          return (
            <TableRow key={'requestListTable' + index} item={items[item]} />
          );
        })}
    </StyledRequestListTable>
  );
};

export default RequestListTable;
