import React, { useState, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'Components/Layout';
import { Avatar } from 'ui-55';

import { StyledTableRow, StyledCheckBox } from './style';
const TableRow = ({
  hasCheckBox,
  item,
  action,
  filterRows,
  connectedDisplay,
}) => {
  const formatServiceCell = (serviceNames) => {
    if (!serviceNames) return;
    if (serviceNames.length >= 2) {
      return `${serviceNames[0]}, ${serviceNames[1]}...`;
    } else if (serviceNames.length > 0) {
      return `${serviceNames[0]}`;
    }
  };
  const [isChecked, setIsChecked] = useState(
    (hasCheckBox &&
      connectedDisplay.map((d) => d.id).indexOf(item?.id) !== -1) ||
      false
  );

  const handleCheck = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  useLayoutEffect(() => {
    const displayedItem =
      connectedDisplay &&
      connectedDisplay.map((d) => d.id).indexOf(item?.id) !== -1;

    setIsChecked(displayedItem);
  }, [connectedDisplay, item]);

  return (
    <StyledTableRow
      hasCheckBox={hasCheckBox}
      onClick={() => {
        handleCheck(item);
        action && action(item);
      }}
    >
      {hasCheckBox && (
        <>
          <StyledCheckBox
            onChange={() => {
              handleCheck(item);
              action && action(item);
            }}
            checked={isChecked}
          />
        </>
      )}
      <Col size={3}>
        {item?.attributes && (
          <Avatar
            size='medium'
            hasText={true}
            hasEmail={true}
            user={item?.attributes}
          />
        )}
      </Col>
      {filterRows?.length &&
        filterRows.map((filter) => {
          return (
            <Col key={item?.attributes?.serviceName} size={2}>
              <span>
                {filter === 'serviceName'
                  ? formatServiceCell(item?.attributes[filter])
                  : item?.attributes[filter]}
              </span>
            </Col>
          );
        })}
      {!filterRows?.length && (
        <>
          <Col title={item?.attributes?.serviceNames} size={3}>
            {item?.attributes.serviceNames && (
              <span>{formatServiceCell(item?.attributes.serviceNames)}</span>
            )}
          </Col>
          <Col
            title={
              item?.attributes?.serviceCities || item?.attributes?.district
            }
            size={3}
          >
            <span>
              {item?.attributes?.serviceCities &&
              item?.attributes?.serviceCities.length > 0
                ? formatServiceCell(item?.attributes?.serviceCities)
                : item?.attributes?.district}
            </span>
          </Col>

          {/* <Col size={2}>{item?.attributes.deliveredOn}</Col> */}

          <Col size={2}>
            <span>{item?.attributes.status}</span>
          </Col>
        </>
      )}
    </StyledTableRow>
  );
};

TableRow.propTypes = {
  hasCheckBox: PropTypes.bool,
  item: PropTypes.object,
  action: PropTypes.func,
  filterRows: PropTypes.array,
  connectedDisplay: PropTypes.array,
};

export default TableRow;
