import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import { Col } from 'Components/Layout';
import TableRow from './TableRow';
import useServicesListing from 'Hooks/services/useServicesListing';
import StyledTagTable, { Header } from './styles';

const TagTable = ({ items, tags }) => {
  const t = useTranslate('archive');
  const { services } = useServicesListing({ shouldFetch: true });
  return (
    <StyledTagTable>
      <Header>
        <Col size={2}>{t('serviceType')}</Col>
        <Col size={2}>{t('client')}</Col>
        <Col size={2}>{t('provider')}</Col>
        <Col size={2}>{t('date')}</Col>
        <Col size={4}> {t('tags')}</Col>
      </Header>
      {items &&
        items.map((item, index) => {
          const serviceId = item?.relationships?.service?.data?.id;
          const service = services?.[serviceId]?.attributes;

          return (
            <TableRow
              key={'TagTable' + index}
              item={{ ...item, service }}
              tags={tags}

            />
          );
        })}
    </StyledTagTable>
  );
};

export default TagTable;

TagTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  sortBy: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object)
}