export const newClientSchema = cities => [
    {
      key: 'client',
      groupBy: 2,
      widget: 'login',
      value: 'client',
      children: [
        {
          type: 'radio',
          key: 'company_status',
          label: '',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client',
          options: [
            { label: 'Particular', value: 0, isSelected: false },
            { label: 'Empresa', value: 1, isSelected: false }
          ]
        },
        { type: 'footnote', label: '' },
        {
          key: 'firstName',
          type: 'text',
          label: 'Nome',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'lastName',
          type: 'text',
          label: 'Apelido',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          type: 'email',
          key: 'email',
          label: 'Endereço de e-mail',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'telephone',
          type: 'text',
          label: 'Número de Telemóvel',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'nif',
          type: 'text',
          label: 'NIF',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          type: 'footnote',
          label: '',
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          type: 'footnote',
          label: 'Morada'
        },
        {
          key: 'country',
          type: 'dropdown',
          label: 'País',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client',
          options: [{ label: 'Portugal', value: 0 }]
        },
        {
          key: '_district',
          type: 'dropdown',
          label: 'Distrito',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client',
          options: cities
        },

        {
          key: 'address',
          type: 'address',
          label: 'Morada',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        { type: 'footnote', label: '' },
        {
          key: 'postalCode',
          type: 'text',
          label: 'Código Postal',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'location',
          type: 'text',
          label: 'Localidade',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        }
      ],
      options: [
        {
          value: 'client',
          label: 'Convidado'
        }
      ]
    }
  ];
