import styled from 'styled-components';
import theme from 'Theme';
import { Row, SmallBody } from 'ui-55';

export const StyledContainer = styled.div`
  padding-right: ${(props) => props.theme.margin * 1.5}px;
  padding-bottom: ${(props) => props.theme.margin * 4}px;
`;

export const StyledSmallBody = styled(SmallBody)`
  margin: ${(props) => props?.noMargin && '0px'};
  color: ${(props) => props.theme.colors.grey};
`;
export const ModalButtonRow = styled(Row)`
  justify-content: flex-start;
  button {
    width: 127px;
    padding: 8px 16px;
    margin-right: 25px;
  }
`;
export const Circle = styled.svg`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  align-self: center;
  margin-right: ${(props) => props.theme.margin / 2}px;
  background-color: ${(props) =>
    props.status === 'open'
      ? props.theme.colors.brand.green
      : props.theme.colors.brand.red};
`;

export const InvoicesHeader = styled.div`
  margin-top: ${(props) => props.theme.margin * 1.5}px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightestBeige};
  padding: 0px ${(props) => props.theme.margin}px;
`;

export const InvoicesContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumBeige};
  padding: ${(props) => props.theme.margin}px;
  button {
    width: 172px;
    margin-right: ${(props) => props.theme.margin}px;
    padding: ${(props) => props.theme.margin / 2}px;
  }
`;

export const InvoiceStatusHeader = styled(Row)`
  margin-top: ${(props) => (props.top ? '32px' : null)};
  flex-flow: row nowrap;
  padding: ${(props) => props.theme.margin}px;
  background: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 2px;
`;
export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.theme.colors.lightBeige};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InvoiceHeaderRow = styled(Row)`
  p {
    margin: 0px 16px;
  }
`;

export const FormContainer = styled(Row)`
  > div {
    max-width: none;
  }
  form > button {
    transition: background-color 0.25s ease-in;
    ${({ success, error, theme }) =>
      `background-color:  ${
        (success && theme.colors.feedback.success.default) ||
        (error && theme.colors.feedback.error.default) ||
        ''
      }`};
    span {
      ${({ success, error, theme }) =>
        `color:  ${((success || error) && theme.colors.white) || ''}`};
    }
    &: hover {
      ${({ success, error, theme }) =>
        `background-color:  ${
          (success && theme.colors.feedback.success.hover) ||
          (error && theme.colors.feedback.error.hover) ||
          ''
        }`};
    }
  }
`;

export const TabContainer = styled.div`
  margin-top: ${(props) => props.theme.margin * 2}px;
  > div > div > button {
    margin: 0 16px 16px 0;
  }
`;

export const ModalStyles = {
  overlay: {
    backgroundColor: `${theme.colors.backdrop}`,
  },
  content: {
    border: 'none',
    background: `${theme.colors.white}`,
    overflow: 'auto',
    // padding: '40px',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none',
    maxWidth: '760px',
    margin: '10% auto 0px',
    height: 'fit-content',
  },
};
