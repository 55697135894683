import styled from 'styled-components';
import { Heading, Icon } from 'ui-55';

export const modalCustonStyles = {
  content: {
    padding: '40px',
    width: '760px',
    left: '320px',
    borderRadius: '16px',
    overflow: 'scroll',
  },
};
export const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;
export const StyledFormContainer = styled.div`
  form {
    h2 {
      margin: 0 !important;
    }
    //! hide guest button
    > div > div > button {
      display: none;
    }
    > div > div > div {
      width: 90%;
      input[type='address'] {
        width: calc(344px * 2);
      }
    }
    button:last-of-type {
      max-width: 172px;
      margin: 16px 200px 0px 0px;
    }
  }
  > button {
    width: 172px;
    position: relative;
    bottom: ${({ theme }) => theme.margin + 40}px;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 0px 0px 0px 10px;
`;
