import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "../../features/polyglot";

import { Icon } from 'ui-55';
import {
  SmallBody,
  Body
} from "Components/Text";

import {
  GraphContainer,
  GraphCol,
  FlexContainer,
  FlexMini,
  BorderedContainer,
  ServiceHeading,
  ServiceCount,
  GreySmallBody
} from "./style";

import PieGraph from "./PieGraph";

const FirstRow = (dashboardData) => {
  const t = useTranslate("dashboard");

  return (
    <FlexContainer className="top-row">
      <BorderedContainer padding={true}>
        <div className="icon-container">
          <Icon name="tool-1" size={20} />
        </div>
        <FlexContainer column marginLeft>
          <GreySmallBody>{t("pedidos")}</GreySmallBody>
          <ServiceHeading size={5}>{dashboardData.service_total}</ServiceHeading>
        </FlexContainer>
      </BorderedContainer>
      <BorderedContainer padding={true}>
        <div className="icon-container">
          <Icon name="Total" size={20} />
        </div>
        <FlexContainer column marginLeft>
          <GreySmallBody>{t("moneyTotal")}</GreySmallBody>
          <ServiceHeading size={5}>{`${dashboardData.money_total ? dashboardData.money_total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0} €`}</ServiceHeading>
        </FlexContainer>
      </BorderedContainer>
    </FlexContainer>
  )
}

const SecondRow = (dashboardData) => {
  const t = useTranslate("dashboard");
  const servicePercentage = (dashboardData.service_completed / dashboardData.service_total ) * 100
  const providerPercentage = (dashboardData.provider_accepted / dashboardData.provider_total ) * 100
  const serviceData = [
    {
      "id": "service_completed",
      "label": "Serviços Completos",
      "value": servicePercentage,
      "color": "#FEC35A"
    },
    {
      "id": "service_total",
      "label": "Serviços Pedidos",
      "value": 100 - servicePercentage,
      "color": "#F3E9E1"
    },
  ]

  const providerData = [
    {
      "id": "provider_accepted",
      "label": "Especialistas Aceites",
      "value": providerPercentage,
      "color": "#FEC35A"
    },
    {
      "id": "provider_total",
      "label": "Especialistas Total",
      "value": 100 - providerPercentage,
      "color": "#F3E9E1"
    },
  ]
  return (
    <FlexContainer className="second-row">
      <BorderedContainer>
        <div className="pie-container">
          <PieGraph data={serviceData} />
          <FlexContainer className="graph-legend" column>
            <ServiceHeading size={2} className="top-text">{dashboardData.service_completed}</ServiceHeading>
            <ServiceHeading size={6}>{t("completeService")}</ServiceHeading>
          </FlexContainer>
        </div>
      </BorderedContainer>
      <BorderedContainer>
        <div className="pie-container">
          <PieGraph data={providerData} />
          <FlexContainer className="graph-legend" column>
            <ServiceHeading size={2} className="top-text">{dashboardData.provider_accepted}</ServiceHeading>
            <ServiceHeading size={6}>{t("specialistNumber")}</ServiceHeading>
          </FlexContainer>
        </div>
      </BorderedContainer>
    </FlexContainer>
  )
}

const ThirdRow = (dashboardData) => {
  const [listDesc, setListDesc] = useState(true);

  return (
    <FlexContainer className="third-row">
      <BorderedContainer fullWidth column padding={true}>
        <ServiceHeading size={5} marginBottom>Serviços por Categoria</ServiceHeading>
        <FlexContainer fullWidth className="service-list-headers">
          <SmallBody>Serviços</SmallBody>
          <FlexMini onClick={() => setListDesc(!listDesc) }>
            <SmallBody>Pedidos</SmallBody>
            <Icon name={listDesc ? 'chevron-down' : 'chevron-up'} size={20} />
          </FlexMini>
        </FlexContainer>
        <FlexContainer fullWidth column>
          { dashboardData && RenderServiceRow(dashboardData, listDesc) }
        </FlexContainer>
      </BorderedContainer>
    </FlexContainer>
  )
}
const sortCountHash = (hash, listDesc) => {
  let resultHash = {};

  let temp = Object.keys(hash).sort(function(a, b) {
    return hash[a] - hash[b]
  })
  if(!listDesc) {
    temp = temp.reverse()
  }
  temp.forEach(function(k) {
    resultHash[k] = hash[k];
  });

  return resultHash;
}

const RenderServiceRow = (dashboardData, listDesc) => {
  let originHash = dashboardData.service_by_category
  let resultHash = sortCountHash(originHash, listDesc)

  return Object.keys(resultHash).reverse().map((key, index) => {
    return (
      <FlexContainer key={`service-${index}`} className="service-list" fullWidth>
        <Body>{key}</Body>
        <ServiceCount>{dashboardData.service_by_category[key]}</ServiceCount>
      </FlexContainer>
    )
  })
}

const Graphs = ({ dashboardData }) => {
  return (
    <GraphCol size={2}>
      <GraphContainer>
        {FirstRow(dashboardData)}
        {SecondRow(dashboardData)}
        {ThirdRow(dashboardData)}
      </GraphContainer>
    </GraphCol>
  );
};

Graphs.propTypes = {
  title: PropTypes.string,
  content: PropTypes.func,
  isOpen: PropTypes.bool
};

Graphs.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "Graphs"
};
export default Graphs;
