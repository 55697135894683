import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/activities';

const useActivity = ({ shouldFetch = false, activityId } = {}) => {
  const dispatch = useDispatch();
  const {
    getActivity,
    createActivity,
  } = actions;
  const {
    //show
    makeGetActivity,
    getActivityLoading,
    getActivityError,
    //create new activity
    getCreateActivityLoading,
    getCreateActivitySuccess,
  } = selectors;

  // SHOW
  const makeActivity = useMemo(makeGetActivity, []);
  const activity = useSelector(state =>
    makeActivity(state, activityId)
  );

  const activityLoading = useSelector(
    state => getActivityLoading(state),
    shallowEqual
  );

  const activityError = useSelector(
    state => getActivityError(state),
    shallowEqual
  );

  const dispatchGetActivity = useCallback(
    id => {
      getActivity(dispatch, id);
    },
    [dispatch, getActivity]
  );

  useEffect(() => {
    if (shouldFetch && !activity && activityId) {
      dispatchGetActivity(activityId);
    }
  }, [dispatchGetActivity, shouldFetch, activity, activityId]);

  // CREATE Activity
  const dispatchCreateActivity = useCallback(
    (payload) => {
      createActivity(dispatch, payload);
    },
    [createActivity, dispatch]
  );

  const createActivityLoading = useSelector(
    state => getCreateActivityLoading(state),
    shallowEqual
  );

  const createActivitySuccess = useSelector(
    state => getCreateActivitySuccess(state),
    shallowEqual
  );

  return {
    //show
    getActivity: dispatchGetActivity,
    activity,
    activityError,
    activityLoading,
    //create  activity
    createActivity: dispatchCreateActivity,
    createActivityLoading,
    createActivitySuccess,
  };
};

export default useActivity;
