import styled from 'styled-components';

export const StyledTableRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};

  :hover {
    background-color: ${props => props.theme.colors.lightestBeige};
  }
`;

export const Service = styled.div`
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.brand.orange};

  :hover {
    cursor: pointer;
  }
`;

export default StyledTableRow;


// import styled from 'styled-components';

// export const StyledTableRow = styled.div`
//   display: flex;
//   align-items: center;
//   font-weight: normal;
//   height: 80px;
//   cursor: pointer;
//   border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};

//   :hover {
//     background-color: ${props => props.theme.colors.lightestBeige};
//     cursor: pointer;
//   }
// `;

// export default StyledTableRow;
