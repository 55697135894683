import { useEffect, useMemo, useCallback } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/conversations';

const useConversation = ({ shouldFetch = false, conversationId } = {}) => {
  const dispatch = useDispatch();
  const { getConversation } = actions;
  const { makeGetConversation, getConversationLoading, getConversationError } = selectors;

  const makeConversation = useMemo(makeGetConversation, []);
  const conversation = useSelector(state => makeConversation(state, conversationId));

  const conversationError = useSelector(state => getConversationError(state), shallowEqual);

  const conversationLoading = useSelector(
    state => getConversationLoading(state),
    shallowEqual
  );

  const dispatchGetConversation = useCallback(
    id => {
      getConversation(id, dispatch);
    },
    [dispatch, getConversation]
  );

  useEffect(() => {
    if (shouldFetch && !conversation && conversationId) {
      dispatchGetConversation(conversationId);
    }
  }, [dispatchGetConversation, shouldFetch, conversation, conversationId]);

  return {
    conversation,
    conversationError,
    conversationLoading,
    getConversation: dispatchGetConversation,
  };
};

export default useConversation;
