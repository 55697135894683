import styled, { createGlobalStyle } from 'styled-components';
import theme from 'theme';

const borderPadding = 25;
const selectedTagColor = theme.colors.darkBlue;
const selectedTagBackColor = theme.colors.brand.yellow;
const extensionHeight = 310;
// const extensionWidth = 390;

export const ExtendedContainer = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column; 

  justify-content: space-between; 
  padding: ${borderPadding}px ${borderPadding * 1.5}px;
  height: ${extensionHeight}px;
  margin-top: ${borderPadding / 2}px;
  -webkit-box-shadow: 5px 5px 23px 0px rgba(184,184,184,1);
  -moz-box-shadow: 5px 5px 23px 0px rgba(184,184,184,1);
  box-shadow: 5px 5px 23px 0px rgba(184,184,184,1);  
  z-index: 2;
  ${props => `${props.popDirection}: 0px;`}
`;

export const Clear = styled.span`
  color: ${theme.colors.brand.orange};
  text-align: right;
  padding-right: 16px;

  path {
    fill: ${theme.colors.brand.orange};
  }

  &:hover {
    cursor: pointer;
  }
`;

// override react-datepicker styles
const headerBackground = 'white';
const borderColor = '#D2CCC6';
const hover = 'pink';
const selected = 'blue';

export const DatePickerWrapperStyles = createGlobalStyle`
  .react-datepicker {
    border: none;
  }

  .react-datepicker__header {
    border: none;
    padding: 16px;
    background-color: ${headerBackground};
    border-bottom: 2px solid ${borderColor};
    
    .react-datepicker__current-month {
      margin-top: -7px;
      margin-bottom: 21px;
    }
  }

  .react-datepicker__day {
    margin: 0px;
    padding: 4px;
  }

  .react-datepicker__day--today {
    border-radius: 50%;
    background-color: ${borderColor};
    color: grey;

    &:hover {
      border-radius: 50%;
      background-color: ${selectedTagBackColor};        
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: white;
    color: black;
  }

  .react-datepicker__day--selected {
    border-radius: 0px;
    background-color: ${selected};

    &:hover {
      background-color: ${hover}
    }
  }

  .react-datepicker__day--in-selecting-range {
    border-radius: 0px;
    background-color: ${selectedTagBackColor}60;
    color: ${selectedTagColor};
    &:hover {
      background-color: ${selectedTagBackColor}60;
      color: ${selectedTagColor};
      border-radius: 0px;
    }
  }

  .react-datepicker__day--selecting-day-start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: ${selectedTagBackColor};
    color: ${selectedTagColor};    
    &:hover {
      background-color: ${selectedTagBackColor};
      color: ${selectedTagColor};      
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;  
      background-color: black;
    }    
  }

  .react-datepicker__day--in-range {
    border-radius: 0px;
    background-color: ${selectedTagBackColor}60;
    color: ${selectedTagColor};
    &:hover {
      border-radius: 0px;
      background-color: ${selectedTagBackColor};
      color: white;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-end,
  .react-datepicker__day--range-start {
    background-color: ${selectedTagBackColor};
    color: ${selectedTagColor};    

    &:hover {
      background-color: ${selectedTagColor};
      color: white;
    }
  }

  .react-datepicker__day--range-start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;

    &:hover {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;  
    }
  }  

  .react-datepicker__day--range-end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    &:hover {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
  
    }    
  }

  .react-datepicker__week {
    &:first-child {
      border-radius: 50%;
    }
  }

`;