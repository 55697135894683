import { createSelector } from 'reselect';

import { getVisitsEntities } from 'redux/entities/selectors';

const selectVisitsState = state => state?.visits;

// SHOW
export const makeGetVisit = () =>
  createSelector(
    getVisitsEntities,
    (_, visitId) => visitId,
    (entities, id) => entities[id]
  );

export const getVisitLoading = createSelector(selectVisitsState, state => {
  return state?.visitLoading || false;
});
export const getVisitLoaded = createSelector(selectVisitsState, state => {
  return state?.visitLoaded || false;
});
export const getVisitError = createSelector(selectVisitsState, state => {
  return state?.visitError || null;
});

// CREATE
export const getCreateVisitLoading = createSelector(
  selectVisitsState,
  state => {
    return state?.isSubmitting || false;
  }
);

export const getCreateVisitSuccess = createSelector(
  selectVisitsState,
  state => {
    return state?.wasSuccessful || false;
  }
);

// UPDATE
export const getUpdateVisitLoading = createSelector(
  selectVisitsState,
  state => {
    return state?.updateVisitLoading || false;
  }
);

export const getUpdateVisitSuccess = createSelector(
  selectVisitsState,
  state => {
    return state?.updateVisitSuccess || false;
  }
);

export const getUpdateVisitFail = createSelector(selectVisitsState, state => {
  return state?.updateVisitFail || false;
});
