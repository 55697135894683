import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import { Col } from 'Components/Layout';
import TableRow from './TableRow';

import { Icon } from 'ui-55';

import StyledTeamTable, { Header } from './styles';

const TeamTable = ({ items, action, orderBy, teams }) => {
  const t = useTranslate('team');

  return (
    <StyledTeamTable>
      <Header>
        <Col size={4}>
          <div onClick={() => orderBy('name')}>
            {t('name')}
            <Icon name="chevron-down" />
          </div>
        </Col>

        {/* <Col size={4}>{t('function')}</Col> */}

        <Col size={4}>
          <div onClick={() => orderBy('team')}>
            {t('team')}
            <Icon name="chevron-down" />
          </div>
        </Col>
      </Header>

      {items &&
        Object.keys(items).map((item, index) => {
          return (
            <TableRow
              key={'TeamTable' + index}
              item={items[item]}
              action={action}
              teams={teams}
            />
          );
        })}
    </StyledTeamTable>
  );
};

export default TeamTable;

TeamTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.func,
  sortBy: PropTypes.func,
  teams: PropTypes.arrayOf(PropTypes.object)
}