import styled from 'styled-components';
import { Row } from 'ui-55';

export const ButtonSlider = styled.button`
  border-radius: 40px;
  outline: none;
  padding: 16px;
  background-color: #fec35a;
  border: transparent;
  width: 24px;
  height: 24px;
  position: relative;
  top: 235px;
  display: none;
`;
export const FormContainer = styled(Row)`
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  border-radius: 4px;
  align-content: flex-start;
  padding: 24px;
  width: auto;
  margin: 0;
  > div {
    max-width: 100%;
  }
  form > button {
    transition: background-color 0.25s ease-in;
    ${({ success, error, theme }) =>
      `background-color:  ${(success &&
        theme.colors.feedback.success.default) ||
        (error && theme.colors.feedback.error.default) ||
        ''}`};
    span {
      ${({ success, error, theme }) =>
        `color:  ${((success || error) && theme.colors.white) || ''}`};
    }
    &: hover {
      ${({ success, error, theme }) =>
        `background-color:  ${(success &&
          theme.colors.feedback.success.hover) ||
          (error && theme.colors.feedback.error.hover) ||
          ''}`};
    }
  }
`;

export const TabContainer = styled.div`
  margin-top: ${(props) => props.theme.margin * 2}px;
  margin-bottom: ${(props) => props.theme.margin * 4}px;
  > div > div > button {
    margin: 0 16px 16px 0;
  }
`;
