import { createSelector } from 'reselect';

import { getProviderServicesEntities } from 'redux/entities/selectors';

const selectProviderServicesState = (state) => state.providerServices;

const getProviderServices = createSelector(selectProviderServicesState, state => {
  return state.providerServices || null
});

const makeGetProviderService = () =>
  createSelector(
    getProviderServicesEntities,
    (_, providerServiceId) => providerServiceId,
    (entities, id) => entities[id]
  );


export {
  getProviderServices,
  makeGetProviderService
}