import { createSelector } from 'reselect';

import { getRatingsEntities } from 'redux/entities/selectors';

const makeGetRating = () =>
  createSelector(
    getRatingsEntities,
    (_, ratingId) => ratingId,
    (entities, id) => entities[id]
  );


export {
  makeGetRating
}