import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';

import useProvider from 'Hooks/providers/useProvider';

import SPECIALIST_FORM_SCHEMA from 'ui-55/src/components/utils/becomespecialist';
import { BackofficeContainer, GridCol, Loader, Form } from 'ui-55';

import { FormContainer } from './styles.js';

const NewCandidate = ({ action }) => {
  const t = useTranslate('specialists');
  const formRef = useRef();
  const history = useHistory();

  const { providerError: error, createProvider } = useProvider();

  const handleSubmit = async values => {
    const submitObj = {
      form_answers: values
    };
    createProvider(submitObj, {
      success: () => {
        toast.success(t('success'));
        history.push('/dashboard/candidates');
      },
      error: e => {
        toast.error(e);
      }
    });
  };

  const scrollToRef = ref => {
    ref.current.scrollIntoView();
  };

  const handleError = errors => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };

  const formQuestions = SPECIALIST_FORM_SCHEMA.questions.map(q => ({
    ...q,
    isOpen: true
  }));

  return (
    <>
      <BackofficeContainer>
        {(
          <GridCol size={12}>
            <FormContainer ref={formRef} error={error}>
              <Form
                onError={handleError}
                submitLabel={t('save')}
                onSubmit={values => handleSubmit(values)}
                questions={formQuestions}
              />
            </FormContainer>
          </GridCol>
        ) || <Loader />}
      </BackofficeContainer>
    </>
  );
};

NewCandidate.propTypes = {
  action: PropTypes.func
};

export default NewCandidate;
