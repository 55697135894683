import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';

import useFilters from 'hooks/useFilters';
import useAppointments from 'hooks/appointments/useAppointments';

import RequestListTable from './RequestListTable';
import { Loader, Heading, Pagination } from 'ui-55';

const ClientDetailRequestList = () => {
  const t = useTranslate('specialists');
  const history = useHistory();
  const location = useLocation();

  const { active, queryString, page, getQueryStringFromFilters } = useFilters(location);
  const { appointments, loading, loaded, totalPages } = useAppointments(
    {query: queryString, shouldFetch: true});

  const handleNavigation = (pageNumber) => {
    history.push(`${getQueryStringFromFilters(active)}&page=${parseInt(pageNumber)}`);
  }

  return loaded && !appointments.length ? (
      <Heading size={5}>{t('noAppointments')}</Heading>
    ) : (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <RequestListTable items={appointments} />
            <Pagination
              translate={t}
              totalPages={totalPages}
              currentPage={page}
              action={handleNavigation}
            />
          </div>
        )}
      </>
    )
};

export default ClientDetailRequestList;
