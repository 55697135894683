import { ACTION_TYPES } from "./actions";

const initialState = {
  loading: false,
  loaded: false,
  notifications: [],
  error: null,
  serviceLoading: false,
  serviceLoaded: false,
  serviceError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data.map(d => d.id),
        [action.meta.filter]: action.payload.data.map(d => d.id),
        loading: false,
        error: false,
        loaded: true
      };
    case ACTION_TYPES.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};
