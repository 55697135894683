import client from './client';

const postUserLogin = ({ email, password }) => {
  return client.post('sign_in', {
    admin: {
      email,
      password
    }
  });
};

const updateUser = payload => {
  return client.patch('update_me', {
    admin: payload
  });
};

const deleteUserLogout = () => {
  return client.delete('sign_out').then(() => {
    delete client.defaults.headers['authorization'];
    delete client.defaults.headers.common['authorization'];
  });
};

const acceptInvitation = payload => {
  return client.patch('invitation/accept', {
    admin: payload
  });
};

export default {
  postUserLogin,
  deleteUserLogout,
  acceptInvitation,
  client,
  updateUser
};
