import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';
import { Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

import KanbanCard from 'Components/KanbanCard';
import { SubHeading } from 'Components/Text';
import { Icon } from 'ui-55';

import StyledKanbanColumn, {
  Header,
  IconContainer,
  ColumnCardsContainer,
  CreateNewRequestContainer
} from './style';

const CREATE_NEW_COLUMNS = [
  {
    column: 'received',
    label: 'createRequest',
    navigation: '/dashboard/create-new-request'
  },
  {
    column: 'new_candidate',
    label: 'createCandidate',
    navigation: '/dashboard/new-candidate'
  }
];

const KanbanColumn = ({
  colName,
  items,
  kanbanType,
  action,
  isInvalidTarget
}) => {
  const t = useTranslate(kanbanType);
  const history = useHistory();
  const handleNavigation = col => {
    history.push(col.navigation);
  };
  const renderCards = useCallback(
    () =>
      items.map((item, index) => {
        return (
          <KanbanCard
            action={() => (action ? action(item) : null)}
            key={'kanbanCard' + item.id}
            cardKey={item.id}
            cardType={kanbanType}
            index={index}
            item={item}
          />
        );
      }),

    [action, items, kanbanType]
  );
  const hasCreateBtn =
    CREATE_NEW_COLUMNS.find(c => c.column === colName) || null;
  return (
    <Droppable droppableId={colName} key={'column' + colName}>
      {provided => (
        <StyledKanbanColumn
          ref={provided.innerRef}
          invalid={isInvalidTarget}
          {...provided.droppableProps}
        >
          <Header hasCreateButton={hasCreateBtn}>
            {<SubHeading>{t(colName)}</SubHeading>}
            <IconContainer>
              <Icon name='maximize' />
            </IconContainer>
          </Header>
          {provided.placeholder}
          {hasCreateBtn && (
            <CreateNewRequestContainer
              onClick={() => handleNavigation(hasCreateBtn)}
            >
              <Icon name='Plus' />
              {t(hasCreateBtn.label)}
            </CreateNewRequestContainer>
          )}
          <ColumnCardsContainer>{renderCards()}</ColumnCardsContainer>
        </StyledKanbanColumn>
      )}
    </Droppable>
  );
};

KanbanColumn.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  kanbanType: PropTypes.oneOf(['requests', 'candidates']),
  colName: PropTypes.string,
  isInvalidTarget: PropTypes.bool,
  action: PropTypes.func
};

export default KanbanColumn;
