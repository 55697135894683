import { createSlice } from "@reduxjs/toolkit";
import config from 'Config';
import content from '../../content.json';

const tokenFromLocalStorage = localStorage.getItem("AUTH_TOKEN");

const initialState = {
    token: tokenFromLocalStorage ? JSON.parse(tokenFromLocalStorage).token.split(' ')[1] : null,
    user: tokenFromLocalStorage ? JSON.parse(tokenFromLocalStorage).user : null,
};

const authUser = createSlice({
    name: 'authUser',
    initialState: initialState,
    reducers: {
        setToken(state, action) {
            state.token = action.payload;
        }
    },
});
export const selectToken = (state) => state.authUser.token;
export const { setToken } = authUser.actions;
export default authUser.reducer;
