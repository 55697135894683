import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useTranslate } from '../../features/polyglot';
import useSettings from 'hooks/useSettings';
import useTagsListing from 'Hooks/tags/useTagsListing';
import useFilters from 'hooks/useFilters';
import client from 'Services/client';
import Select from 'Components/Select';
import FilterButton from 'Components/FilterButton';
import { Icon, Search, IconSwitch, Button, Row } from 'ui-55';
import StyledFilterBar, {
  Header,
  Filters,
  Selects,
  StyledFilterSelect,
  Flex,
} from './styles';
import { LanguageSelect } from 'features/languages/components/LanguageSelect';

const MAP_STATE_TO_API = {
  admins: 'admin_id',
  appointment_status: 'status',
  appointment_tag: 'tag',
  candidate_lead_status: 'status',
  cities: 'city_ids',
  services: 'service_ids',
  date: 'date',
  start_date: 'start_date',
  delivery_start_date: 'delivery_start_date',
  newsletter: 'newsletter',
  status: 'status',
  recurring: 'recurring',
  is_active: 'active',
  is_premium: 'premium',
  distance: 'latitude',
  availability: 'availability',
  kanban: 'kanban',
};
const ARY_KEYS = ['admin_id', 'status', 'city_ids', 'service_ids', 'tag'];

const renderPlaceholder = (t) => (
  <StyledFilterSelect>
    <Icon name='sliders' /> {t('filters')}
  </StyledFilterSelect>
);

const FilterSection = ({
  availableFilters,
  showLayout,
  handleLayoutChange,
  hasAddButton,
  addButtonAction,
  hasCSVLink,
  hasLanguageSelect,
  hasPagination,
  gpsData,
  availabilityData,
  hasFilters,
  hasSearch,
  multiFilters = []
}) => {
  const t = useTranslate('filters');
  const s = useTranslate('buttons');
  const location = useLocation();
  const history = useHistory();
  const { settings } = useSettings();
  const { tags } = useTagsListing({
    shouldFetch: true,
  });




  const {
    active,
    getFiltersFromQuery,
    getQueryStringFromFilters,
    page,
    parseValueType,
  } = useFilters(location);
  const [localFilters, setLocalFilters] = useState(active);
  // get FilterButton options from settings
  const localOptions = useMemo(() => {
    const options = {};
    availableFilters.forEach((e) => {
      return (options[MAP_STATE_TO_API[e]] = settings[e]);
    });
    if (tags !== null && tags !== undefined) {
      options[MAP_STATE_TO_API['appointment_tag']] = tags.map(tag => ({
        label: tag.attributes.name,
        value: tag.id,
      }));
    }
    return options
  }, [settings, tags]);

  const makeDateLabel = (filters) => {
    if (filters?.start_date && filters?.end_date) {
      return `${filters?.start_date} - ${filters?.end_date}`;
    }
    if (filters?.delivery_start_date && filters?.delivery_end_date) {
      return `${filters?.delivery_start_date} - ${filters?.delivery_end_date}`;
    }
  };
  const dateLabel = useMemo(() => {
    return makeDateLabel(active)
  }, [active])

  const queryText = getFiltersFromQuery(location.search)?.search;

  // set local filters
  useEffect(() => {
    setLocalFilters(active);
  }, [active]);

  // get Available Filter options
  const getAvailableFilterOptions = (availableFilters) => {
    let options;
    if (
      !availabilityData?.deliveredOn &&
      availableFilters.includes('availability')
    ) {
      options = [...availableFilters].filter((f) => f !== 'availability');
    } else {
      options = [...availableFilters];
    }
    return options.map((f) => {
      return { label: t(MAP_STATE_TO_API[f]), value: f };
    });
  };
  const availableFilterOptions = getAvailableFilterOptions(availableFilters);

  // search => trigger api
  const handleQuery = _.debounce(function (e) {
    const filters = { ...localFilters, search: e };
    if (e === '') delete filters.search;

    const stringQuery = getQueryStringFromFilters(filters);
    const pageQuery = `${stringQuery ? '&' : '?'}page=1`;

    history.push(`${stringQuery}${pageQuery}`);
  }, 1500);

  // add local filter without triggering call
  const handleAddFilter = (v) => {
    const key = MAP_STATE_TO_API[v.value];
    const filters = { ...localFilters, [key]: 'all' };
    setLocalFilters(filters);
  };

  // update query string with filter changes => trigger api
  const handleFilterChange = (e, filterType) => {
    let v, filters;
    // if "e" is null it means the filter was removed
    if (e) {
      // if "e" is an array it means thats a multi selection
      if (Array.isArray(e)) {
        v = e.map((item) => item.value);
      } else {
        v = ARY_KEYS.includes(filterType) ? [e.value] : e.value;
      }

      if (filterType === 'latitude') {
        filters = makeDistanceFilters();
      } else if (filterType === 'availability') {
        filters = {
          ...localFilters,
          [filterType]: availabilityData?.deliveredOn,
        };
      } else {
        filters = { ...localFilters, [filterType]: v };
      }
    } else {
      filters = { ...localFilters }
      delete filters[filterType]
    }

    filters = removeAll(filters);
    const stringQuery = getQueryStringFromFilters(filters);
    const pageQuery = page ? `${stringQuery ? '&' : '?'}page=1` : '';
    history.push(`${stringQuery}${pageQuery}`);
  };

  const removeAll = (filters) => {
    if (Object.values(filters).indexOf('all') !== -1) {
      delete filters[Object.keys(filters)[Object.values(filters).indexOf('all')]]
      removeAll(filters);
    }
    return filters;
  }

  const makeDistanceFilters = () => {
    return {
      ...localFilters,
      latitude: gpsData?.latitude,
      longitude: gpsData?.longitude,
    };
  };

  const handleCloseFilter = (key) => {
    if (active[key]) {
      // push new query to url, with filter removed
      const newFilters = { ...active };
      delete newFilters[key];
      if (key === 'start_date') delete newFilters['end_date'];
      if (key === 'delivery_start_date') delete newFilters['delivery_end_date'];
      if (key === 'latitude') delete newFilters['longitude'];

      const stringQuery = getQueryStringFromFilters(newFilters);

      // reset to first page unless flagged
      const pageQuery = hasPagination
        ? `${stringQuery ? `${stringQuery}&` : '?'}page=1`
        : `${location.pathname}${stringQuery}`;
      history.push(`${pageQuery}`);
    } else {
      // delete the local filter to remove from display
      const newLocals = { ...localFilters };
      delete newLocals[key];
      setLocalFilters(newLocals);
    }
  };

  const handleCreateCsv = () => {
    const getPageEndpoint = (url) => {
      const endpoints = {
        requests: `appointments`,
        archive: 'appointments',
        specialists: 'providers',
        clients: 'clients',
        candidates: 'candidate_leads',
      };
      return endpoints[url];
    };

    const pageUrl = window.location.pathname.split('/');
    const pathname = getPageEndpoint(pageUrl[pageUrl.length - 1])
    const base = `${pathname}${location.search}`;
    const requestCSVQuery = `${base}${location.search.length ? `&` : `?`
      }csv=true`;

    client
      .get(requestCSVQuery)
      .then((res) => {
        if (res?.status === 200) toast.success(t('csvSuccess'));
      })
      .catch((err) => toast.error(t('csvError')));
  };

  const isMultiHandler = (filter) => multiFilters.includes(filter)

  return (
    <StyledFilterBar>
      <Header>
        <Selects>
          {hasLanguageSelect && (
            <LanguageSelect/>
          )}
          {hasSearch && (
            <Search
              defaultValue={queryText}
              type='filter'
              translate={s}
              onChange={handleQuery}
            />
          )}
          {hasFilters && (
            <Select
              placeholder={renderPlaceholder(t)}
              options={availableFilterOptions}
              onChange={handleAddFilter}
              value={null}
            />
          )}
        </Selects>
        <Flex>
          <Flex marginRight>
            {hasCSVLink && (
              <Row padding={0} justify='flex-end'>
                <Button
                  type='csv-button'
                  text={t('csvExport')}
                  btnType='transparent'
                  icon={'download'}
                  action={handleCreateCsv}
                />
              </Row>
            )}
            {hasAddButton && (
              <Button
                type='plus'
                btnType='borded'
                icon='Plus'
                action={addButtonAction}
              />
            )}
          </Flex>
          {showLayout && (
            <IconSwitch switchType='layout' onChange={handleLayoutChange} />
          )}
        </Flex>
      </Header>
      <Filters>
        {localFilters &&
          localOptions &&
          Object.keys(localFilters).map((filter, index) => {
            const value = ARY_KEYS.includes(filter)
              ? active[filter]?.[0]
              : active[filter];
            const selected = localOptions[filter]?.find(
              (e) => {

                if (typeof e.value === "boolean") {
                  return String(e.value) === parseValueType(value)
                }
                if (filter === 'tag') {
                  return parseInt(e.value) === parseInt(value)
                }
                return e.value === parseValueType(value)

              }
            );
            let selectedLabel = ['start_date', 'date'].includes(filter)
              ? dateLabel
              : selected?.label;

            const filterLength = active[filter]?.length;
            if (typeof active[filter] !== "string" && filterLength > 1) {
              selectedLabel += " +" + (filterLength - 1)
            }
            const showServiceFilter = !(filter === 'service_ids' && location?.pathname === '/dashboard/request-details')

            return (
              <React.Fragment key={`filter-button-${filter}-${index}`}>
                {filter !== 'archived' &&
                  filter !== 'search' &&
                  filter !== 'longitude' &&
                  filter !== 'page' &&
                  filter !== 'id' &&
                  filter !== 'end_date' &&
                  filter !== 'delivery_end_date' &&
                  filter !== 'provider_id' &&
                  filter !== 'kanban' &&
                  showServiceFilter &&
                  (
                    <FilterButton
                      isMulti={isMultiHandler(filter)}
                      filterType={filter}
                      selectedLabel={
                        ['latitude', 'availability'].includes(filter)
                          ? filter
                          : selectedLabel
                      }
                      filterOptions={localOptions[filter]}
                      onClose={handleCloseFilter}
                      onChange={handleFilterChange}
                    />
                  )}
              </React.Fragment>
            );
          })}
      </Filters>
    </StyledFilterBar>
  );
};

export default FilterSection;

FilterSection.propTypes = {
  availableFilters: PropTypes.arrayOf(
    PropTypes.oneOf([
      'admins',
      'appointment_status',
      'appointment_tag',
      'candidate_lead_status',
      'cities',
      'services',
      'date',
      'delivery_start_date',
      'start_date',
      'newsletter',
      'status',
      'recurring',
      'is_active',
      'is_premium',
      'distance',
      'availability',
    ])
  ),
  hasLanguageSelect: PropTypes.bool,
  showLayout: PropTypes.bool,
  handleLayoutChange: PropTypes.func,
  hasAddButton: PropTypes.bool,
  hasCSVLink: PropTypes.bool,
  hasPagination: PropTypes.bool,
  gpsData: PropTypes.shape({
    PropTypes,
  }),
  availabilityData: PropTypes.bool,
  hasFilters: PropTypes.bool,
  hasSearch: PropTypes.bool,
  addButtonAction: PropTypes.func
};

FilterSection.defaultProps = {
  hasLanguageSelect: false,
  hasPagination: true,
  hasFilters: true,
  hasSearch: true,
};
