import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';
import Modal from 'react-modal';
import useFilters from 'hooks/useFilters';
import useAppointments from 'hooks/appointments/useAppointments';
import useTagsListing from 'Hooks/tags/useTagsListing';
import TagTable from 'Components/TagTable';
import { Loader, Pagination, BackofficeContainer, Row } from 'ui-55';
import { Button, Icon } from 'ui-55';
import NewTagRender from './ModalRenders/newTagRender';
import { LinkDetail } from 'Components/ServiceDetailSidebar/style';
import {
  TagFilter,
  HeadingDetail,
  FilterButtons,
  NewTagLink,
  ModalStyles,
  AppointmentContent
} from './styles';





const Tag = () => {


  const t = useTranslate('tag');

  const history = useHistory();
  const location = useLocation();

  const { active, queryString, page, getQueryStringFromFilters } = useFilters(
    location
  );
  const { appointments, loading, totalPages } = useAppointments({
    query: queryString,
    shouldFetch: true,
  });

  const handleNavigation = (pageNumber) => {
    history.push(
      `${location.pathname}?${getQueryStringFromFilters(active)}&page=${parseInt(pageNumber)}`
    );
  };
  const { tags } = useTagsListing({
    shouldFetch: true,
  });

  const [activeFilters, setActiveFilters] = useState({
    page: page
  });

  const TagFilterButton = ({ tag }) => {
    const testSelected = () => {
      if (!activeFilters['tag_ids']) return false;
      if (activeFilters['tag_ids'].includes(tag.id)) {
        return true;
      }
    };
    const [selected, setSelected] = useState(testSelected());

    const handleClick = () => {
      let updatedFilters;

      if (!activeFilters['tag_ids']) {
        updatedFilters = {
          ...activeFilters,
          tag_ids: [tag.id],
        };
      } else {
        let newIds;
        if (activeFilters['tag_ids'].includes(tag.id)) {
          newIds = activeFilters['tag_ids'].filter((id) => id !== tag.id);
        } else {
          newIds = [...activeFilters['tag_ids'], tag.id];
        }
        updatedFilters = {
          ...activeFilters,
          page: page,
          tag_ids: newIds,
        };
      }
      setSelected(!selected);
      setActiveFilters(updatedFilters);
      const tagIds = (updatedFilters.tag_ids.length > 0 ?  "tag[]="+updatedFilters.tag_ids.join('&tag[]=') : "");
      history.push(
        `${location.pathname}?${getQueryStringFromFilters(active)}&${tagIds}&page=${parseInt(page)}`
      );

    };

    return (
      <Button
        btnType={selected ? 'active' : 'resting'}
        text={tag.attributes?.name}
        action={handleClick}
      />
    );
  };



  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderModal = useCallback(
    () => {

      return (
        <>
          <Modal style={ModalStyles} ariaHideApp={false} isOpen={isModalOpen}>
            <Row justify='space-between' align='center'>
              <HeadingDetail size={3}>{t('newTag')}</HeadingDetail>
              <Button
                icon='Close'
                btnType='transparent'
                action={() => handleToggleModal()}
              />
            </Row>
            <NewTagRender handleToggleModal={closeModal} />
          </Modal>
        </>
      );
    },
    [handleToggleModal, isModalOpen, t]
  );

  return (
    <>
      <BackofficeContainer>
        <TagFilter>
          <HeadingDetail size={5}>{t('tags')}</HeadingDetail>
          <FilterButtons>
            {tags &&
              Object.keys(tags).map((key, index) => {
                return (
                  <TagFilterButton
                    key={`tag-filter-btn-${index}`}
                    tag={tags[key]}
                  />
                );
              })}
            <NewTagLink onClick={(e) => handleToggleModal('newTag')}>
              <Icon name='Plus' />
              <LinkDetail>{t('newTag')}</LinkDetail>
            </NewTagLink>
          </FilterButtons>
        </TagFilter>
        <AppointmentContent>
          {loading ? <Loader /> : <TagTable

            items={appointments}
            tags={tags}
          />}
          <Pagination
            translate={t}
            totalPages={totalPages}
            currentPage={page}
            action={handleNavigation}

          />
        </AppointmentContent>
        {renderModal()}
      </BackofficeContainer>
    </>
  );
};

export default Tag;
