import client from './client';
import { applyFilters } from 'Utils/filters';

const getNotifications = (pageNumber, filters) => {
  if (filters?.archived && pageNumber) {
    return client.get(`${applyFilters(filters, 'notifications')}&page=${pageNumber}`);
  } else if (filters !== "notifications") {
    return client.get(`${applyFilters(filters, 'notifications')}`);
  }
  return client.get(applyFilters({}, 'notifications'));
};

export default {
  getNotifications,
  client
};
