import client from './client';

const getServices = () => {
  return client.get('/services');
};

const getService = id => {
  return client.get(`services/${id}`);
};

const getServiceForms = () => {
  return client.get('/forms');
};

export default { getServices, getService, getServiceForms, client };
