import providerContactsClient from 'Services/providerContactsService';
import { buildFilterQuery } from 'Utils/filters';

const REDUCER = 'providerContacts';
export const ACTION_TYPES = {
  GET_PROVIDER_CONTACT: `${REDUCER}/GET_PROVIDER_CONTACT`,
  GET_PROVIDER_CONTACT_SUCCESS: `${REDUCER}/GET_PROVIDER_CONTACT_SUCCESS`,
  GET_PROVIDER_CONTACT_FAIL: `${REDUCER}/GET_PROVIDER_CONTACT_FAIL`,
  GET_PROVIDER_CONTACTS: `${REDUCER}/GET_PROVIDER_CONTACTS`,
  GET_PROVIDER_CONTACTS_SUCCESS: `${REDUCER}/GET_PROVIDER_CONTACTS_SUCCESS`,
  GET_PROVIDER_CONTACTS_FAIL: `${REDUCER}/GET_PROVIDER_CONTACTS_FAIL`,
  BULK_CREATE_PROVIDER_CONTACTS: `${REDUCER}/BULK_CREATE_PROVIDER_CONTACTS`,
  BULK_CREATE_PROVIDER_CONTACTS_SUCCESS: `${REDUCER}/BULK_CREATE_PROVIDER_CONTACTS_SUCCESS`,
  BULK_CREATE_PROVIDER_CONTACTS_FAIL: `${REDUCER}/BULK_CREATE_PROVIDER_CONTACTS_FAIL`,
  UPDATE_PROVIDER_CONTACT: `${REDUCER}/UPDATE_PROVIDER_CONTACT`,
  UPDATE_PROVIDER_CONTACT_SUCCESS: `${REDUCER}/UPDATE_PROVIDER_CONTACT_SUCCESS`,
  UPDATE_PROVIDER_CONTACT_FAIL: `${REDUCER}/UPDATE_PROVIDER_CONTACT_FAIL`,
};

// SHOW
const getProviderContact = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_PROVIDER_CONTACT
  });
  providerContactsClient
    .getProviderContact(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_PROVIDER_CONTACT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_PROVIDER_CONTACT_FAIL,
        error: 'Error getting provider'
      });
    });
};

// INDEX
const getProviderContactsListing = (dispatch, pageNumber, filter) => {
  dispatch({
    type: ACTION_TYPES.GET_PROVIDER_CONTACTS
  });
  providerContactsClient
    .getProviderContacts(pageNumber, filter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_PROVIDER_CONTACTS_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter, pageNumber }, 'providers')
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_PROVIDER_CONTACTS_FAIL,
        error: 'Error getting provider contacts '
      });
    });
};


// BULK CREATE
const bulkCreateProviderContacts = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS,
  });
  providerContactsClient
    .bulkCreateProviderContacts(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS_FAIL,
        error: e.message
      });
    });
};

// UPDATE
const updateProviderContact = (dispatch, id, payload, cb = {}) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PROVIDER_CONTACT
  });
  const { error, success } = cb;
  providerContactsClient
    .updateProviderContact(id, payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_PROVIDER_CONTACT_SUCCESS,
          payload: res.data
        });
        if (success) {
          success()
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROVIDER_CONTACT_FAIL,
        error: e
      });
      if (error) {
        error('Ocorreu um erro')
      }
    });
};

export {
  getProviderContact,
  getProviderContactsListing,
  bulkCreateProviderContacts,
  updateProviderContact
};
