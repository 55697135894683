import client from './client';
import { applyFilters } from 'Utils/filters';

const getActivities = (pageNumber, filters) => {
  if (pageNumber) {
    return client.get(`${applyFilters(filters, 'activities')}&page=${pageNumber}`);
  } else if (filters !== 'activities') {
    return client.get(`${applyFilters(filters, 'activities')}`);
  }
  return client.get(applyFilters({}, 'activities'));
};

const getActivity = id => {
  return client.get(`/activities/${id}`);
};

const createActivity = payload => {
  return client.post(`/activities/`, {
    activity: payload
  });
};

export default {
  getActivities,
  getActivity,
  createActivity,
  client
};
