import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { removeAppointmentProvider } from 'Redux/appointments/actions';
import { useTranslate } from '../../../features/polyglot';

import Modal from 'react-modal';

import useAppointment from 'Hooks/appointments/useAppointment';
import useProvider from 'Hooks/providers/useProvider';
import useProviderService from 'Hooks/useProviderService';
import useProviderContact from 'Hooks/providerContacts/useProviderContact';
import useProviderContactsListing from 'Hooks/providerContacts/useProviderContactsListing';
import useService from 'hooks/services/useService';
import useAuth from 'Hooks/useAuth';
import VisitRender from './visitRender';

import useFilters from 'hooks/useFilters';
import useProviders from 'Hooks/providers/useProviders';

import {
  SpecialistRenderContainer,
  StyledInfo,
  IconDetails,
  IconContainer,
  BodyDetail,
  Section,
  SpecialistsContainer,
  StyledModalLeftSection,
  StyledModalRightSection,
  ModalCustomStyles,
  CloseBtnContainer,
  SmallBodyDetail,
  ProviderPreSelectContainer,
  ModalContentContainer,
  RightContent,
  RightRow,
  PriceEstimate,
  RatingContainer,
  StatsContainer,
  Stat,
  StyledIconContainer,
} from './styles';

import {
  Body,
  Icon,
  Row,
  Col,
  Loader,
  Avatar,
  Badge,
  Pagination,
  Button,
  TextInput,
  StarsRating,
  Checkbox,
} from 'ui-55';

import SpecialistTable from 'Components/SpecialistTable';

const SLUGS_WITH_VISITS = [
  'plumbing',
  'electrician',
  'repairs',
  'gardening',
  'bricolage',
];
const SLUGS_WITH_INTERVIEW = ['elder_company', 'babysitting'];

const SpecialistRender = ({
  t,
  appointment,
  providerId,
  isOpenModal,
  setOpenModal,
  togglePaymentModal,
}) => {
  const { user } = useAuth();
  const b = useTranslate('badges');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const appointmentId = queryString.parse(location.search).id;

  // Append service ids for filtering if it does not exist yet
  // reported for admins that access requests by changing the id in URL
  const serviceIds = queryString.parse(location.search)['service_ids[]'];
  if(!serviceIds && appointment.relationships.service.data.id) {
    const search = `id=${appointmentId}&service_ids[]=${appointment.relationships.service.data.id}&page=1`
    history.replace({ search: search });
  }

  const {
    active,
    queryString: qs,
    page,
    getQueryStringFromFilters,
  } = useFilters(location);
  const {
    providers,
    getProvidersById,
    loading,
    totalPages,
  } = useProviders({ query: qs, shouldFetch: true });

  const { entityProviderContacts } = useProviderContactsListing();
  const [providerContacts, setProviderContacts] = useState([]);

  const makeProviderContacts = (updatedEntities) => {
    if (appointment?.relationships?.providerContacts?.data?.length) {
      const contactIds = appointment?.relationships?.providerContacts?.data?.map(
        (e) => e.id
      );
      const providerContacts = contactIds.map((e) => updatedEntities[e]);
      setProviderContacts(providerContacts);
    }
  }

  const {
    getAppointment,
    updateAppointment,
    updateAppointmentLoading,
    appointmentLoading,
  } = useAppointment();

  const { provider } = useProvider({ providerId: providerId });
  const [providersPreSelection, setProvidersPreSelection] = useState(
    provider ? [provider] : []
  );

  useEffect(() => {
    if (providerContacts) {
      const ids = providerContacts.map((e) => {
        return e?.relationships?.provider?.data?.id;
      });
      const provs = getProvidersById(ids);
      setProvidersPreSelection(provs);
    }
  }, [providerContacts]);

  useEffect(() => {
    if (entityProviderContacts) {
      makeProviderContacts(entityProviderContacts);
    }
  }, [entityProviderContacts]);

  const handlePaginationNavigation = (pageNumber) => {
    history.push(
      `${getQueryStringFromFilters(active)}&page=${parseInt(pageNumber)}`
    );
  };

  const handleSelectionSubmit = useCallback(
    (providerId, data) => {
      const { visitRequired, estimatedHours, isPaidVisit } = data;

      const payload = {
        provider_id: providerId,
        current_admin_id: user?.id,
        visit_required: visitRequired,
        estimated_hours: estimatedHours,
        is_paid_visit: isPaidVisit,
        status: visitRequired
          ? 'awaiting_visit_payment'
          : 'awaiting_service_payment',
      };

      if (!isPaidVisit && visitRequired) {
        payload['status'] = 'awaiting_visit';
        updateAppointment(payload, appointment?.id, {
          success: () => {
            toast.success(t('interviewSuccess'));
          },
          error: (e) => {
            toast.error(e);
          },
        });
        return;
      }

      if (visitRequired) {
        togglePaymentModal('issueVisitInvoice', payload, appointment);
      } else {
        togglePaymentModal('issueServiceInvoice', payload, appointment);
      }
    },
    [appointment, t, togglePaymentModal, updateAppointment, user.id]
  );

  useEffect(() => {
    if (updateAppointmentLoading) {
      setOpenModal(false);
      getAppointment(appointmentId);
    }
  }, [
    appointmentId,
    getAppointment,
    handleSelectionSubmit,
    isOpenModal,
    setOpenModal,
    updateAppointmentLoading,
  ]);

  const handlePreSelection = useCallback(
    (prov) => {
      const providerSelectionIds = providersPreSelection.map((p) => p.id);
      const isProviderSelected = providerSelectionIds.indexOf(prov.id) !== -1;
      setProvidersPreSelection(
        isProviderSelected
          ? providersPreSelection.filter((p) => p.id !== prov.id)
          : [...providersPreSelection, prov]
      );
    },
    [providersPreSelection]
  );

  const {
    bulkCreateProviderContacts,
  } = useProviderContact();
  const handleSubmitPreSelection = () => {
    const providerIds = providersPreSelection.map((p) => p.id);
    const payload = {
      provider_ids: providerIds,
      status: 3,
      appointment_id: appointment.id,
    };
    bulkCreateProviderContacts(payload);
    setOpenModal(!isOpenModal);
  };

  const handleNavigateToSpecialist = (specialistId) => {
    history.push(`/dashboard/specialist-details?provider_id=${specialistId}`);
  };

  // render upon picking specialist or if a specialist is assigned
  const StyledSpecialist = ({ providerContact, appointment }) => {
    const { updateProviderContact } = useProviderContact();

    const { provider } = useProvider({
      providerId: providerContact?.relationships?.provider?.data?.id,
    });
    const isSelected =
      appointment?.relationships?.provider?.data?.id === provider?.id;

    const { providerService } = useProviderService({
      providerServiceId: providerContact?.attributes?.providerServiceId,
    });
    const serviceType = providerService?.attributes?.serviceType || 'standard';
    const rating = providerService?.attributes?.averageRating;
    const serviceCount = providerService?.attributes?.providerServiceCount || 0;

    // const dateCreated = new Date(providerContact?.attributes?.createdAt);

    const { service } = useService({ serviceId: appointment?.relationships?.service?.data?.id });

    const serviceSlug = service?.attributes?.slug;
    const isPaidVisit = SLUGS_WITH_VISITS.includes(serviceSlug);

    const [available, setAvailable] = useState(
      providerContact?.attributes?.available
    );
    const [estimatedHours, setEstimatedHours] = useState(() => {
      if (SLUGS_WITH_VISITS.includes(serviceSlug)) {
        return providerContact?.attributes?.estimatedHours
          ? parseFloat(providerContact?.attributes?.estimatedHours)
          : 0;
      } else {
        return appointment?.attributes?.estimatedHours
          ? parseFloat(appointment?.attributes?.estimatedHours)
          : 0;
      }
    });
    const [visitRequired, setVisitRequired] = useState(
      providerContact?.attributes?.visitRequired
    );
    const [pendingUpdate, setPendingUpdate] = useState(false);

    useEffect(() => {
      let estimateToUse;
      if (SLUGS_WITH_VISITS.includes(serviceSlug)) {
        estimateToUse = providerContact?.attributes?.estimatedHours
          ? parseFloat(providerContact?.attributes?.estimatedHours)
          : 0;
      } else {
        estimateToUse = appointment?.attributes?.estimatedHours
          ? parseFloat(appointment?.attributes?.estimatedHours)
          : 0;
      }

      if (
        available === providerContact?.attributes?.available &&
        estimatedHours === estimateToUse &&
        visitRequired === providerContact?.attributes?.visitRequired
      ) {
        setPendingUpdate(false);
      } else {
        setPendingUpdate(true);
      }
    }, [
      available,
      estimatedHours,
      visitRequired,
      setPendingUpdate,
      providerContact,
      serviceSlug,
      appointment.attributes.estimatedHours,
    ]);



    const handleEstimatedHoursChange = (v) => {
      const price = isNaN(parseFloat(v)) ? 0 : parseFloat(v);
      setEstimatedHours(price);
    };

    const handleProviderContactUpdate = () => {
      const payload = {
        available: available,
        estimated_hours: estimatedHours,
        visit_required: visitRequired,
        is_paid_visit: isPaidVisit,
      };
      updateProviderContact(providerContact.id, payload, {
        success: () => {
          toast.success(t('providerContactUpdateSuccess'));
        },
        error: (e) => {
          toast.error(t('providerContactUpdateError'));
        },
      });
    };

    const removedSelectedProvider = () => {
      removeAppointmentProvider(dispatch, appointmentId);
    };

    return (
      <>
        {provider && (
          <SpecialistsContainer>
            <Section hasBorder={true}>
              <Row padding={0}>
                <Col size={1} padding={0}>
                  <Avatar
                    size='medium'
                    user={provider?.attributes}
                    hasText={true}
                    action={() => handleNavigateToSpecialist(provider?.id)}
                  />
                </Col>
                {isSelected &&
                  appointment?.attributes?.status !==
                  'awaiting_service_payment' &&
                  appointment?.attributes?.status !==
                  'awaiting_visit_payment' && (
                    <Col size={2} padding={0} justify='center'>
                      <StyledIconContainer
                        onClick={() => removedSelectedProvider()}
                      >
                        {<Icon name='Close' size={14} />}
                      </StyledIconContainer>
                    </Col>
                  )}
              </Row>
              {!isSelected &&
                !!providerContact?.attributes?.available &&
                providerContacts.length &&
                !pendingUpdate ? (
                <>
                  <Button
                    btnType='borded'
                    text='Selecionar'
                    action={() =>
                      handleSelectionSubmit(provider?.id, {
                        visitRequired,
                        estimatedHours,
                        isPaidVisit,
                      })
                    }
                    isFullWidth
                  />
                </>
              ) : (
                <Col />
              )}
              {pendingUpdate && (
                <>
                  <Button
                    btnType='primary'
                    text='Atualizar'
                    action={() => handleProviderContactUpdate()}
                    isFullWidth
                  />
                </>
              )}
              {/* <div>
                {<Contacted>
                    {t("contacted")} {formattedDate}
                  </Contacted>}
              </div> */}
            </Section>
            <>
              <Row>
                <Col size={5}>
                  {/* <SmallBody bold={true}>{t('details')}</SmallBody> */}
                  <RatingContainer>
                    <Badge
                      translate={b}
                      status={serviceType}
                      text={serviceType}
                    />
                    {rating && (
                      <>
                        <StarsRating
                          defaultRating={
                            providerService?.attributes?.averageRating
                          }
                          starSize={13}
                        />
                        <SmallBodyDetail grey={true}>
                          {providerService?.attributes?.averageRating}
                        </SmallBodyDetail>
                      </>
                    )}
                  </RatingContainer>
                  <IconDetails>
                    <IconContainer marginBottom={true}>
                      <Icon name='mail' />
                    </IconContainer>
                    <BodyDetail>{provider?.attributes?.email}</BodyDetail>
                  </IconDetails>
                  <IconDetails>
                    <IconContainer>
                      <Icon name='phone' />
                    </IconContainer>
                    <BodyDetail>{provider?.attributes?.phoneNumber}</BodyDetail>
                  </IconDetails>
                  <StatsContainer>
                    {/* {hours >= 0 &&
                      <Col size={6} padding={0}>
                        <Stat>
                          <Icon name="Clock" /> {hours} {t("hours")}
                        </Stat>
                      </Col>
                    } */}

                    {serviceCount >= 0 && (
                      <Col size={6} padding={0}>
                        <Stat>
                          <Icon name='tool-1' /> {serviceCount} {t('services')}
                        </Stat>
                      </Col>
                    )}
                  </StatsContainer>
                </Col>

                <Col size={3}>
                <RightRow>
                    <BodyDetail isDisabled={isSelected}>
                      {t('unavailable')}
                    </BodyDetail>
                    <Checkbox
                      action={() => {
                        const newValue = (available === null) ? false : !available;
                        setAvailable(newValue);
                      }}
                      checked={(available != null) ? !available : false}
                      disabled={isSelected}
                    />
                  </RightRow>
                  <RightRow>
                    <BodyDetail isDisabled={isSelected}>
                      {t('available')}
                    </BodyDetail>
                    <Checkbox
                      action={() => {
                        setAvailable(!available);
                      }}
                      checked={available}
                      disabled={isSelected}
                    />
                  </RightRow>
                  <PriceEstimate>
                    {SLUGS_WITH_VISITS.includes(serviceSlug) && (
                      <>
                        <BodyDetail isDisabled={isSelected}>
                          {t('price_estimate')}
                        </BodyDetail>
                        <TextInput
                          type='number'
                          onChange={(v) => handleEstimatedHoursChange(v)}
                          value={estimatedHours}
                          disabled={isSelected}
                        />
                      </>
                    )}
                  </PriceEstimate>
                  <RightRow>
                    {SLUGS_WITH_VISITS.includes(serviceSlug) && (
                      <>
                        <BodyDetail isDisabled={isSelected}>
                          {t('visit_required')}
                        </BodyDetail>
                        <Checkbox
                          action={() => {
                            setVisitRequired(!visitRequired);
                          }}
                          checked={visitRequired}
                          disabled={isSelected}
                        />
                      </>
                    )}

                    {SLUGS_WITH_INTERVIEW.includes(serviceSlug) && (
                      <>
                        <BodyDetail isDisabled={isSelected}>
                          {t('interviewRequired')}
                        </BodyDetail>
                        <Checkbox
                          action={() => {
                            setVisitRequired(!visitRequired);
                          }}
                          checked={visitRequired}
                          disabled={isSelected}
                        />
                      </>
                    )}
                  </RightRow>
                </Col>
              </Row>
            </>
          </SpecialistsContainer>
        )}
        {isSelected && (
          <VisitRender
            provider={provider}
            appointment={appointment}
            isRequired={providerContact?.attributes?.visitRequired}
            togglePaymentModal={togglePaymentModal}
          />
        )}
      </>
    );
  };

  const providerPreSelectionRender = useCallback(() => {
    return (
      providersPreSelection &&
      providersPreSelection.length > 0 &&
      providersPreSelection.map((prov, index) => {
        return (
          <ProviderPreSelectContainer key={`provider-${prov?.id}`}>
            <Avatar
              size='medium'
              hasText={true}
              hasEmail={true}
              user={prov?.attributes}
            />
            <span role='presentation' onClick={() => handlePreSelection(prov)}>
              <Icon name='Close' />
            </span>
          </ProviderPreSelectContainer>
        );
      })
    );
  }, [handlePreSelection, providersPreSelection]);

  const columns = ['specialists', 'state'];
  const gpsFilterData = {
    latitude: `${appointment?.attributes?.latitude}`,
    longitude: `${appointment?.attributes?.longitude}`,
  };

  const providerContact = providerContacts.find(
    (e) => e?.relationships?.provider?.data?.id === provider?.id
  )

  return (
    <>
      {appointmentLoading ? (
        <Loader />
      ) : (
        <SpecialistRenderContainer>
          <Modal
            style={ModalCustomStyles}
            ariaHideApp={false}
            isOpen={isOpenModal}
          >
            <ModalContentContainer>
              <StyledModalLeftSection>
                <SpecialistTable
                  hasSearch
                  hasFilter={true}
                  availabilityFilterData={{
                    deliveredOn: appointment?.attributes?.deliveredOn,
                  }}
                  gpsFilterData={gpsFilterData}
                  isSorted
                  hasCheckBox
                  title={t('selectSpecialist')}
                  filterRows={['status']}
                  columns={columns}
                  connectedDisplay={providersPreSelection}
                  action={(vals) => handlePreSelection(vals)}
                  items={providers}
                  itemsLoading={loading}
                />
                <Pagination
                  totalPages={totalPages}
                  translate={t}
                  currentPage={page}
                  action={handlePaginationNavigation}
                />
              </StyledModalLeftSection>
              <StyledModalRightSection>
                <RightContent>
                  <CloseBtnContainer>
                    <Button
                      icon='Close'
                      btnType='transparent'
                      action={() => {
                        setOpenModal(!isOpenModal);
                      }}
                    ></Button>
                  </CloseBtnContainer>
                  <Body>{`Selecção (${providersPreSelection.length})`}</Body>
                  {providerPreSelectionRender()}
                  {providersPreSelection.length ? (
                    <Button
                      btnType='primary'
                      text={'Selecionar'}
                      action={handleSubmitPreSelection}
                    />
                  ) : null}
                </RightContent>
              </StyledModalRightSection>
            </ModalContentContainer>
          </Modal>
          <StyledInfo onClick={() => setOpenModal(!isOpenModal)}>
            <Icon name='Plus' />
            {t('selectSpecialist')}
          </StyledInfo>
          {provider && (
            <>
              <StyledSpecialist
                key={`styled-specialist-${provider?.id}`}
                providerContact={providerContact}
                appointment={appointment}
              />
            </>
          )}
          {!provider && <Body alt='true'>{t('noSpecialist')}</Body>}
          {!provider &&
            providerContacts &&
            providerContacts.map((p, i) => {
              return (
                <React.Fragment key={`styled-specialist-${i}`}>
                  <StyledSpecialist
                    providerContact={p}
                    appointment={appointment}
                  />
                </React.Fragment>
              );
            })}
        </SpecialistRenderContainer>
      )}
    </>
  );
};

SpecialistRender.propTypes = {
  t: PropTypes.func,
  appointment: PropTypes.object,
  providerId: PropTypes.string,
  isOpenModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  togglePaymentModal: PropTypes.func,
};

export default SpecialistRender;
