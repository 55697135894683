/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../../features/polyglot';

import useAdmin from 'Hooks/admins/useAdmin';
import useTeamsListing from 'Hooks/teams/useTeamsListing';
import { Button, Form } from 'ui-55';
import { StyledFormContainer } from './styles';

import { ACTION_TYPES as ADMIN_ACTON_TYPES } from 'redux/admin/actions';

import { Row } from 'ui-55';

import { ButtonContainer } from 'Pages/Team/styles';

const NewMemberRender = ({ handleToggleModal }) => {
  const t = useTranslate('team');
  const dispatch = useDispatch();

  const { inviteAdmin, inviteAdminSuccess } = useAdmin();
  const { teams } = useTeamsListing();
  const teamOptions = teams.map(t => {
    return {
      label: t.attributes?.name,
      value: t.id
    };
  });

  const NEW_MEMBER_FORM_SCHEMA = [
    {
      key: 'first_name',
      label: t('name'),
      required: true
    },
    {
      key: 'last_name',
      label: t('lastName'),
      required: true
    },
    {
      key: 'email',
      label: t('email'),
      type: 'text',
      widget: 'email',
      required: true
    },
    {
      key: 'team_ids',
      label: t('team'),
      required: true,
      isMulti: true,
      type: 'dropdown',
      options: teamOptions
    }
    // {
    //   key: 'password',
    //   label: t('pw'),
    //   type: 'text',
    //   widget: 'password',
    //   required: true
    // },
    // {
    //   key: 'password_confirm',
    //   label: t('confirmpw'),
    //   type: 'text',
    //   widget: 'password',
    //   required: true
    // }
  ];

  const [values, setValues] = useState({});
  const handleCreateAdmin = () => {
    inviteAdmin(values);
  };

  useEffect(() => {
    if (inviteAdminSuccess) {
      handleToggleModal();
      dispatch({
        type: ADMIN_ACTON_TYPES.CLEAR_ADMIN_INVITE
      });
    }
  }, [inviteAdminSuccess, handleToggleModal, dispatch]);

  return (
    <>
      <Row>
        <StyledFormContainer>
          <Form
            questions={NEW_MEMBER_FORM_SCHEMA}
            onChange={(key, value) => setValues({ ...values, [key]: value })}
          />{' '}
        </StyledFormContainer>
      </Row>
      <ButtonContainer>
        <Button
          btnType='borded'
          text={t('cancel')}
          isFullWidth
          action={() => handleToggleModal()}
        />
        <Button
          btnType='primary'
          text={t('addMember')}
          isFullWidth
          action={handleCreateAdmin}
        />
      </ButtonContainer>
    </>
  );
};

export default NewMemberRender;
