import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';

import useServicesListing from 'Hooks/services/useServicesListing';

import { Col } from 'Components/Layout';
import TableRow from './TableRow';

import StyledArchiveTable, { Header } from './style';

const ArchiveTable = ({ items }) => {
  const t = useTranslate('archive');

  const { services } = useServicesListing({ shouldFetch: true });

  return (
    <StyledArchiveTable>
      <Header>
        <Col size={2}>{t('serviceType')}</Col>

        <Col size={2}>{t('client')}</Col>

        <Col size={2}>{t('provider')}</Col>

        <Col size={2}>{t('date')}</Col>

        <Col size={2}>{t('totalHours')}</Col>

        <Col size={2}>{t('totalPrice')}</Col>
      </Header>

      {items &&
        items.map((item, index) => {
          const serviceId = item?.relationships?.service?.data?.id;
          const service = services?.[serviceId]?.attributes;

          return (
            <TableRow key={'archive-row' + index} item={{ ...item, service }} />
          );
        })}
    </StyledArchiveTable>
  );
};

ArchiveTable.propTypes = {
  items: PropTypes.array
};

export default ArchiveTable;
