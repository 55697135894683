import React from 'react';

import { ReactComponent as BoldSvg } from './icons/bold.svg';
import { ReactComponent as ItalicSvg } from './icons/italic.svg';
import { ReactComponent as EditSvg } from './icons/edit.svg';
import { ReactComponent as JustifySvg } from './icons/justify.svg';
import { ReactComponent as CenterSvg } from './icons/center.svg';
import { ReactComponent as AlignRightSvg } from './icons/align-right.svg';
import { ReactComponent as AlignLeftSvg } from './icons/align-left.svg';
import { ReactComponent as DropSvg } from './icons/drop.svg';
import { ReactComponent as LinkSvg } from './icons/link.svg';
import { ReactComponent as OListSvg } from './icons/ol.svg';
import { ReactComponent as UlistSvg } from './icons/ul.svg';
import { ReactComponent as UnderlineSvg } from './icons/underline.svg';
import { ReactComponent as HeadingSvg } from './icons/heading.svg';

import { Row, Col } from 'ui-55';
import { StyledDropdown } from './style';

import EditorButton from './EditorButton';

const ICON_MAP = {
  bold: <BoldSvg />,
  italic: <ItalicSvg />,
  edit: <EditSvg />,
  justify: <JustifySvg />,
  alignRight: <AlignRightSvg />,
  alignLeft: <AlignLeftSvg />,
  alignCenter: <CenterSvg />,
  link: <LinkSvg />,
  heading: <HeadingSvg />,
  ol: <OListSvg />,
  ul: <UlistSvg />,
  underline: <UnderlineSvg />,
  drop: <DropSvg />
};

const EditDropdown = ({ name, items }) => {
  return (
    <StyledDropdown>
      <Row align='center' noWrap={true}>
        {ICON_MAP[name]}
        <DropSvg />
      </Row>
      <Col className='dropdown-content' padding={0}>
        {items.map(itm => (
          <EditorButton
            as='div'
            description={itm.description}
            cmd={itm.cmd}
            name={itm.name}
            key={`editor-${itm.name}`}
          />
        ))}
      </Col>
    </StyledDropdown>
  );
};

export default EditDropdown;
