import styled from 'styled-components';
import { SmallBody, Heading } from 'ui-55';

const StyledProviderServiceCard = styled.div`
  border-top: 1px solid ${props => props.theme.colors.mediumBeige};
  margin: 0px;
  width: calc(50% - 48px);
  display: flex;
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px 0px 8px;
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${props => props.grey && props.theme.colors.grey};
  margin: 0px;
`;

export const RatingContainer = styled.div`
  margin-bottom: 8px;
  align-self: flex-start;
  > div > div {
    padding: 0;
  }
`;

export const BadgeContainer = styled.div`
  width: fit-content;
  margin-bottom: 8px;
`;

export default StyledProviderServiceCard;
