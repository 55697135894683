import { createSelector } from 'reselect';

import { getProvidersEntities } from 'redux/entities/selectors';

const selectProvidersState = (state) => state.providers;

// index
const selectProviders = createSelector(selectProvidersState, state => {
  return state?.providers || null; 
});
const selectQueries = createSelector(selectProvidersState, state => {
  return state?.queries || null; 
});
const selectQueryData = createSelector(
  selectProvidersState, 
  (state, query) => {
  return state?.queries[query] || null;
})

// SHOW
export const makeGetProvider = () =>
  createSelector(
    getProvidersEntities,
    (_, providerId) => providerId,
    (entities, id) => entities[id]
  );

const getProviderLoading = createSelector(selectProvidersState, (state) => {
  return state.providerLoading || null;
});

const getProviderError = createSelector(selectProvidersState, (state) => {
  return state.providerError || null;
});

const getExtraProps = createSelector(selectProvidersState, (state) => {
  return state.extraProps || null;
});

// UPDATE
const getUpdateProviderLoading = createSelector(
  selectProvidersState,
  (state) => {
    return state.updateProviderLoading || false;
  }
);

const getUpdateProviderSuccess = createSelector(
  selectProvidersState,
  (state) => {
    return state.updateProviderSuccess || false;
  }
);

const getUpdateProviderFail = createSelector(selectProvidersState, (state) => {
  return state.updateProviderFail || false;
});

const getProvidersForms = createSelector(selectProvidersState, (state) => {
  return state.providerForms || null;
});

export {
  getProviderLoading,
  getProviderError,
  getExtraProps,
  getUpdateProviderLoading,
  getUpdateProviderSuccess,
  getUpdateProviderFail,
  getProvidersForms,
  //refact
  selectProviders,
  selectQueries,
  selectQueryData,
};
