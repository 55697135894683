import { ACTION_TYPES } from './actions';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isSubmitting: false,
  wasSuccessful: false,
  candidateLeads: [],
  updateCandidateLeadLoading: false,
  updateCandidateLeadSuccess: false,
  updateCandidateLeadFail: false,
  loadedQueries: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CANDIDATE_LEADS:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.GET_CANDIDATE_LEADS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true,
        loadedQueries: {
          ...state.loadedQueries,
          [`${JSON.stringify(action.filters)}`]: {
              loaded: true,
              error: action.error,
            }
        }        
      };
    case ACTION_TYPES.GET_CANDIDATE_LEADS_SUCCESS:
      return {
        ...state,
        [action.meta.filter 
          && action.meta.filter]: action.payload.data.map((d) => d.id),
        loading: false,
        loaded: true,
        loadedQueries: {
          ...state.loadedQueries,
          [action.meta.filter && action.meta.filter]: {
              loaded: true,
              error: false,
            }
        }        
      };

      // UPDATE LEAD
    case ACTION_TYPES.UPDATE_LEAD:
      return {
        ...state,
        isSubmitting: true,
      };
    case ACTION_TYPES.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isSubmitting: false,
        wasSuccessful: true,
      };
    case ACTION_TYPES.UPDATE_LEAD_FAIL:
      return {
        ...state,
        error: action.error,
        wasSuccessful: false,
      };

      // UPDATE CANDIDATE LEAD
    case ACTION_TYPES.UPDATE_CANDIDATE_LEAD:
      return {
        ...state,
        updateCandidateLeadLoading: true,
      };
    case ACTION_TYPES.UPDATE_CANDIDATE_LEAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updateCandidateLeadLoading: false,
        updateCandidateLeadSuccess: true,
        updateCandidateLeadFail: false
      };
    case ACTION_TYPES.UPDATE_CANDIDATE_LEAD_FAIL:
      return {
        ...state,
        error: action.error,
        updateCandidateLeadLoading: false,
        updateCandidateLeadSuccess: false,
        updateCandidateLeadFail: true      };      
    default:
      return state;
  }
};
