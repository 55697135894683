import { createSelector } from 'reselect';

import { getProviderContactsEntities } from 'redux/entities/selectors';
import { buildFilterQuery } from 'Utils/filters';

const selectProviderContactsState = (state) => state.providerContacts;

// SHOW
export const makeGetProviderContact = () =>
  createSelector(
    getProviderContactsEntities,
    (_, providerContactId) => providerContactId,
    (entities, id) => entities[id]
  );

const getProviderContactLoading = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.providerContactLoading || null;
  }
);

const getProviderContactError = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.providerContactError || null;
  }
);

// INDEX
const getLoadedQueries = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.loadedQueries || false;
  }
);

const selectFilteredItems = (state, filters) => {
  const providerContacts = selectProviderContactsState(state);
  return providerContacts[buildFilterQuery(filters, 'providerContacts')] || [];
};

const getProviderContacts = createSelector(
  (state, { filter, pageNumber }) =>
    selectFilteredItems(state, {
      filter,
      pageNumber,
    }) || [],
  (state) => getProviderContactsEntities(state) || {},
  (ids, providerContacts) => ids.map((id) => providerContacts[id])
);

const getLoading = createSelector(selectProviderContactsState, (state) => {
  return state.loading || state.providerLoading || false;
});
const getLoaded = createSelector(selectProviderContactsState, (state) => {
  return state.loaded || state.providerLoaded || false;
});
const getError = createSelector(selectProviderContactsState, (state) => {
  return state.error || null;
});

const getProviderContactsTotalPages = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.providerContactsTotalPages || null;
  }
);

const getTotalPages = createSelector(selectProviderContactsState, (state) => {
  return state.totalPages || null;
});

// bulk CREATE PROVIDER CONTACTS
const getBulkCreateProviderContactsLoading = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.isSubmitting || false;
  }
);

const getBulkCreateProviderContactsSuccess = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.wasSuccessful || false;
  }
);

// UPDATE
const getUpdateProviderContactLoading = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.updateProviderContactLoading || false;
  }
);

const getUpdateProviderContactSuccess = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.updateProviderContactSuccess || false;
  }
);

const getUpdateProviderContactFail = createSelector(
  selectProviderContactsState,
  (state) => {
    return state.updateProviderContactFail || false;
  }
);

export {
  // show
  getProviderContactLoading,
  getProviderContactError,
  // index
  getLoadedQueries,
  getProviderContacts,
  getLoading,
  getLoaded,
  getError,
  getProviderContactsTotalPages,
  getTotalPages,
  // bulk create provider contacts
  getBulkCreateProviderContactsLoading,
  getBulkCreateProviderContactsSuccess,
  // update
  getUpdateProviderContactLoading,
  getUpdateProviderContactSuccess,
  getUpdateProviderContactFail,
};
