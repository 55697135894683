import styled from 'styled-components';
import { Body, SmallBody } from 'ui-55';

const StyledInteractionCard = styled.div`
  background-color: ${props => props.theme.colors.lightestBeige};  
  padding: 16px;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
`;

export const SmallBodyDetail = styled(SmallBody)`
  margin-left: 11px;
  color: ${props => props.theme.colors.grey};
`;

export const BodyDetail = styled(Body)`
color: ${props => props.theme.colors.darkBlue};
`;

export default StyledInteractionCard;