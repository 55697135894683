import { createSelector } from 'reselect';

import { getActivitiesEntities } from 'redux/entities/selectors';
import { buildFilterQuery } from 'Utils/filters';

const selectActivitiesState = state => state.activities;

const getLoadedQueries = createSelector(selectActivitiesState, state => {
  return state.loadedQueries || false;
})

const getTotalCount = createSelector(selectActivitiesState, state => {
  return state.totalCount || false;
})


const selectFilteredItems = (state, filters) => {
  const activities = selectActivitiesState(state);

  return activities[buildFilterQuery(filters, 'activities')];
};

// INDEX
const getActivities = createSelector(
  (state, { filter, pageNumber }) =>
    selectFilteredItems(state, {
      filter,
      pageNumber
    }) || [],
  state => getActivitiesEntities(state) || {},
  (ids, activities) => ids.map(id => activities[id])
);

const getLoading = createSelector(selectActivitiesState, state => {
  return state.loading || false;
});

const getLoaded = createSelector(selectActivitiesState, state => {
  return state.loaded || false;
});

const getError = createSelector(selectActivitiesState, state => {
  return state.error || null;
});

// SHOW
export const makeGetActivity = () =>
  createSelector(
    getActivitiesEntities,
    (_, activityId) => activityId,
    (entities, id) => entities[id]
  );

const getActivityLoading = createSelector(selectActivitiesState, state => {
  return state.getActivityLoading || null;
});

const getActivityError = createSelector(selectActivitiesState, state => {
  return state.getActivityError || null;
});

// CREATE Activity
const getCreateActivityLoading = createSelector(selectActivitiesState, state => {
  return state.isSubmitting || false;
});

const getCreateActivitySuccess = createSelector(selectActivitiesState, state => {
  return state.wasSuccessful || false;
});



export {
  getLoadedQueries,
  getTotalCount,
  // index
  getActivities,
  getLoading,
  getLoaded,
  getError,
  //show
  getActivityLoading,
  getActivityError,
  // activity
  getCreateActivityLoading,
  getCreateActivitySuccess,
};
