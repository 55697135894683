import styled from 'styled-components';
import { media  } from 'Components/Layout'
import { Link, Tiny } from 'Components/Text';

const StyledSidebar = styled.div`
  background-color: ${(props) => props.theme.colors.brand.orange};
  color: white;
  width: 240px;
  height: 100vh;
  position: fixed;
  svg {
    margin-top: ${(props) => props.theme.margin * 2}px;
  }
  ${(props) =>
    media.tablet(`
      position: absolute;
      left: ${props.isOpen ? '0px' : '-240px'};
      transition: all 0.25s;
    `)}
`;

export const NavHeader = styled(Tiny)`
  text-transform: uppercase;
  margin: 0px 32px 4px;
  color: ${props => props.theme.colors.white};
`;

export const NavSection = styled.div`
  margin-top: 40px;
  a {
    color: white;
    font-size: 16px;
    line-height: 24px;
    &:visited {
      color: white;
    }
  }
`;

const getActiveLinkStyle = (theme, active) => {
  if (!active) {
    return `
      background-color: none;
      border-left: solid 2px transparent;
      `;
  } else {
    return `
      background-color: ${theme.colors.brand.orangeLight};
      border-left: solid 2px white;
      `;
  }
};

export const LogoLink = styled(Link)`
  padding: 0px 30px;
  display: flex;
  align-items: center;
  background-color: none;
  border-left: solid 2px transparent;
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.orangeLight};
  }
`;
export const NavLink = styled(Link)`
  padding: 0px 30px;
  height: 40px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.orangeLight};
  }
  ${props => getActiveLinkStyle(props.theme, props.active)}
`;

export const LogoContainer = styled.div`
  margin: 30px 0px 80px;
`;

export default StyledSidebar;
