import { createSelector } from 'reselect';

import { getInteractionsEntities } from 'redux/entities/selectors';

const selectInteractionsState = state => state?.interactions;

// SHOW
const makeGetInteraction = () =>
  createSelector(
    getInteractionsEntities,
    (_, interactionId) => interactionId,
    (entities, id) => entities[id]
  );

const getInteractionLoading = createSelector(selectInteractionsState, state => {
  return state.interactionLoading;
});

const getInteractionError = createSelector(selectInteractionsState, state => {
  return state.interactionError;
});

// CREATE
const getCreateInteractionLoading = createSelector(selectInteractionsState, state => {
  return state.isSubmitting || false;
});

const getCreateInteractionSuccess = createSelector(selectInteractionsState, state => {
  return state.wasSuccessful || false;
});

export {
  makeGetInteraction,
  getInteractionError,
  getInteractionLoading,
  getCreateInteractionLoading,
  getCreateInteractionSuccess
};
