import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/notifications';

const useNotifications = (pageNumber = 1, filters = 'notifications') => {
  const dispatch = useDispatch();
  const { getNotificationsData } = actions;
  const { getNotifications, getLoading, getError, getLoaded } = selectors;

  const error = useSelector((state) => getError(state));
  const loading = useSelector((state) => getLoading(state), shallowEqual);
  const loaded = useSelector((state) => getLoaded(state), shallowEqual);

  const notifications = useSelector((state) =>
    getNotifications(state, { filter: filters, pageNumber })
  );

  const dispatchGetNotificationsData = useCallback(
    (pageNumber, filters) => {
      getNotificationsData(dispatch, pageNumber, filters);
    },
    [dispatch, getNotificationsData]
  );

  useEffect(() => {
    if ((!loaded && !loading) || (filters && !notifications.length)) {
      dispatchGetNotificationsData(pageNumber, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return {
    notifications,
    loaded,
    error,
    loading,
  };
};

export default useNotifications;
