import React from 'react';
import { useTranslate } from '../../features/polyglot';
import { useHistory, useLocation } from 'react-router-dom';
import Filters from 'Components/Filters';
import useProviders from 'Hooks/providers/useProviders';
import useFilters from 'hooks/useFilters';
import SpecialistTable from 'Components/SpecialistTable';

import { Loader, Pagination, BackofficeContainer, Heading } from 'ui-55';

const AVAILABLE_FILTERS = ['status', 'services', 'cities', 'newsletter', 'appointment_tag'];
const MULTI_FILTERS = ['service_ids', 'city_ids', 'tag'];

const Specialists = () => {
  const t = useTranslate('specialists');
  const history = useHistory();
  const location = useLocation();

  const { active, queryString, page, getQueryStringFromFilters } = useFilters(
    location
  );
  const { providers, loading, totalPages } = useProviders({
    query: queryString,
    shouldFetch: true,
  });

  const handleSpecialistDetails = (provider) => {
    history.push(
      `/dashboard/specialist-details?provider_id=${provider.id}&page=1`
    );
  };

  const handleNavigation = (pageNumber) => {
    history.push(
      `${getQueryStringFromFilters(active)}&page=${parseInt(pageNumber)}`
    );
  };

  return (
    <>
      <BackofficeContainer>
        <Filters
          availableFilters={AVAILABLE_FILTERS}
          hasCSVLink
          showLayout={false}
          hasAddButton={false}
          multiFilters={MULTI_FILTERS} />
        {!loading && !providers.length ? (
          <Heading size={5}>No providers</Heading>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                <SpecialistTable
                  items={providers}
                  action={handleSpecialistDetails}
                  availableFilters={AVAILABLE_FILTERS}
                  hasCSVLink
                  multiFilters={MULTI_FILTERS}
                  hasFilter={false}
                />
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  action={handleNavigation}
                  translate={t}
                />
              </>
            )}
          </>
        )}
      </BackofficeContainer>
    </>
  );
};

export default Specialists;
