import visitsClient from 'Services/visitsService';


const REDUCER = 'visits';
export const ACTION_TYPES = {
  GET_VISIT: `${REDUCER}/GET_VISIT`,
  GET_VISIT_SUCCESS: `${REDUCER}/GET_VISIT_SUCCESS`,
  GET_VISIT_FAIL: `${REDUCER}/GET_VISIT_FAIL`,
  CREATE_VISIT: `${REDUCER}/CREATE_VISIT`,
  CREATE_VISIT_SUCCESS: `${REDUCER}/CREATE_VISIT_SUCCESS`,
  CREATE_VISIT_FAIL: `${REDUCER}/CREATE_VISIT_FAIL`,
  UPDATE_VISIT: `${REDUCER}/UPDATE_VISIT`,
  UPDATE_VISIT_SUCCESS: `${REDUCER}/UPDATE_VISIT_SUCCESS`,
  UPDATE_VISIT_FAIL: `${REDUCER}/UPDATE_VISIT_FAIL`
};

// SHOW
const getVisit = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_VISIT
  });
  visitsClient
    .getVisit(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_VISIT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_VISIT_FAIL,
        error: 'Error getting VISIT '
      });
    });
};

// CREATE
const createVisit = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.CREATE_VISIT
  });
  visitsClient
    .createVisit(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_VISIT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_VISIT_FAIL,
        error: 'Error getting visit'
      });
    });
};

// UPDATE
const updateVisit = (dispatch, id, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_VISIT
  });
  visitsClient
    .updateVisit(id, payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_VISIT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_VISIT_FAIL,
        error: e
      });
    });
};

export { getVisit, createVisit, updateVisit };
