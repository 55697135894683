import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';
import {toast } from 'react-toastify';

import ModalContent from 'Components/ModalContent';

import { Loader, Icon, Button } from 'ui-55';

import { Heading } from 'Components/Text';
import { StyledModal, CloseContainer, ButtonSection, Inner } from './style';

const Modal = ({
  title,
  isOpen,
  isLoading,
  isUpdateSuccess,
  data,
  modalType,
  onClose,
  onSuccess,
  primaryBtnText,
  secondaryBtnText,
  children
}) => {
  const t = useTranslate('modals');

  const [updateData, setUpdateData] = useState(null);

  const toggleModal = () => {
    onClose();
  };

  const handleSuccess = e => {
    if (data && data.item) {
        const item = data?.item;
        const hasProvider = Boolean(item?.relationships?.provider?.data);
        const isWaitingProvider = Boolean(
          item?.attributes?.status === 'awaiting_specialist'
        );
        if (isWaitingProvider && !hasProvider) {
          return toast.error('Especialista deve estar selecionado')
        }
    }
    onSuccess(e, updateData);
  };

  return (
    <StyledModal
      ariaHideApp={false}
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      modalType={modalType}
    >
      <CloseContainer onClick={toggleModal}>
        <Icon name='Close' />
      </CloseContainer>

      <Inner>
        {isLoading || isUpdateSuccess ? (
          <Loader />
        ) : (
          <>
            <Heading size={3}>{title}</Heading>
            {data && data.item && (
              <ModalContent
                modalType={modalType}
                data={data}
                updateData={updateData}
                setUpdateData={setUpdateData}
              />
            )}
          </>
        )}
      </Inner>
      {children}
      {!(isLoading || isUpdateSuccess) && (
        <ButtonSection>
          <Button
            btnType='secondary'
            text={t(`${secondaryBtnText}`)}
            isFullWidth={true}
            action={toggleModal}
          />
          <Button
            btnType='primary'
            text={t(`${primaryBtnText}`)}
            isFullWidth={true}
            action={handleSuccess}
          />
        </ButtonSection>
      )}
    </StyledModal>
  );
};

export default Modal;

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  modalType: PropTypes.oneOf([
    'kanban-0',
    'kanban-1',
    'kanban-2',
    'kanban-3',
    'kanban-candidate-0',
    'kanban-candidate-1'
  ]),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  primaryBtnText: PropTypes.string,
  secondaryBtnText: PropTypes.string,
  isUpdateSuccess: PropTypes.bool,
  children: PropTypes.node
};

Modal.defaultProps = {
  primaryBtnText: 'confirm',
  secondaryBtnText: 'cancel'
};
