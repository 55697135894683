import { createSelector } from 'reselect';

import { getCandidateLeadsEntities } from 'redux/entities/selectors';
import { buildFilterQuery } from 'Utils/filters';

const selectCandidateLeadsState = state => {
  return state.candidateLeads;
};

const getLoadedQueries = createSelector(selectCandidateLeadsState, state => {
  return state.loadedQueries || false;
})

const getCandidateLeads = createSelector(
  (state, { filter }) =>
    selectCandidateLeadsState(state)[
      buildFilterQuery({ filter }, 'candidateLeads')
    ] || [],
  state => getCandidateLeadsEntities(state) || {},
  (ids, candidateLeads) => ids.map(id => candidateLeads[id])
);
const getLoading = createSelector(selectCandidateLeadsState, state => {
  return state.loading || false;
});
const getLoaded = createSelector(selectCandidateLeadsState, state => {
  return state.loaded || false;
});
const getError = createSelector(selectCandidateLeadsState, state => {
  return state.error || null;
});

// UPDATE LEAD
const getSubmitting = createSelector(selectCandidateLeadsState, state => {
  return state.isSubmitting || null;
});

const getWasSuccessful = createSelector(selectCandidateLeadsState, state => {
  return state.wasSuccessful || false;
});

// UPDATE CANDIDATE LEAD
const getUpdateCandidateLeadLoading = createSelector(
  selectCandidateLeadsState,
  state => {
    return state.updateCandidateLeadLoading || false;
  }
);

const getUpdateCandidateLeadSuccess = createSelector(
  selectCandidateLeadsState,
  state => {
    return state.updateCandidateLeadSuccess || false;
  }
);

const getUpdateCandidateLeadFail = createSelector(
  selectCandidateLeadsState,
  state => {
    return state.updateCandidateLeadFail || false;
  }
);

// SHOW
export const makeGetCandidateLead = () =>
  createSelector(
    getCandidateLeadsEntities,
    (_, candidateLeadId) => candidateLeadId,
    (entities, id) => entities[id]
  );
export const getCandidateLeadLoading = createSelector(
  selectCandidateLeadsState,
  state => {
    return state.candidateLeadLoading || false;
  }
);
export const getCandidateLeadLoaded = createSelector(
  selectCandidateLeadsState,
  state => {
    return state.candidateLeadLoaded || false;
  }
);
export const getCandidateLeadError = createSelector(
  selectCandidateLeadsState,
  state => {
    return state.candidateLeadError || null;
  }
);

export {
  getLoadedQueries,
  getCandidateLeads,
  getLoading,
  getError,
  getLoaded,
  getSubmitting,
  getWasSuccessful,
  getUpdateCandidateLeadLoading,
  getUpdateCandidateLeadSuccess,
  getUpdateCandidateLeadFail
};
