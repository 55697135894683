import React from 'react';

import { useTranslate } from '../../features/polyglot';
import useSettings from 'hooks/useSettings';

import { Col } from 'Components/Layout';
import TableRow from './TableRow';

import StyledClientTable, { Header } from './style';

const ClientTable = ({ items, action }) => {
  const t = useTranslate('clients');

  const { settings } = useSettings();

  return (
    <StyledClientTable>
      <Header>
        <Col size={9}>{t('name')}</Col>

        <Col size={3}>{t('region')}</Col>
      </Header>

      {items &&
        Object.keys(items).map((item, index) => {
          const hasCity = items[item]?.relationships?.cities?.data?.length > 0;
          let clientCity = null;

          if (hasCity) {
            const cityObj = items[item]?.relationships?.cities?.data[0];
            clientCity = settings?.cities.filter(c =>
              parseInt(c.value) === parseInt(cityObj.id));
            clientCity = clientCity[0].label;
          }

          return (
            <TableRow
              key={'clientTable' + index}
              item={items[item]}
              action={action}
              clientCity={clientCity}
            />
          );
        })}
    </StyledClientTable>
  );
};

export default ClientTable;
