import client from './client';
import { applyFilters } from 'Utils/filters';

const getProviderContact = (id) => {
  return client.get(`/provider_contact/${id}`);  
}

const getProviderContacts = (pageNumber, filters) => {
  if (pageNumber) {
    return client.get(
      `${applyFilters(filters, 'providerContacts')}&page=${pageNumber}`
    );
  } else if (filters !== 'providerContacts') {
    return client.get(`${applyFilters(filters, 'provider_contacts')}`);
  }
  return client.get(applyFilters({}, 'provider_contacts'));
};

const bulkCreateProviderContacts = payload => {
  return client.post(`/provider_contacts/`, {
    provider_contact: payload
  });
};

const updateProviderContact = (id, payload) => {
  return client.patch(`/provider_contacts/${id}`, {
    provider_contact: payload
  });
};


export default {
  getProviderContact,
  getProviderContacts,
  bulkCreateProviderContacts,
  updateProviderContact,
  client
};
