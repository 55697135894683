import client from "./client";

const getVisit = (id) => {
    return client.get(`visits/${id}`)
};

const createVisit = payload => {
  return client.post(`visits`, payload);
};

const updateVisit = (id, payload) => {
  return client.patch(`/visits/${id}`, {
    visit: payload
  })
}


export default { getVisit, createVisit, updateVisit, client };

