import styled from "styled-components";
import { SmallBodyFAQ } from "Components/Text";

export const ListContainer = styled.div`
  width: 100%;
`;

export const ListTitle = styled(SmallBodyFAQ)`
  color: ${props => props.theme.colors.grey}
`
export const TopInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  h3 {
    margin: 0;
    &:nth-of-type(2) {
      font-weight: normal;
      color: ${props => props.theme.colors.brand.orange}
    }
  }
`;
