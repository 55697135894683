import { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/dashboard';

const useDashboard = (filters = {}) => {
  const dispatch = useDispatch();
  const { getDashboardData } = actions;

  const { getDashboard, getLoading, getLoaded, getError } = selectors;

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state));
  const loaded = useSelector(state => getLoaded(state));

  const dashboard = useSelector(
    state => getDashboard(state, { filters: filters }),
    shallowEqual
  );

  useEffect(() => {
    if (!loaded && !loading) {
      getDashboardData(dispatch, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDashboard, loaded, loading]);

  return {
    dashboard,
    error,
    loaded,
    loading
  };
};

export default useDashboard;
