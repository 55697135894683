import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '../../features/polyglot';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
// import ImageUploader from 'react-images-upload';
import sc from 'lodash/startCase';
import sortBy from 'lodash/sortBy';
import usePolymorph from 'Hooks/usePolymorph';
import Editor from 'Components/ContentEditor';
import {
    Row,
    Col,
    Heading,
    Body,
    Button,
    BackofficeContainer,
    Loader,
} from 'ui-55';
import { ReactComponent as EditSvg } from 'Components/ContentEditor/icons/edit.svg';
import { ReactComponent as TrashSvg } from 'Components/ContentEditor/icons/trash.svg';
import { useDispatch, useSelector } from "react-redux";
import { selectLocale, setLocale } from '../../features/polyglot/polyglotSlice';
import {
  actions as contentActions,
  selectors as contentSelectors,
} from 'redux/content';
import styled from 'styled-components';
import ImageUploader from 'react-images-upload';
import { ReactComponent as ExpandSvg } from 'Components/ContentEditor/icons/expand.svg';
import { LanguageSelect } from 'features/languages/components/LanguageSelect';


export const StyledExpandSvg = styled(ExpandSvg)`
  transition: all 0.25s;
  transform: ${({ expanded }) => expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`

export const StyledRepeaterHeader = styled(Row)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin}px;

`

export const StyledImageUploader = styled(ImageUploader)`
  max-width: 720px;
  box-shadow: none;
  margin: ${({ theme }) => theme.margin}px;
  > div {
    border: 4px dotted ${({ theme }) => theme.colors.brand.darkblue} !important;
    border-radius: 0;
    button {
      background-color: ${({ theme }) => theme.colors.brand.yellow} !important;
      color: ${({ theme }) => theme.colors.darkBlue} !important;
    }
  }
`;
export const ImgContainer = styled(Row)`
  img {
    max-width: 300px;
    height: auto;
  }
`;
export const PlainTextControls = styled(Row)`
  width: auto;
  max-width: 500px;
  margin: 0;
  button {
    margin-right: ${({ theme }) => theme.margin}px;
  }
`;
export const PlainTextEditor = styled.textarea`
  width: calc(100% - ${({ theme }) => theme.margin * 4}px);
  max-width: 720px;
  min-height: 100px;
  background-color: ${({ theme }) => theme.colors.white} !important;
  border: 1px dotted ${({ theme }) => theme.colors.brand.darkblue} !important;
  margin: ${({ theme }) => theme.margin / 2}px ${({ theme }) => theme.margin}px;
  padding: ${({ theme }) => theme.margin / 4}px ${({ theme }) => theme.margin}px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkBlue};
`;
export const ContentCol = styled(Col)`
  justify-content: 'space-between';
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
`;

export const Expandable = styled(Col)`
  && {
    align-items: flex-end;
    transition: all 0.25s;
    height: auto;
    transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
    max-height: ${({ isOpen }) => (isOpen ? '500px' : '0px')};
  }
`;

export const RepeaterColumn = styled(Col)`
   {
    width: 100%;
  }
  ul {
    width: calc(100% - ${({ theme }) => theme.margin * 4}px);
    list-style-type: none;
    li > div > div {
      width: calc(100% - ${({ theme }) => theme.margin * 2}px);
    }
  }
`;
const TOPBAR_AND_CMS_CONTROLS_HEIGHT = 150;
export const CMSContainer = styled(Col)`
  max-height: calc(100vh - ${TOPBAR_AND_CMS_CONTROLS_HEIGHT}px);
  overflow: scroll;
  padding: 0;
  flex: 5;
  > div {
    width: calc(100% - ${({ theme }) => theme.margin * 4}px);
    margin: ${({ theme }) => theme.margin}px;
    max-width: 1024px;
  }
`;

export const EditCol = styled(Row)`
  flex-flow: column wrap;
  background: ${({ theme }) => theme.colors.lightestBeige};
  > div {
    width: calc(100% - ${({ theme }) => theme.margin * 4}px);
    padding-right: ${({ theme }) => theme.margin}px;
    padding-top: ${({ theme }) => theme.margin / 2}px;
    padding-left: ${({ theme }) => theme.margin}px;
    margin-bottom: ${({ theme }) => theme.margin / 2}px;
  }
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 48px;
  width: 48px;
  margin-right: ${({ theme }) => theme.margin}px;
  &:hover {
    background: ${({ theme }) => theme.colors.lightBeige}aa;
  }
  > svg {
    height: 24px;
    width: 24px;
  }
`;

export const CMSControls = styled(Col)`
  align-items: center;
  flex: 1;
  padding-top: 0;
  filter: drop-shadow(0px 4px 13px rgba(193, 188, 183, 0.3));
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.colors.lightestBeige};
  padding-left: ${({ theme }) => theme.margin}px;
  padding-right: ${({ theme }) => theme.margin}px;
  border-radius: 0px 0px 16px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBeige};
  opacity: ${({ show }) => show ? 1 : 0};
  transition: opacity 0.25s;
  button {
    padding: ${({ theme }) => theme.margin / 2}px;
    margin-bottom: ${({ theme }) => theme.margin}px;
  }
`;


export const ContentDetail = () => {
    const locale = useSelector(selectLocale);
    const t = useTranslate('cms');
    let location = useLocation();
    const [editingKey, setEditingKey] = useState('');
    const [editedValues, setEditedValues] = useState({});
    const [editedPlainText, setEditedPlainText] = useState({});
    const [openTabs, setOpenTabs] = useState([]);
    const [addedListItems, setAddedListItems] = useState({});
    const [deletedItemIds, setDeletedItemIds] = useState([]);
    const querySlug = queryString.parse(location.search).slug;
    const { content, updateContent, loading } = usePolymorph({ id: querySlug, language: locale });
    const contentElements = content?.elements;
    const dispatch = useDispatch();



    useEffect(() => {
        contentActions.getContent(dispatch, { id: querySlug, lang: locale });
    },[locale]);


    const getElements = (els = []) => {
        const elements = [];
        els.forEach((el) => {
            const elementData = {
                name: el.name,
                type: el.elementable_type,
                id: el.id,
                value: el.elementable?.value || el.elementable?.media_url,
                order: el.order,
            };
            if (el.elementable?.elements) {
                elementData.value = getElements(el.elementable?.elements);
            }
            elements.push(elementData);
        });
        return sortBy(elements, el => el.order);
    };
    const elements = useMemo(() => getElements(content?.elements), [
        contentElements,
        getElements,
        locale
    ]);

    const handleSaveChanges = (value, e, element) => {
        if (e) {
            e.preventDefault();
        }
        setEditedValues({
            ...editedValues,
            [element.id]: value,
        });
        setEditingKey('');
    };

    const handleCancel = () => {
        setEditingKey('');
        setEditedValues(editedValues);
    };
    const handleRevertChanges = () => {
        setEditingKey('');
        setEditedValues({});
        setEditedPlainText({});
        setAddedListItems({});
        setDeletedItemIds([]);
    };

    const handleUpdateContent = () => {
        updateContent(editedValues, addedListItems, deletedItemIds, () => {
            toast.success(t('successChanges'));
            setEditingKey('');
            setEditedValues({});
            setEditedPlainText({});
            setAddedListItems({});
            setDeletedItemIds([]);
        });
    };

    const handlePicUpload = (pic, element) => {
        const reader = new FileReader();
        reader.onloadend = function () {
            setEditedValues({
                ...editedValues,
                [element.id]: reader.result,
            });
        };
        if (pic && pic[0]) reader.readAsDataURL(pic[0]);
    };

    const renderHeader = (element, deletable, ordered) => {
        const isEditing = element.id === editingKey;
        const isExpanded = openTabs.indexOf(element.id) !== -1;
        return (
            <Row justify='space-between'>
                <Heading size={5}>{ordered && `${element.order}. `}{sc(element.name)}</Heading>
                <Row justify='flex-end'>
                    {!isEditing && (
                        <IconButton
                            onClick={() => {
                                setEditingKey(element.id);
                                setEditedValues(editedValues);
                                setOpenTabs([...openTabs, element.id]);
                            }}
                        >
                            <EditSvg />
                        </IconButton>
                    )}
                    <IconButton
                        onClick={() => {
                            if (isExpanded) {
                                setOpenTabs([...openTabs.filter((ot) => ot !== element.id)]);
                            } else {
                                setOpenTabs([...openTabs, element.id]);
                            }
                        }}
                    >
                        <StyledExpandSvg expanded={isExpanded} />
                    </IconButton>
                    {deletable && (
                        <IconButton
                            onClick={() => {
                                setDeletedItemIds([...deletedItemIds, element.id]);
                            }}
                        >
                            <TrashSvg />
                        </IconButton>
                    )}
                </Row>
            </Row>
        );
    };
    const renderRepeaterHeader = (element, listTemplate) => {
        const templateChildrenIds = Array.isArray(listTemplate?.value)
            ? [listTemplate.id, ...listTemplate.value.map((v) => v.id)]
            : [listTemplate.id];
        const idFromName = element.name + '-' + element.id;
        const childrenIds = [];
        const getValueIds = (el) => [...(el?.value || []).map(v => v.id)]
        const getAllChildrenIds = (el) => {
            if (el) {
                childrenIds.push(el.id);
                if (Array.isArray(el.value)) {
                    childrenIds.push(...getValueIds());
                    el.value.forEach(getAllChildrenIds)
                }
            }
        }
        getAllChildrenIds(element)
        return (
            <StyledRepeaterHeader justify='space-between'>
                <Heading size={4}>{sc(idFromName)}</Heading>
                <Col />
                {listTemplate && (
                    <Button
                        btnType='transparent'
                        action={() => {
                            setAddedListItems({
                                ...addedListItems,
                                [idFromName]: [
                                    ...(addedListItems[idFromName] ?? []),
                                    listTemplate,
                                ],
                            });
                            setOpenTabs([...openTabs, ...templateChildrenIds]);
                        }}
                        text={`+ ${t('add')}${sc(listTemplate.name)}`}
                    />
                )}
                <Col />
                <Button
                    action={() => {
                        setOpenTabs([...openTabs, ...childrenIds]);
                    }}
                    btnType='secondary'
                    text={`${t('openAll')}`}
                />
            </StyledRepeaterHeader>
        );
    };

    const renderPTEditor = (element, elementValue, isEditing) => {
        return (
            <>
                <PlainTextEditor
                    value={editedPlainText[element.id] ?? elementValue}
                    disabled={!isEditing}
                    rows={4}
                    onChange={(e) =>
                        setEditedPlainText({
                            ...editedPlainText,
                            [element.id]: e.target.value,
                        })
                    }
                />
                {isEditing && (
                    <PlainTextControls justify='flex-end'>
                        <Button
                            text={t('save')}
                            action={() => {
                                setEditedValues({
                                    ...editedValues,
                                    [element.id]: editedPlainText[element.id],
                                });
                                setEditingKey('');
                            }}
                        />
                        <Button
                            btnType='secondary'
                            text={t('cancel')}
                            action={() => {
                                const plainText = { ...editedPlainText };
                                delete plainText[element.id];
                                setEditedPlainText(plainText);
                                setEditingKey('');
                            }}
                        />
                    </PlainTextControls>
                )}
            </>
        );
    };
    const renderElementByType = (element, deletable, ordered) => {
        const isEditing = element.id === editingKey;
        const isExpanded = openTabs.indexOf(element.id) !== -1;
        const isDeleted = deletedItemIds.indexOf(element.id) !== -1;
        if (isDeleted) {
            return '';
        }
        const elementValue = editedValues[element.id] || element.value;
        const listTemplate =
            (Array.isArray(element?.value) && { ...element?.value?.[0] }) || {};
        const elementList = addedListItems[element.name + '-' + element.id] || [];
        const getIdFromName = (name, idx) => element.id + '-' + name + '-' + idx;

        listTemplate.id = getIdFromName(listTemplate.name, elementList.length);
        listTemplate.value = Array.isArray(listTemplate?.value)
            ? listTemplate.value.map((val) => ({
                ...val,
                id: getIdFromName(val.name, listTemplate.value.length),
            }))
            : listTemplate.value;
        switch (element.type) {
            case 'GroupElement':
                return (
                    <ContentCol key={element.name + 'group'}>
                        <Row size={2}>
                            <Heading size={3}>
                                {ordered && `${element.order}. `}
                                {sc(element.name)}
                            </Heading>
                            <Col />

                            <Button
                                action={() => {
                                    setOpenTabs([
                                        ...openTabs,
                                        ...element.value.map((el) => el.id),
                                    ]);
                                }}
                                btnType='secondary'
                                text={`${t('openAll')}`}
                            />
                        </Row>
                        <Row size={1} />
                        {element.value.map((el) => renderElementByType(el))}
                    </ContentCol>
                );
            case 'RepeaterElement':
                return (
                    <RepeaterColumn key={element.name + 'repeater'}>
                        {renderRepeaterHeader(element, listTemplate)}
                        <ul>
                            {(elementList ?? []).map((el, i) => (
                                <li key={`list-${el.name}-${i}`}>
                                    {renderElementByType(el, true)}
                                </li>
                            ))}
                            {element.value.map((el, i) => (
                                <li key={`list-${el.name}-${i}`}>
                                    {renderElementByType(el, true, true)}
                                </li>
                            ))}
                        </ul>
                    </RepeaterColumn>
                );
            case 'PlainTextElement':
                return (
                    <EditCol>
                        {renderHeader(element, deletable, ordered)}
                        <Expandable isOpen={isExpanded}>
                            {renderPTEditor(element, elementValue, isEditing)}
                        </Expandable>
                    </EditCol>
                );
            case 'RichTextElement':
                return (
                    <EditCol>
                        {renderHeader(element, deletable)}
                        <Expandable isOpen={isExpanded}>
                            <Editor
                                value={elementValue}
                                disabled={!isEditing}
                                t={t}
                                onSaveChanges={(value, e) =>
                                    handleSaveChanges(value, e, element)
                                }
                                onCancel={handleCancel}
                            />
                        </Expandable>
                    </EditCol>
                );
            case 'MediaElement':
                return (
                    <ImgContainer key={element.name + 'media'} align='center' noWrap>
                        <Col size={2}>
                            <Row>
                                <Heading size={5}>{element.name}</Heading>
                            </Row>
                            <img src={elementValue} alt={element.name + 'img'} />
                        </Col>
                        <StyledImageUploader
                            buttonText={t('chooseImg')}
                            label={t('maxSize')}
                            fileSizeError={t('fileSizeError')}
                            fileTypeError={t('fileTypeError')}
                            singleImage
                            withPreview
                            onChange={(pic) => handlePicUpload(pic, element)}
                        />
                    </ImgContainer>
                );
            default:
                return '';
        }
    };
    const numberOfChanges = [
        ...new Set([
            ...Object.keys(editedValues),
            ...Object.keys(addedListItems),
            ...deletedItemIds,
        ]),
    ].length;
    return (
        <BackofficeContainer>
            <LanguageSelect />
            <Row>
                <CMSContainer>
                    {loading ? <Loader /> : elements.map((el) => renderElementByType(el))}
                </CMSContainer>
                <Col>
                    <CMSControls show={numberOfChanges && !editingKey} align='center'>
                        {(
                            <>
                                <Body>
                                    {numberOfChanges} {t('changes')}
                                </Body>
                                <Button
                                    text={t('updateContent')}
                                    btnType='primary'
                                    onMouseDown={handleUpdateContent}
                                />
                                <Button
                                    text={t('revert')}
                                    btnType='secondary'
                                    onMouseDown={handleRevertChanges}
                                />
                            </>
                        ) || ''}
                    </CMSControls>
                    <Row size={1} />
                    <Row size={1} />
                </Col>
            </Row>
        </BackofficeContainer>
    );
};