import tagsClient from "Services/tagsService";

import { buildFilterQuery } from 'Utils/filters';

const REDUCER = "tags";
export const ACTION_TYPES = {
  GET_TAG: `${REDUCER}/GET_TAG`,
  GET_TAG_SUCCESS: `${REDUCER}/GET_TAG_SUCCESS`,
  GET_TAG_FAIL: `${REDUCER}/GET_TAG_FAIL`,
  GET_TAGS: `${REDUCER}/GET_TAGS`,
  GET_TAGS_SUCCESS: `${REDUCER}/GET_TAGS_SUCCESS`,
  GET_TAGS_FAIL: `${REDUCER}/GET_TAGS_FAIL`,
  CREATE_TAG: `${REDUCER}/CREATE_TAG`,
  CREATE_TAG_SUCCESS: `${REDUCER}/CREATE_TAG_SUCCESS`,
  CREATE_TAG_FAIL: `${REDUCER}/CREATE_TAG_FAIL`,
  CLEAR_TAG_SUCCESS: `${REDUCER}/CLEAR_TAG_SUCCESS`,
};

// SHOW
const getTag = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_TAG
  });
  tagsClient
    .getTag(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_TAG_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_TAG_FAIL,
        error: "Error getting tag "
      });
    });
};

// INDEX
const getTagsListing = (dispatch, pageNumber, filter) => {
  dispatch({
    type: ACTION_TYPES.GET_TAGS
  });
  tagsClient
    .getTags(pageNumber, filter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_TAGS_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter, pageNumber }, 'tags')
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_TAGS_FAIL,
        error: 'Error getting tags '
      });
    });
};

// CREATE
const createTag = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.CREATE_TAG
  });
  tagsClient
    .createTag(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_TAG_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_TAG_FAIL,
        TagError: 'Error getting tag'
      });
    });
};

export { getTag, getTagsListing, createTag };
