import client from './client';

const getProvider = (id) => {
  return client.get(`providers/${id}`);
};

const updateProviderForm = (data, id) => {
  return client.patch(`/providers/` + id, data);
};

const updateProvider = (id, payload) => {
  return client.patch(`/providers/${id}`, { provider: payload });
};

const createProvider = (data) => {
  return client.post('/providers', { provider: data });
};

//refact
const fetchProviders = (query) => {
  return client.get(`providers/${query}`)
}

export default {
  // getProviders,
  getProvider,
  updateProviderForm,
  client,
  createProvider,
  updateProvider,
  fetchProviders,
};
