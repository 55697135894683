import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/teams';

import { buildFilterQuery } from 'Utils/filters';

const useTeamsListing = ({
  pageNumber = 0,
  filters = {},
  shouldFetch = false
} = {}) => {
  const dispatch = useDispatch();
  const { getTeamsListing } = actions;
  const {
    getTeams,
    getLoading,
    getError,
    getLoaded,
    getLoadedQueries,
    getTotalCount
  } = selectors;

  const teams = useSelector(state =>
    getTeams(state, { filter: filters, pageNumber })
  );

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const loadedQueries = useSelector(state => getLoadedQueries(state));
  const totalCount = useSelector(state => getTotalCount(state));

  const key = buildFilterQuery({ filter: filters, pageNumber }, 'teams');
  const queryState = loadedQueries[key];
  const totalCountTeams = totalCount[key];

  const dispatchGetTeamsListing = useCallback(
    (pageNumber, filters) => {
      getTeamsListing(dispatch, pageNumber, filters);
    },
    [dispatch, getTeamsListing]
  );

  useEffect(() => {
    if (shouldFetch && !queryState?.loaded && !loading) {
      getTeamsListing(dispatch, pageNumber, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getTeamsListing,
    pageNumber,
    filters,
    shouldFetch,
    loading,
    teams.length
  ]);

  return {
    teams,
    totalCountTeams,
    getTeamsListing: dispatchGetTeamsListing,
    error,
    loading,
    loaded
  };
};

export default useTeamsListing;
