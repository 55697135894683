import styled from 'styled-components';
import { SmallBody, Body, SubHeading } from 'Components/Text';
import { Row, Col } from 'ui-55';
import {media} from 'Components/Layout'

const StyledClientDetailCard = styled.div`
  padding: ${(props) => props.isEraseButton && '46px 0px'};
  background: #ffffff;
  ${props => media.mobile('margin: 0 auto;')}
  > div {
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: ${(props) => props.theme.margin / 2}px;
    padding: ${(props) => props.theme.margin / 2}px;
    border: 1px solid ${(props) => props.theme.colors.mediumBeige};
  }
`;

export const SubHeadingDetail = styled(SubHeading)`
  margin-left: 24px;
  margin-bottom: 14px;
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.lightBeige};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;
  }
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${props => props.theme.colors.grey};
  margin: 0px;
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
`;

export const RowDetail = styled(Row)`
  border-bottom: ${props =>
    props.position === 'top'
      ? `1px solid ${props.theme.colors.mediumBeige}`
      : 'none'};
`;

export const ColDetail = styled(Col)`
  margin: 24px 0px;
  margin-left: 16px;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Iva = styled(SmallBodyDetail)`
  display: inline;
`;

export default StyledClientDetailCard;
