import styled from 'styled-components';
import { Body, SmallBody, Heading } from 'Components/Text';
import { TextInput } from 'ui-55';
import theme from 'Theme';

import { Col, Row } from 'Components/Layout';

/*  Specialist render *****************************************/

export const ModalCustomStyles = {
  overlay: {
    backgroundColor: `${theme.colors.backdrop}`
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: 'none',
    background: `${theme.colors.white}`,
    overflow: 'auto',
    padding: 0,
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none',
    maxWidth: '1060px',
    margin: '0 auto'
  }
};

export const ModalContentContainer = styled(Row)`
  margin: 0px;
  max-width: none;
  position: relative;
  min-height: calc(100vh - 80px);
`;

export const ProviderPreSelectContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  border-radius: 2px;
  width: calc(100% - 16px);
  margin-top: ${props => props.theme.margin}px;
  > div {
    padding: 5px;
    word-break: break-all;
  }
  svg {
    height: 20px;
    path {
      fill: ${props => props.theme.colors.brand.red};
    }
  }
`;

export const SpecialistRenderContainer = styled.div`
  margin: ${props => props.theme.margin}px;
`;

export const StyledInfo = styled.span`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.brand.orange};
  svg {
    height: 28px;
    width: 28px;
    margin-right: 12px;
  }
  path {
    fill: ${props => props.theme.colors.brand.orange};
  }
  :hover {
    cursor: pointer;
  }
`;
export const StyledIconContainer = styled.span.attrs({
  role: 'presentation'
})`
  align-self: flex-start;
  path {
    fill: ${props => props.theme.colors.brand.orange};
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Section = styled.div`
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  border-bottom: ${props =>
    props.hasBorder ? `1px solid ${props.theme.colors.mediumBeige}` : 'none'};
  padding-bottom: ${props => (props.hasBorder ? '25px' : '32px')};
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px 0px 8px 0px;
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
  ${props => props.isDisabled &&
    `color: ${props.theme.colors.mediumBeige};`
  }
`;

export const SpecialistsContainer = styled.div`
  margin-top: ${(props) => props.theme.margin}px;
  border: ${(props) => `1px solid ${props.theme.colors.mediumBeige}`};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  > div button {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.lightBeige};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: ${props => (props.marginBottom ? '16px' : '0px')};
  svg {
    width: 14px;
  }
`;

export const IconDetails = styled.div`
  display: flex;
`;

export const StyledModalLeftSection = styled(Col)`
  flex: 2;
  display: flex;
  padding: 30px;
  max-width: 620px;
`;

export const CloseBtnContainer = styled(Row)`
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: -70px;
  right: -25px;
  > button {
    width: auto !important;
    align-self: flex-end;
    cursor: pointer;
  }
`;

export const StyledModalRightSection = styled(Col)`
  flex: 1;
  button {
    width: 100%;
    padding: 10px;
    margin: ${props => props.theme.margin * 2}px 0px;
  }
  background-color: ${props => props.theme.colors.lightestBeige};
  padding: 30px;
`;

export const StyledFilterContainer = styled(Col)`
  input {
    min-height: 46px;
    min-width: 645px;
  }
`;

export const RightContent = styled.div`
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 46px;
  padding-top: 40px;
`;

export const RightRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PriceEstimate = styled.div`
  margin: 16px 0px;
`;

export const Contacted = styled(SmallBody)`
  margin: 0px;
  color: ${props => props.theme.colors.grey};
  text-align: right;
  font-style: italic;
  margin-top: -10px;
`;

export const RatingContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
   > div {
    padding: 0;
    margin-right: 8px;
  }
`;

export const StatsContainer = styled(Row)`
  width: 100%;
  margin-top: 24px;
  display: flex;
`;

export const Stat = styled(SmallBody)`
  margin: 0px;
  color: ${props => props.theme.colors.grey};

  path {
    heigh: 14px;
    width: 14px;
    fill: ${props => props.theme.colors.grey};
  }
`;

/* ******************************************************************************** */
// H O U R S      R E N D E R

export const StyledHours = styled(Col)``;

export const HoursRow = styled(Row)`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey};
  min-height: 80px;
`;

export const HoursInput = styled(TextInput)`
  width: 148px;
`;

/* ******************************************************************************** */
// P A Y M E N T      R E N D E R

export const PaymentDetails = styled(Row)`
  width: 100%;
  border: solid 1px ${props => props.theme.colors.mediumBeige};
`;

export const PaymentCol = styled(Col)`
  flex-direction: initial;
  align-items: center;
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${props => props.theme.colors.grey};
`;

export const Dot = styled.div`
  border-radius: 50%;
  height: ${props => props.theme.margin}px;
  width: ${props => props.theme.margin}px;
  margin-right: ${props => props.theme.margin * 0.5}px;
  background-color: ${props => props.paid
    ? props.theme.colors.feedback.success.default
    : props.theme.colors.feedback.error.default};
`;

export const TableContainer = styled.div`
    margin-top: ${props => props.theme.margin * 1.5}px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${props => props.theme.margin * 1.5}px;
`;

//visit styles
export const StyledVisitContainer = styled.div`
  margin-top: ${props => props.theme.margin}px;
  border: ${props => `1px solid ${props.theme.colors.mediumBeige}`};
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${props => props.theme.margin}px;
  div {
    padding: 0px;
  }
  .date-col > div {
    width: 100%;
    padding: 0;
    > input {
      padding: 10px ${props => props.theme.margin}px;
    }
  }
  .time-col {
    margin-left: ${props => props.theme.margin * 1.5}px;
  }
  .button-col {
    button {
      margin-top: 44px;
      margin-bottom: 0px;
      margin-bottom: 0px;
      padding: 10px;
    }
  }
  .checkbox-row {
    p {
      max-width: 100%;
    }
    > div {
      margin-top: ${props => props.theme.margin * 1.5}px;
      margin-bottom: 0px;
    }
  }
`;

