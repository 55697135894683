import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'redux/visits';

const useVisit = ({ visitId } = {}) => {
  const dispatch = useDispatch();
  const { createVisit, updateVisit } = actions;
  const {
    // show
    makeGetVisit,
    // create
    getCreateVisitLoading,
    getCreateVisitSuccess,
    // update
    getUpdateVisitLoading,
    getUpdateVisitFail,
    getUpdateVisitSuccess
  } = selectors;

  // show
  const makeVisit = useMemo(makeGetVisit, []);
  const visit = useSelector(state => makeVisit(state, visitId));

  // create
  const dispatchCreateVisit = useCallback(
    payload => {
      createVisit(dispatch, payload);
    },
    [createVisit, dispatch]
  );

  const createVisitLoading = useSelector(
    state => getCreateVisitLoading(state),
    shallowEqual
  );

  const createVisitSuccess = useSelector(
    state => getCreateVisitSuccess(state),
    shallowEqual
  );

  // update
  const dispatchUpdateVisit = useCallback(
    (id, payload) => {
      updateVisit(dispatch, id, payload);
    },
    [dispatch, updateVisit]
  );

  const updateVisitLoading = useSelector(state => getUpdateVisitLoading(state));
  const updateVisitFail = useSelector(state => getUpdateVisitFail(state));
  const updateVisitSuccess = useSelector(state => getUpdateVisitSuccess(state));

  return {
    //show
    visit,
    // create
    createVisit: dispatchCreateVisit,
    createVisitLoading,
    createVisitSuccess,
    // update
    updateVisit: dispatchUpdateVisit,
    updateVisitLoading,
    updateVisitFail,
    updateVisitSuccess
  };
};

export default useVisit;
