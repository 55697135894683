import styled from 'styled-components';
import Select from 'Components/Select';
import { Row, Body, Heading, SmallBody } from 'ui-55';


export const AvatarContainer = styled.div`
  margin-right: 24px;
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px 0px 8px;
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${props => props.grey && props.theme.colors.grey};
  margin: 8px 8px 0px 0px;
`;


export const StyledProviderStatusCard = styled.div``;

export const StatusSelect = styled(Select)`
    background-color: ${props => props.theme.colors.mediumBeige};
`;

export const ProviderStatus = styled.div`
  padding: 12px 24px 24px 24px;
`;

export const Line = styled(Row)`
  display: flex;
  align-items: center;
  margin-top: ${props => props.noMargin ? '' : '16px'};
`;

export const AdminContainer = styled.div`
  margin-left: 24px;
`;

export const AddAdmin = styled.div`
  color: ${props => props.theme.colors.brand.orange};
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  path {
    fill: ${props => props.theme.colors.brand.orange};
  }

  svg {
    margin-right: 8px;
  }
`;

export const ToggleAdmin = styled.div`
  cursor: pointer;
`;