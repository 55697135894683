import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import useAdmin from 'Hooks/admins/useAdmin';
import useProvider from 'Hooks/providers/useProvider';

import { Avatar } from 'ui-55';

import StyledActivityCard, {
  Dot,
  BodyDetail,
  HeadingDetail,
  CommentCard,
  CommentBody,
  CommentDetails,
  StrongPadding,
  DotCard,
  ProvidersContacted
} from './styles';

const ActivityCard = ({ activity }) => {
  const b = useTranslate('badges');
  const t = useTranslate('activity');

  const { admin } = useAdmin({
    adminId: activity?.relationships?.admin?.data?.id,
    shouldFetch: true
  });

  const activityType = activity?.attributes?.activityType;

  const createdAt = new Date(activity?.attributes?.createdAt);
  const hour = createdAt.getHours();
  const minutes = createdAt.getMinutes();
  const date = createdAt.getDate();
  const month = createdAt.getMonth();
  const year = createdAt.getFullYear();

  const currentStatus = activity?.attributes?.appointmentStatus;
  const adminIsDeleted = admin?.attributes?.adminStatus === 'deleted';
  const adminName = adminIsDeleted
    ? '[deleted admin]'
    : admin?.attributes?.fullName;

  return (
    <>
      {activity && (
        <StyledActivityCard>
          {activityType === 'comment' && admin?.attributes && (
            <CommentCard>
              <Avatar user={admin?.attributes} size='small' />

              <CommentBody>
                <CommentDetails>
                  <HeadingDetail size={6} adminIsDeleted={adminIsDeleted}>
                    {adminName}
                  </HeadingDetail>
                  <BodyDetail color='grey'>
                    {createdAt && (
                      <>
                        {hour}h{minutes} {date} {month}, {year}
                      </>
                    )}
                  </BodyDetail>
                </CommentDetails>

                <BodyDetail>{activity.attributes.commentText}</BodyDetail>
              </CommentBody>
            </CommentCard>
          )}
          {activityType !== 'comment' && (
            <>
              <Dot activityType={activityType} status={currentStatus} />

              <DotCard>
                {activityType === 'created' && (
                  <BodyDetail>
                    {t('created')}{' '}
                    <strong>
                      &quot;{currentStatus && b(`${currentStatus}`)} &quot;
                    </strong>
                  </BodyDetail>
                )}

                {activityType === 'status_change' && (
                  <BodyDetail>
                    {t('status_change')}{' '}
                    <strong>&quot;{b(currentStatus)}&quot;</strong>
                  </BodyDetail>
                )}

                {activityType === 'admin_change' && admin?.attributes && (
                  <BodyDetail>
                    <StrongPadding>{adminName}</StrongPadding>{' '}
                    {t('admin_change')}
                  </BodyDetail>
                )}

                {activityType === 'providers_contacted' && admin?.attributes && (
                  <ProvidersContacted>
                    <BodyDetail>
                      <StrongPadding>{adminName}</StrongPadding>
                      {t('selected')}{' '}
                      <strong>
                        {
                          activity.relationships?.contactedProviders?.data
                            ?.length
                        }
                      </strong>{' '}
                      {activity.relationships?.contactedProviders?.data
                        ?.length > 1
                        ? t('specialists')
                        : t('specialist')}{' '}
                      55+
                    </BodyDetail>

                    {activity.relationships?.contactedProviders?.data?.map(
                      (p, i) => {
                        return (
                          <ProviderAvatar
                            key={`provider-av-${i}`}
                            providerId={p.id}
                          />
                        );
                      }
                    )}
                  </ProvidersContacted>
                )}

                <BodyDetail color='grey'>
                  {createdAt && (
                    <>
                      {hour}h{minutes}
                    </>
                  )}
                </BodyDetail>
              </DotCard>
            </>
          )}
        </StyledActivityCard>
      )}
    </>
  );
};

const ProviderAvatar = ({ providerId }) => {
  const { provider } = useProvider({
    providerId: providerId,
    shouldFetch: true
  });
  return (
    <>
      {provider && (
        <Avatar user={provider?.attributes} size={'small'} hasText={true} />
      )}
    </>
  );
};

ProviderAvatar.propTypes = {
  providerId: PropTypes.string
};

ActivityCard.propTypes = {
  activity: PropTypes.object
};

export default ActivityCard;
