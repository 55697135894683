import styled from "styled-components";
import { Col } from "Components/Layout";
import { SubHeading, Body, SmallBody } from "Components/Text";

export const GraphContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  /*background-color: red;*/
`;

export const GreySmallBody = styled(SmallBody)`
  color: ${props => props.theme.colors.grey};
`;


export const GraphCol = styled(Col)`
  padding: 0;
`

export const ServiceCount = styled(Body)`
  width: 130px;
  box-sizing: border-box;
  padding-right: ${props => props.theme.margin * 3}px;
  text-align: left;
`

export const FlexMini = styled.div`
  cursor: pointer;
  display: flex;
  width: 130px;
  box-sizing: border-box;
  padding-right: ${props => props.theme.margin * 3}px;
  > p {
    margin: 0;
    font-weight: bold;
    padding-top: 1px;
  };
  > svg {
    margin-left: ${props => props.theme.margin / 2}px
  }
`
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  width: ${props => props.column ? 'unset' : '100%'};
  justify-content: space-between;
  margin-left: ${props => props.marginLeft ?
    `${props.theme.margin}px` : 0 };
  &.top-row {
    h3 {
      margin-top: ${props => props.theme.margin / 2}px;
    }
  }
  &.second-row {
    margin-top: ${props => props.theme.margin * 1.5}px;
  }
  &.third-row {
    margin-top: ${props => props.theme.margin * 1.5}px;
    padding-bottom: ${props => props.theme.margin * 5}px;
  }
  > p {
    margin: 0;
  }
  &.service-list {
    box-sizing: border-box;
    padding: 0 ${props => props.theme.margin}px;
    margin-top: ${props => props.theme.margin}px;
  }
  &.service-list-headers  {
    box-sizing: border-box;
    padding: ${props => props.theme.margin}px;
    background-color: ${props => props.theme.colors.lightestBeige};
    > p {
      font-weight: bold;
    }
  }
`
export const ServiceHeading = styled(SubHeading)`
  margin-top: 0;
  line-height: 1;
  margin-bottom: ${props => props.marginBottom ?
    `${props.theme.margin}px`
    :
    'unset'
  };`;
export const BorderedContainer = styled.div`
  width: ${props => props.fullWidth ?
    '100%'
    :
    `calc(50% - ${props.theme.margin}px)`
  };
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  padding: ${props =>
    props.padding
      ? `${props.theme.margin * 1.5}px ${props.theme.margin}px`
      : 0};
  box-sizing: border-box;
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  div.icon-container {
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.colors.lightBeige};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  div.pie-container {
    width: 100%;
    height: 240px;
    position: relative;
    > .graph-legend {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 16%;
      .top-text {
        margin-bottom: ${props => props.theme.margin / 2}px;
      }
    }
  }
`

export const AccordionTitle = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  > h2 {
    color: ${props => props.theme.colors.brand.orange};
  }
  svg {
    transition: all 0.25s;
    transform: rotate(${props => (props.isOpen ? "180deg" : 0)});
  }
`;
export const ContentContainer = styled.div`
  transition: all 0.5s;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};
  padding-bottom: 1%;
  position: relative;
  color: ${props => props.theme.colors.grey};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  bottom: ${props => (props.isOpen ? 0 : 100)}px;
  height: ${props => (props.isOpen ? "auto" : 0)}px;
  transform: scaleY(${props => (props.isOpen ? 1 : 0)});
`;
