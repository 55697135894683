import styled from 'styled-components';
import { Row } from 'ui-55';

export const FormContainer = styled(Row)`
  > div {
    max-width: none;
    h2 {
      color: ${({ theme }) => theme.colors.darkBlue};
    }
  }
  form > button {
    transition: background-color 0.25s ease-in;
    ${({ success, error, theme }) =>
      `background-color:  ${(success &&
        theme.colors.feedback.success.default) ||
        (error && theme.colors.feedback.error.default) ||
        ''}`};
    span {
      ${({ success, error, theme }) =>
        `color:  ${((success || error) && theme.colors.white) || ''}`};
    }
    &: hover {
      ${({ success, error, theme }) =>
        `background-color:  ${(success &&
          theme.colors.feedback.success.hover) ||
          (error && theme.colors.feedback.error.hover) ||
          ''}`};
    }
  }
`;
