import React from "react";
import PropTypes from "prop-types";
import { ResponsivePie } from '@nivo/pie'

const PieGraph = ({ data }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 56, right: 80, bottom: 40, left: 80 }}
    sortByValue={false}
    startAngle={-100}
    endAngle={100}
    innerRadius={0.95}
    colors={d => d.color}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
    enableRadialLabels={false}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    enableSlicesLabels={false}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    isInteractive={false}
  />
)

PieGraph.propTypes = {
  data: PropTypes.array
};

PieGraph.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: "PieGraph"
};
export default PieGraph;
