import { ACTION_TYPES } from "./actions";

const initialState = {
  // show
  adminLoading: false,
  adminLoaded: false,
  adminError: false,
  // index
  loading: false,
  loaded: false,
  error: false,
  admins: [],
  adminsTotalCount: null,
  adminsTotalPages: null,
  adminsCurrentPage: null,
  totalPages: {},
  loadedQueries: {},
  // invite
  inviteLoading: false,
  inviteSuccess: false,
  inviteError: false,
  // update
  updateAdminLoading: false,
  updateAdminSuccess: false,
  updateAdminFail: false,
};

export default (state = initialState, action) => {
  const pages = { ...state.totalPages };

  switch (action.type) {
    // show
    case ACTION_TYPES.GET_ADMIN:
        return {
            ...state,
            adminLoading: true
        }
    case ACTION_TYPES.GET_ADMIN_SUCCESS:
        return {
          ...state,
          adminLoading: false,
          adminError: false,
          adminLoaded: true
        }
    case ACTION_TYPES.GET_ADMIN_FAIL:
        return {
            ...state,
            adminError: action.error,
            adminLoading: false,
            adminLoaded: true
        }

    // index
    case ACTION_TYPES.GET_ADMINS:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_ADMINS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.GET_ADMINS_SUCCESS:
      if (action.payload.meta) {
        pages[action.meta.filter] = parseInt(
          action.payload.meta['Total-Pages']
        );
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        [action.meta.filter
          ? action.meta.filter
          : 'admins']: action.payload.data.map(d => d.id),
        adminsTotalCount: action.payload.meta
          ? parseInt(action.payload.meta['Total-Count'])
          : state.adminsTotalCount,
        adminsTotalPages: action.payload.meta
          ? parseInt(action.payload.meta['Total-Pages'])
          : state.adminsTotalPages,
        adminsCurrentPage: action.payload.meta
          ? parseInt(action.payload.meta['Current-Page'])
          : state.adminsCurrentPage,
        totalPages: action.payload.meta ? pages : state.totalPages,
        loadedQueries: {
          ...state.loadedQueries,
          [action.meta.filter && action.meta.filter]: {
            loaded: true,
            error: false
          }
        }
      };

    // invite
    case ACTION_TYPES.INVITE_ADMIN:
      return {
        ...state,
        inviteLoading: true
      }
    case ACTION_TYPES.INVITE_ADMIN_SUCCESS:
      return {
        ...state,
        inviteLoading: false,
        inviteSuccess: true
      }
    case ACTION_TYPES.INVITE_ADMIN_FAIL:
      return {
        ...state,
        inviteError: action.error,
        inviteLoading: false,
        inviteSuccess: false
      }
    case ACTION_TYPES.CLEAR_ADMIN_INVITE:
      return {
        ...state,
        inviteError: false,
        inviteLoading: false,
        inviteSuccess: false
      }

    // UPDATE
    case ACTION_TYPES.UPDATE_ADMIN:
      return {
        ...state,
        updateAdminLoading: true
      };
    case ACTION_TYPES.UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminSuccess: true
      };
    case ACTION_TYPES.UPDATE_ADMIN_DELETE_SUCCESS:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminSuccess: true,
        admins: [],
        totalPages: {},
        loadedQueries: {},
      }
    case ACTION_TYPES.UPDATE_ADMIN_FAIL:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminFail: true
      };
    case ACTION_TYPES.CLEAR_ADMIN_UPDATE:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminSuccess: false,
        updateAdminFail: false,
      }

    default:
      return state;
  }
};
