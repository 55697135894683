import styled from "styled-components";
import { Body, Heading } from 'ui-55';
import theme from "Theme";

const StyledActivityCard = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const getDotColor = (activityType, status) => {
  const statusColors = {
    received: theme.colors.brand.blue,
    contact: theme.colors.brand.blue,
    analysis: theme.colors.brand.yellow,
    awaiting_specialist: theme.colors.brand.yellow,
    awaiting_conclusion: theme.colors.brand.green,
    closed: theme.colors.brand.blue,
    cancelled: theme.colors.feedback.error.default
  }

  switch (activityType) {
    case 'providers_contacted':
      return theme.colors.mediumBeige;
    case 'admin_change':
      return theme.colors.mediumBeige;
    case 'created':
      return theme.colors.brand.blue;
    case 'status_change':
      return statusColors[status];
    default:
      return 'transparent';
  }

}

export const Dot = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${props => getDotColor(props.activityType, props.status)};
  margin: 5px 16px 0px 9px;
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
  padding: 0px;
  ${props => props.color && 
    `color: ${theme.colors[props.color]};`}
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px;
  padding: 0px;
  ${props => props.adminIsDeleted
    && `color: ${props.theme.colors.grey};`
  }
`;

export const CommentCard = styled.div`
  display: flex;
  width: 100%;
`;

export const CommentBody = styled.div`
  background-color: ${theme.colors.lightestBeige};
  padding: 16px;
  width: 100%;
`;

export const CommentDetails = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
    margin-top: -6px;
  }
`;

export const StrongPadding = styled.strong`
  margin-right: 8px;
`;

export const DotCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ProvidersContacted = styled.div`

`;

export default StyledActivityCard;