import { createSelector } from 'reselect';

import { getInvoicesEntities } from 'redux/entities/selectors';
import { buildFilterQuery } from 'Utils/filters';

const selectInvoicesState = (state) => state?.invoices;

// SHOW
const makeGetInvoice = () =>
  createSelector(
    getInvoicesEntities,
    (_, invoiceId) => invoiceId,
    (entities, id) => entities[id]
  );

const getInvoiceLoading = createSelector(selectInvoicesState, (state) => {
  return state?.invoiceLoading;
});

const getInvoiceError = createSelector(selectInvoicesState, (state) => {
  return state?.invoiceError;
});

// INDEX
const getLoadedQueries = createSelector(selectInvoicesState, (state) => {
  return state?.loadedQueries || false;
});

const selectFilteredItems = (state, filters) => {
  const invoices = selectInvoicesState(state);

  return invoices[buildFilterQuery(filters, 'invoices')];
};

const getInvoices = createSelector(
  (state, { filter }) =>
    selectFilteredItems(state, {
      filter,
    }) || [],
  (state) => getInvoicesEntities(state) || {},
  (ids, invoices) => ids.map((id) => invoices[id])
);

const getInvoicesLoading = createSelector(selectInvoicesState, (state) => {
  return state?.invoicesLoading || false;
});

const getInvoicesLoaded = createSelector(selectInvoicesState, (state) => {
  return state?.invoicesLoaded || false;
});

const getInvoicesError = createSelector(selectInvoicesState, (state) => {
  return state?.invoicesError || null;
});

// UPDATE
export const getUpdateInvoiceLoading = createSelector(
  selectInvoicesState,
  (state) => {
    return state?.updateInvoiceLoading || false;
  }
);

export const getUpdateInvoiceSuccess = createSelector(
  selectInvoicesState,
  (state) => {
    return state?.updateInvoiceSuccess || false;
  }
);

export const getUpdateInvoiceFail = createSelector(
  selectInvoicesState,
  (state) => {
    return state?.updateInvoiceFail || false;
  }
);

const getInvoicesFromProvider = createSelector(selectInvoicesState, (state) => {
  return state.providerInvoices || null;
});

export {
  // show
  makeGetInvoice,
  getInvoiceError,
  getInvoiceLoading,
  // index
  getInvoicesFromProvider,
  getInvoices,
  getInvoicesLoading,
  getInvoicesError,
  getInvoicesLoaded,
  getLoadedQueries,
};
