import styled from 'styled-components';
import { Body } from 'ui-55';
import { media } from 'Components/Layout'

export const StyledProviderCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1;
  width: 100%;
  ${media.tablet('max-width: 600px;')}
  > div {
    ${media.mobile('justify-content: center;')}
    div {
    }
  }
`;

export const Provider = styled.div`
  display: flex;
  margin: ${props => props.theme.margin}px;
  align-items: center;
  flex-flow: row wrap;
`;

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.lightBeige};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: ${props => (props.marginBottom ? '16px' : '0px')};

  svg {
    width: 14px;
  }
`;

export const ContactDetails = styled.div`

`;

export const Empty = styled.div`
  border-top: 1px solid ${props => props.theme.colors.mediumBeige};
  margin: 0px;
  width: calc(50% - 48px);
`;

export const IconDetails = styled.div`
  display: flex;
  margin-top: 16px;
  width: 50%;
  ${media.mobile('width: 100%; max-width: 150px; p {max-width: 100px; text-overflow: ellipsis; overflow: hidden}')}
`;

export const BodyDetail = styled(Body)`
  margin: 0px;
`;

export const AvatarContainer = styled.div`
  margin-right: 24px;
`;

export const Services = styled.div`
  display: flex;
  flex-flow: row wrap;
  > * {
    padding: 16px 24px;
  }
`;
