import styled from 'styled-components';
import { Row } from 'ui-55';

export const LogoutContainer = styled.div`
  margin-top: 20px;
  width: 200px;
`;
export const FormContainer = styled(Row)`
  align-content: flex-start;
  width: 100%;
  margin: 0;
  > div {
    max-width: 100%;
    form > button {
      display: none;
    }
    form > div > div:nth-child(2) {
      flex-flow: row wrap;
      display: flex;
      > div {
        display: flex;
        width: calc(50% - ${({ theme }) => theme.margin}px);
        margin-right: ${({ theme }) => theme.margin}px;
        margin-left: 0;
      }
      h2 {
        width: 100%;
        font-size: 20px;
      }
      button {
        width: calc(50% + ${({ theme }) => theme.margin}px);
        background: ${({ theme }) => theme.colors.lightBeige};
        span {
          color: ${({ theme }) => theme.colors.grey};
        }
      }
    }
  }
`;
