import React, { useState, useCallback, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { useTranslate } from '../../features/polyglot';
import useAuth from 'Hooks/useAuth';

import { Heading, Body } from 'Components/Text';

import StyledAcceptInvitation from './style';
import {
  StyledHeaderText,
  StyledLogoHolder,
  StyledErrorHolder,
  StyledCard
} from './style';

import { Col, Row, Loader, Logo, Button, TextInput } from 'ui-55';

const AcceptInvitation = () => {
  const t = useTranslate('acceptInvitation');
  const history = useHistory();
  const params = queryString.parse(history?.location?.search);

  const { user } = useAuth();
  useEffect(() => {
    if (user && !acceptInvitationSuccess) {
      // clearLogin();
    }
  });

  const [newPassword, setNewPassword] = useState({
    firstField: null,
    secondField: null,
    error: { firstField: null, secondField: null }
  });

  const {
    error,
    loading,
    acceptInvitation,
    acceptInvitationSuccess
  } = useAuth();

  const handleSubmitPassword = () => {
    const minimumLength = 6;

    if (!newPassword.firstField) {
      return setNewPassword(prev => ({
        ...prev,
        error: { firstField: t('required') }
      }));
    }
    if (!newPassword.secondField) {
      return setNewPassword(prev => ({
        ...prev,
        error: { secondField: t('required') }
      }));
    }

    if (newPassword.firstField?.length < minimumLength) {
      return setNewPassword(prev => ({
        ...prev,
        error: { firstField: t('passwordLength') }
      }));
    }

    if (newPassword.secondField?.length < minimumLength) {
      return setNewPassword(prev => ({
        ...prev,
        error: { secondField: t('passwordLength') }
      }));
    }

    if (newPassword.firstField === newPassword.secondField) {
      // if (action)
      //   return action({ isSet: true, password: newPassword.secondField });
      return acceptInvitation({
        invitation_token: params?.token,
        password: newPassword.secondField
      });
    } else {
      return setNewPassword(prev => ({
        ...prev,
        error: { secondField: t('diferentPasswords') }
      }));
    }
  };

  const redirectToDashboard = useCallback(() => history.push('/dashboard'), [
    history
  ]);

  useEffect(() => {
    if (acceptInvitationSuccess) {
      redirectToDashboard();
    }
  }, [acceptInvitationSuccess, redirectToDashboard]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <StyledAcceptInvitation>
          <Row justify='center'>
            <Col size={9}>
              <StyledCard passing={4}>
                <StyledLogoHolder>
                  <Logo />
                </StyledLogoHolder>
                <StyledHeaderText>
                  <Heading size={3} center>
                    {t('passwordScreenHeader')}
                  </Heading>
                  <Body>{t('passwordScreenTitle')}</Body>
                  {error && (
                    <StyledErrorHolder>
                      <Body>{t(`${Object.values(error)[0]}`)}</Body>
                    </StyledErrorHolder>
                  )}
                </StyledHeaderText>

                <TextInput
                  label={t('newPassword')}
                  type='password'
                  hasIcon
                  error={newPassword?.error?.firstField}
                  onChange={v =>
                    setNewPassword({ ...newPassword, firstField: v })
                  }
                />
                <TextInput
                  label={t('repeatNewPassword')}
                  type='password'
                  hasIcon
                  error={newPassword?.error?.secondField}
                  onChange={v =>
                    setNewPassword({ ...newPassword, secondField: v })
                  }
                />

                <Button
                  type='submit'
                  text={t('redefinePassword')}
                  isFullWidth
                  action={() => handleSubmitPassword()}
                />
              </StyledCard>
            </Col>
          </Row>
        </StyledAcceptInvitation>
      )}
    </>
  );
};

export default AcceptInvitation;
