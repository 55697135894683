import { createSelector } from "reselect";

const selectDashboardState = state => state.dashboard;

export const getDashboard = createSelector(selectDashboardState, state => {
  return state.dashboard || false;
});

export const getLoading = createSelector(selectDashboardState, state => {
  return state.loading || false;
});
export const getLoaded = createSelector(selectDashboardState, state => {
  return state.loaded || false;
});
export const getError = createSelector(selectDashboardState, state => {
  return state.error || null;
});
