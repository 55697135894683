import adminClient from "Services/adminService";

import { buildFilterQuery } from 'Utils/filters';

const REDUCER = "admins";
export const ACTION_TYPES = {
  GET_ADMIN: `${REDUCER}/GET_ADMIN`,
  GET_ADMIN_SUCCESS: `${REDUCER}/GET_ADMIN_SUCCESS`,
  GET_ADMIN_FAIL: `${REDUCER}/GET_ADMIN_FAIL`,
  GET_ADMINS: `${REDUCER}/GET_ADMINS`,
  GET_ADMINS_SUCCESS: `${REDUCER}/GET_ADMINS_SUCCESS`,
  GET_ADMINS_FAIL: `${REDUCER}/GET_ADMINS_FAIL`,
  INVITE_ADMIN: `${REDUCER}/INVITE_ADMIN`,
  INVITE_ADMIN_SUCCESS: `${REDUCER}/INVITE_ADMIN_SUCCESS`,
  INVITE_ADMIN_FAIL: `${REDUCER}/INVITE_ADMIN_FAIL`,
  UPDATE_ADMIN: `${REDUCER}/UPDATE_ADMIN`,
  UPDATE_ADMIN_SUCCESS: `${REDUCER}/UPDATE_ADMIN_SUCCESS`,
  UPDATE_ADMIN_FAIL: `${REDUCER}/UPDATE_ADMIN_FAIL`,
  CLEAR_ADMIN_UPDATE: `${REDUCER}/CLEAR_ADMIN_UPDATE`,
  CLEAR_ADMIN_INVITE: `${REDUCER}/CLEAR_ADMIN_INVITE`,
  UPDATE_ADMIN_DELETE_SUCCESS: `${REDUCER}/UPDATE_ADMIN_DELETE_SUCCESS`,
};

// SHOW
const getAdmin = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_ADMIN
  });
  adminClient
    .getAdmin(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_ADMIN_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_ADMIN_FAIL,
        error: "Error getting admin "
      });
    });
};

// INDEX
const getAdminsListing = (dispatch, pageNumber, filter) => {
  dispatch({
    type: ACTION_TYPES.GET_ADMINS
  });
  adminClient
    .getAdmins(pageNumber, filter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_ADMINS_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter, pageNumber }, 'admins')
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_ADMINS_FAIL,
        error: 'Error getting admins '
      });
    });
};

// INVITE_ADMIN
const inviteAdmin = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.INVITE_ADMIN,
  });
  adminClient
    .inviteAdmin(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.INVITE_ADMIN_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.INVITE_ADMIN_FAIL,
        error: e
      });
    });
};

// UPDATE ADMIN
const updateAdmin = (dispatch, payload, id) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_ADMIN,
  });
  adminClient
    .updateAdmin(payload, id)
    .then(res => {
      if (res && res.data) {
        if (res.data?.data?.attributes?.admin_status === "deleted") {
          dispatch({
            type: ACTION_TYPES.UPDATE_ADMIN_DELETE_SUCCESS,
            payload: res.data
          })
        } else {
          dispatch({
            type: ACTION_TYPES.UPDATE_ADMIN_SUCCESS,
            payload: res.data
          });
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_ADMIN_FAIL,
        error: e
      });
    });
}

export { getAdmin, getAdminsListing, inviteAdmin, updateAdmin };
