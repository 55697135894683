import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../features/polyglot';

import useAppointment from 'Hooks/appointments/useAppointment';
import { getLeadsEntities } from 'redux/entities/selectors';

import { Col } from 'Components/Layout';
import { Heading, Body } from 'Components/Text';
import { StyledHours, HoursRow, HoursInput } from './styles';

const HoursRender = ({ appointment, serviceId }) => {
  const t = useTranslate('requests');
  const { updateAppointment } = useAppointment();
  const hourlyRate = appointment?.attributes?.hourlyRate;
  const existingHours = appointment?.attributes?.totalHours;
  const existingTotal = (hourlyRate * existingHours).toFixed(2);
  const leads = useSelector((state) => getLeadsEntities(state) || {});
  const lead = leads?.[appointment?.relationships?.lead?.data?.id];

  const offerType = lead?.attributes?.formAnswers?.offerType
  /* const hasKmPrice = service?.attributes?.hasKmPrice; */

  const [hoursTimer, setHoursTimer] = useState(0);
  const [totalHours, setTotalHours] = useState(existingHours || 0);
  const [totalHoursPrice, setTotalHoursPrice] = useState(existingTotal || 0);

  const handleHourChange = (value) => {
    if (value >= 0) {
      setTotalHours(value);

      const newPrice = (value * hourlyRate).toFixed(2);
      setTotalHoursPrice(newPrice);

      const payload = { total_hours: value };

      if (hoursTimer) clearTimeout(hoursTimer);
      setHoursTimer(
        setTimeout(function () {
          updateAppointment(payload, appointment?.id, {
            success: () => {
              console.log('success');
            },
            error: (e) => {
              toast.error(e)
            }
          });
        }, 1500)
      );
    }
  };

  const kmRate = appointment?.attributes?.kmPrice;
  const existingKms = appointment?.attributes?.totalKms;
  const existingKmTotal = (kmRate * existingKms) / 100;

  const frozenStatuses = ['awaiting_visit_payment', 'awaiting_service_payment'];
  const isFrozen = frozenStatuses.includes(appointment?.attributes?.status)

  const [kmTimer, setKmTimer] = useState(0);
  const [totalKms, setTotalKms] = useState(existingKms || 0);
  const [totalKmsPrice, setTotalKmsPrice] = useState(existingKmTotal || 0);

  const handleKmsChange = (value) => {
    if (value >= 0) {
      setTotalKms(value);

      const newKmPrice = (value * kmRate) / 100;
      setTotalKmsPrice(newKmPrice);

      const payload = { total_kms: value };

      if (kmTimer) clearTimeout(kmTimer);
      setKmTimer(
        setTimeout(function () {
          updateAppointment(payload, appointment?.id, {
            success: () => {
              console.log('success');
            },
          });
        }, 1500)
      );
    }
  };

  return (
    <StyledHours>
      <HoursRow>
        <Col size={5}>
          <Heading size={6}>{t('totalHours')}</Heading>
        </Col>
        <Col size={4}>
          {isFrozen || offerType === 2
            ?
              <Body>
                {totalHours}
              </Body>
            :
              <HoursInput
                defaultValue={totalHours}
                onChange={(v) => handleHourChange(v)}
                type='number'
                placeholder={totalHours ? totalHours : 0}
                step="0.5"
              />
          }
        </Col>
        <Col size={3}>
          <Body>{totalHoursPrice}€</Body>
        </Col>
      </HoursRow>

      <HoursRow>
        <Col size={5}>
          <Heading size={6}>{t('totalKilometers')}</Heading>
        </Col>
        <Col size={4}>
          {isFrozen
            ?
              <Body>
                {totalKms}
              </Body>
            :
              <HoursInput
                defaultValue={totalKms}
                onChange={(v) => handleKmsChange(v)}
                type='number'
                placeholder={totalKms ? totalKms : 0}
              />
          }
        </Col>
        <Col size={3}>
          <Body>{totalKmsPrice}€</Body>
        </Col>
      </HoursRow>
    </StyledHours>
  );
};

export default HoursRender;
