import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/candidateLeads';

import { buildFilterQuery } from 'Utils/filters';

const useCandidateLeadsListing = ({ filters = {}, shouldFetch = false }) => {
  const dispatch = useDispatch();
  const { getCandidateLeadsListing } = actions;
  const {
    getCandidateLeads,
    getLoading,
    getError,
    getLoaded,
    getLoadedQueries
  } = selectors;

  const candidateLeads = useSelector(
    state => getCandidateLeads(state, { filter: filters }),
    shallowEqual
  );

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const loadedQueries = useSelector(state => getLoadedQueries(state));

  const key = buildFilterQuery({ filter: filters }, 'candidateLeads');
  const queryState = loadedQueries[key];

  const dispatchGetCandidateLeadsListing = useCallback(
    filters => {
      getCandidateLeadsListing(dispatch, filters);
    },
    [dispatch, getCandidateLeadsListing]
  );

  useEffect(() => {
    if (shouldFetch && !loading && !queryState?.loaded) {
      dispatchGetCandidateLeadsListing(filters);
    }
  }, [
    dispatchGetCandidateLeadsListing,
    shouldFetch,
    filters,
    queryState,
    loading,
    candidateLeads.length,
    loadedQueries
  ]);

  return {
    candidateLeads,
    error,
    loading,
    loaded
  };
};

export default useCandidateLeadsListing;
