import { ACTION_TYPES } from './actions';

const initialState = {
  search: null,
  path: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_LOCAL_FILTERS:
      return {
        search: action.payload?.search,
        path: action.payload?.path,
      };
    case ACTION_TYPES.RESET_LOCAL_FILTERS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
