import { createSelector } from "reselect";

import { getTeamsEntities } from "redux/entities/selectors";
import { buildFilterQuery } from 'Utils/filters';

const selectTeamsState = state => state.teams;

// SHOW
export const makeGetTeam = () =>
  createSelector(
    getTeamsEntities,
    (_, teamId) => teamId,
    (entities, id) => entities[id]
  );
  
export const getTeamLoading = createSelector(selectTeamsState, state => {
  return state.teamLoading || false;
});
export const getTeamLoaded = createSelector(selectTeamsState, state => {
  return state.teamLoaded || false;
});
export const getTeamError = createSelector(selectTeamsState, state => {
  return state.teamError || null;
});

// INDEX
export const getLoadedQueries = createSelector(selectTeamsState, state => {
  return state.loadedQueries || false;
});

export const getTotalCount = createSelector(selectTeamsState, state => {
  return state.totalCount || false;
})

export const getTotalPages = createSelector(selectTeamsState, state => {
  return state.totalPages || null;
});

export const selectFilteredItems = (state, filters) => {
  const teams = selectTeamsState(state);

  return teams[buildFilterQuery(filters, 'teams')];
};

export const getTeams = createSelector(
  (state, { filter, pageNumber }) =>
    selectFilteredItems(state, {
      filter,
      pageNumber
    }) || [],
  state => getTeamsEntities(state) || {},
  (ids, teams) => ids.map(id => teams[id])
);

export const getLoading = createSelector(selectTeamsState, state => {
  return state.loading || false;
});

export const getLoaded = createSelector(selectTeamsState, state => {
  return state.loaded || false;
});

export const getError = createSelector(selectTeamsState, state => {
  return state.error || null;
});

// CREATE
export const getCreateTeamLoading = createSelector(selectTeamsState, state => {
  return state.isSubmitting || false;
});

export const getCreateTeamSuccess = createSelector(selectTeamsState, state => {
  return state.wasSuccessful || false;
});
