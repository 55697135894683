export const buildFilterQuery = (filters, resource) => {
  const { filter, pageNumber } = filters;
  const sortedFilters = sortFilters(filter);
  if (Object.keys(sortedFilters).length && pageNumber) {
    return `${JSON.stringify(sortedFilters)}-${pageNumber}`;
  } else if (Object.keys(sortedFilters).length) {
    return JSON.stringify(sortedFilters);
  } else if (pageNumber) {
    return 'page-' + pageNumber;
  } else {
    return resource;
  }
};

export const applyFilters = (filters, entity) => {
  let query = `/${entity}?`;

  if (typeof filters === 'string' || Object.keys(filters).length === 0) {
    return query;
  }
  Object.keys(filters).forEach((key) => {
    if (filters[key] === 'todos') {
      return;
    } else if (typeof filters[key] === 'object') {
      let aryQuery = ``;

      filters &&
        filters[key] &&
        filters[key].forEach((elem) => {
          aryQuery += `${key}[]=${elem?.toString()}&`;
        });

      query += aryQuery;
    } else if (filters[key] || filters[key] === false || filters[key] === 0) {
      query += `${key}=${filters[key]?.toString()}&`;
    }
  });
  return query.slice(0, -1);
};
export const sortFilters = (filters) => {
  function Comparator(a, b) {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  }

  const ary = Object.keys(filters).map((filter) => {
    return [filter, filters[filter]];
  });

  const sorted = ary.sort(Comparator);

  const sortedObj = sorted.reduce(function (result, item, index, array) {
    result[item[0]] = item[1];
    return result;
  }, {});

  return sortedObj;
};
