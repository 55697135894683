import dashboardClient from "Services/dashboardService";

const REDUCER = "dashboard";
export const ACTION_TYPES = {
  GET_DASHBOARD: `${REDUCER}/GET_DASHBOARD`,
  GET_DASHBOARD_SUCCESS: `${REDUCER}/GET_DASHBOARD_SUCCESS`,
  GET_DASHBOARD_FAIL: `${REDUCER}/GET_DASHBOARD_FAIL`
};

const getDashboardData = (dispatch, filters) => {
  dispatch({
    type: ACTION_TYPES.GET_DASHBOARD
  });
  dashboardClient
    .getDashboard(filters)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_DASHBOARD_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_DASHBOARD_FAIL,
        error: "Error getting dashboard"
      });
    });
};

export { getDashboardData };
