import invoicesClient from 'Services/invoicesService';

import { buildFilterQuery } from 'Utils/filters';

const REDUCER = 'invoices';
export const ACTION_TYPES = {
  // show
  GET_INVOICE: `${REDUCER}/GET_INVOICE`,
  GET_INVOICE_SUCCESS: `${REDUCER}/GET_INVOICE_SUCCESS`,
  GET_INVOICE_FAIL: `${REDUCER}/GET_INVOICE_FAIL`,
  // index
  GET_INVOICES: `${REDUCER}/GET_INVOICES`,
  GET_INVOICES_SUCCESS: `${REDUCER}/GET_INVOICES_SUCCESS`,
  GET_INVOICES_FAIL: `${REDUCER}/GET_INVOICES_FAIL`,
  // update
  UPDATE_INVOICE: `${REDUCER}/UPDATE_INVOICE`,
  UPDATE_INVOICE_SUCCESS: `${REDUCER}/UPDATE_INVOICE_SUCCESS`,
  UPDATE_INVOICE_FAIL: `${REDUCER}/UPDATE_INVOICE_FAIL`,

  //Providers
  GET_PROVIDER_INVOICES: `${REDUCER}/GET_PROVIDER_INVOICES`,
  GET_PROVIDER_INVOICES_SUCCESS: `${REDUCER}/GET_PROVIDER_INVOICES_SUCCESS`,
  GET_PROVIDER_INVOICES_FAIL: `${REDUCER}/GET_PROVIDER_INVOICES_FAIL`,
  PATCH_PROVIDER_INVOICE: `${REDUCER}/PATCH_PROVIDER_INVOICE`,
  PATCH_PROVIDER_INVOICE_SUCCESS: `${REDUCER}/PATCH_PROVIDER_INVOICE_SUCCESS`,
  PATCH_PROVIDER_INVOICE_FAIL: `${REDUCER}/PATCH_PROVIDER_INVOICE_FAIL`,
};
// show
const getInvoice = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_INVOICE,
  });
  invoicesClient
    .getInvoice(id)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_INVOICE_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_INVOICE_FAIL,
        invoiceError: 'Error getting invoice',
      });
    });
};

// index
const getInvoicesListing = (dispatch, filter) => {
  dispatch({
    type: ACTION_TYPES.GET_INVOICES,
  });
  invoicesClient
    .getInvoices(filter)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_INVOICES_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter }, 'invoices'),
          },
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_INVOICES_FAIL,
        error: 'Error getting invoices ',
      });
    });
};

// update
const updateInvoice = (dispatch, id, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_INVOICE,
  });
  invoicesClient
    .updateInvoice(id, payload)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_INVOICE_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_INVOICE_FAIL,
        error: e,
      });
    });
};
const getProviderInvoices = (dispatch, id, payload) => {
  dispatch({
    type: ACTION_TYPES.GET_PROVIDER_INVOICES,
  });
  invoicesClient
    .getProviderInvoices(id)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_PROVIDER_INVOICES_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.GET_PROVIDER_INVOICES_FAIL,
        error: e?.message,
      });
    });
};

const patchProviderInvoices = (dispatch, id, payload) => {
  dispatch({
    type: ACTION_TYPES.PATCH_PROVIDER_INVOICE,
  });
  invoicesClient
    .patchProviderInvoice(id)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.PATCH_PROVIDER_INVOICE_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((e) => {
      dispatch({
        type: ACTION_TYPES.PATCH_PROVIDER_INVOICE_FAIL,
        error: e?.message,
      });
    });
};

export {
  getProviderInvoices,
  patchProviderInvoices,
  getInvoice,
  getInvoicesListing,
  updateInvoice,
};
