import styled from 'styled-components';
import { SmallBody, Link } from 'Components/Text';

export const StyledTableRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 0px;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};

  &:hover {
    background-color: ${props => props.theme.colors.lightestBeige};
  }
`;

export const SmallBodyDetail = styled(SmallBody)`
  margin: 0px;
`;

export const ServiceName = styled(Link)`
  color: ${props => props.theme.colors.brand.orange};

  :hover {
    color: ${props => props.theme.colors.brand.orange};
  }
  :active {
    color: ${props => props.theme.colors.brand.orange};
  }
  :visited {
    color: ${props => props.theme.colors.brand.orange};
  }
  :focus {
    color: ${props => props.theme.colors.brand.orange};
  }
`;

export const BadgeContainer = styled.div`
  width: fit-content;
  margin-bottom: 8px;
`;

export default StyledTableRow;
