import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import queryString from 'query-string';
import { useTranslate } from '../../features/polyglot';
import { toast } from 'react-toastify';

import useAdmin from 'Hooks/admins/useAdmin';
import useAppointment from 'Hooks/appointments/useAppointment';

import ServiceDetailCard from 'Components/ServiceDetailCard';
import ServiceDetailSidebar from 'Components/ServiceDetailSidebar';
import ActivityFeed from 'Components/ActivityFeed';
import PaymentModal from 'Components/PaymentModal';
import {
  SpecialistRender,
  HoursRender,
  DocumentsRender,
  PaymentRender,
} from './RequestDetailsTabRenders';

import {
  GridCol,
  Tabs,
  Row,
  Loader,
  SubHeading,
  Form,
  BackofficeContainer,
} from 'ui-55';
import { ButtonSlider, FormContainer, TabContainer } from './styles';

const RequestDetails = ({ action }) => {
  const match = useLocation();
  const t = useTranslate('requests');
  const [success, setSuccess] = useState(false);

  const appointmentId = queryString.parse(match.search).id;
  const [openModal, setOpenModal] = useState(false);
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  const {
    appointment,
    appointmentForm,
    appointmentLoading: loading,
    getAppointment,
    updateAppointment,
    appointmentError: error,
  } = useAppointment({
    appointmentId: appointmentId,
  });

  const handleSubmit = (answers) => {
    const appointmentAnswers = { ...appointmentForm?.answers };
    Object.keys(answers).forEach((key) => {
      appointmentAnswers[key] = answers[key];
    });

    updateAppointment(
      {
        is_urgent: appointmentAnswers?.['is-urgent'],
        form_answers: appointmentAnswers,
      },
      appointmentId,
      {
        success: () => {
          setSuccess(true);
        },
        error: (e) => {
          toast.error(e);
        },
      }
    );
  };

  const serviceId = appointment?.relationships?.service.data?.id;
  const clientId = appointment?.relationships?.client.data?.id;
  const providerId = appointment?.relationships?.provider.data?.id;
  const { admin } = useAdmin(appointment?.relationships?.admin.data?.id);

  const data = {
    admin,
    clientId,
    providerId,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeSuccess = () => {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  useEffect(() => {
    if (success) {
      removeSuccess();
    }
    return () => {
      clearTimeout(removeSuccess);
    };
  }, [removeSuccess, success]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  };

  const formRef = useRef();
  const handleError = (errors) => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };

  useEffect(() => {
    getAppointment(appointmentId);
  }, []);

  const paymentT = useTranslate('payment');
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentModalType, setPaymentModalType] = useState(null);
  const [paymentModalData, setPaymentModalData] = useState({});
  const handleToggleSpecialistModal = () => {
    setDefaultTabIndex(1);
    setOpenModal(true);
  };
  const handleTogglePaymentModal = (
    modalType,
    payload = null,
    appointment = null
  ) => {
    const appointmentId = appointment?.id;
    setIsPaymentModalOpen(!isPaymentModalOpen);
    setPaymentModalType(modalType);
    if (payload && appointmentId) {
      setPaymentModalData({ payload: payload, appointment: appointment });
    }
  };
  const handlePaymentModalAction = (payload, appointmentId) => {
    updateAppointment(payload, appointmentId, {
      success: () => {
        if ([2, 8].includes(payload?.status)) {
          toast.success(t('invoiceVoided'));
        } else {
          toast.success(t('invoiceSuccess'));
        }
        setIsPaymentModalOpen(false);
      },
      error: (e) => {
        toast.error(e);
      },
    });
  };
  const renderPaymentModal = useCallback(() => {
    // 'issueVisitInvoice', 'issueServiceInvoice', 'resetToAnalysis','voidToConcluded
    return (
      <PaymentModal
        isModalOpen={isPaymentModalOpen}
        handleToggleModal={handleTogglePaymentModal}
        modalType={paymentModalType}
        t={paymentT}
        data={paymentModalData}
        successAction={handlePaymentModalAction}
      />
    );
  }, [
    handlePaymentModalAction,
    handleTogglePaymentModal,
    isPaymentModalOpen,
    paymentModalData,
    paymentModalType,
    paymentT,
  ]);

  const handleLoadAppointment = () => {
    getAppointment(appointmentId)
  }

  const tabsContent = [
    {
      name: 'Serviço',
      children: () => (
        <FormContainer
          ref={formRef}
          success={success}
          error={error}
          justify='start'
        >
          <Form
            onError={handleError}
            onSubmit={handleSubmit}
            submitLabel={success ? t('success') : t('saveAction')}
            questions={appointmentForm?.questions}
            answers={appointmentForm?.answers}
            context='BO'
            hiddenFields={['login-buttons', 'password']}
          />
        </FormContainer>
      ),
    },
    {
      name: 'Especialista 55+',
      children: () => (
        <SpecialistRender
          t={t}
          appointment={appointment}
          providerId={providerId}
          isOpenModal={openModal}
          setOpenModal={setOpenModal}
          togglePaymentModal={handleTogglePaymentModal}
        />
      ),
    },
    {
      name: 'Horas',
      children: () => (
        <HoursRender appointment={appointment} serviceId={serviceId} />
      ),
    },
    {
      name: 'Pagamentos',
      children: () => <PaymentRender appointment={appointment} />,
    },
    {
      name: 'Documentos',
      children: () => <DocumentsRender files={appointmentForm?.documents} />,
    },
    {
      name: 'Atividade',
      children: () => <ActivityFeed appointment={appointment} loadAppointment={handleLoadAppointment} />,
    },
  ];

  return (
    <>
      <BackofficeContainer>
        {(appointmentId && (
          <>
            <Row
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
              }}
            >
              <GridCol size={8}>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <ServiceDetailCard
                      appointment={appointment}
                      serviceId={serviceId}
                    />
                    <TabContainer>
                      <Tabs
                        tabs={tabsContent}
                        initialTabIndex={defaultTabIndex}
                        action={setDefaultTabIndex}
                      />
                    </TabContainer>
                  </>
                )}
              </GridCol>
              {/*//! We need a button to slide the tabs at smallDestop sizes */}
              <ButtonSlider />
              <GridCol size={1} />
              <GridCol size={4}>
                <ServiceDetailSidebar
                  appointment={appointment}
                  data={data}
                  action={handleToggleSpecialistModal}
                  togglePaymentModal={handleTogglePaymentModal}
                />
              </GridCol>
            </Row>
          </>
        )) || <SubHeading>Pedido inválido</SubHeading>}
        {renderPaymentModal()}
      </BackofficeContainer>
    </>
  );
};

RequestDetails.propTypes = {
  action: PropTypes.func,
};

export default RequestDetails;
