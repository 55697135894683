import { createSelector } from "reselect";

const selectAuthState = state => state.auth;

const getAuthUser = createSelector(selectAuthState, state => {
  return state.user || null;
});
const getLoading = createSelector(selectAuthState, state => {
  return state.loading || false;
});
const getLoaded = createSelector(selectAuthState, state => {
  return state.loaded || false;
});
const getError = createSelector(selectAuthState, state => {
  return state.error || null;
});

// accept invitation
const getAcceptInvitationLoading = createSelector(selectAuthState, state => {
  return state.acceptInvitationLoading || false;
})
const getAcceptInvitationError = createSelector(selectAuthState, state => {
  return state.acceptInvitationError || false;
})
const getAcceptInvitationSuccess = createSelector(selectAuthState, state => {
  return state.acceptInvitationSuccess || false;
})

export { 
  getAuthUser, 
  getLoading, 
  getError, 
  getLoaded,
  getAcceptInvitationLoading,
  getAcceptInvitationError,
  getAcceptInvitationSuccess
 };
