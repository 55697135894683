import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/activities';

import { buildFilterQuery } from 'Utils/filters';

const useActivitiesListing = ({
  pageNumber = 0,
  filters = {},
  shouldFetch = false
}) => {
  const dispatch = useDispatch();
  const { getActivitiesListing } = actions;
  const {
    getActivities,
    getLoading,
    getError,
    getLoaded,
    getLoadedQueries,
    getTotalCount
  } = selectors;

  const activities = useSelector(state =>
    getActivities(state, { filter: filters, pageNumber })
  );

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const loadedQueries = useSelector(state => getLoadedQueries(state));
  const totalCount = useSelector(state => getTotalCount(state));

  const key = buildFilterQuery({ filter: filters, pageNumber }, 'activities');
  const queryState = loadedQueries[key];
  const totalCountActivities = totalCount[key];

  const dispatchGetActivitiesListing = useCallback(
    (pageNumber, filters,cb) => {
      getActivitiesListing(dispatch, pageNumber, filters, cb);
    },
    [dispatch, getActivitiesListing]
  );

  useEffect(() => {
    if (shouldFetch && !queryState?.loaded && !loading) {
      getActivitiesListing(dispatch, pageNumber, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getActivitiesListing,
    pageNumber,
    filters,
    shouldFetch,
    loading,
    activities.length
  ]);

  return {
    activities,
    totalCountActivities,
    getActivitiesListing: dispatchGetActivitiesListing,
    error,
    loading,
    loaded
  };
};

export default useActivitiesListing;
