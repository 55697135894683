import { createSelector } from 'reselect';
import { get } from 'lodash';

const getContentObject = state => state.content.content;
const getContentKeys = state => state.content.keys;

export const getContent = createSelector(
  [state => getContentObject(state), (state, contentId) => contentId],
  (content, contentId) => get(content, `[${contentId}].data`)
);

export const getContentLoading = createSelector(
  [state => getContentObject(state), (state, contentId) => contentId],
  (content, contentId) => get(content, `[${contentId}].loading`)
);

export const getContentError = createSelector(
  [state => getContentObject(state), (state, contentId) => contentId],
  (content, contentId) => get(content, `[${contentId}].error`)
);

export const getContentLang = createSelector(
  [state => getContentKeys(state), (state, lang = 'en-uk') => lang],
  (content, lang) => get(content, lang)
);
