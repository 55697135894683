import notificationsService from "Services/notificationsService";

const REDUCER = "notifications";
export const ACTION_TYPES = {
  GET_NOTIFICATIONS: `${REDUCER}/GET_NOTIFICATIONS`,
  GET_NOTIFICATIONS_SUCCESS: `${REDUCER}/GET_NOTIFICATIONS_SUCCESS`,
  GET_NOTIFICATIONS_FAIL: `${REDUCER}/GET_NOTIFICATIONS_FAIL`,
};

const getNotificationsData = (dispatch, pageNumber, filters) => {
  dispatch({
    type: ACTION_TYPES.GET_NOTIFICATIONS
  });
  notificationsService
    .getNotifications(pageNumber, filters)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_NOTIFICATIONS_SUCCESS,
          payload: res.data,
          meta: {
            filter:
              (filters === "notifications" && filters) ||
              `${JSON.stringify(filters)}-${pageNumber}`
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_NOTIFICATIONS_FAIL,
        error: "Error getting notifications"
      });
    });
};

export { getNotificationsData };
