import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import { Col, Row, Icon } from 'ui-55';
import TableRow from './TableRow';

import {
  StyledInvoiceItemsTable,
  Header,
  BoldCell,
  AddAssociationLink,
  LinkDetail
} from './styles';
import { TotalCell, UnderlineCell, InputContainer, PaymentInput, SmallBodyDetail } from './TableRow/styles';

const InvoiceItemsTable = ({
  userType,
  appointment,
  handleInputChange
}) => {
  const t = useTranslate('payment');
  const [lineItems, setLineItems] = useState(appointment?.attributes?.lineItems);

  const isDisabled = [
    'awaiting_service_payment',
    'awaiting_visit_payment'
  ].includes(appointment?.attributes?.status);

  const lineOrder = ['service', 'service_pack', 'discount', 'adjustment', 'urgent', 'km',];
  const getTotals = () => {
    if (lineItems) {
      let runningTotal = 0;
      const totals = {
        service: 0,
        discount: 0,
        adjustment: 0,
        urgency: 0,
        km: 0
      }

      lineOrder.forEach(line => {
        if (lineItems[line]) {
          if (line === 'discount') {
            runningTotal -= lineItems[line]?.amountOff;
          } else {
            runningTotal += lineItems[line]?.unitAmount;
          }

          totals[line] = runningTotal;
        }
      })

      totals['subtotal'] = runningTotal;
      totals['iva'] = runningTotal * 0.23;
      totals['total'] = runningTotal + (runningTotal * 0.23)

      return totals;
    } else {
      return {}
    }
  }

  const totals = getTotals();

  const totalHours = appointment?.attributes?.totalHours;
  const [providerHourlyRate, setProviderHourlyRate] = useState(
    appointment?.attributes?.overrideProviderPrice ||
    appointment?.attributes?.providerHourlyRate * 100)
  const [providerServiceTotal, setProviderServiceTotal] = useState(totalHours * providerHourlyRate)
  // const [providerTotal, setProviderTotal] = useState(providerServiceTotal + totals['iva']);
  const [, setProviderTotal] = useState(providerServiceTotal);

  const handleProviderChange = (e) => {
    const value = parseFloat(e.target.value) || 0;

    setProviderHourlyRate(value * 100)
  }

  useEffect(() => {
    setProviderServiceTotal(totalHours * providerHourlyRate);
    // setProviderTotal((totalHours * providerHourlyRate) + totals['iva']);
    setProviderTotal((totalHours * providerHourlyRate));
  }, [providerHourlyRate, totalHours, totals])

  useEffect(() => {
    handleInputChange({ overrideProviderPrice: providerHourlyRate })
  }, [providerHourlyRate])

  useEffect(() => {
    handleInputChange({
      amountOff: lineItems?.discount?.amountOff,
      lineItems: lineItems,
      totalPriceWithTax: totals?.total
    })
  }, [lineItems])

  const handleClientChange = (e) => {
    const discountPercent = parseFloat(e.target.value) || 0;
    const discountAmount = (totals.service * (discountPercent / 100)).toFixed(2)

    const newLineItems = {
      ...lineItems,
      discount: {
        amountOff: discountAmount,
        percentOff: discountPercent
      }
    }
    setLineItems(newLineItems)
  }

  const handleAdjustmentChange = (e) => {
    if (e === 'removeLine') {
      handleRemoveLine()
    } else {
      const newLineItems = {
        ...lineItems,
        adjustment: {
          description: e.description || 'Taxa Adicional',
          unitAmount: e.unitAmount || 0
        }
      }
      setLineItems(newLineItems)
    }
  }

  const handleAddLine = () => {
    const newLineItems = {
      ...lineItems,
      adjustment: {
        description: 'Taxa Adicional',
        unitAmount: 0
      }
    }
    setLineItems(newLineItems)
  }

  const handleRemoveLine = () => {
    const newLineItems = { ...lineItems }
    delete newLineItems.adjustment

    setLineItems(newLineItems)
  }

  const getProviderTotals = () => {
    const totals = {
      service: providerServiceTotal,
      km: appointment?.attributes?.kmPrice * appointment?.attributes?.totalKms,
      urgent:  appointment?.attributes?.isUrgent ?
        appointment?.attributes?.providerUrgentPrice * appointment?.attributes?.totalHours
        :
        0
    }

    totals['total'] = totals['service'] + totals['km'] + totals['urgent'];
    return totals;
  }
  const providerTotals = getProviderTotals();

  return (
    <>
      {lineItems &&
        <StyledInvoiceItemsTable>
          <Header>
            <Col size={6}>
              {t('service')}
            </Col>

            <Col size={3}>
              {t('unitPrice')}
            </Col>

            <Col size={2}>
              {t('qty')}
            </Col>

            <Col size={1}>
              {t('price')}
            </Col>
          </Header>

          {userType === 'Client' &&
            <>
              {lineOrder.map((key, index) => {
                return lineItems[key] && (
                  <TableRow
                    key={`invoice-line-item-c${index}`}
                    lineType={key}
                    lineItem={lineItems[key]}
                    totals={totals}
                    appointment={appointment}
                    onChange={key === 'adjustment' ? handleAdjustmentChange : handleClientChange}
                    isDisabled={isDisabled}
                    tableType={userType}
                  />
                )}
              )}

          {/* Subtotal */}
              <Row>
                <Col size={6}>
                  {!lineItems?.adjustment && !isDisabled &&
                    <AddAssociationLink
                    onClick={() => handleAddLine('wu')}
                    margin={12}
                    >
                      <Icon name='Plus' />
                      <LinkDetail>{t('addLine')}</LinkDetail>
                    </AddAssociationLink>
                  }
                </Col>

                <Col size={3}>{t('subtotal')}</Col>
                <Col size={2}></Col>
                <BoldCell size={1}>{(totals.subtotal / 100).toFixed(2)}€</BoldCell>
              </Row>

          {/* IVA */}
              <Row>
                <Col size={6} />

                <Col size={3}>{t('iva')}</Col>
                <Col size={2}>23%</Col>
                <Col size={1}>{(totals.iva / 100).toFixed(2)}€</Col>
              </Row>
            </>
          }

          {userType === 'Provider' &&
            <>
              <Row>
                <UnderlineCell size={6}>{lineItems['service']?.description?.split(' - ')[0]}</UnderlineCell>

                <InputContainer size={3}>
                <SmallBodyDetail>
                  {isDisabled
                  ?
                    <>
                      {(providerHourlyRate / 100).toFixed(2)}
                    </>
                  :
                    <PaymentInput
                      defaultValue={(providerHourlyRate / 100).toFixed(2)}
                      onChange={(v) => handleProviderChange(v)}
                      type='number'
                      placeholder={(providerHourlyRate / 100).toFixed(2)}
                      step="0.01"
                      min="0"
                    />
                  }
                  {` €`}
                </SmallBodyDetail>
              </InputContainer>

                <UnderlineCell size={2}>{lineItems['service']?.description?.split(' - ')[1]}</UnderlineCell>
                <UnderlineCell size={1}>{(providerServiceTotal / 100).toFixed(2)}€</UnderlineCell>
              </Row>

              {['urgent', 'km'].map((key, index) => {
                return lineItems[key] && (
                  <TableRow
                    key={`invoice-line-item-p${index}`}
                    lineType={key}
                    lineItem={lineItems[key]}
                    totals={providerTotals}
                    appointment={appointment}
                    isDisabled={isDisabled}
                    tableType={userType}
                  />
                )}
              )}

            </>
          }

          {/* Total */}
              <Row>
                <Col size={6} />

                {userType === 'Client' &&
                  <>
                    <TotalCell size={3} userType={userType}>{t('serviceTotal')}</TotalCell>
                    <TotalCell size={2} userType={userType}></TotalCell>
                    <TotalCell size={1} userType={userType} >
                      {(totals.total / 100).toFixed(2)}€
                    </TotalCell>
                  </>
                }

                {userType === 'Provider' &&
                  <>
                    <TotalCell size={3}>{t('serviceTotal')}</TotalCell>
                    <TotalCell size={2}></TotalCell>

                    <TotalCell size={1}>
                      {(providerTotals.total / 100).toFixed(2)}€
                      {/*{(providerTotals.total / 100).toFixed(2)}€*/}
                    </TotalCell>
                  </>
                }
              </Row>

        </StyledInvoiceItemsTable>
      }
    </>
  );
};

export default InvoiceItemsTable;

InvoiceItemsTable.propTypes = {
  userType: PropTypes.oneOf(['Client', 'Provider']),
  appointment: PropTypes.object,
  handleInputChange: PropTypes.func
}
