import { ACTION_TYPES } from './actions';
import normalize from 'json-api-normalizer';

import useStorage from 'Hooks/useStorage';

const { user } = useStorage();
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  acceptInvitationLoading: false,
  acceptInvitationSuccess: false,
  acceptInvitationError: false,
  user
};

const cleanUser = user => {
  const data = normalize(user);
  return data.admin[Object.keys(data.admin)[0]];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER:
    case ACTION_TYPES.LOGOUT_USER:
    case ACTION_TYPES.UPDATE_USER:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.LOGIN_USER_FAIL:
    case ACTION_TYPES.LOGOUT_USER_FAIL:
    case ACTION_TYPES.UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: cleanUser(action.payload),
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.LOGOUT_USER_SUCCESS:
      return {
        ...initialState,
        user: null
      };

    // Accept Invitation
    case ACTION_TYPES.ACCEPT_INVITATION:
      return {
        ...state,
        acceptInvitationLoading: true
      };
    case ACTION_TYPES.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptInvitationLoading: false,
        acceptInvitationSuccess: true,
        user: cleanUser(action.payload)
      };

    case ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: cleanUser(action.payload),
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.ACCEPT_INVITATION_FAIL:
      return {
        ...state,
        acceptInvitationLoading: false,
        acceptInvitationError: true
      };

    default:
      return state;
  }
};
