import { createSelector } from "reselect";

import { getNotificationsEntities } from "redux/entities/selectors";

const selectNotificationsState = state => state.notifications;

const getNotifications = createSelector(
  (state, { filter, pageNumber }) =>
      selectNotificationsState(state)[
      (filter === "notifications" && filter) ||
      `${JSON.stringify(filter)}-${pageNumber}`
    ] || [],
  state => getNotificationsEntities(state) || {},
  (ids, notifications) => ids.map(id => notifications[id])
);

const getLoading = createSelector(selectNotificationsState, state => {
  return state.loading || false;
});
const getLoaded = createSelector(selectNotificationsState, state => {
  return state.loaded || false;
});
const getError = createSelector(selectNotificationsState, state => {
  return state.error || null;
});

export {
  getNotifications,
  getLoading,
  getLoaded,
  getError
};
