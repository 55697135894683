import React from 'react';

import { Row } from 'Components/Layout';
import { SubHeading } from 'Components/Text';
import Graphs from 'Components/Graphs';
import NotificationsList from 'Components/NotificationsList';

import { NotificationCol, TopInfo } from './style';

const DashboardContainer = ({ dashboardData }) => {
  return (
    <>
      <Row className='row' maxWidth>
        <Graphs dashboardData={dashboardData} />
        <NotificationCol size={1}>
          <TopInfo>
            <SubHeading size={5}>Notificações</SubHeading>
            {false && (
              <SubHeading size={6} pointer>
                Ver Todos
              </SubHeading>
            )}
          </TopInfo>
          <NotificationsList notificationFilter={{}} />
          <NotificationsList
            personal={true}
            notificationFilter={{ ...{}, my_notifications: true }}
          />
        </NotificationCol>
      </Row>
    </>
  );
};

export default DashboardContainer;
