import clientsClient from 'Services/clientsService';

const REDUCER = 'clients';
export const ACTION_TYPES = {
  // index
  GET_CLIENTS: `${REDUCER}/GET_CLIENTS`,
  GET_CLIENTS_SUCCESS: `${REDUCER}/GET_CLIENTS_SUCCESS`,
  GET_CLIENTS_FAIL: `${REDUCER}/GET_CLIENTS_FAIL`,
  // create
  CREATE_CLIENT: `${REDUCER}/CREATE_CLIENT`,
  CREATE_CLIENT_SUCCESS: `${REDUCER}/CREATE_CLIENT_SUCCESS`,
  CREATE_CLIENT_FAIL: `${REDUCER}/CREATE_CLIENT_FAIL`,
  // show
  GET_CLIENT: `${REDUCER}/GET_CLIENT`,
  GET_CLIENT_SUCCESS: `${REDUCER}/GET_CLIENT_SUCCESS`,
  GET_CLIENT_FAIL: `${REDUCER}/GET_CLIENT_FAIL`,
  // update
  UPDATE_CLIENT: `${REDUCER}/UPDATE_CLIENT`,
  UPDATE_CLIENT_SUCCESS: `${REDUCER}/UPDATE_CLIENT_SUCCESS`,
  UPDATE_CLIENT_FAIL: `${REDUCER}/UPDATE_CLIENT_FAIL`,
  // delete
  DELETE_CLIENT: `${REDUCER}/DELETE_CLIENT`,
  DELETE_CLIENT_SUCCESS: `${REDUCER}/DELETE_CLIENT_SUCCESS`,
  DELETE_CLIENT_FAIL: `${REDUCER}/DELETE_CLIENT_FAIL`
};

const getClients = (dispatch, query) => {
  dispatch({
    type: ACTION_TYPES.GET_CLIENTS,
    query: query,
  });
  clientsClient
    .fetchClients(query)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CLIENTS_SUCCESS,
          payload: res.data,
          query: query,
          meta: res.data.meta
        })
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CLIENTS_FAIL,
        query: query,
        error: e,
      })
    })
}

const getClient = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_CLIENT
  });
  clientsClient
    .getClient(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CLIENT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CLIENT_FAIL,
        clientError: 'Error getching client'
      });
    });
};

const updateClientDetailForm = (dispatch, payload, id) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CLIENT,
    payload
  });
  clientsClient
    .updateClient(payload, id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_CLIENT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_CLIENT_FAIL,
        errors: 'An error ocurred while updating your form'
      });
    });
};

const deleteClient = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.DELETE_CLIENT
  });
  clientsClient
    .deleteClient(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.DELETE_CLIENT_SUCCESS,
          payload: id,
          operation: 'delete'
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.DELETE_CLIENT_FAIL,
        clientError: 'Error deleting client'
      });
    });
};

const createNewClient = (dispatch, payload , cb) => {
  dispatch({ type: ACTION_TYPES.CREATE_CLIENT });
  clientsClient
    .createClient(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_CLIENT_SUCCESS,
          payload: res.data
        });
        if (cb.success) {
          cb.success(res.data?.data?.id)
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_CLIENT_FAIL,
        error: e?.message
      });
      if (cb.error) {
        cb.error(Object.keys(e?.response?.data?.meta?.message)?.[0])
      }
    });
};

export {
  getClients,
  getClient,
  updateClientDetailForm,
  deleteClient,
  createNewClient
};
