import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAppointmentsEntities } from 'redux/entities/selectors';
import { actions, selectors } from 'Redux/appointments';

const useAppointments = ({ query = '', shouldFetch = false } = {}) => {
  const dispatch = useDispatch();
  const { getAppointments } = actions;
  const { selectQueries, selectAppointments } = selectors;

  // get all appointment objects & query dict
  const allAppointments = useSelector((state) => selectAppointments(state));
  const entityAppointments = useSelector((state) =>
    getAppointmentsEntities(state)
  );
  const queries = useSelector((state) => selectQueries(state));

  // set appointments with ids from query
  const [appointments, setAppointments] = useState([]);
  // set loading info from query data
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  const makeAppointments = () =>
    queries[query]?.data?.map((id) => allAppointments[id]);

  const makeAllAppointments = () => {
    const apps = { ...allAppointments, ...entityAppointments };
    return Object.keys(apps).map((k) => apps[k]);
  };

  // set appointments to allAppointments if no query
  useEffect(() => {
    if (!query.length) {
      setAppointments(makeAllAppointments());
    }
  }, []);

  useEffect(() => {
    if (queries[query]?.data) {
      setAppointments(makeAppointments());
      setLoading(queries[query]?.loading);
      setLoaded(queries[query]?.loaded);
      setError(queries[query]?.error);
      setTotalPages(queries[query]?.totalPages);
    } else {
      setAppointments(makeAllAppointments());
    }
  }, [queries]);

  const dispatchGetApps = useCallback(
    (dispatch, query) => {
      getAppointments(dispatch, query);
    },
    [dispatch, getAppointments, query]
  );

  useEffect(() => {
    if (shouldFetch) {
      dispatchGetApps(dispatch, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    appointments,
    queries,
    loading,
    loaded,
    error,
    totalPages,
    makeAllAppointments,
  };
};

export default useAppointments;
