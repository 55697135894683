import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import { Icon } from 'ui-55';

import StyledClientDetailCard, {
  Detail,
  IconContainer,
  SmallBodyDetail,
  BodyDetail,
  RowDetail,
  ColDetail
} from './style';
import { Col, Row } from 'ui-55';

const ClientDetailCard = ({ isEraseButton, data, action }) => {
  const t = useTranslate('clientDetail');
  return (
    <>
      {isEraseButton && action && (
        <StyledClientDetailCard isEraseButton={isEraseButton} onClick={action}>
          <RowDetail align='center'>
            <ColDetail>
              <IconContainer>
                <Icon name='trash' />
              </IconContainer>
            </ColDetail>
            <ColDetail size={8}>
              <Detail>
                <SmallBodyDetail>{t('deleteClient')}</SmallBodyDetail>
                <BodyDetail>{t('confirmationDeletePhrase')}</BodyDetail>
              </Detail>
            </ColDetail>
            <ColDetail size={2}></ColDetail>
          </RowDetail>
        </StyledClientDetailCard>
      )}
      {data && (
        <StyledClientDetailCard>
          <Col size={4}>
            <Row align='center'>
              <IconContainer>
                <Icon name='tool-1' />
              </IconContainer>
              <Col>
                <SmallBodyDetail>{t('totalServices')}</SmallBodyDetail>
                <BodyDetail>104 €</BodyDetail>
              </Col>
            </Row>
          </Col>
          <Col size={4}>
            <Row align='center'>
              <IconContainer>
                <Icon name='Total' />
              </IconContainer>
              <Col>
                <SmallBodyDetail>{t('totalPaid')}</SmallBodyDetail>
                <BodyDetail>0</BodyDetail>
              </Col>
            </Row>
          </Col>
        </StyledClientDetailCard>
      )}
    </>
  );
};

ClientDetailCard.propTypes = {
  appointment: PropTypes.object,
  action: PropTypes.func
};

export default ClientDetailCard;
