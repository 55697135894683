import { ACTION_TYPES } from './actions';

const initialState = {
  getActivityLoading: null,
  getActivityError: null,
  isSubmitting: false,
  wasSuccessful: false,
  totalPages: {},
  totalCount: {},
  loadedQueries: {}
};

export default (state = initialState, action) => {
  const pages = { ...state.totalPages };
  const count = { ...state.totalCount };
  switch (action.type) {

    case ACTION_TYPES.GET_ACTIVITIES:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_ACTIVITIES_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.GET_ACTIVITIES_SUCCESS:
      if (action.payload.meta) {
        pages[action.meta.filter] = parseInt(
          action.payload.meta['Total-Pages']
        );
        count[action.meta.filter] = parseInt(
          action.payload.meta['Total-Count']
        )
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        [action.meta.filter
          ? action.meta.filter
          : 'activities']: action.payload.data.map(d => d.id),
        totalPages: action.payload.meta ? pages : state.totalPages,
        totalCount: action.payload.meta ? count : state.totalCount,

        loadedQueries: {
          ...state.loadedQueries,
          [action.meta.filter && action.meta.filter]: {
            loaded: true,
            error: false
          }
        }
      };


    // SHOW
    case ACTION_TYPES.GET_ACTIVITY:
      return {
        ...state,
        getActivityLoading: true
      };
    case ACTION_TYPES.GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        getActivityLoading: false,
        getActivityError: false,
      };
    case ACTION_TYPES.GET_ACTIVITY_FAIL:
      return {
        ...state,
        getActivityError: true,
        getActivityLoading: false
      };

    // CREATE NEW
    case ACTION_TYPES.CREATE_ACTIVITY:
      return {
        ...state,
        isSubmitting: true,
        wasSuccessful: false
      };
    case ACTION_TYPES.CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isSubmitting: false,
        wasSuccessful: true,
        loaded: false,
      };
    case ACTION_TYPES.CREATE_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        wasSuccessful: false
      };
    default:
      return state;
  }
};
