import client from './client';

const getContentDetail = (id, lang = 'en-uk') => {
  return client.get(`content/${id}?lang=${lang}`);
};

const updateContent = (id, content, lang = 'en-uk') => {
  return client.patch(`content/${id}?lang=${lang}`, {
    localized_content: content
  });
};

const getContentKeys = (lang = 'en-uk') => {
  return client.get(`content?lang=${lang}`);
};

export default { getContentDetail, getContentKeys, updateContent };

