import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CONFIG from 'Config';
import { Loader, BackofficeContainer } from 'ui-55';
import useCandidateLead from 'Hooks/candidateLeads/useCandidateLead';
import useCandidateLeadsListing from 'Hooks/candidateLeads/useCandidateLeadsListing';
import useFilters from 'hooks/useFilters';

import {
  getValueFromDestinationCol,
  isColumnValid,
} from 'utils/candidateStatus';

import Kanban from 'Components/Kanban';
import Filters from 'Components/Filters';

const STATUS_COLUMNS = CONFIG.boards.candidates.columns;

const Candidates = () => {
  const history = useHistory();
  const location = useLocation();

  const availableFilters = [
    'admins',
    'candidate_lead_status',
    'services',
    'cities',
  ];
  const MULTI_FILTERS = ['status', 'service_ids', 'admin_id', 'city_ids'];

  const { active } = useFilters(location);
  const { updateCandidateLead } = useCandidateLead();

  const { candidateLeads, loading } = useCandidateLeadsListing({
    filters: active,
    shouldFetch: true,
  });

  const COLUMN_NAMES = new Set(Object.values(STATUS_COLUMNS));
  const kanbanItems = useMemo(
    () =>
      candidateLeads?.map((a) => ({
        ...a,
        column: STATUS_COLUMNS[a?.attributes?.status],
      })),
    [candidateLeads]
  );

  const handleModalOpen = (candidateLeadId, destinationCol) => {
    const candidateLead = candidateLeads.find(
      (candidate) => candidate.id === candidateLeadId
    );
    const newStatus = getValueFromDestinationCol(destinationCol);
    const payload = { status: newStatus };
    updateCandidateLead(candidateLead.id, payload);
    return;
  };

  const handleNavigateToNewCandidate = (candidate) => {
    const providerId = candidate?.relationships?.provider?.data?.id;
    history.push(`/dashboard/specialist-details?provider_id=${providerId}`);
  };

  // const renderModal = () => {
  //   const modal = getModalTypeFromStatus(modalData?.item);

  //   return (
  //     <Modal
  //       title={t('new_candidate')}
  //       data={modalData}
  //       isOpen={modalIsOpen}
  //       isLoading={updateCandidateLeadLoading}
  //       updateSuccess={updateSuccess}
  //       onClose={handleModalClose}
  //       onSuccess={handleUpdate}
  //       modalType={modal?.modalType}
  //       allowScroll={true}
  //     />
  //   );
  // };

  return (
    <>
      <BackofficeContainer>
        <Filters
          availableFilters={availableFilters}
          multiFilters={MULTI_FILTERS}
          layoutStyle='kanban'
        />

        {loading ? (
          <Loader />
        ) : (
          <Kanban
            onChangeStatus={handleModalOpen}
            action={(c) => handleNavigateToNewCandidate(c)}
            items={kanbanItems}
            colNames={[...COLUMN_NAMES]}
            hasFilters={Object.keys(active).length !== 0}
            kanbanType='candidates'
            isColumnValid={isColumnValid}
          />
        )}
      </BackofficeContainer>
      {/* {renderModal()} */}
    </>
  );
};

export default Candidates;
