import React from 'react';

import { Col } from 'Components/Layout';
import { Avatar } from 'ui-55';

import { StyledTableRow } from './styles';

const TableRow = ({ item, action, teams }) => {
  const teamIds = item?.relationships?.teams?.data;

  return (
    <>
      {item && (
        <StyledTableRow onClick={() => action && action(item)}>
          <Col size={4}>
            {item?.attributes && (
              <>
                <Avatar
                  size='medium'
                  hasText={true}
                  hasEmail={true}
                  user={item?.attributes}
                />
              </>
            )}
          </Col>

          {/* <Col size={4}>{item?.attributes?.city}</Col> */}

          <Col size={4}>
            {teamIds &&
              teamIds.map((team, index) => {
                const teamName = teams.find(t => t.id === team.id)?.attributes
                  ?.name;
                return `
                  ${teamName}
                  ${index + 1 === teamIds.length ? '' : ','}
                `;
              })}
          </Col>
        </StyledTableRow>
      )}
    </>
  );
};

export default TableRow;
