import { ACTION_TYPES } from "./actions";

const initialState = {
  // show
  teamLoading: false,
  teamLoaded: false,
  teamError: false,
  // index
  loading: false,
  loaded: false,
  error: false,
  teams: [],
  teamsTotalCount: null,
  teamsTotalPages: null,
  teamsCurrentPage: null,
  totalPages: {},
  loadedQueries: {},
  // create
  isSubmitting: false,
  wasSuccessful: false
};

export default (state = initialState, action) => {
  const pages = { ...state.totalPages };
  
  switch (action.type) {
    // show
    case ACTION_TYPES.GET_TEAM:
        return {
            ...state,
            teamLoading: true
        }
    case ACTION_TYPES.GET_TEAM_SUCCESS:

        return {
          ...state,
          teamLoading: false,
          teamError: false,
          teamLoaded: true
        }
    case ACTION_TYPES.GET_TEAM_FAIL:
        return {
            ...state,
            teamError: action.error,
            teamLoading: false,
            teamLoaded: true
        }

    // index
    case ACTION_TYPES.GET_TEAMS:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_TEAMS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.GET_TEAMS_SUCCESS:
      if (action.payload.meta) {
        pages[action.meta.filter] = parseInt(
          action.payload.meta['Total-Pages']
        );
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        [action.meta.filter
          ? action.meta.filter
          : 'teams']: action.payload.data.map(d => d.id),
        teamsTotalCount: action.payload.meta
          ? parseInt(action.payload.meta['Total-Count'])
          : state.teamsTotalCount,
        teamsTotalPages: action.payload.meta
          ? parseInt(action.payload.meta['Total-Pages'])
          : state.teamsTotalPages,
        teamsCurrentPage: action.payload.meta
          ? parseInt(action.payload.meta['Current-Page'])
          : state.teamsCurrentPage,
        totalPages: action.payload.meta ? pages : state.totalPages,
        loadedQueries: {
          ...state.loadedQueries,
          [action.meta.filter && action.meta.filter]: {
            loaded: true,
            error: false
          }
        }
      };

      // create
    case ACTION_TYPES.CREATE_TEAM:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        teams: [action.payload?.data?.id].concat(state.teams),
        payload: action.payload,
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.CREATE_TEAM_FAIL:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        wasSuccessful: false
      };
    case ACTION_TYPES.CLEAR_TEAM_SUCCESS:
      return {
        ...state,
        error: false,
        isSubmitting: false,
        wasSuccessful: false
      }

    default:
      return state;
  }
};
