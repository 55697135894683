import styled from 'styled-components';

import { Row } from 'Components/Layout';

const width = {
  availabilityCheckboxContainer: `200px`,
  filterContainer: `200px`
};

export const StyledFilterContainer = styled(Row)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.isFullWidth ? '100%' : '')};
  padding: 0px;
  margin: ${props => props.theme.margin * 1.5}px 0px;
  input {
    min-height: 46px;
    min-width: 555px;
  }
`;

export const FilterContainer = styled.div`
  width: ${width.filterContainer};
`;

const StyledSpecialistTable = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBlue};
  text-transform: capitalize;
  width: 100%;
`;

export const Header = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.lightestBeige};
  height: 48px;
  width: 100%;
  display: flex;
  font-weight: bold;
`;

export const SortIconContainer = styled.div`
  display: flex;
  div > span {
    display: flex;
    flex-flow: column;
    height: 15px;
  }
  svg {
    position: relative;
    bottom: 7px;
    left: 10px;
  }
`;

export const StyledCheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: ${props => props.theme.margin * 1.5}px;
  height: ${props => props.theme.margin * 1.5}px;
  border-radius: 3px;
  transition: all 150ms;
  position: relative;
  margin: 0px;
`;

export const AvailabilityCheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${width.availabilityCheckboxContainer};

  input {
    min-width: ${props => props.theme.margin * 1.5}px;
    min-height: ${props => props.theme.margin * 1.5}px;
    height: ${props => props.theme.margin * 1.5}px;
    width: ${props => props.theme.margin * 1.5}px;
    margin-right: ${props => props.theme.margin * 0.5}px;
  }
`;

export default StyledSpecialistTable;
