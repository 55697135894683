import React from "react";
import {
  HeadingDetail,
  StyledTagBadge,
  Card
} from "./style";
import useAppointment from 'Hooks/appointments/useAppointment';
import { Body } from 'Components/Text';
import { Icon } from 'ui-55';
import Select from 'Components/Select';
import useTagsListing from 'Hooks/tags/useTagsListing';
import { useTranslate } from '../../features/polyglot';



const CardTagAppointmnet = (props) => {
  const t = useTranslate('tag');

  const { tags } = useTagsListing({
    shouldFetch: true,
  });
  const tagOptions = tags.map(tag => {
    return {
      label: tag.attributes?.name,
      value: tag.id
    };
  });
  const {
    updateAppointment
  } = useAppointment();

  const appointment = props?.appointment;
  if (appointment === undefined || appointment?.attributes?.tags === undefined || appointment?.attributes?.tags === null) {
    return (
      <></>
    );
  }
  const handleSelectChange = (newTag) => {
    const anotherTags = appointment.attributes.tags.map(tag => ({ id: tag.id }));
    const newTags = [...anotherTags, { id: newTag.value }];
    const payload = {
      tags: newTags
    };
    updateAppointment(payload, appointment.id);
  }
  const handleRemoveTag = (tagToRemove) => {
    const newTags = appointment.attributes.tags.filter(tag => tag.id !== tagToRemove.id).map(tag => ({ id: tag.id }));
    const payload = {
      tags: newTags
    };
    updateAppointment(payload, appointment.id);
  }

  return (
    <>
      <Card>
        <HeadingDetail size={6}>{t('tags')}</HeadingDetail>

        <Select
          options={tagOptions}
          onChange={v => handleSelectChange(v)}
          selectType='tag'
          value={null}
        /><br />
        {appointment?.attributes?.tags?.length === 0 && (<Body alt='true'>Não tem Tag Selecionada</Body>)}
        {Object.keys(appointment?.attributes?.tags).map((key) => {
          return (
            <React.Fragment key={key}>
              <StyledTagBadge>
                <div
                  role='button'
                  tabIndex={-1}
                  onClick={() =>
                    handleRemoveTag(appointment?.attributes?.tags[key])
                  }
                >
                  {appointment?.attributes?.tags[key]?.name}
                  <Icon name='Close' />
                </div>
              </StyledTagBadge>
            </React.Fragment>);
        })}
      </Card>
    </>
  );


}


export default CardTagAppointmnet;
