import client from './client';

const getInteraction = id => {
  return client.get(`interactions/${id}`);
};

const createInteraction = payload => {
  return client.post(`interactions`, payload);
};


export default { 
  getInteraction, 
  createInteraction,
  client 
};
