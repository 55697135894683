import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/clients';

const useClients = ({ query = '', shouldFetch = false} = {}) => {
  const dispatch = useDispatch();
  const { getClients } = actions;
  const {
    selectQueries,
    selectClients
  } = selectors;

  // get all client objects & query dict
  const allClients = useSelector(state => selectClients(state));
  const queries = useSelector(state => selectQueries(state));

  // set clients with ids from query
  const [clients, setClients] = useState([]);
  // set loading info from query data
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  const makeClients = () => 
    queries[query]?.data?.map(id => allClients[id])

  const makeAllClients = () => 
    Object.keys(allClients).map(k => k);

  useEffect(() => {
    if (queries[query]?.data) {
      setClients(makeClients());
      setLoading(queries[query]?.loading);
      setLoaded(queries[query]?.loaded);
      setError(queries[query]?.error);
      setTotalPages(queries[query]?.totalPages);
    } else {
      setClients(makeAllClients());
    }
  }, [queries])

  const dispatchGetApps = useCallback((dispatch, query) => {
    getClients(dispatch, query);
  }, [dispatch, getClients, query]);

  useEffect(() => {
    if (shouldFetch) {
      dispatchGetApps(dispatch, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    clients,
    queries,
    loading,
    loaded,
    error,
    totalPages,
  };
};

export default useClients;
