/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  actions as contentActions,
  selectors as contentSelectors,
} from 'redux/content';

import { Heading, SmallBody } from 'ui-55';

//========================================================================================
/*                                                                                      *
 *                                         Utils                                        *
 *                                                                                      */
//========================================================================================

const PROJECT_EXTRACTORS = {
  title: (props, element) => {
    props.text = element.elementable.value;
  },
  description: (props, element) => {
    props.text = element.elementable.value;
  },
};
const PROJECT_ELEMENTS = {
  sections: (props) => <React.Fragment key={props.key}></React.Fragment>,
  title: ({ text }) => <Heading>{text}</Heading>,
  description: ({ text }) => <SmallBody>{text}</SmallBody>,
};

const extractProps = (element, name, renderer, extractor = {}) => {
  const props = {};
  const componentsPropExtractor = {
    ...PROJECT_EXTRACTORS,
    ...extractor,
  };
  if (componentsPropExtractor[name]) {
    componentsPropExtractor[name](props, element, renderer);
  }
  return props;
};

const getElementValueByName = (element, name) =>
  element.elementable.elements.find((e) => e.name === name).elementable.value;
const getElementsByName = (element, name) =>
  element.elementable.elements.find((e) => e.name === name)?.elementable
    ?.elements ?? [];


const usePolymorph = ({ id, pageElements, extractor, language } = {}) => {
  const dispatch = useDispatch();

  const content = useSelector((state) =>
    contentSelectors.getContent(state, id, language)
  );
  const loading = useSelector((state) =>
    contentSelectors.getContentLoading(state, id)
  );
  const languageContent = useSelector(
    (state) =>
      contentSelectors.getContentLang(state, language) || {
        loading: false,
        loaded: false,
      }
  );

  const dispatchGetContent = () => contentActions.getContent(dispatch, { id, lang: language });

  const dispatchGetContentKeys = () =>  contentActions.getContentKeys(dispatch, { lang: language });

  const TYPE_MAP = {
    PlainTextElement: 'plain_text',
    MediaElement: 'media',
    RepeaterElement: 'repeater',
    RichTextElement: 'rich_text',
  };
  const dispatchUpdateContent = (values, lists, deletedIds, successCallback) => {
      // const updatedIds = Object.keys(values);
      const updatedContent = [];
      const getValuesFromArray = (el) =>
        Array.isArray(el?.value ?? null)
          ? {
            elements: el.value.map((val, idx) => {
              return {
                type: TYPE_MAP[val.type],
                name: val.name,
                order: idx + 1,
                ...getValuesFromArray(val),
              };
            }),
          }
          : { value: values[el.id] || el.value };
      const updateNestedContent = (elx, arr = []) => {
        elx.forEach((ct, i) => {
          const idFromName = ct.name + '-' + ct.id;
          if (deletedIds.indexOf(ct.id) === -1) {
            const newElement = {
              name: ct.name,
              order: ct.order || i + 1,
            };
            const elementable = ct.elementable;
            if (elementable) {
              if (elementable.elements) {
                newElement.elements = [];
                updateNestedContent(elementable.elements, newElement.elements);
              }
              switch (ct.elementable_type) {
                case 'MediaElement':
                  newElement.type = TYPE_MAP[ct.elementable_type];
                  newElement.media_type = 'image';
                  if (elementable.media_url) {
                    if (values[ct.id]) {
                      newElement.media_base64 =
                        values[ct.id] || elementable.media_url;
                    } else {
                      newElement.media_url = elementable.media_url;
                    }
                  }
                  break;
                case 'PlainTextElement':
                  newElement.type = TYPE_MAP[ct.elementable_type];
                  if (elementable.value || values[ct.id]) {
                    newElement.value = values[ct.id] || elementable.value;
                  }
                  break;
                case 'RichTextElement':
                  newElement.type = TYPE_MAP[ct.elementable_type];
                  if (elementable.value || values[ct.id]) {
                    newElement.value = values[ct.id] || elementable.value;
                  }
                  break;
                case 'RepeaterElement':
                  newElement.type = TYPE_MAP[ct.elementable_type];
                  if (lists[idFromName]) {
                    newElement.elements = [
                      ...newElement.elements,
                      ...lists[idFromName].map((li, idx) => ({
                        name: li.name,
                        type: TYPE_MAP[li.type] || li.name,
                        order: newElement.elements.length + idx + 1,
                        ...getValuesFromArray(li),
                      })),
                    ];
                  }
                  break;
                default:
                  newElement.type = ct.name;
                  break;
              }
            }
            arr.push(newElement);
          }
        });
      };
      if (content.elements) {
        updateNestedContent(content.elements, updatedContent);
      }

      contentActions.updateContent(
        dispatch,
        { id, content: updatedContent, lang: language },
        successCallback
      );
    };

  const [parsedContent, setParsedContent] = useState({});

  const parseCMSContent = () => {
    const ELEMENTS = {
      ...PROJECT_ELEMENTS,
      ...pageElements,
    };
    const parsedElements = {};
    const renderItems = [];
    const elements = content.elements;
    const renderElements = (els, container = false) => {
      const containerItems = [];
      if (els) {
        ((Array.isArray(els) && els) || []).forEach((element) => {
          const name = element.name;
          const props = {
            key: element.name + '-' + element.id,
            ...extractProps(element, name, renderElements, extractor),
          };
          if (ELEMENTS[name]) {
            if (container) {
              containerItems.push(ELEMENTS[name]?.(props) || '');
            } else {
              if (parsedElements[name]) {
                parsedElements[name] = [
                  parsedElements[name],
                  ELEMENTS[name]?.(props) || '',
                ];
              } else {
                parsedElements[name] = ELEMENTS[name]?.(props) || '';
              }
              renderItems.push(ELEMENTS[name]?.(props) || '');
            }
          }
          if (
            element.elementable &&
            element.elementable.elements &&
            Object.keys(props).length === 1
          ) {
            renderElements(element.elementable.elements, container);
          }
        });
      }
      return containerItems;
    };
    renderElements(elements);
    setParsedContent(parsedElements);
    return renderItems;
  };

  const treeComponents = useMemo(() => {
    const singleItems =
      languageContent?.data
        ?.filter((ck) => !ck.content?.category)
        .map((c) => ({
          ...c.content,
          label: c.content.label || c.content.key,
        })) || [];
    const categories = [];
    // eslint-disable-next-line no-unused-expressions
    languageContent?.data?.forEach((ck, i) => {
      const ckCategory = ck?.content?.category;
      if (
        ckCategory &&
        categories.map((c) => c.label).indexOf(ckCategory) === -1
      ) {
        categories.push({
          label: ckCategory,
          elements: [ck.content],
        });
      } else if (categories.map((c) => c.label).indexOf(ckCategory) !== -1) {
        categories
          .find((cat) => cat.label === ckCategory)
          .elements.push(ck.content);
      }
    });
    return [...categories, ...singleItems];
  }, [languageContent]);

  useEffect(() => {
    if (!content && id) {
      dispatchGetContent();
    }
  }, []);

  useEffect(() => {
    if (content) {
      parseCMSContent();
    }
  }, [content]);

  return {
    parseCMSContent,
    loading,
    parsedContent,
    treeComponents,
    updateContent: dispatchUpdateContent,
    getContentKeys: dispatchGetContentKeys,
    languageContent,
    getElementValueByName,
    getElementsByName,
    content,
  };
};

export default usePolymorph;
