import client from './client';
import { applyFilters } from 'Utils/filters';

const getInvoice = (id) => {
  return client.get(`invoices/${id}`);
};

const getInvoices = (filters) => {
  if (filters !== 'invoices') {
    return client.get(`${applyFilters(filters, 'invoices')}`);
  }
  return client.get(applyFilters({}, 'invoices'));
};

const updateInvoice = (id, payload) => {
  return client.patch(`/invoices/${id}`, {
    invoice: payload,
  });
};

const getProviderInvoices = (id) => {
  return client.get(`/provider_invoices?provider_id=${id}`);
};

const patchProviderInvoice = (invoiceId) => {
  return client.patch(`/provider_invoices/${invoiceId}`, {
    // enum status: {
    //   open: 0,
    //   paid: 1
    // }
    provider_invoice: {
      status: 1,
    },
  });
};

export default {
  getProviderInvoices,
  patchProviderInvoice,
  getInvoice,
  getInvoices,
  updateInvoice,
  client,
};
