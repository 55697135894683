import styled from 'styled-components';

export const StyledTableRow = styled.div`
  padding: ${props => (props.hasCheckBox ? 10 : 0)}px;
  display: flex;
  align-items: center;
  font-weight: normal;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};

  .service {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.brand.orange};
  }
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.lightestBeige};
  }
`;

export const StyledCheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  transition: all 150ms;
  position: relative;
  margin: 0px;
`;

export default StyledTableRow;
