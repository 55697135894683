import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from '../../features/polyglot';

import useService from 'Hooks/services/useService';
import useAppointment from 'Hooks/appointments/useAppointment';
import { Icon, Select, Loader, Row } from 'ui-55';

import StyledServiceDetailCard, {
  Detail,
  IconContainer,
  SmallBodyDetail,
  BodyDetail,
  RowDetail,
  Iva,
  SubHeadingDetail,
  ColDetail,
  StyledInput,
  StyledInputError,
} from './style';


// inb4, sorry 🙏
const hourSelectionOptions = [
  {
    label: '00:00',
    value: '00:00',
  },
  {
    label: '00:30',
    value: '00:30',
  },
  {
    label: '01:00',
    value: '01:00',
  },
  {
    label: '01:30',
    value: '01:30',
  },
  {
    label: '02:00',
    value: '02:00',
  },
  {
    label: '02:30',
    value: '02:30',
  },
  {
    label: '03:00',
    value: '03:00',
  },
  {
    label: '03:30',
    value: '03:30',
  },
  {
    label: '04:00',
    value: '04:00',
  },
  {
    label: '04:30',
    value: '04:30',
  },
  {
    label: '05:00',
    value: '05:00',
  },
  {
    label: '05:30',
    value: '05:30',
  },
  {
    label: '06:00',
    value: '06:00',
  },
  {
    label: '06:30',
    value: '06:30',
  },
  {
    label: '07:00',
    value: '07:00',
  },
  {
    label: '07:30',
    value: '07:30',
  },
  {
    label: '08:00',
    value: '08:00',
  },
  {
    label: '08:30',
    value: '08:30',
  },
  {
    label: '09:00',
    value: '09:00',
  },
  {
    label: '09:30',
    value: '09:30',
  },
  {
    label: '10:00',
    value: '10:00',
  },
  {
    label: '10:30',
    value: '10:30',
  },
  {
    label: '11:00',
    value: '11:00',
  },
  {
    label: '11:30',
    value: '11:30',
  },
  {
    label: '12:00',
    value: '12:00',
  },
  {
    label: '12:30',
    value: '12:30',
  },
  {
    label: '13:00',
    value: '13:00',
  },
  {
    label: '13:30',
    value: '13:30',
  },
  {
    label: '14:00',
    value: '14:00',
  },
  {
    label: '14:30',
    value: '14:30',
  },
  {
    label: '15:00',
    value: '15:00',
  },
  {
    label: '15:30',
    value: '15:30',
  },
  {
    label: '16:00',
    value: '16:00',
  },
  {
    label: '16:30',
    value: '16:30',
  },
  {
    label: '17:00',
    value: '17:00',
  },
  {
    label: '17:30',
    value: '17:30',
  },
  {
    label: '18:00',
    value: '18:00',
  },
  {
    label: '18:30',
    value: '18:30',
  },
  {
    label: '19:00',
    value: '19:00',
  },
  {
    label: '19:30',
    value: '19:30',
  },
  {
    label: '20:00',
    value: '20:00',
  },
  {
    label: '20:30',
    value: '20:30',
  },
  {
    label: '21:00',
    value: '21:00',
  },
  {
    label: '21:30',
    value: '21:30',
  },
  {
    label: '22:00',
    value: '22:00',
  },
  {
    label: '22:30',
    value: '22:30',
  },
  {
    label: '23:00',
    value: '23:00',
  },
  {
    label: '23:30',
    value: '23:30',
  },
];

const ServiceDetailCard = ({ appointment, serviceId }) => {
  const t = useTranslate('requests');
  const [inputType, setInputType] = useState('text');

  const [inputErrors] = useState({
    hours: null,
    delivered_on: null,
  });

  const { service } = useService({
    serviceId: serviceId,
    shouldFetch: true,
  });

  const { updateAppointment, updateAppointmentLoading } = useAppointment();

  const dateFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };

  //  checks for default values in case of a text type input
  //  case true -> returns new Date
  //  case false -> returns no schedules

  const inputTypeDefaults =
    inputType === 'text' && appointment?.attributes?.deliveredOn
      ? new Date(appointment?.attributes?.deliveredOn).toLocaleDateString(
          'pt-PT',
          dateFormatOptions
        )
      : t('noSchedules');

  const handleAppointmentStatus = (key, value) => {
    const updatedValues = {};
    if (key === 'start-hour' || key === 'end-hour') {
      // if default values set no Errors

      if (key === 'start-hour') {
        updateAppointment({ start_hour: value }, appointment?.id);
      }
      if (key === 'end-hour') {
        updateAppointment({ end_hour: value }, appointment?.id);
      }
      // else if no values or appointment
      // set to no hours
    } else {
      if (!value) {
        updatedValues[key] = appointment?.attributes?.deliveredOn;
      } else {
        updatedValues[key] = value;
      }
      updateAppointment(updatedValues, appointment?.id);
    }
  };

  const totalLineItems = () => {
    const lineItems = appointment?.attributes?.lineItems;
    let total = 0;

    if (lineItems) {
      Object.keys(lineItems).forEach(key => {
        if (key === 'discount') {
          total -= parseInt(lineItems[key].amountOff)
        } else {
          total += parseInt(lineItems[key].unitAmount)
        }
      })
    }
    return total;
  }
  const totalPrice = totalLineItems();

  return (
    <StyledServiceDetailCard>
      <SubHeadingDetail>{service?.attributes?.name}</SubHeadingDetail>
      <RowDetail position='top'>
        <ColDetail size={4}>
          <IconContainer>
            <Icon name='Hourglass' />
          </IconContainer>
          <Detail>
            <SmallBodyDetail>{t('estimatedHours')}:</SmallBodyDetail>
            {appointment?.attributes?.estimatedHours ? (
              <BodyDetail>
                {appointment.attributes?.estimatedHours} {t('hours')}
              </BodyDetail>
            ) : (
              <BodyDetail>{t('noEstimation')}</BodyDetail>
            )}
            {/* <StyledInput
                type='text'
                placeholder={
                  appointment?.attributes?.estimatedHours ?? 'No Estimation'
                }
                defaultValue={appointment?.attributes?.estimatedHours}
              /> */}
          </Detail>
        </ColDetail>
        <ColDetail size={4}>
          <IconContainer>
            <Icon name='calendar' />
          </IconContainer>
          <Detail>
            <SmallBodyDetail>{t('date')}</SmallBodyDetail>
            <StyledInput
              type={inputType}
              defaultValue={inputTypeDefaults}
              onBlur={(e) =>
                handleAppointmentStatus('delivered_on', e.target.value)
              }
              onClick={() => setInputType('date')}
            />
          </Detail>
        </ColDetail>
      </RowDetail>

      <RowDetail>
        <ColDetail size={4}>
          <IconContainer>
            <Icon name='Total' />
          </IconContainer>
          <Detail>
            <SmallBodyDetail>{t('total')}</SmallBodyDetail>
            {appointment?.attributes?.totalPrice ? (
              <div style={{ alignSelf: 'flex-start' }}>
                <BodyDetail>
                  {(totalPrice / 100).toFixed(2)}€{' '}
                </BodyDetail>
                <Iva>{t('tax')}</Iva>
              </div>
            ) : (
              <BodyDetail>No Total Yet</BodyDetail>
            )}
          </Detail>
        </ColDetail>
        <ColDetail size={4}>
          <IconContainer>
            <Icon name='Clock' />
          </IconContainer>
          <Detail>
            <SmallBodyDetail>{t('schedule')}</SmallBodyDetail>
            <Row
              className='hour-picker'
              padding={0}
              justify='flex-start'
              align='center'
            >
              {updateAppointmentLoading ? (
                <Loader />
              ) : (
                <>
                  <Select
                    options={hourSelectionOptions}
                    defaultValue={{
                      label: appointment?.attributes?.startHour,
                      value: appointment?.attributes?.startHour,
                    }}
                    placeholder={t('start-hour')}
                    onChange={(v) =>
                      handleAppointmentStatus('start-hour', v.value)
                    }
                  />
                  <Select
                    options={hourSelectionOptions}
                    defaultValue={{
                      label: appointment?.attributes?.endHour,
                      value: appointment?.attributes?.endHour,
                    }}
                    placeholder={t('end-hour')}
                    onChange={(v) =>
                      handleAppointmentStatus('end-hour', v.value)
                    }
                  />
                </>
              )}
            </Row>
            <StyledInputError error={inputErrors.errors}>{inputErrors.hours}</StyledInputError>
          </Detail>
        </ColDetail>
      </RowDetail>
    </StyledServiceDetailCard>
  );
};

ServiceDetailCard.propTypes = {
  appointment: PropTypes.object,
};

export default ServiceDetailCard;
