import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useHistory, useLocation } from 'react-router-dom';

import { getFormattedDateString, getDateFromFormatted } from 'utils/dateStrings';
import useFilters from 'hooks/useFilters';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Icon } from 'ui-55';
import { 
  ExtendedContainer,
  DatePickerWrapperStyles,
  Clear
} from './styles';

const Datepick = ({
  toggleExtension,
    type
}) => {
  const location = useLocation();
  const history = useHistory();
  const date_type = type
  const { active, getQueryStringFromFilters } = useFilters(location);
  const [localStart, setLocalStart] = useState(null);
  const [localEnd, setLocalEnd] = useState(null);

  useEffect(() => {
    // filter dates are always stored '31-01-2001' format
      if (active?.start_date && active?.end_date) {
        setLocalStart(active?.start_date)
        setLocalEnd(active?.end_date)
      }
  }, [active])

  useEffect(() => {
    if (localEnd !== active?.end_date && !!localEnd) {
      handleSubmit()
    }
  }, [localEnd])
  
  const handleChange = (dates) => {
    const [start, end] = dates;

    if (start) {
      if (end) {
        setLocalEnd(getFormattedDateString(end));
      } else {
        setLocalEnd(null);
      }
      setLocalStart(getFormattedDateString(start));
    }
  }

  const handleSubmit = () => {

    let payload = {...active}
    if (date_type === 'start_date'){
      payload.start_date = localStart;
      payload.end_date = localEnd;
    }else{
      payload.delivery_start_date = localStart;
      payload.delivery_end_date = localEnd;
    }
    const string = getQueryStringFromFilters(payload)

    history.push(`${location.pathname}${string}${!string.length ? '?' : '&'}page=1`);
    toggleExtension(false);
  }

  const handleClear = () => {
    const payload = { ...active }
    delete payload['start_date'];
    delete payload['end_date'];
    delete payload['delivery_start_date'];
    delete payload['delivery_end_date'];
    const string = getQueryStringFromFilters(payload)

    history.push(`${location.pathname}${string}${!string.length ? '?' : '&'}page=1`);
    toggleExtension(false);
  }

  return (
    <>
      <ExtendedContainer>
        <div>
          <DatePicker 
            selected={null}
            startDate={getDateFromFormatted(localStart) || null}
            endDate={getDateFromFormatted(localEnd) || null}
            openToDate={getDateFromFormatted(localStart) || new Date()}
            onChange={handleChange}
            selectsRange
            inline
          />
        </div>
        
        <DatePickerWrapperStyles />
        <Clear onClick={() => handleClear()}>
          <Icon name="Close"/> Limpar
        </Clear>
      </ExtendedContainer>
    </>    
  )
}

export default Datepick;

Datepick.propTypes = {
  toggleExtension: PropTypes.func
}