import { ACTION_TYPES } from './actions';

const initialState = {
  // show
  visitLoading: false,
  visitLoaded: false,
  visitError: false,
  // create
  isSubmitting: false,
  wasSuccessful: false,
  // udpate
  updateVisitFail: false,
  updateVisitLoading: false,
  updateVisitSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // show
    case ACTION_TYPES.GET_VISIT:
      return {
        ...state,
        visitLoading: true
      };
    case ACTION_TYPES.GET_VISIT_SUCCESS:
      return {
        ...state,
        visitLoading: false,
        visitError: false,
        visitLoaded: true
      };
    case ACTION_TYPES.GET_VISIT_FAIL:
      return {
        ...state,
        visitError: action.error,
        visitLoading: false,
        visitLoaded: true
      };

    // create
    case ACTION_TYPES.CREATE_VISIT:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.CREATE_VISIT_SUCCESS:
      return {
        ...state,
        visits: [action.payload?.data?.id].concat(state.visits),
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.CREATE_VISIT_FAIL:
      return {
        ...state,
        visitError: action.error,
        isSubmitting: false,
        wasSuccessful: false
      };
    case ACTION_TYPES.CLEAR_VISIT_SUCCESS:
      return {
        ...state,
        visitError: false,
        isSubmitting: false,
        wasSuccessful: false
      };

    // UPDATE
    case ACTION_TYPES.UPDATE_VISIT:
      return {
        ...state,
        updateVisitLoading: true
      };
    case ACTION_TYPES.UPDATE_VISIT_SUCCESS:
      return {
        ...state,
        updateVisitLoading: false,
        updateVisitSuccess: true
      };
    case ACTION_TYPES.UPDATE_VISIT_FAIL:
      return {
        ...state,
        visitError: action.error,
        updateVisitLoading: false,
        updateVisitFail: true
      };

    default:
      return state;
  }
};
