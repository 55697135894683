import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AcceptInvitation from 'Pages/AcceptInvitation';
import { Login, Dashboard } from 'Pages';

// eslint-disable-next-line react/prop-types
const AppRouter = ({ component: Component, path, props, exact }) => {
  return (
    <Route path={path} exact={exact} render={() => <Component {...props} />} />
  );
};

const routes = [
  { path: '/', component: Login, exact: true },
  { path: '/reset-password', component: AcceptInvitation },
  {
    path: '/dashboard',
    component: Dashboard
  }
];

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, index) => {
          return (
            <AppRouter
              key={route.path}
              path={route.path}
              exact={route.exact}
              isPrivate={route.isPrivate}
              component={route.component}
            />
          );
        })}
      </Switch>
    </BrowserRouter>
  );
}
