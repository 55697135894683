import { ACTION_TYPES } from './actions';

const initialState = {
  interactionLoading: false,
  interactionLoaded: false,
  interactionError: false,
  isSubmitting: false,
  wasSuccessful: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    // SHOW
    case ACTION_TYPES.GET_INTERACTION:
      return {
        ...state,
        interactionLoading: true
      };
    case ACTION_TYPES.GET_INTERACTION_SUCCESS:
      return {
        ...state,
        interactionLoading: false,
        interactionError: false,
        interactionLoaded: true
      };
    case ACTION_TYPES.GET_INTERACTION_FAIL:
      return {
        ...state,
        interactionError: action.error,
        interactionLoading: false,
        interactionLoaded: true
      };

    // CREATE
    case ACTION_TYPES.CREATE_INTERACTION:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.CREATE_INTERACTION_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.CREATE_INTERACTION_FAIL:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        wasSuccessful: false
      };

    default:
      return state;
  }
};
