import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslate } from '../../features/polyglot';
import Modal from 'react-modal';
import { Search, Button, Icon, Pagination } from 'ui-55';

import useAdminsListing from 'Hooks/admins/useAdminsListing';
import useTeamsListing from 'Hooks/teams/useTeamsListing';
import { getTotalPages } from 'Redux/admin/selectors';

import TeamTable from 'Components/TeamTable';

import NewMemberRender from './ModalRenders/newMemberRender';
import NewTeamRender from './ModalRenders/newTeamRender';

import { Row, BackofficeContainer } from 'ui-55';

import {
  Searchbar,
  TeamFilter,
  HeadingDetail,
  FilterButtons,
  NewTeamLink,
  SearchSection,
  ModalStyles,
} from './styles';

import { LinkDetail } from 'Components/ServiceDetailSidebar/style';

const Team = () => {
  const t = useTranslate('team');
  const s = useTranslate('buttons');
  const history = useHistory();


  const handleMemberDetails = (admin) => {
    history.push(`/dashboard/member-detail?id=${admin.id}`);
  };

  const [timer, setTimer] = useState(0);
  const handleSearch = (v) => {
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(function () {
        const updatedFilters = { ...activeFilters };

        // delete updatedFilters['order_by'];

        if (!v.length) {
          delete updatedFilters['search'];
        } else {
          updatedFilters['search'] = v;
        }
        setActiveFilters(updatedFilters);
      }, 1500)
    );
  };
  const [currentPage, setCurrentPage] = useState(1)
  const [activeFilters, setActiveFilters] = useState({
    page: currentPage
  });
  const [totalPages, setTotalPages] = useState(1);


  const totalPagesObj = useSelector((state) => getTotalPages(state));

  const { admins } = useAdminsListing({
    shouldFetch: true,
    filters: activeFilters,
  });
  const { teams } = useTeamsListing({
    shouldFetch: true,
  });

  useEffect(() => {
    if (totalPagesObj) {
      Object.keys(totalPagesObj).forEach((key) => {
        const filterKey = key.split('-')[0];
        if (filterKey === JSON.stringify(activeFilters)) {
          setTotalPages(totalPagesObj[key]);
        }
      });
    }
  }, [totalPagesObj, activeFilters]);

  const handleOrderBy = (v) => {
    const updatedFilters = {
      ...activeFilters,
      order_by: v,
    };

    setActiveFilters(updatedFilters);
  };

  // const handleNavigation = useCallback(pageNumber => {
  //   setCurrentPage(pageNumber);
  // }, []);

  const TeamFilterButton = ({ team }) => {
    const testSelected = () => {
      if (!activeFilters['team_ids']) return false;
      if (activeFilters['team_ids'].includes(team.id)) {
        return true;
      }
    };
    const [selected, setSelected] = useState(testSelected());

    const handleClick = () => {
      let updatedFilters;

      if (!activeFilters['team_ids']) {
        updatedFilters = {
          ...activeFilters,
          team_ids: [team.id],
        };
      } else {
        let newIds;
        if (activeFilters['team_ids'].includes(team.id)) {
          newIds = activeFilters['team_ids'].filter((id) => id !== team.id);
        } else {
          newIds = [...activeFilters['team_ids'], team.id];
        }
        updatedFilters = {
          ...activeFilters,
          team_ids: newIds,
        };
      }
      setSelected(!selected);
      setActiveFilters(updatedFilters);
    };

    return (
      <Button
        btnType={selected ? 'active' : 'resting'}
        text={team.attributes?.name}
        action={handleClick}
      />
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);

  const handleToggleModal = useCallback(
    (type) => {
      setIsModalOpen(!isModalOpen);
      setModalType(type);
    },
    [isModalOpen]
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderModal = useCallback(
    (modalType) => {
      // 'newMember', 'newTeam'
      const data = {
        newMember: {
          title: t('newMember'),
          body: () => <NewMemberRender handleToggleModal={closeModal} />,
        },
        newTeam: {
          title: t('newTeam'),
          body: () => <NewTeamRender handleToggleModal={closeModal} />,
        },
      };

      return (
        <>
          <Modal style={ModalStyles} ariaHideApp={false} isOpen={isModalOpen}>
            <Row justify='space-between' align='center'>
              <HeadingDetail size={3}>{data[modalType]?.title}</HeadingDetail>
              <Button
                icon='Close'
                btnType='transparent'
                action={() => handleToggleModal(null)}
              />
            </Row>
            {data[modalType]?.body()}
          </Modal>
        </>
      );
    },
    [handleToggleModal, isModalOpen, t]
  );

  return (
    <>
      <BackofficeContainer>
        <Searchbar>
          <SearchSection>
            <Search
              type='filter'
              onChange={(v) => handleSearch(v)}
              translate={s}
            />
          </SearchSection>

          <SearchSection>
            <Button
              btnType='borded'
              icon='Plus'
              action={(e) => handleToggleModal('newMember')}
            />
          </SearchSection>
        </Searchbar>

        <TeamFilter>
          <HeadingDetail size={5}>{t('teams')}</HeadingDetail>
          <FilterButtons>
            {teams &&
              Object.keys(teams).map((key, index) => {
                return (
                  <TeamFilterButton
                    key={`team-filter-btn-${index}`}
                    team={teams[key]}
                  />
                );
              })}
            <NewTeamLink onClick={(e) => handleToggleModal('newTeam')}>
              <Icon name='Plus' />
              <LinkDetail>{t('newTeam')}</LinkDetail>
            </NewTeamLink>
          </FilterButtons>
        </TeamFilter>

        <>
          {admins && (
            <>
              <TeamTable
                items={admins}
                action={handleMemberDetails}
                orderBy={handleOrderBy}
                teams={teams}
              />

              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                translate={t}
                action={p => {
                  setCurrentPage(p)
                  setActiveFilters({
                    ...activeFilters,
                    page: p
                  })
                }}
              />
            </>
          )}
        </>

        {renderModal(modalType)}
      </BackofficeContainer>
    </>
  );
};

export default Team;
