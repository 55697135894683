import styled from 'styled-components';
import theme from 'Theme';
import { Body, SmallBody } from 'Components/Text';

export const Text = styled(Body)`
  margin: 0px 0px 24px 0px;
`;

export const Section = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  max-width: 480px;
`;

export const SmallHeading = styled(SmallBody)`
  color: ${props => props.theme.colors.grey};
  margin: 0px;
  margin-bottom: ${props => (props.noMargin ? '0px' : '8px')};
`;

export const DetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 210px;
`;

export const RoundIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.lightBeige};

  svg {
    width: 18px;
  }
`;

export const IconDetails = styled.div`
  display: flex;
  margin-bottom: 16px;
  height: 48px;
`;

export const DropzoneContainer = styled.div`
  display: flex;
`;

const getBackgroundFromStatus = (theme, selected, type) => {
  switch (type) {
    case 'appointment':
      switch (selected?.value) {
        case 0:
          return theme.brand.blue;
        case 1:
          return theme.brand.blue;
        case 2:
          return theme.brand.yellow;
        case 3:
          return theme.brand.yellow;
        case 4:
          return theme.brand.yellow;
        case 5:
          return theme.brand.yellow;
        case 6:
          return theme.brand.yellow;
        case 7:
          return theme.brand.yellow;
        case 8:
          return theme.feedback.success.default;
        case 9:
          return theme.brand.blue;
        case 10:
          return theme.brand.blue;
        case 11:
          return theme.feedback.error.default;
        case 12:
          return theme.feedback.error.default;
        default:
          return 'white';
      }
    case 'candidateLead':
      switch (selected?.value) {
        case 0:
          return theme.brand.blue;
        case 1:
          return theme.brand.yellow;
        case 2:
          return theme.brand.yellow;
        case 3:
          return theme.brand.yellow;
        case 4:
          return theme.brand.blue;
        case 5:
          return theme.feedback.error.default;
        case 6:
          return theme.feedback.success.default;
        default:
          return 'white';
      }
    default:
      return 'white';
  }
};

const getColorFromStatus = (theme, selected) => {
  if (selected) {
    const white = [0, 1, 8, 8, 10, 11, 12];
    if (white.includes(selected.value)) return theme.darkBlue;
    else return theme.white;
  }
};

export const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    filter: 'drop-shadow(0px 4px 13px rgba(193, 188, 183, 0.3))',
    marginTop: 0,
    borderRadius: 2
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided, { isFocused, ...state }) => ({
    ...provided,
    color: getColorFromStatus(theme.colors, state.selectProps),
    minWidth: '150px'
  }),
  control: (provided, { isFocused, isDisabled, ...state }) => {
    return {
      ...provided,
      borderRadius: 2,
      height: 48,
      marginBottom: 12,
      backgroundColor: getBackgroundFromStatus(
        theme.colors,
        state.selectProps.value,
        state.selectProps?.styleProps?.type
      ),
      color: getColorFromStatus(theme.colors, state.selectProps),
      boxShadow: 'none',
      borderColor: theme.colors.mediumBeige,
      '&:hover': {
        borderColor: theme.colors.mediumBeige
      }
    };
  },
  option: (provided, state) => {
    const color = state.isDisabled ? theme.colors.grey : theme.colors.darkBlue;
    const backgroundColor = theme.colors.white;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      color,
      transition,
      backgroundColor,
      '&:hover': {
        backgroundColor: theme.colors.lightBeige
      }
    };
  },
  singleValue: (provided, state) => {
    return {
      color: getColorFromStatus(theme.colors, state.selectProps)
    };
  },
  placeholder: provided => {
    return {
      ...provided,
      color: theme.colors.darkBlue
    };
  },
  dropdownIndicator: (provided, { ...state }) => {
    return {
      ...provided,
      color: getColorFromStatus(theme.colors, state.selectProps),
      '&:hover': {
        color: getColorFromStatus(theme.colors, state.selectProps)
      }
    };
  }
};
