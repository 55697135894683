import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'redux/teams';

const useTeam = ({ teamId } = {}) => {
  const dispatch = useDispatch();
  const { createTeam } = actions;
  const {
    // show
    makeGetTeam,
    // create
    getCreateTeamLoading,
    getCreateTeamSuccess
  } = selectors;

  // show
  const makeTeam = useMemo(makeGetTeam, []);
  const team = useSelector(state => makeTeam(state, teamId));

  // create
  const dispatchCreateTeam = useCallback(
    payload => {
      createTeam(dispatch, payload);
    },
    [createTeam, dispatch]
  );

  const createTeamLoading = useSelector(
    state => getCreateTeamLoading(state),
    shallowEqual
  );

  const createTeamSuccess = useSelector(
    state => getCreateTeamSuccess(state),
    shallowEqual
  );

  return {
    //show
    team,
    // create
    createTeam: dispatchCreateTeam,
    createTeamLoading,
    createTeamSuccess
  };
};

export default useTeam;
