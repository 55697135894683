import settingsClient from "Services/settingsService";

const REDUCER = "settings";
export const ACTION_TYPES = {
  GET_SETTINGS: `${REDUCER}/GET_SETTINGS`,
  GET_SETTINGS_SUCCESS: `${REDUCER}/GET_SETTINGS_SUCCESS`,
  GET_SETTINGS_FAIL: `${REDUCER}/GET_SETTINGS_FAIL`
};

const getSettings = dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_SETTINGS
  });
  settingsClient
    .getSettings()
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_SETTINGS_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_SETTINGS_FAIL,
        error: "Error getting settings "
      });
    });
};

export { getSettings };
