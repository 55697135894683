import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import useAuth from 'Hooks/useAuth';
import useActivity from 'Hooks/activities/useActivity';
import useActivityListing from 'Hooks/activities/useActivityListing';

import { MONTHS } from 'utils/dateStrings';

import ActivityCard from 'Components/ActivityCard';
import { Avatar, TextInput, Button } from 'ui-55';

import StyledActivityFeed, {
  Timeline,
  FeedContent,
  Comment,
  AvatarContainer,
  InputContainer,
  ButtonContainer,
  SmallBodyDetail,
  HeadingDetail,
} from './style';

let hasNewComment = false;

const ActivityFeed = ({ appointment, loadAppointment }) => {
  const { user } = useAuth();
  const [commentValue, setCommentValue] = useState('');

  const { createActivity, createActivitySuccess } = useActivity();

  const memoUser = useMemo(
    () => ({
      avatar: user?.attributes?.avatar,
      avatarDefault: user?.attributes?.avatarDefault,
      fullName: user?.attributes?.fullName,
    }),
    [user]
  );

  const {
    activities,
    getActivitiesListing,
  } = useActivityListing({
    pageNumber: 1,
    filters: { appointment_id: appointment.id },
    shouldFetch: true,
  });

  useEffect(() => {
    if (createActivitySuccess && hasNewComment) {
      getActivitiesListing(1, { appointment_id: appointment.id });
    }
  }, [createActivitySuccess, appointment.id, getActivitiesListing])

  const groupActivitiesByDate = useCallback(() => {
    const activitiesByDate = {};
    if (activities?.length > 0) {
      activities.forEach((activity) => {
        const activityDate = new Date(activity?.attributes?.createdAt);
        const formattedDate = `${activityDate.getDate()} ${MONTHS[activityDate.getMonth()]
          }`;
        activitiesByDate[formattedDate]
          ? activitiesByDate[formattedDate].push(activity)
          : (activitiesByDate[formattedDate] = [activity]);
      });
    }
    return activitiesByDate;
  }, [activities]);

  const renderActivities = useCallback(() => {
    const groupedActivities = groupActivitiesByDate();
    return (
      groupedActivities &&
      Object.keys(groupedActivities).map((date, index) => {
        return (
          <>
            <HeadingDetail size={5}>{date}</HeadingDetail>
            {groupedActivities[date] &&
              groupedActivities[date].map((item, index) => {
                return (
                  <ActivityCard
                    activity={item}
                    key={`activity-card-${index}`}
                  />
                );
              })}
          </>
        );
      })
    );
  }, [groupActivitiesByDate]);

  const handleAdminComment = () => {
    const payload = {
      comment_text: commentValue.trim(),
      appointment_id: appointment.id,
    };
    hasNewComment = true;
    createActivity(payload);
  };

  useEffect(() => {
    if (createActivitySuccess) {
      setCommentValue('');
    }
  }, [createActivitySuccess, setCommentValue]);

  useEffect(() => {
    return () => {
      loadAppointment();
      hasNewComment = false
    }
  }, [])

  return (
    <StyledActivityFeed>
      <Timeline />

      <FeedContent>
        <Comment>
          <AvatarContainer>
            <Avatar hasCarat={false} hasText={false} user={memoUser} />
          </AvatarContainer>
          <InputContainer>
            <TextInput
              placeholder={'Deixa um comentário'}
              isFullWidth={true}
              value={commentValue}
              onChange={(v) => setCommentValue(v)}
            />
            <ButtonContainer>
              <Button
                text={'Enviar'}
                btnType='primary'
                action={() => handleAdminComment()}
                isDisabled={commentValue.length <= 0 || !commentValue.trim()}
                isFullWidth
              />
              <SmallBodyDetail>
                Só pode ser visto por membros da equipa
              </SmallBodyDetail>
            </ButtonContainer>
          </InputContainer>
        </Comment>

        {renderActivities()}
      </FeedContent>
    </StyledActivityFeed>
  );
};

export default ActivityFeed;

ActivityFeed.propTypes = {
  appointmet: PropTypes.object,
};
