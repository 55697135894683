import styled from "styled-components";
import { Col } from "Components/Layout";

export const NotificationCol = styled(Col)`
  padding-top: 0;
  padding-right: 0;
  padding-left: ${props => props.theme.margin * 2}px;
`;

export const TopInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  h3 {
    margin: 0;
    &:nth-of-type(2) {
      font-weight: normal;
      color: ${props => props.theme.colors.brand.orange}
    }
  }
`;
