import React, { useState, useEffect, useMemo } from 'react';

import useNotifications from 'Hooks/useNotifications';
import useProviders from 'Hooks/providers/useProviders';
import useAppointments from 'Hooks/appointments/useAppointments';

import NotificationRow from 'Components/NotificationRow';

import { get } from 'lodash';

import { ListContainer, ListTitle } from './style';

const NotificationsList = ({ notificationFilter, personal }) => {
  const [notificationsFilters, setNotificationsFilters] = useState(
    notificationFilter
  );

  useEffect(() => {
    setNotificationsFilters(notificationFilter);
  }, [notificationFilter]);

  const { notifications } = useNotifications(null, notificationsFilters);
  const { makeAllProviders } = useProviders();
  const { makeAllAppointments } = useAppointments();
  const [providers, setProviders] = useState([]);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    if (notifications.length) {
      setProviders(makeAllProviders());
      setAppointments(makeAllAppointments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  let newCandidateList = notifications?.filter((notif) => {
    return notif.attributes.notificationType === 'new_candidate';
  });

  let newRequestList = notifications?.filter((notif) => {
    return notif.attributes.notificationType === 'new_appointment';
  });

  let newAttentionList = notifications?.filter((notif) => {
    return notif.attributes.notificationType === 'provider_submitted_form';
  });

  return (
    <>
      {personal ? (
        <>{attentionList(newAttentionList, providers)}</>
      ) : (
        <>
          {candidateList(newCandidateList, providers)}
          {appointmentList(newRequestList, appointments)}
        </>
      )}
    </>
  );
};

const attentionList = (newAttentionList, providers) => {
  return (
    <ListContainer>
      <ListTitle>Requer Atenção</ListTitle>
      {newAttentionList.length > 0 &&
        newAttentionList.slice(0, 3).map((notification, index) => {
          const candidateId = useMemo(
            () => get(notification, 'relationships.notifiable.data.id'),
            [notification]
          );

          if (!candidateId) {
            return null;
          }

          const [candidate] = providers.filter((e) => e.id === candidateId);

          return (
            <NotificationRow
              key={`notification-${index}`}
              notification={notification}
              candidate={candidate}
            />
          );
        })}
    </ListContainer>
  );
};

const appointmentList = (newRequestList, appointments) => {
  return (
    <ListContainer>
      <ListTitle>Novos pedidos</ListTitle>
      {newRequestList.length > 0 &&
        newRequestList.slice(0, 3).map((notification, index) => {
          const appointmentId =
            notification?.relationships?.notifiable?.data?.id;

          if (!appointmentId) {
            return null;
          }
          const [appointment] = appointments.filter(
            (e) => e.id === appointmentId
          );

          return (
            <NotificationRow
              key={`notification-${index}`}
              notification={notification}
              appointment={appointment}
            />
          );
        })}
    </ListContainer>
  );
};

const candidateList = (newCandidateList, providers) => {
  return (
    <ListContainer>
      <ListTitle>Novos Candidatos</ListTitle>
      {newCandidateList.length > 0 &&
        newCandidateList.slice(0, 3).map((notification, index) => {
          const candidateId = notification?.relationships?.notifiable?.data?.id;
          if (!candidateId) {
            return null;
          }
          const [candi] = providers.filter((e) => e.id === candidateId);

          return (
            <NotificationRow
              key={`notification-${index}`}
              notification={notification}
              candidate={candi}
            />
          );
        })}
    </ListContainer>
  );
};

export default NotificationsList;
