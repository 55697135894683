import client from './client';
import { applyFilters } from 'Utils/filters';

const getAppointments = (pageNumber, filters) => {
  if (pageNumber) {
    return client.get(
      `${applyFilters(filters, 'appointments')}&page=${pageNumber}`
    );
  } else if (filters !== 'appointments') {
    return client.get(`${applyFilters(filters, 'appointments')}`);
  }
  return client.get(applyFilters({}, 'appointments'));
};

const getAppointment = id => {
  return client.get(`/appointments/${id}`);
};

const updateAppointment = (payload, appointmentId) => {
  return client.patch(`/appointments/${appointmentId}`, {
    appointment: payload
  });
};

const duplicateAppointment = (appointmentId) => {
  return client.post(`/appointments/${appointmentId}/duplicate`, {appointment: {
    appointment_id: appointmentId
  }});
};

const removeAppointmentProvider = appointmentId => {
  return client.delete(`/appointments/${appointmentId}/remove_provider`);
};

const createNewAppointment = data => {
  return client.post('/leads', data);
};

//refactorr
const fetchAppointments = (query) => {
  return client.get(`appointments/${query}`)
}

export default {
  getAppointments,
  getAppointment,
  updateAppointment,
  duplicateAppointment,
  createNewAppointment,
  removeAppointmentProvider,
  fetchAppointments,
  client
};
