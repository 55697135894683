import conversationsClient from 'Services/conversationsService';

const REDUCER = 'conversations';
export const ACTION_TYPES = {
  // show
  GET_CONVERSATION: `${REDUCER}/GET_CONVERSATION`,
  GET_CONVERSATION_SUCCESS: `${REDUCER}/GET_CONVERSATION_SUCCESS`,
  GET_CONVERSATION_FAIL: `${REDUCER}/GET_CONVERSATION_FAIL`,
};

const getConversation = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_CONVERSATION
  });
  conversationsClient
    .getConversation(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CONVERSATION_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CONVERSATION_FAIL,
        conversationError: 'Error getting conversation'
      });
    });
};

export { getConversation };
