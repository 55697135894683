import React from 'react';

import { Col } from 'Components/Layout';
import { Avatar } from 'ui-55';

import { StyledTableRow } from './style';

const TableRow = ({ item, action, clientCity }) => {
  return (
    <StyledTableRow onClick={() => action && action(item)}>
      <Col size={9}>
        {item?.attributes && (
          <Avatar
            size='medium'
            hasText={true}
            hasEmail={true}
            user={item?.attributes}
          />
        )}
      </Col>

      <Col size={3}>{clientCity && clientCity}</Col>
    </StyledTableRow>
  );
};

export default TableRow;
