import React from 'react';
import { useTranslate } from '../polyglot';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { Row, Button, Form, Heading, Icon } from 'ui-55';
import useSettings from 'Hooks/useSettings';
import styled from 'styled-components';
import { useGetLanguagesQuery } from '../../features/languages/languageSlice';
import { Body } from 'Components/Text';

export const StyledLanguageBadge = styled.div`
  padding: 6px;
  margin-bottom: 2px;
  display: inline-block;
  flex-direction: flex-start;
  align-items: center;
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  line-height: 16px;
  background: ${props => props.theme.colors.lightBeige};
  border-radius: 2px;
  width: fit-content;
  margin-right: 4px;
  cursor: pointer;

  svg {
    margin-left: 4px;
    height: 14px;
    width: 14px;
    fill: ${props => props.theme.colors.darkBlue};
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
`;

export const modalCustonStyles = {
  content: {
    padding: '40px',
    width: '760px',
    left: '320px',
    borderRadius: '16px',
    overflow: 'scroll',
  },
};
export const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;
export const StyledFormContainer = styled.div`
  form {
    h2 {
      margin: 0 !important;
    }
    //! hide guest button
    > div > div > button {
      display: none;
    }
    > div > div > div {
      width: 90%;
      input[type='address'] {
        width: calc(344px * 2);
      }
    }
    button:last-of-type {
      max-width: 172px;
      margin: 16px 200px 0px 0px;
    }
  }
  > button {
    width: 172px;
    position: relative;
    bottom: ${({ theme }) => theme.margin + 40}px;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 0px 0px 0px 10px;
`;


export const LanguageCreate = ({ isOpen, action }) => {
  const translate = useTranslate('newClients');
  const options = {
    page: 1,
    search: "",
    perPage: 10
  };

  const { data, isFetching, error } = useGetLanguagesQuery(options);

  const getActiveLanguages = (languages) => {
    if(languages === undefined || languages === null) {
      return [];
    }
    return languages.filter(language => language.attributes.active === true);
  }

  const activeLanguages = getActiveLanguages(data?.data);
  const languagesToActivate = [{ label: 'Portugês', value: 'pt' }, { label: 'English', value: 'en' }, { label: 'Español', value: 'es' }, { label: 'Français', value: 'fr' }];


  const questions = [
    {
      key: 'language',
      groupBy: 2,
      widget: 'login',
      value: 'language',
      children: [
        {
          key: 'code',
          type: 'dropdown',
          label: 'Idioma',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'language',
          options: languagesToActivate
        }
      ],
      options: [
        {
          value: 'client',
          label: 'Convidado'
        }
      ]
    }
  ];
  const handleError = errors => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
    }
  };

  return (
    <ReactModal
      appElement={document.getElementById('root')}
      style={modalCustonStyles}
      isOpen={isOpen}
    >
      <Row justify='space-between'>
        <StyledHeading size={3}>Habilitar Idioma</StyledHeading>
        <span role='presentation' onClick={() => action.cancel()}>
          <StyledIcon name='Close' />
        </span>
      </Row>
      <StyledFormContainer>
        <Card>
          {activeLanguages.length === 0 && (<Body alt='true'>Nenhuma Linguagem Habilitada</Body>)}
          {activeLanguages.map((language, index) => (
            <React.Fragment key={index}>
              <StyledLanguageBadge>
                <div
                  role='button'
                  tabIndex={-1}
                  onClick={() => { /* Ação ao clicar no idioma */ }}
                >
                  {language.attributes.name}
                </div>
              </StyledLanguageBadge>
            </React.Fragment>
          ))}
        </Card>
        {/* {languagesToActivate.length === 0 && (<><Body alt='true'>Os idiomas estão habilitados</Body><br/><br/><br/><br/><br/></>)} */}
        {languagesToActivate.length != 0 && (<Form
          questions={questions}
          submitLabel={"Habilitar Idioma"}
          onSubmit={values => action.submit(values)}
          hiddenFields={['district_parish']}
          onError={errors => handleError(errors)}
        />)}

        <Button
          text={translate('cancel')}
          btnType='borded'
          action={() => action.cancel()}
        />
      </StyledFormContainer>
    </ReactModal>
  );
};


