import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/appointments';

const useAppointment = ({ shouldFetch = false, appointmentId } = {}) => {
  const dispatch = useDispatch();
  const {
    getAppointment,
    createNewAppointment,
    updateAppointment,
    duplicateAppointment
  } = actions;
  const {
    //show
    makeGetAppointment,
    getAppointmentLoading,
    getAppointmentError,
    //create new apointment
    getCreateNewAppointmentLoading,
    getCreateNewAppointmentSuccess,
    //update
    getUpdateAppointmentLoading,
    getUpdateAppointmentSuccess,
    //duplicate
    getDuplicateAppointmentLoading,
    getDuplicateAppointmentSuccess,
    //form
    getAppointmentForm
  } = selectors;

  // SHOW
  const makeAppointment = useMemo(makeGetAppointment, []);
  const appointment = useSelector(state =>
    makeAppointment(state, appointmentId)
  );

  const appointmentLoading = useSelector(
    state => getAppointmentLoading(state),
    shallowEqual
  );

  const appointmentError = useSelector(
    state => getAppointmentError(state),
    shallowEqual
  );

  const dispatchGetAppointment = useCallback(
    id => {
      getAppointment(dispatch, id);
    },
    [dispatch, getAppointment]
  );

  useEffect(() => {
    if (shouldFetch && !appointment && appointmentId) {
      dispatchGetAppointment(appointmentId);
    }
  }, [dispatchGetAppointment, shouldFetch, appointment, appointmentId]);

  // CREATE NEW APPOINTMENT
  const dispatchCreateNewAppointment = useCallback(
    (values, cb) => {
      createNewAppointment(dispatch, values, cb);
    },
    [createNewAppointment, dispatch]
  );

  const createNewAppointmentLoading = useSelector(
    state => getCreateNewAppointmentLoading(state),
    shallowEqual
  );

  const createNewAppointmentSuccess = useSelector(
    state => getCreateNewAppointmentSuccess(state),
    shallowEqual
  );

  // UPDATE
  const dispatchUpdateAppointment = useCallback(
    (payload, id, cb) => {
      updateAppointment(dispatch, payload, id, cb);
    },
    [dispatch, updateAppointment]
  );

  const updateAppointmentLoading = useSelector(
    state => getUpdateAppointmentLoading(state),
    shallowEqual
  );
  const updateAppointmentSuccess = useSelector(
    state => getUpdateAppointmentSuccess(state),
    shallowEqual
  );

  // FORM
  const appointmentForm = useSelector(
    state => getAppointmentForm(state),
    shallowEqual
  );

  // DUPLICATE
  const dispatchDuplicateAppointment = useCallback(
    (id, cb) => {
      duplicateAppointment(dispatch, id, cb);
    },
    [dispatch, duplicateAppointment]
  );
  
  const duplicateAppointmentLoading = useSelector(
    state => getDuplicateAppointmentLoading(state),
    shallowEqual
  );
  
  const duplicateAppointmentSuccess = useSelector(
    state => getDuplicateAppointmentSuccess(state),
    shallowEqual
  );


  return {
    //show
    getAppointment: dispatchGetAppointment,
    appointment,
    appointmentError,
    appointmentLoading,
    //create new appointment
    createNewAppointment: dispatchCreateNewAppointment,
    createNewAppointmentLoading,
    createNewAppointmentSuccess,
    //update
    updateAppointment: dispatchUpdateAppointment,
    updateAppointmentLoading,
    updateAppointmentSuccess,
    duplicateAppointment: dispatchDuplicateAppointment,
    duplicateAppointmentLoading,
    duplicateAppointmentSuccess,
    //form
    appointmentForm
  };
};

export default useAppointment;
