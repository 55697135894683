import { createSlice } from "@reduxjs/toolkit";
import content from '../../content.json';

const allowedLanguages = ["fr", "en-uk", "pt", "es"];
const defaultLanguage = allowedLanguages.includes(navigator.language)
    ? navigator.language.slice(0, 2)
    : "pt";
const languageStorage = localStorage.getItem('language');


const initialState = {
    locale: languageStorage != null ? languageStorage : defaultLanguage,
    phrases: { default: content[defaultLanguage], ...content }
};

const polyglotSlice = createSlice({
    name: 'polyglot',
    initialState: initialState,
    reducers: {
        setLocale(state, action) {
            state.locale = action.payload;
            localStorage.setItem("language", action.payload);
        }
    },
});
export const selectLocale = (state) => state.polyglot.locale;
export const selectPhrases = (state) => state.polyglot.phrases;

export const { setLocale } = polyglotSlice.actions;
export default polyglotSlice.reducer;
