import servicesClient from 'Services/servicesService';

const REDUCER = 'services';
export const ACTION_TYPES = {
  GET_SERVICE: `${REDUCER}/GET_SERVICE`,
  GET_SERVICE_SUCCESS: `${REDUCER}/GET_SERVICE_SUCCESS`,
  GET_SERVICE_FAIL: `${REDUCER}/GET_SERVICE_FAIL`,
  GET_SERVICE_LISTING: `${REDUCER}/GET_SERVICE_LISTING`,
  GET_SERVICE_LISTING_SUCCESS: `${REDUCER}/GET_SERVICE_LISTING_SUCCESS`,
  GET_SERVICE_LISTING_FAIL: `${REDUCER}/GET_SERVICE_LISTING_FAIL`,
  GET_SERVICE_FORMS: `${REDUCER}/GET_SERVICE_FORMS`,
  GET_SERVICE_FORMS_SUCCESS: `${REDUCER}/GET_SERVICE_FORMS_SUCCESS`,
  GET_SERVICE_FORMS_FAIL: `${REDUCER}/GET_SERVICE_FORMS_FAIL`
};

const getServicesListing = dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_SERVICE_LISTING
  });
  servicesClient
    .getServices()
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_SERVICE_LISTING_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_SERVICE_LISTING_FAIL,
        error: 'Error getting services'
      });
    });
};

const getService = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_SERVICE
  });

  servicesClient
    .getService(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_SERVICE_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_SERVICE_FAIL,
        clientError: 'Error getching service'
      });
    });
};

const getServiceForms = dispatch => {
  servicesClient
    .getServiceForms()
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_SERVICE_FORMS_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_SERVICE_FORMS_FAIL,
        error: 'Error fetching providers forms'
      });
    });
};

export { getServicesListing, getService, getServiceForms };
