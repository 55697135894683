import styled from 'styled-components';
import { Row } from 'Components/Layout';
import { Heading } from 'Components/Text';
import { AddAssociationLink } from 'Components/ServiceDetailSidebar/style';
import theme from 'Theme';

const StyledTag = styled.div``;

export const Searchbar = styled(Row)`
  padding: 24px 0px;
  margin: 0px;
  justify-content: space-between;
  max-width: calc(100% - ${theme.margin}px);
`;

export const SearchSection = styled.div`
  input {
    height: 48px;
  }
`;
export const AppointmentContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 72vh;
    padding-bottom: ${props => props.theme.margin}px
`;

export const TagFilter = styled.div`
  display: flex;
  display: block;
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 32px;
`;

export const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  button {
    margin-right: 16px;
    margin-bottom: 8px;
    padding: 8px 16px;
  }
`;

export const NewTagLink = styled(AddAssociationLink)`
  margin: 0px 0px 8px 16px;
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px;
  display: block;
`;

// M O D A L      S T Y L E S

export const ModalStyles = {
  overlay: {
    backgroundColor: `${theme.colors.backdrop}`
  },
  content: {
    border: 'none',
    background: `${theme.colors.white}`,
    overflow: 'auto',
    // padding: '40px',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none',
    maxWidth: '760px',
    margin: '10% auto 0px',
    height: 'fit-content'
  }
};

export const ButtonContainer = styled(Row)`
  button {
    margin-right: 24px;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const SelectedTags = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const StyledTagBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  line-height: 16px;
  background: ${props => props.theme.colors.lightBeige};
  border-radius: 2px;
  width: fit-content;
  margin-right: 4px;
  cursor: pointer;

  svg {
    margin-left: 4px;
    height: 14px;
    width: 14px;
    fill: ${props => props.theme.colors.darkBlue};
  }
`;

export default StyledTag;
