import interactionsClient from 'Services/interactionsService';

const REDUCER = 'interactions';
export const ACTION_TYPES = {
  // show
  GET_INTERACTION: `${REDUCER}/GET_INTERACTION`,
  GET_INTERACTION_SUCCESS: `${REDUCER}/GET_INTERACTION_SUCCESS`,
  GET_INTERACTION_FAIL: `${REDUCER}/GET_INTERACTION_FAIL`,
  CREATE_INTERACTION: `${REDUCER}/CREATE_INTERACTION`,
  CREATE_INTERACTION_SUCCESS: `${REDUCER}/CREATE_INTERACTION_SUCCESS`,
  CREATE_INTERACTION_FAIL: `${REDUCER}/CREATE_INTERACTION_FAIL`,
};

const getInteraction = (id, dispatch) => {
  dispatch({
    type: ACTION_TYPES.GET_INTERACTION
  });
  interactionsClient
    .getInteraction(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_INTERACTION_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_INTERACTION_FAIL,
        interactionError: 'Error getting interaction'
      });
    });
};

const createInteraction = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.CREATE_INTERACTION
  });
  interactionsClient
    .createInteraction(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_INTERACTION_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_INTERACTION_FAIL,
        interactionError: 'Error getting interaction'
      });
    });
};

export { getInteraction, createInteraction };
