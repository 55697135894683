import styled from 'styled-components';
import { Col } from 'ui-55';

export const StyledFormContainer = styled(Col)`
   {
    height: 30vh;
    flex: 1;
    form {
      flex-flow: row wrap;
      > button {
        display: none;
      }
      > div {
        width: calc(50% - ${({ theme }) => theme.margin}px);
        margin-right: ${({ theme }) => theme.margin}px;
        div {
          z-index: 10;
        }
      }
    }
  }
`;
