import React from 'react';
import { useTranslate } from '../../features/polyglot';
import { useHistory, useLocation } from 'react-router-dom';
import useFilters from 'hooks/useFilters';
import useAppointments from 'hooks/appointments/useAppointments';
import Filters from 'components/Filters';
import { Pagination, Heading, Loader } from 'ui-55';
import RequestListTable from './RequestListTable';
import StyledListTab from './style';

const AVAILABLE_FILTERS = ['appointment_status', 'services', 'start_date', 'delivery_start_date'];
const MULTI_FILTERS = ['status', 'service_ids'];

const SpecialistDetailRequestList = () => {
  const t = useTranslate('specialists');
  const history = useHistory();
  const location = useLocation();

  const { active, queryString, page } = useFilters(
    location
  );

  const { appointments, loading, loaded, totalPages } = useAppointments({
    query: queryString,
    shouldFetch: true,
  });

  const handleNavigation = (pageNumber) => {
    history.push(
      `specialist-details?provider_id=${active.provider_id}&page=${parseInt(pageNumber)}`
    );
  };

  return loaded && !appointments.length ? (
    <Heading size={5}>{t('noAppointments')}</Heading>
  ) : (
    <>
      {loading ? (
        <Loader />
      ) : (
        <StyledListTab>
          <Filters availableFilters={AVAILABLE_FILTERS} multiFilters={MULTI_FILTERS} />
          <RequestListTable items={appointments} />
          <Pagination
            translate={t}
            totalPages={totalPages}
            currentPage={page}
            action={handleNavigation}
          />
        </StyledListTab>
      )}
    </>
  );
};

export default SpecialistDetailRequestList;
