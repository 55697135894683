import styled from "styled-components";
import { Heading } from 'Components/Text';

export const HeadingDetail = styled(Heading)`
  margin: 0px 0px 8px 0px;
`;

export const StyledTagBadge = styled.div`
  padding: 6px;
  margin-bottom: 2px;
  display: inline-block;
  flex-direction: flex-start;
  align-items: center;
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  line-height: 16px;
  background: ${props => props.theme.colors.lightBeige};
  border-radius: 2px;
  width: fit-content;
  margin-right: 4px;
  cursor: pointer;

  svg {
    margin-left: 4px;
    height: 14px;
    width: 14px;
    fill: ${props => props.theme.colors.darkBlue};
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
`;