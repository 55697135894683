import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';

import { Button, Row, Col } from 'ui-55';
import {
  HeadingDetail,
  ModalStyles,
  ButtonContainer,
  Container,
  SmallBodyDetail,
  BodyDetail,
  Content,
} from './styles';

const PaymentModal = ({
  isModalOpen,
  handleToggleModal,
  modalType,
  t,
  data,
  successAction,
}) => {
  const { payload, appointment } = data;


  const modalContent = {
    issueVisitInvoice: {
      title: t('issueVisitInvoice'),
      newStatus: 'Aguarda Pagamento Visita',
    },
    issueServiceInvoice: {
      title: t('issueServiceInvoice'),
      newStatus: 'Aguarda Pagamento Serviço',
    },
    resetToAnalysis: {
      title: t('resetToAnalysis'),
      newStatus: 'Análise'
    },
    voidToConcluded: {
      title: t('voidToConcluded'),
      newStatus: 'Aguarda Conclusão'
    }    
  };

  return (
    <>
      <Modal style={ModalStyles} ariaHideApp={false} isOpen={isModalOpen}>
        <Row justify='space-between' align='center'>
          <Col size={6}>
            <HeadingDetail size={3}>{t('newStatus')}</HeadingDetail>
          </Col>
          <Col padding={0}>
            <Button
              icon='Close'
              btnType='transparent'
              action={() => handleToggleModal(null)}
            />
          </Col>
        </Row>
        {/* {modalContent[modalType]?.body()} */}
        <InvoiceRender
          handleToggleModal={handleToggleModal}
          newStatus={modalContent[modalType]?.newStatus}
          t={t}
          successAction={successAction}
          payload={payload}
          appointment={appointment}
        />
      </Modal>
    </>
  );
};

const InvoiceRender = ({
  handleToggleModal,
  t,
  newStatus,
  successAction,
  payload,
  appointment,
}) => {
  const appointmentId = appointment?.id;

  const serviceTotal = (((appointment?.attributes?.totalPrice * 100) - appointment?.attributes?.amountOff) / 100).toFixed(2);
  const specialistTotal = appointment?.attributes?.providerTotalPrice?.toFixed(2);

  const email = 'Proceda ao Pagamento';

  const totalLineItems = () => {
    const lineItems = appointment?.attributes?.lineItems;
    let total = 0;

    if (lineItems) {
      Object.keys(lineItems).forEach(key => {
        if (key === 'discount') {
          total -= parseInt(lineItems[key].amountOff)
        } else {
          total += parseInt(lineItems[key].unitAmount)
        }
      })
    }
    return total;
  }
  const totalPrice = totalLineItems();

  return (
    <>
      <Row>
        <Content size={12}>
          {['Aguarda Pagamento Serviço', 'Aguarda Pagamento Visita'].includes(newStatus) &&
            <>
              <Container>
                <BodyDetail>
                  {t('moveTo')} {newStatus}
                </BodyDetail>
              </Container>

              <Container>
                <SmallBodyDetail>{t('newStatus')}</SmallBodyDetail>
                <BodyDetail>{newStatus}</BodyDetail>
              </Container>

              <Container>
                <SmallBodyDetail>{t('sendToClient')}</SmallBodyDetail>
                <BodyDetail>
                  <strong>{t('email')}</strong>: {email}
                </BodyDetail>
              </Container>

              {newStatus === 'Aguarda Pagamento Serviço' &&
                serviceTotal &&
                specialistTotal && (
                  <Container>
                    <Row>
                      <Col size={4} padding={0}>
                        <SmallBodyDetail>{t('serviceTotal')}</SmallBodyDetail>
                        <BodyDetail>
                          <strong>{(totalPrice / 100).toFixed(2)} €</strong>
                        </BodyDetail>
                        <SmallBodyDetail>{t('noTax')}</SmallBodyDetail>
                      </Col>
                      <Col size={4} padding={0}>
                        <SmallBodyDetail>{t('specialistTotal')}</SmallBodyDetail>
                        <BodyDetail>
                          <strong>{specialistTotal} €</strong>
                        </BodyDetail>
                        <SmallBodyDetail>{t('noTax')}</SmallBodyDetail>
                      </Col>
                      <Col size={4} />
                    </Row>
                  </Container>
                )}
            </>
          }

          {newStatus === 'Análise' &&
            <Container>
              <BodyDetail>{t('analysisModal')}</BodyDetail>
              <BodyDetail>{t('wishToContinue')}</BodyDetail>
            </Container>
          }

          {newStatus === 'Aguarda Conclusão' &&
            <Container>
              <BodyDetail>{t('analysisModal')}</BodyDetail>
              <BodyDetail>{t('wishToContinue')}</BodyDetail>
            </Container>
          }


        </Content>
      </Row>
      <ButtonContainer>
        <Button
          btnType='borded'
          text={t('cancel')}
          isFullWidth
          action={() => handleToggleModal()}
        />
        <Button
          btnType='primary'
          text={t('confirm')}
          isFullWidth
          action={() => successAction(payload, appointmentId)}
        />
      </ButtonContainer>
    </>
  );
};

export default PaymentModal;

PaymentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  modalType: PropTypes.oneOf([
    'issueVisitInvoice',
    'issueServiceInvoice',
    'resetToAnalysis',
    'voidToConcluded',
    null,
  ]),
  t: PropTypes.func,
  data: PropTypes.shape({
    payload: PropTypes.object,
    appointmentId: PropTypes.string,
  }),
  successAction: PropTypes.func,
};

InvoiceRender.propTypes = {
  handleToggleModal: PropTypes.func,
  t: PropTypes.func,
  newStatus: PropTypes.string,
  successAction: PropTypes.func,
  payload: PropTypes.object,
  appointmentId: PropTypes.string,
};
