import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import normalize from 'json-api-normalizer';
import queryString from 'query-string';
import {toast} from 'react-toastify';
import useStorage from 'Hooks/useStorage';
import { actions, selectors } from 'Redux/auth';

const { clearStorage, setStorageAuthToken, STORAGE_AUTH_TOKEN } = useStorage();
const useAuth = ({ token } = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loginUser, logoutUser, acceptInvitation, updateUser } = actions;
  const {
    getAuthUser,
    getLoading,
    getError,
    // accept invitation
    getAcceptInvitationLoading,
    getAcceptInvitationError,
    getAcceptInvitationSuccess
  } = selectors;

  const user = useSelector(state => getAuthUser(state), shallowEqual);
  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  // const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const dispatchLoginUser = useCallback(
    (email, password, callback) => {
      loginUser(dispatch, { email, password, callback });
    },
    [dispatch, loginUser]
  );

  const dispatchLogoutUser = useCallback(() => {
    logoutUser(dispatch, {
      callback: () => {
        clearStorage();
        history.go(0);
      }
    });
  }, [dispatch, history, logoutUser]);

  const cleanUser = user => {
    const data = normalize(user);
    return data.admin[Object.keys(data.admin)[0]];
  };

  const dispatchUpdateUser = useCallback(
    (formAnswers, cb) => {
      updateUser(dispatch, formAnswers, res => {
        setStorageAuthToken({
          ...STORAGE_AUTH_TOKEN,
          user: cleanUser(res?.data)
        });
        if (cb) {
          cb();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUser, user]
  );

  // ACCEPT INVITATION
  const dispatchAcceptInvitation = useCallback(
    payload => {
      acceptInvitation(dispatch, payload);
    },
    [dispatch, acceptInvitation]
  );

  const acceptInvitationLoading = useSelector(
    state => getAcceptInvitationLoading(state),
    shallowEqual
  );
  const acceptInvitationError = useSelector(
    state => getAcceptInvitationError(state),
    shallowEqual
  );
  const acceptInvitationSuccess = useSelector(
    state => getAcceptInvitationSuccess(state),
    shallowEqual
  );

  const redirectToDashboard = useCallback(() => history.push('/dashboard'), [
    history
  ]);

  const signInAndRedirectToDashboard = useCallback(
    async (email, pw) => {
      if (email && pw) {
        dispatchLoginUser(email, pw, {success: redirectToDashboard, fail: e => toast.error(e)});
      }
    },
    [dispatchLoginUser, redirectToDashboard]
  );

  const gotoSignInPage = useCallback(() => history.push('/'), [history]);

  useEffect(() => {
    if (!user) {
      const path = queryString.parse(history?.location?.pathname);
      if (Object.keys(path)[0] !== '/reset-password') {
        gotoSignInPage();
        clearStorage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchLogoutUser, gotoSignInPage, user]);

  return {
    user,
    error,
    loading,
    redirectToDashboard,
    signInAndRedirectToDashboard,
    dispatchLogoutUser,
    gotoSignInPage,
    updateUser: dispatchUpdateUser,
    //accept invitation
    acceptInvitation: dispatchAcceptInvitation,
    acceptInvitationLoading,
    acceptInvitationError,
    acceptInvitationSuccess
  };
};

export default useAuth;
