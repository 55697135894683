import React from 'react';
import { useTranslate } from '../../../features/polyglot';
import { useHistory } from 'react-router-dom';

import useClient from 'hooks/clients/useClient';
import useProvider from 'hooks/providers/useProvider';

import { Avatar, Col } from 'ui-55';
import StyledTableRow, { Service } from './style';

const TableRow = ({ item }) => {
  const t = useTranslate('archive');
  const history = useHistory();

  // TODO: Proptypes for the item
  const { service } = item;
  const { client } = useClient({
    clientId: item?.relationships?.client?.data?.id,
  });
  const { provider } = useProvider({
    providerId: item?.relationships?.provider?.data?.id,
  });

  const handleNavigateToClient = (clientId) =>
    history.push(`/dashboard/client-detail?client_id=${clientId}&page=1`);
  const handleNavigateToSpecialist = (specialistId) =>
    history.push(
      `/dashboard/specialist-details?provider_id=${specialistId}&page=1`
    );
  const handleNavigationToRequest = (appointmentId) => {
    const serviceId = item?.relationships?.service?.data?.id;
    history.push(`/dashboard/request-details?id=${appointmentId}&service_ids[]=${serviceId}&page=1`);
  };

  return (
    <StyledTableRow>
      <Col size={2}>
        {service && (
          <Service
            className='service'
            onClick={() => handleNavigationToRequest(item?.id)}
          >
            {service.name}
          </Service>
        )}
      </Col>

      <Col size={2}>
        {client && (
          <Avatar
            size='small'
            hasText={true}
            user={client.attributes}
            action={() => handleNavigateToClient(client?.id)}
          />
        )}
      </Col>

      <Col size={2}>
        {provider && (
          <Avatar
            size='small'
            hasText={true}
            user={provider.attributes}
            action={() => handleNavigateToSpecialist(provider.id)}
          />
        )}
      </Col>

      <Col size={2}>
        {item?.attributes?.deliveredOn &&
          new Date(item.attributes.deliveredOn).toLocaleDateString('PT-PT')}
      </Col>
      <Col size={2}>
        {item?.attributes?.totalHours ? (
          <span>
            {item.attributes.totalHours} {t('hours')}
          </span>
        ) : (
          <span></span>
        )}
      </Col>
      <Col size={2}>
        {item?.attributes?.totalPrice && (
          <span>{item?.attributes?.totalPrice?.toFixed(2)} €</span>
        )}
      </Col>
    </StyledTableRow>
  );
};

export default TableRow;
