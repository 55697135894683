import React, { useCallback, useState } from 'react';
import { useTranslate } from '../../../features/polyglot';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import useVisit from 'Hooks/useVisit';
import {
  Col,
  Row,
  Select,
  TextInput,
  Heading,
  Icon,
  Button,
  Loader,
  CheckBoxGroup
} from 'ui-55';
import { preferredHoursOptions } from 'ui-55/src/components/OfferTypeWidget/selectableOptions';
import { StyledVisitContainer, StyledInfo } from './styles';

const VisitRender = ({ appointment, isRequired, togglePaymentModal }) => {
  const t = useTranslate('requests');
  const appointmentId = appointment?.id;
  const { visits } = useSelector(state => state.entities);

  const visitId = Object.values(visits).find(
    e =>
      e?.relationships?.provider?.data?.id ===
      appointment?.relationships?.provider?.data?.id
  )?.id;

  const {
    visit,
    updateVisit,
    createVisit,
    updateVisitLoading,
    createVisitLoading
  } = useVisit({
    visitId
  });

  const providerId = appointment?.relationships?.provider?.data?.id;

  const visitDetailsDate = !visit?.attributes?.deliveryDate
    ? null
    : new Date(visit?.attributes?.deliveryDate).toISOString().split('T')[0];

  const visitDetailsHours = !visit?.attributes?.deliveryDate
    ? null
    : new Date(visit?.attributes?.deliveryDate)
        .toLocaleString()
        .split(', ')[1]
        .slice(0, 5);

  const visitDetailsIsPaid = visit?.attributes?.isPaidVisit;
  const visitDetailsIsConfirmed = visit?.attributes?.confirmed;

  const [visitDetails, setVisitDetails] = useState({
    open: Boolean(visitDetailsDate) && Boolean(visitDetailsHours),
    id: visitId,
    showButton: false,
    isDisabled:
      Boolean(visitDetailsDate) &&
      Boolean(visitDetailsHours) &&
      Boolean(visitDetailsIsConfirmed),
    confirmed: visitDetailsIsConfirmed,
    isPaidVisit: visitDetailsIsPaid,
    visitDate: { value: visitDetailsDate, error: null },
    visitTime: { value: visitDetailsHours, error: null }
  });


  const handleVisit = useCallback(() => {
    // check for date values and set Errors
    if (!visitDetails.visitDate.value) {
      return setVisitDetails({
        ...visitDetails,
        visitDate: { ...visitDetails.visitDate, error: t('requiredField') }
      });
    }
    // check for time values and set Errors
    if (!visitDetails.visitTime.value) {
      return setVisitDetails({
        ...visitDetails,
        visitTime: { ...visitDetails.visitTime, error: t('requiredField') }
      });
    }

    const createObj = {
      delivery_date: new Date(
        `${visitDetails.visitDate.value} ${visitDetails.visitTime.value}`
      ),
      appointment_id: appointmentId,
      provider_id: providerId,
      is_paid_visit: visitDetails.isPaidVisit,
      confirmed: visitDetails.confirmed
    };

    const updateObj = {
      delivery_date: new Date(
        `${visitDetails.visitDate.value} ${visitDetails.visitTime.value}`
      ),
      is_paid_visit: visitDetails.isPaidVisit,
      confirmed: visitDetails.confirmed && new Date()
    };
    visit ? updateVisit(visitId, updateObj) : createVisit(createObj);
  }, [
    appointmentId,
    createVisit,
    providerId,
    t,
    updateVisit,
    visit,
    visitDetails,
    visitId
  ]);

  const handleTogglePaymentModal = () => {
    const payload = {
      provider_id: providerId,
      visit_required: true,
      status: "awaiting_visit_payment"
    }

    togglePaymentModal('issueVisitInvoice', payload, appointment);
  }

  return (
    <>
      {isRequired || (visit && visit.attributes?.syncStatus !== 'pending_destroy')
        ? (
          <StyledVisitContainer>
            {updateVisitLoading || createVisitLoading ? (
              <Loader />
            ) : (
              <>
                <Heading size={5}> {t('visit')}</Heading>
                {!visit && (
                  <StyledInfo
                  onClick={handleTogglePaymentModal}

                  >
                    <Icon name='Plus' />
                    {t('scheduleVisit')}
                  </StyledInfo>
                )}
                {visitDetails?.open && (
                  <>
                    <Row>
                      <Col className='date-col' size={4}>
                        <TextInput
                          error={visitDetails.visitDate.error}
                          defaultValue={visitDetails.visitDate.value}
                          label={t('visitDate')}
                          disabled={visitDetails.isDisabled}
                          type='date'
                          onChange={value =>
                            setVisitDetails({
                              ...visitDetails,
                              isDisabled: false,
                              showButton: true,
                              visitDate: {
                                ...visitDetails.visitDate,
                                error: null,
                                value: value
                              }
                            })
                          }
                        />
                      </Col>
                      <Col className='time-col' size={2}>
                        <Select
                          error={visitDetails.visitTime.error}
                          defaultValue={preferredHoursOptions.find(
                            e => e.value === visitDetails.visitTime.value
                          )}
                          label={t('visitTime')}
                          isDisabled={visitDetails.isDisabled}
                          options={preferredHoursOptions}
                          onChange={opt =>
                            setVisitDetails({
                              ...visitDetails,
                              isDisabled: false,
                              showButton: true,
                              visitTime: {
                                ...visitDetails.visitTime,
                                error: null,
                                value: opt.value
                              }
                            })
                          }
                        />
                      </Col>
                      <Col size={2} />
                    </Row>
                    <Col className='button-col' size={4}>
                      {visitDetails.showButton && (
                        <Button
                          isFullWidth
                          text={t('saveVisit')}
                          btnType='borded'
                          action={handleVisit}
                        />
                      )}
                      <Row className='checkbox-row'>
                        {visitDetailsDate && visitDetailsHours && (
                          <CheckBoxGroup
                            list={[
                              {
                                value: 'confirmed',
                                question: t('confirmVisit'),
                                isSelected: visitDetails.confirmed
                              }
                            ]}
                            defaultValue={{
                              value: 'confirmed',
                              question: t('confirmVisit'),
                              isSelected: visitDetails.confirmed
                            }}
                            action={vals => {
                              setVisitDetails({
                                ...visitDetails,
                                showButton: vals[0]?.isSelected ? true : false,
                                isDisabled: vals[0]?.isSelected ? true : false,
                                confirmed: vals[0]?.isSelected
                              });
                            }}
                          />
                        )}
                      </Row>
                    </Col>
                    <Col size={4} />
                  </>
                )}
              </>
            )}
          </StyledVisitContainer>
        )
      :
        <>
          {appointment?.attributes?.status === 'analysis' &&
          providerId &&
            <StyledVisitContainer>
              <Heading size={5}> {t('visit')}</Heading>
              <StyledInfo onClick={handleTogglePaymentModal}>
                <Icon name='Plus' />
                {t('scheduleVisit')}
              </StyledInfo>
            </StyledVisitContainer>
          }
      </>
      }
    </>
  );
};

VisitRender.propTypes = {
  appointment: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string,
    relationships: PropTypes.object,
    type: PropTypes.string
  }),
  isRequired: PropTypes.bool,
  togglePaymentModal: PropTypes.func
};
export default VisitRender;
