import client from "./client";
import { applyFilters } from 'Utils/filters';

const getAdmin = (id) => {
    return client.get(`admins/${id}`)
};

const getAdmins = (pageNumber, filters) => {
  if (pageNumber) {
    return client.get(`${applyFilters(filters, 'admins')}&page=${pageNumber}`);
  } else if (filters !== 'admins') {
    return client.get(`${applyFilters(filters, 'admins')}`);
  }
  return client.get(applyFilters({}, 'admins'));
};

const inviteAdmin = (payload) => {
  return client.post(`invite`, {admin: payload});
}

const updateAdmin = (payload, id) => {
  return client.patch(`admins/${id}`, {admin: payload});
}

export default { getAdmin, getAdmins, inviteAdmin, updateAdmin, client };

