import { createSelector } from "reselect";

const selectSettingsState = state => state.settings;

export const getAllSettings = createSelector(selectSettingsState, state => {
    return state.settings || false;
});
export const getLoading = createSelector(selectSettingsState, state => {
    return state.settingsLoading || false;
});
export const getLoaded = createSelector(selectSettingsState, state => {
    return state.settingsLoaded || false;
});
export const getError = createSelector(selectSettingsState, state => {
    return state.settingsError || null;
});
