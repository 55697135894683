import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/tags';

import { buildFilterQuery } from 'Utils/filters';

const useTagsListing = ({
  pageNumber = 0,
  filters = {},
  shouldFetch = false
} = {}) => {
  const dispatch = useDispatch();
  const { getTagsListing } = actions;
  const {
    getTags,
    getLoading,
    getError,
    getLoaded,
    getLoadedQueries,
    getTotalCount
  } = selectors;

  const tags = useSelector(state =>
    getTags(state, { filter: filters, pageNumber })
  );

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const loadedQueries = useSelector(state => getLoadedQueries(state));
  const totalCount = useSelector(state => getTotalCount(state));

  const key = buildFilterQuery({ filter: filters, pageNumber }, 'tags');
  const queryState = loadedQueries[key];
  const totalCountTags = totalCount[key];

  const dispatchGetTagsListing = useCallback(
    (pageNumber, filters) => {
      getTagsListing(dispatch, pageNumber, filters);
    },
    [dispatch, getTagsListing]
  );

  useEffect(() => {
    if (shouldFetch && !queryState?.loaded && !loading) {
      getTagsListing(dispatch, pageNumber, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getTagsListing,
    pageNumber,
    filters,
    shouldFetch,
    loading,
    tags.length
  ]);

  return {
    tags,
    totalCountTags,
    getTagsListing: dispatchGetTagsListing,
    error,
    loading,
    loaded
  };
};

export default useTagsListing;
