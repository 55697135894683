import React from 'react';
import PropTypes from 'prop-types';

import useAdmin from 'Hooks/admins/useAdmin';

import {Avatar} from 'ui-55';

  const AvatarLabel = ({userId}) => {
    const { admin } = useAdmin({ adminId: userId })

    return (
      <>
        <Avatar
          hasText={true}
          user={admin?.attributes}
          size="small"
        />
      </>
    )
  }

export default AvatarLabel;

AvatarLabel.propTypes = {
  userId: PropTypes.string
}

