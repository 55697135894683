import { ACTION_TYPES } from './actions';

const initialState = {
  conversationLoading: false,
  conversationLoaded: false,
  conversationError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_CONVERSATION:
      return {
        ...state,
        conversationLoading: true
      };
    case ACTION_TYPES.GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversationLoading: false,
        conversationError: false,
        conversationLoaded: true
      };
    case ACTION_TYPES.GET_CONVERSATION_FAIL:
      return {
        ...state,
        conversationError: action.error,
        conversationLoading: false,
        conversationLoaded: true
      };

    default:
      return state;
  }
};
