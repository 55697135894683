import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'Redux/candidateLeads';

const useCandidateLead = ({ shouldFetch = false, candidateLeadId } = {}) => {
  const dispatch = useDispatch();
  const { getCandidateLead, updateCandidateLead } = actions;
  const {
    //show
    makeGetCandidateLead,
    getCandidateLeadError,
    getCandidateLeadLoading,
    getCandidateLeadLoaded,
    //update
    getUpdateCandidateLeadLoading,
    getUpdateCandidateLeadSuccess,
    getUpdateCandidateLeadFail,
    //form
    getSubmitting,
    getWasSuccessful
  } = selectors;

  // SHOW
  const makeCandidateLead = useMemo(makeGetCandidateLead, []);
  const candidateLead = useSelector(state =>
    makeCandidateLead(state, candidateLeadId)
  );

  const candidateLeadError = useSelector(
    state => getCandidateLeadError(state),
    shallowEqual
  );
  const candidateLeadLoading = useSelector(
    state => getCandidateLeadLoading(state),
    shallowEqual
  );
  const candidateLeadLoaded = useSelector(
    state => getCandidateLeadLoaded(state),
    shallowEqual
  );

  const dispatchGetCandidateLead = useCallback(
    id => {
      getCandidateLead(dispatch, id);
    },
    [dispatch, getCandidateLead]
  );

  useEffect(() => {
    if (shouldFetch && !candidateLead && candidateLeadId) {
      dispatchGetCandidateLead(candidateLeadId);
    }
  }, [dispatchGetCandidateLead, candidateLeadId, shouldFetch, candidateLead]);

  // UPDATE
  const dispatchUpdateCandidateLead = useCallback(
    (id, payload) => {
      updateCandidateLead(dispatch, id, payload);
    },
    [dispatch, updateCandidateLead]
  );

  const updateCandidateLeadLoading = useSelector(state =>
    getUpdateCandidateLeadLoading(state)
  );

  const updateCandidateLeadSuccess = useSelector(state =>
    getUpdateCandidateLeadSuccess(state)
  );

  const updateCandidateLeadFail = useSelector(state =>
    getUpdateCandidateLeadFail(state)
  );

  // FORM
  const submitting = useSelector(state => getSubmitting(state), shallowEqual);
  const wasSuccessful = useSelector(
    state => getWasSuccessful(state),
    shallowEqual
  );

  return {
    //SHOW
    candidateLead,
    candidateLeadLoaded,
    candidateLeadError,
    candidateLeadLoading,
    //UPDATE
    updateCandidateLead: dispatchUpdateCandidateLead,
    updateCandidateLeadLoading,
    updateCandidateLeadSuccess,
    updateCandidateLeadFail,
    //FORM
    submitting,
    wasSuccessful
  };
};

export default useCandidateLead;
