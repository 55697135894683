import styled from "styled-components";
import { SmallBody } from 'ui-55';

const StyledSpecialistFeed = styled.div`
  padding-bottom: 100px;
`;

export const Conversation = styled.div``;

export const InputContainer = styled.div``;

export const AvailableChars = styled(SmallBody)`
  text-align: right;
  margin-top: 4px;
  color: ${props => props.theme.colors.grey};
`;

export default StyledSpecialistFeed;