import { createSelector } from 'reselect';
import { getAppointmentsEntities } from 'redux/entities/selectors';

const selectAppointmentsState = state => state.appointments;



// INDEX
const selectAppointments = createSelector(selectAppointmentsState, state => {
  return state?.appointments || null; 
});
const selectQueries = createSelector(selectAppointmentsState, state => {
  return state?.queries || null; 
});
const selectQueryData = createSelector(
  selectAppointmentsState, 
  (state, query) => {
  return state?.queries[query] || null;
})

// SHOW
export const makeGetAppointment = () =>
  createSelector(
    getAppointmentsEntities,
    (_, appointmentId) => appointmentId,
    (entities, id) => entities[id]
  );

const getAppointmentLoading = createSelector(selectAppointmentsState, state => {
  return state.getAppointmentLoading || null;
});

const getAppointmentError = createSelector(selectAppointmentsState, state => {
  return state.getAppointmentError || null;
});

// CREATE NEW APPOINTMENT
const getCreateNewAppointmentLoading = createSelector(
  selectAppointmentsState,
  state => {
    return state.isSubmitting || false;
  }
);

const getCreateNewAppointmentSuccess = createSelector(
  selectAppointmentsState,
  state => {
    return state.wasSuccessful || false;
  }
);

// UPDATE
const getUpdateAppointmentLoading = createSelector(
  selectAppointmentsState,
  state => {
    return state.updateAppointmentLoading || null;
  }
);
const getUpdateAppointmentError = createSelector(
  selectAppointmentsState,
  state => {
    return state.updateAppointmentError || null;
  }
);
const getUpdateAppointmentSuccess = createSelector(
  selectAppointmentsState,
  state => {
    return state.updateAppointmentSuccess || null;
  }
);

// DUPLICATE
const getDuplicateAppointmentLoading = createSelector(
  selectAppointmentsState,
  state => {
    return state.duplicateAppointmentLoading || null;
  }
);
const getDuplicateAppointmentError = createSelector(
  selectAppointmentsState,
  state => {
    return state.duplicateAppointmentError || null;
  }
);
const getDuplicateAppointmentSuccess = createSelector(
  selectAppointmentsState,
  state => {
    return state.duplicateAppointmentSuccess || null;
  }
);


// PAGINATION
const getAppointmentsTotalCount = createSelector(
  selectAppointmentsState,
  state => {
    return state.appointmentsTotalCount || null;
  }
);
const getAppointmentsTotalPages = createSelector(
  selectAppointmentsState,
  state => {
    return state.appointmentsTotalPages || null;
  }
);
const getAppointmentsCurrentPage = createSelector(
  selectAppointmentsState,
  state => {
    return state.appointmentsCurrentPage || null;
  }
);
const getTotalPages = createSelector(selectAppointmentsState, state => {
  return state.totalPages || null;
});

// FORM
const getAppointmentForm = createSelector(selectAppointmentsState, state => {
  return state.appointmentForm || null;
});




export {
  // index
  selectAppointments,
  selectQueries,
  selectQueryData,
  //show
  getAppointmentLoading,
  getAppointmentError,
  //new appointment
  getCreateNewAppointmentLoading,
  getCreateNewAppointmentSuccess,
  //update
  getUpdateAppointmentLoading,
  getUpdateAppointmentError,
  getUpdateAppointmentSuccess,
  //duplicate
  getDuplicateAppointmentLoading,
  getDuplicateAppointmentError,
  getDuplicateAppointmentSuccess,
  // form
  getAppointmentForm,
  //pagination
  getAppointmentsTotalCount,
  getAppointmentsTotalPages,
  getAppointmentsCurrentPage,
  getTotalPages,
};
