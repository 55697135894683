import styled from 'styled-components';
import { SmallBody, Heading } from 'ui-55';

const StyledActivityFeed = styled.div`
  position: relative;
  height:100%;
  display: flex;
`;

export const Timeline = styled.div`
  position: absolute;
  border-right: 5px solid ${props => props.theme.colors.lightestBeige};
  min-height: calc(100vh - 450px);
  height: 100%;
  width: 20px;
`;

export const FeedContent = styled.div`
  z-index: 0;
  padding-left: 6px;
  width: 100%;
`;

export const Comment = styled.div`
  display: flex;
`;

export const AvatarContainer = styled.div`
  margin-top: 12px;
`;

export const InputContainer = styled.div`
  width: 100%;
  input {
    margin-bottom: 8px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${props => props.theme.colors.grey};
`;

export const HeadingDetail = styled(Heading)`
  margin: 40px 0px 25px 40px;
  color: ${props => props.theme.colors.grey}
`;

export default StyledActivityFeed;
