import styled from 'styled-components';

const StyledFilterBar = styled.div`
  margin-bottom: 32px;
  input {
    max-height: 48px;
  }
  button[type='plus'] {
    height: 42px;
  }
  button[type='csv-button'] {
    padding: 0px 16px;
    > svg {
      position: relative;
      right: 125px;
      bottom: 4px;
      width: 20px;
      > path {
        fill: ${(props) => props.theme.colors.brand.orange};
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Selects = styled.div`
  display: flex;

  > * {
    margin-right: 32px;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 16px 16px 0px 0px;
  }
`;

export const StyledFilterSelect = styled.span`
  color: ${(props) => props.theme.colors.darkBlue};

  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
`;

export const Flex = styled.div`
  display: flex;
  ${(props) => props.marginRight && `margin-right: 32px;`}
`;

export default StyledFilterBar;
