export default {
  appName: '55+',
  locale: 'pt',
  social: {
    facebook: 'http://facebook.com'
  },
  apiOrigin: process.env.REACT_APP_API_ORIGIN,
  sessionExpirationDays: 30,
  boards: {
    requests: {
      columns: {
        received: 'received',
        contact: 'received',
        analysis: 'analysis',
        awaiting_specialist: 'analysis',
        awaiting_visit_payment: 'awaiting_details',
        awaiting_visit: 'awaiting_details',
        awaiting_meeting: 'awaiting_details',
        awaiting_service_payment: 'awaiting_details',
        awaiting_conclusion: 'awaiting_conclusion',
        complete: 'closed',
        closed: 'closed',
        cancelled: 'cancelled',
        rejected: 'cancelled'
      }
    },
    candidates: {
      columns: {
        new_candidate: 'new_candidate',
        closed: 'closed',
        analysis: 'analysis',
        awaiting: 'awaiting_details',
        reopened: 'reopened',
        accepted: 'accepted',
        awaiting_details: 'awaiting_details',
        rejected: 'rejected'
      }
    }
  }
};
