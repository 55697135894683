import { useState, useEffect } from 'react';
import { sortFilters } from 'utils/filters';

// this is v.2.0 filter strategy, not yet in use across BO

const FILTERS = [
  // valid for query string
  'id',
  'archived',
  'admin_id',
  'status',
  'city_ids',
  'recurring',
  'service_ids',
  'start_date',
  'end_date',
  'delivery_start_date',
  'delivery_end_date',
  'active',
  'premium',
  'newsletter',
  'latitude',
  'longitude',
  'search',
  'availability',
  'kanban',
  'tag',
  'provider_id'
];

const useFilters = (location) => {
  const getPageFromLocation = (location) => {
    return parseInt(location.search.split('page=')[1]) || 1;
  };

  const [active, setActive] = useState({});
  const [queryString, setQueryString] = useState(location?.search);
  const [page, setPage] = useState(getPageFromLocation(location));

  useEffect(() => {
    if (active) {
      getQueryStringFromFilters(active);
      setPage(getPageFromLocation(location));
    }
  }, [active]);

  useEffect(() => {
    setActive(getFiltersFromQuery(location.search));
    setQueryString(location.search);
  }, [location]);

  const getFiltersFromQuery = (query) => {
    if (!query.length) return {};

    const ary = query.slice(1).split('&');
    const result = {};
    ary.forEach((q) => {
      const [k, v] = q.split('=');
      const key = k.split('[')[0];

      if (key !== 'page') {
        if (result[key]) {
          result[key].push(v)
        } else {
          result[key] = k.split('[')[1] ? [v] : v;
        }
      }
    });
    return sortFilters(result);
  };

  const getQueryStringFromFilters = (filters) => {
    const sortedFilters = sortFilters(filters);
    let query = '?';
    Object.keys(sortedFilters).forEach((key) => {
      if (FILTERS.includes(key)) {
        const value = parseValueType(sortedFilters[key]);

        if (Array.isArray(sortedFilters[key])) {
          if (Array.isArray(value)) {
            value.forEach(item => query += `${key}[]=${item}&`);
          } else {
            query += `${key}[]=${value}&`;
          }
        } else if (key === 'latitude' || key === 'longitude') {
          // do not parse string to int
          query += `${key}=${sortedFilters[key]}&`;
        } else {
          query += `${key}=${value}&`;
        }
      }
    });
    return query.slice(0, -1);
  };

  const parseValueType = (value) => {
    if (value === 'true') {
      return value;
    } else if (value === false) {
      return 'false';
    } else if (value === true) {
      return 'true';
    } else if (!isNaN(value) && !isNaN(parseFloat(value))) {
      return parseInt(value);
    } else {
      return value;
    }
  };

  return {
    active,
    queryString,
    page,
    getQueryStringFromFilters,
    getFiltersFromQuery,
    parseValueType,
  };
};

export default useFilters;
