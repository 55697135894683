import { ACTION_TYPES } from './actions';

const initialState = {
  // create
  isSubmitting: false,
  wasSuccessful: false,
  // index
  loading: false,
  loaded: false,
  error: false,
  providerContactsTotalCount: null,
  providerContactsTotalPages: null,
  providerContactsCurrentPage: null,
  totalPages: {},
  loadedQueries: {},
  // udpate
  updateProviderContactFail: false,
  updateProviderContactLoading: false,
  updateProviderContactSuccess: false,
  // show
  providerContactError: false,
  providerContactLoaded: false,
  providerContactLoading: false,
};

export default (state = initialState, action) => {
  const pages = { ...state.totalPages };

  switch (action.type) {

    // SHOW
    case ACTION_TYPES.GET_PROVIDER_CONTACT:
      return {
        ...state,
        providerContactLoading: true
      };
    case ACTION_TYPES.GET_PROVIDER_CONTACT_SUCCESS:
      return {
        ...state,
        providerContactLoading: false,
        providerContactError: false,
        providerContactLoaded: true
      };
    case ACTION_TYPES.GET_PROVIDER_CONTACT_FAIL:
      return {
        ...state,
        providerContactError: action.error,
        providerContactLoading: false,
        providerContactLoaded: true
      };

    // INDEX
    case ACTION_TYPES.GET_PROVIDER_CONTACTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case ACTION_TYPES.GET_PROVIDER_CONTACTS:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_PROVIDER_CONTACTS_SUCCESS:
      if (action.payload.meta) {
        pages[action.meta.filter] = parseInt(
          action.payload.meta['Total-Pages']
        );
      }
      return {
        ...state,
        providerContacts: action.payload.data.map(d => d.id),
        loading: false,
        loaded: true,
        [action.meta.filter
          ? action.meta.filter
          : 'providerContacts']: action.payload.data.map(d => d.id),
        providerContactsTotalCount: action.payload.meta
          ? parseInt(action.payload.meta['Total-Count'])
          : state.providerContactsTotalCount,
        providerContactsTotalPages: action.payload.meta
          ? parseInt(action.payload.meta['Total-Pages'])
          : state.providerContactsTotalPages,
        providerContactsCurrentPage: action.payload.meta
          ? parseInt(action.payload.meta['Current-Page'])
          : state.providerContactsCurrentPage,
        totalPages: action.payload.meta ? pages : state.totalPages,
        loadedQueries: {
          ...state.loadedQueries,
          [action.meta.filter && action.meta.filter]: {
            loaded: true,
            error: false
          }
        }
      };

    // CREATE NEW
    case ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.BULK_CREATE_PROVIDER_CONTACTS_FAIL:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        wasSuccessful: false
      };

    // UPDATE
    case ACTION_TYPES.UPDATE_PROVIDER_CONTACT:
      return {
        ...state,
        updateProviderContactLoading: true
      };
    case ACTION_TYPES.UPDATE_PROVIDER_CONTACT_SUCCESS:
      return {
        ...state,
        updateProviderContactLoading: false,
        updateProviderContactSuccess: true
      };
    case ACTION_TYPES.UPDATE_PROVIDER_CONTACT_FAIL:
      return {
        ...state,
        updateProviderContactLoading: false,
        updateProviderContactFail: true
      };

    default:
      return state;
  }
};
