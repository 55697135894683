import camelcase from 'camelcase-keys';
import { ACTION_TYPES } from './actions';

const initialState = {
  providers: {},
  queries: {},
  providerForms: [],
  providerLoading: false,
  providerLoaded: false,
  providerError: false,
  extraProps: {},
  updateProviderLoading: false,
  updateProviderSuccess: false,
  updateProviderFail: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // index
    case ACTION_TYPES.GET_PROVIDERS:
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: true,
            loaded: false,
            error: false,
            totalPages: null,
            data: [],
          },
        },
      };
    case ACTION_TYPES.GET_PROVIDERS_SUCCESS: {
      const data = [...camelcase(action.payload.data, { deep: true })];
      const providerIds = data?.map((e) => e.id);
      const updatedProvsObj = { ...state.providers };
      providerIds.map(
        (id) => (updatedProvsObj[id] = data.filter((e) => e.id === id)[0])
      );
      const totalPages = parseInt(action.meta?.['Total-Pages']);

      return {
        ...state,
        providers: updatedProvsObj,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: false,
            loaded: true,
            error: false,
            totalPages: totalPages,
            data: providerIds,
          },
        },
      };
    }
    case ACTION_TYPES.GET_PROVIDERS_FAIL:
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.query]: {
            loading: false,
            loaded: false,
            error: action.error,
            data: null,
          },
        },
      };

    case ACTION_TYPES.UPDATE_PROVIDER_FORM:
    case ACTION_TYPES.CREATE_PROVIDER:
      return {
        ...state,
        providerLoading: true,
      };
    case ACTION_TYPES.GET_PROVIDER_SUCCESS:
      return {
        ...state,

        providerLoading: false,
        providerForms: {
          ...state.providerForms,
          answers: action?.payload?.data?.attributes?.form_answers,
        },
        extraProps: {
          ...state.extraProps,
          [action.payload.data.id]: {
            attributes: action.payload.data?.attributes,
            finishedServicesCount:
              action.payload.data?.attributes?.finished_services_count,
            relationships: {
              candidateLead: action.payload.data?.relationships?.candidate_lead,
              providerServices:
                action.payload.data?.relationships?.provider_services,
            },
          },
        },
      };
    case ACTION_TYPES.UPDATE_PROVIDER_FORM_SUCCESS:
    case ACTION_TYPES.CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        providerForms: {
          ...state.providerForms,
          answers: action?.payload?.data?.attributes?.form_answers,
        },
        providerLoading: false,
        providerError: false,
        providerLoaded: true,
      };
    case ACTION_TYPES.GET_PROVIDER_FAIL:
    case ACTION_TYPES.CREATE_PROVIDER_FAIL:
    case ACTION_TYPES.UPDATE_PROVIDER_FORM__FAIL:
      return {
        ...state,
        providerError: action.error,
        providerLoading: false,
        providerLoaded: true,
      };

    // UPDATE
    case ACTION_TYPES.UPDATE_PROVIDER:
      return {
        ...state,
        updateProviderLoading: true,
      };
    case ACTION_TYPES.UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        updateProviderLoading: false,
        updateProviderSuccess: true,
      };
    case ACTION_TYPES.UPDATE_PROVIDER_FAIL:
      return {
        ...state,
        updateProviderLoading: false,
        updateProviderFail: true,
      };
    case ACTION_TYPES.UPDATE_PROVIDER_FORM_FAIL:
      return {
        ...state,
        updateProviderLoading: false,
        updateProviderFail: true,
      };

    default:
      return state;
  }
};
