import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { actions, selectors } from 'redux/tags';

const useTag = ({ tagId } = {}) => {
  const dispatch = useDispatch();
  const { createTag } = actions;
  const {
    // show
    makeGetTag,
    // create
    getCreateTagLoading,
    getCreateTagSuccess
  } = selectors;

  // show
  const makeTag = useMemo(makeGetTag, []);
  const tag = useSelector(state => makeTag(state, tagId));

  // create
  const dispatchCreateTag = useCallback(
    payload => {
      createTag(dispatch, payload);
    },
    [createTag, dispatch]
  );

  const createTagLoading = useSelector(
    state => getCreateTagLoading(state),
    shallowEqual
  );

  const createTagSuccess = useSelector(
    state => getCreateTagSuccess(state),
    shallowEqual
  );

  return {
    //show
    tag,
    // create
    createTag: dispatchCreateTag,
    createTagLoading,
    createTagSuccess
  };
};

export default useTag;
