export const MONTHS = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro'
}

export const getFormattedDateString = (date = new Date()) => {
  return date.toLocaleString('pt-PT').replaceAll('/', '-').split(',')[0];
}  
export const getDateFromFormatted = (string) => {
  if (!string) return null; 
  
  const split = string.split('-');
  return new Date(split[2], (split[1] - 1), split[0]);  
}