import styled from "styled-components";
import theme from 'Theme';
import { Heading } from 'Components/Text';
import { Row } from 'Components/Layout';

export const StyledMemberDetail = styled.div``;

export const ButtonContainer = styled(Row)`
  cursor: pointer;
  button {
    margin-right: 24px;
  }
`;

export const DeleteMember = styled.div`
  margin-top: 40px;
  padding: 24px;
  max-width: 356px;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  margin-right: 24px;
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px;
  display: block;
`;

export const ModalStyles = {
  overlay: {
    backgroundColor: `${theme.colors.backdrop}`
  },
  content: {
    border: 'none',
    background: `${theme.colors.white}`,
    overflow: 'inherit',
    // padding: '40px',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none',
    maxWidth: '760px',
    margin: '10% auto 0px',
    height: 'fit-content',
  }
};

export const Reassign = styled.div`
  width: 100%;
`