import teamsClient from "Services/teamsService";

import { buildFilterQuery } from 'Utils/filters';

const REDUCER = "teams";
export const ACTION_TYPES = {
  GET_TEAM: `${REDUCER}/GET_TEAM`,
  GET_TEAM_SUCCESS: `${REDUCER}/GET_TEAM_SUCCESS`,
  GET_TEAM_FAIL: `${REDUCER}/GET_TEAM_FAIL`,
  GET_TEAMS: `${REDUCER}/GET_TEAMS`,
  GET_TEAMS_SUCCESS: `${REDUCER}/GET_TEAMS_SUCCESS`,
  GET_TEAMS_FAIL: `${REDUCER}/GET_TEAMS_FAIL`,
  CREATE_TEAM: `${REDUCER}/CREATE_TEAM`,
  CREATE_TEAM_SUCCESS: `${REDUCER}/CREATE_TEAM_SUCCESS`,
  CREATE_TEAM_FAIL: `${REDUCER}/CREATE_TEAM_FAIL`,
  CLEAR_TEAM_SUCCESS: `${REDUCER}/CLEAR_TEAM_SUCCESS`,
};

// SHOW
const getTeam = (dispatch, id) => {
  dispatch({
    type: ACTION_TYPES.GET_TEAM
  });
  teamsClient
    .getTeam(id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_TEAM_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_TEAM_FAIL,
        error: "Error getting team "
      });
    });
};

// INDEX
const getTeamsListing = (dispatch, pageNumber, filter) => {
  dispatch({
    type: ACTION_TYPES.GET_TEAMS
  });
  teamsClient
    .getTeams(pageNumber, filter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_TEAMS_SUCCESS,
          payload: res.data,
          meta: {
            filter: buildFilterQuery({ filter, pageNumber }, 'teams')
          }
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_TEAMS_FAIL,
        error: 'Error getting teams '
      });
    });
};

// CREATE
const createTeam = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.CREATE_TEAM
  });
  teamsClient
    .createTeam(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.CREATE_TEAM_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.CREATE_TEAM_FAIL,
        TeamError: 'Error getting team'
      });
    });
};

export { getTeam, getTeamsListing, createTeam };
