import styled from 'styled-components';
import { Row, media } from 'Components/Layout';
import Card from 'Components/Card';

export const LoginRow = styled(Row)`
  width: 100%;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  min-width: 311px;
  padding: ${(props) => props.theme.margin * 4}px;
  a {
    margin-bottom: ${(props) => props.theme.margin}px;
    text-align: center;
    color: ${(props) => props.theme.colors.brand.orange} !important;
  }
  ${props => media.tablet(`
      padding: ${props.theme.margin * 2}px;
      min-width: unset;
      max-width: 375px;
    `)}
  ${props => media.mobile(`
      min-width: unset;
      padding: ${props.theme.margin}px;
      max-width: 275px;
    `)}
`;
