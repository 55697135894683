import React, { useState, useEffect } from 'react';
import { useTranslate } from '../../features/polyglot';

import { Col, Row, Page } from 'Components/Layout';
import { Heading } from 'Components/Text';
import { Button, TextInput, Logo, ErrorText } from 'ui-55';

import useStorage from 'Hooks/useStorage';
import useAuth from 'Hooks/useAuth';

import { LoginRow, StyledCard } from './style';

const Login = () => {
  const { user } = useStorage();
  const t = useTranslate('login');
  const [email, setEmail] = useState(user?.data?.attributes?.email);
  const [password, setPassword] = useState('');
  const {
    signInAndRedirectToDashboard,
    error,
    redirectToDashboard
  } = useAuth();

  useEffect(() => {
    if (user) {
      redirectToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page bg='orange'>
      <LoginRow justify='center'>
        <Col size={3} />
        <Col size={6} center>
          <StyledCard>
            <Logo isCenter />
            <Heading size={3} center>
              {t('login')}
            </Heading>
            <TextInput
              label={t('email')}
              defaultValue={email}
              onChange={(v) => setEmail(v)}
            />
            <TextInput
              onChange={(v) => setPassword(v)}
              label={t('password')}
              type='password'
              hasIcon
            />
            <ErrorText error={error}>{error}</ErrorText>

            <Row>
              <Col size={4} center>
                {/* <Link to='/'>{t('forgotPw')}</Link> */}
                <Button
                  type='submit'
                  text={t('login')}
                  action={() => signInAndRedirectToDashboard(email, password)}
                  isFullWidth
                />
              </Col>
            </Row>
          </StyledCard>
        </Col>
        <Col size={3} />
      </LoginRow>
    </Page>
  );
};

export default Login;
