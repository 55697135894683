import { apiSlice } from "../api/apiSlice";

const endpointUrl = "/languages";

function parseQueryParams(params) {
  const query = new URLSearchParams();

  if (params.page) {
    query.append("page", params.page.toString());
  }

  if (params.perPage) {
    query.append("per_page", params.perPage.toString());
  }

  if (params.search) {
    query.append("search", params.search);
  }

  if (params.isActive) {
    query.append("is_active", params.isActive.toString());
  }

  return query.toString();
}

function getLanguages({ page = 1, perPage = 10, search = "" }) {
  const params = { page, perPage, search, isActive: true };

  return `${endpointUrl}`;
}

function deleteLanguageMutation(language) {
  return {
    url: `${endpointUrl}/${language.id}`,
    method: "DELETE",
  };
}

function createLanguageMutation(language) {
  return { url: endpointUrl, method: "POST", body: language };
}

function updateLanguageMutation(language) {
  return {
    url: `${endpointUrl}/${language.id}`,
    method: "PUT",
    body: language,
  };
}

function getLanguage({ id }) {
  return `${endpointUrl}/${id}`;
}

const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getLanguages: query({
      query: getLanguages,
      providesTags: ["Languages"],
    }),
    getLanguage: query({
      query: getLanguage,
      providesTags: ["Languages"],
    }),
    createLanguage: mutation({
      query: createLanguageMutation,
      invalidatesTags: ["Languages"],
    }),
    deleteLanguage: mutation({
      query: deleteLanguageMutation,
      invalidatesTags: ["Languages"],
    }),
    updateLanguage: mutation({
      query: updateLanguageMutation,
      invalidatesTags: ["Languages"],
    }),
  }),
});

export const {
  useGetLanguagesQuery,
  useDeleteLanguageMutation,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  useGetLanguageQuery,
} = servicesApiSlice;
