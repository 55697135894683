import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProviderContactsEntities } from 'redux/entities/selectors';
import { actions, selectors } from 'Redux/providerContacts';

import { buildFilterQuery } from 'Utils/filters';

const useProviderContactsListing = ({
  pageNumber = 0,
  filters = {},
  shouldFetch = false,
} = {}) => {
  const dispatch = useDispatch();
  const { getProviderContactsListing } = actions;
  const {
    getProviderContacts,
    getLoading,
    getError,
    getLoaded,
    getLoadedQueries,
  } = selectors;

  const entityProviderContacts = useSelector((state) =>
    getProviderContactsEntities(state)
  );

  const getProviderContactsById = (idAry) => {
    return idAry.map((id) => entityProviderContacts[id]);
  };
  const providerContacts = useSelector((state) =>
    getProviderContacts(state, { filter: filters, pageNumber })
  );

  const error = useSelector((state) => getError(state));
  const loading = useSelector((state) => getLoading(state));
  const loaded = useSelector((state) => getLoaded(state));

  const loadedQueries = useSelector((state) => getLoadedQueries(state));

  const key = buildFilterQuery(
    { filter: filters, pageNumber },
    'providerContacts'
  );
  const queryState = loadedQueries[key];

  const dispatchGetProviderContactsListing = useCallback(
    (pageNumber, filters) => {
      getProviderContactsListing(dispatch, pageNumber, filters);
    },
    [dispatch, getProviderContactsListing]
  );

  useEffect(() => {
    if (shouldFetch && !loading && !queryState?.loaded) {
      getProviderContactsListing(dispatch, pageNumber, filters);
    }
  }, [
    dispatch,
    getProviderContactsListing,
    pageNumber,
    filters,
    shouldFetch,
    loading,
    providerContacts,
    queryState,
  ]);

  return {
    providerContacts,
    entityProviderContacts,
    getProviderContactsById,
    getPageProviderContacts: dispatchGetProviderContactsListing,
    error,
    loaded,
    loading,
  };
};

export default useProviderContactsListing;
