import client from './client';

const fetchClients = (query) => {
  return client.get(`clients/${query}`)
}

const getClient = id => {
  return client.get(`clients/${id}`);
};

const updateClient = (payload, id) => {
  return client.patch(`clients/${id}`, {
    client: payload
  });
};

const deleteClient = id => {
  return client.delete(`clients/${id}`);
};

const createClient = payload => {
  return client.post(`clients`, {
    client: payload
  });
};
export default {
  fetchClients,
  getClient,
  updateClient,
  deleteClient,
  createClient,
  client
};
