import React, { useState, useMemo } from 'react';
import { useTranslate } from '../../features/polyglot';
import { useHistory, useLocation } from 'react-router-dom';

import useFilters from 'hooks/useFilters';
import useAppointment from 'Hooks/appointments/useAppointment';
import useAppointments from 'Hooks/appointments/useAppointments';

import CONFIG from 'Config';
import {
  getStringFromStatusValue,
  isColumnValid,
} from 'Utils/appointmentStatus';

import RequestCard from 'Components/RequestCard';
import Kanban from 'Components/Kanban';
import Filters from 'Components/Filters';

import { Loader, BackofficeContainer } from 'ui-55';

const STATUS_COLUMNS = CONFIG.boards.requests.columns;
const AVAILABLE_FILTERS = [
  'admins',
  'appointment_status',
  'appointment_tag',
  'cities',
  'recurring',
  'services',
  'start_date',
  'delivery_start_date'
];
const MULTI_FILTERS = ['admin_id', 'status', 'tag', 'city_ids', 'service_ids'];

const Requests = () => {
  const t = useTranslate('requests');

  const history = useHistory();
  const location = useLocation();

  const { active, queryString } = useFilters(location);
  const { appointments, loading, loaded } = useAppointments({
    query: queryString,
    shouldFetch: true,
  });

  const {
    updateAppointment,
    updateAppointmentLoading,
    updateAppointmentSuccess,
  } = useAppointment();

  const [modalData, setModalData] = useState(null);
  const [isKanban, setIsKanban] = useState(true);

  const handleLayoutChange = () => {
    setIsKanban(!isKanban);
  };

  const handleRequestDetails = (appointment) => {
    const serviceId = appointment.relationships?.service?.data?.id;
    history.push(`/dashboard/request-details?id=${appointment.id}&service_ids[]=${serviceId}&page=1`);
  };

  const handleModalOpen = (appointmentId, destinationCol) => {
    const modalAppointment = appointments.find(
      (app) => app.id === appointmentId
    );
    setModalData({
      item: modalAppointment,
      destinationCol: destinationCol,
    });
  };

  const COLUMN_NAMES = new Set(Object.values(STATUS_COLUMNS));
  const [updatedAppointments, setUpdatedAppointments] = useState([]);

  const kanbanItems = useMemo(
    () =>
      appointments?.map((a) => ({
        ...a,
        column: STATUS_COLUMNS[a?.attributes?.status],
      })),
    [appointments]
  );

  const handleUpdate = (e, updateData) => {
    if (updateData.appointment) {
      setUpdatedAppointments(
        updatedAppointments.map((apt) => {
          if (apt.id === updateData.appointment?.id) {
            return {
              ...apt,
              attributes: {
                ...(apt?.attributes ?? {}),
                status: getStringFromStatusValue(
                  updateData.appointment?.payload?.status
                ),
              },
            };
          }
          return { ...apt };
        })
      );
      updateAppointment(
        updateData.appointment.payload,
        updateData.appointment.id,
        {
          success: () => {
            setModalData(null);
          },
        }
      );
    }
  };

  const handleCreateNewRequest = () => {
    history.push(`/dashboard/create-new-request`);
  };
  return (
    <>
      <BackofficeContainer>
        <Filters
          availableFilters={AVAILABLE_FILTERS}
          layout={isKanban}
          handleLayoutChange={handleLayoutChange}
          layoutStyle='kanban'
          hasPagination={false}
          showLayout
          hasAddButton
          addButtonAction={handleCreateNewRequest}
          hasCSVLink
          multiFilters={MULTI_FILTERS}
        />
        {loading && !loaded ? (
          <Loader></Loader>
        ) : isKanban ? (
          <Kanban
            action={(c) => handleRequestDetails(c)}
            onChangeStatus={handleModalOpen}
            isLoading={updateAppointmentLoading}
            handleModalUpdate={handleUpdate}
            updateSuccess={updateAppointmentSuccess}
            modalTitle={t('requests')}
            modalData={modalData}
            items={kanbanItems}
            colNames={[...COLUMN_NAMES]}
            hasFilters={Object.keys(active).length !== 0}
            kanbanType='requests'
            isColumnValid={isColumnValid}
          />
        ) : (
          // L I S T    V I E W
          <div>
            {appointments &&
              appointments.map((appointment, index) => {
                const listPosition =
                  index === 0
                    ? 'top'
                    : (index === appointments.length - 1 && 'bottom') ||
                    'middle';

                return (
                  <RequestCard
                    action={() => handleRequestDetails(appointment)}
                    key={'request' + index}
                    appointment={appointment}
                    listPosition={listPosition}
                  />
                );
              })}
          </div>
        )}
      </BackofficeContainer>
    </>
  );
};

export default Requests;
