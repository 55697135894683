import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext } from 'react-beautiful-dnd';

import { getValueFromStatusString } from 'utils/appointmentStatus';
import { BackofficeKanbanContainer } from 'Components/Layout';
import Modal from 'Components/Modal';
import KanbanColumn from 'Components/KanbanColumn';

const getModalTypeFromStatus = appointment => {
  const currentStatus = getValueFromStatusString(
    appointment?.attributes.status
  );

  if (appointment?.attributes.recurrent && currentStatus === 8) {
    return {
      modalType: 'kanban-3',
      title: 'titleThree',
      statusList: [8]
    };
  }

  const modalKey = [
    {
      modalType: 'kanban-0',
      title: 'titleOne',
      statusList: [0]
    },
    {
      modalType: 'kanban-1',
      title: 'titleOne',
      statusList: [1, 5, 6, 8, 9, 10, 11, 12, 3, 4, 7]
    },
    {
      modalType: 'kanban-2',
      title: 'titleOne',
      statusList: [2]
    }
  ];

  return modalKey.find(modal => modal.statusList.includes(currentStatus));
};

const Kanban = ({
  items,
  colNames,
  onChangeStatus,
  hasFilters,
  kanbanType,
  action,
  isColumnValid,
  modalTitle,
  handleModalUpdate,
  updateSuccess,
  modalData,
  isLoading
}) => {
  const COLUMNS = useRef({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [invalidCols, setInvalidCols] = useState([]);
  const handleModalClose = useCallback(() => {
    colNames.forEach(cn => {
      COLUMNS.current[cn] = [];
    });

    items.forEach(item => {
      if (!COLUMNS.current[item.column]) {
        return null;
      }
      COLUMNS.current[item.column].push(item);
    });
    setColumns(COLUMNS.current);
    setModalIsOpen(false);
  }, [colNames, items]);

  const renderModal = useCallback(
    s => {
      const modal = getModalTypeFromStatus(modalData?.item);
      if (modal) {
        return (
          <Modal
            title={modalTitle}
            data={modalData}
            isOpen={modalIsOpen}
            isLoading={isLoading}
            updateSuccess={updateSuccess}
            onClose={handleModalClose}
            onSuccess={handleModalUpdate}
            modalType={modal?.modalType}
            allowScroll={true}
          />
        );
      }
    },
    [
      handleModalClose,
      handleModalUpdate,
      isLoading,
      modalData,
      modalIsOpen,
      modalTitle,
      updateSuccess
    ]
  );
  const [columns, setColumns] = useState({});

  useEffect(() => {
    colNames.forEach(cn => {
      COLUMNS.current[cn] = [];
    });

    items.forEach(item => {
      if (!COLUMNS.current[item.column]) {
        return null;
      }
      COLUMNS.current[item.column].push(item);
    });
    setColumns(COLUMNS.current);
  }, [colNames, columns, items]);

  useEffect(() => {
    if (modalData && Object.keys(modalData).length && !modalIsOpen) {
      setModalIsOpen(true);
    } else if (!modalData || modalIsOpen || !Object.keys(modalData).length) {
      setModalIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  const handleChangeColumn = useCallback(
    params => {
      setInvalidCols([]);
      const destinationColumn = params?.destination?.droppableId;
      if (!destinationColumn) {
        return null;
      }
      const cardId = params.draggableId;
      const item = items.find(i => Number(i.id) === Number(cardId));
      const sourceColumn = params.source.droppableId;

      if (destinationColumn === sourceColumn) {
        return;
      }

      const validDestination = isColumnValid(
        item?.attributes?.status,
        destinationColumn
      );

      if (validDestination) {
        const newColumns = {
          ...columns,
          [sourceColumn]: columns[sourceColumn].filter(c => c.id !== cardId)
        };
        const destinationItem = items.find(itm => itm.id === cardId);
        newColumns[destinationColumn] = [
          {
            ...destinationItem,
            attributes: {
              ...(destinationItem?.attributes || {}),
              status: destinationColumn
            }
          },
          ...columns[destinationColumn]
        ];
        setColumns(newColumns);
        if (onChangeStatus) {
          onChangeStatus(cardId, destinationColumn);
        }
      }
    },
    [columns, isColumnValid, items, onChangeStatus]
  );
  return (
    <>
      <DragDropContext
        onDragEnd={handleChangeColumn}
        onDragStart={params => {
          const item = items.find(
            i => Number(i.id) === Number(params.draggableId)
          );
          const invalid = Object.keys(columns)?.filter(col => {
            const validDestination = isColumnValid(
              item?.attributes?.status,
              col
            );
            return params.source.droppableId === col
              ? false
              : !validDestination;
          });
          setInvalidCols(invalid);
        }}
      >
        <BackofficeKanbanContainer hasFilters={hasFilters}>
          {columns &&
            Object.keys(columns).map((key, index) => {
              return (
                <KanbanColumn
                  action={action}
                  key={'kanbanCol' + index}
                  colName={key}
                  isInvalidTarget={invalidCols.indexOf(key) !== -1}
                  items={columns[key]}
                  kanbanType={kanbanType}
                />
              );
            })}
        </BackofficeKanbanContainer>
      </DragDropContext>
      {renderModal()}
    </>
  );
};

export default Kanban;

Kanban.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      column: PropTypes.string
    })
  ),
  kanbanData: PropTypes.shape({
    admins: PropTypes.object,
    clients: PropTypes.object,
    services: PropTypes.object
  }),
  colNames: PropTypes.arrayOf(PropTypes.string),
  onChangeStatus: PropTypes.func,
  kanbanType: PropTypes.oneOf(['requests', 'candidates']),
  hasFilters: PropTypes.bool,
  action: PropTypes.func,
  isColumnValid: PropTypes.func
};
